import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MeterGroupReportBase } from '../metergroup-report.base';

import { ReportModalMeterGroupService } from '../../services/report-modal-meter-group.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportType } from '../../shared/report-type';
import { ReportingSearchParams } from '../../shared/reporting-search-params';

@Component({
  selector: 'period-metergroup-report',
  templateUrl: '../metergroup-report.base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodMetergroupReportComponent extends MeterGroupReportBase {
  protected readonly reportType = ReportType.Period;

  public constructor(
    reportModalMeterGroupService: ReportModalMeterGroupService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(reportModalMeterGroupService, facilityService, reportingSearchService, reportEventService);
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return params;
  }

}
