import { Injectable } from '@angular/core';
import { aggregateBy, AggregateDescriptor, AggregateResult } from '@progress/kendo-data-query';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { absoluteChangeKey, relativeChangeKey } from '../constants/table-report-constants';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import {
  ReportingSeries,
  ReportingSerieType,
  ReportSeriesDataPoint
} from '../shared/reporting-series';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { PeriodLabelService } from './period-label.service';
import { ReportType } from '../shared/report-type';
import { sanitizeAggregateResult } from '../shared/table-report-functions';
import { getIntervalsByResolution } from '../shared/reporting.functions';
import { isAverageCost } from '../../reportingobjects/shared/relational-value-functions';

export const measuredValueKey = 'measured';
export const normalizedValueKey = 'normalized';

type ValueKey = typeof measuredValueKey | typeof normalizedValueKey;

type QuantityKey = number;
type IndexKey = number;

export const hiddenSumSeriesTypes = [
  'consumption',
  'relatedMin',
  'relatedMax',
  'relatedAverage',
  'distribution1',
  'distribution2'
];

type ReportingGridField = `values.${QuantityKey | ReportingSerieType}.${ValueKey}.${ReportingSerieType}.${IndexKey}`;

export const supportedAggregateTypes = ['sum', 'min', 'max', 'average'] as const;
export type ReportingAggregateType = typeof supportedAggregateTypes[number];

export interface ReportingGridColumnBase<Field extends string> {
  title: string;
  field: Field;
  color: string;
  comparisonColor: string;
  showChange: boolean;
  derivedId: number;
  unitKey: ReportingUnit;
  isPercentSerie: boolean;
  isRelatedSerie: boolean;
  quantityId: Quantities;
  inspectionPeriodName?: string;
}

export type ReportingGridColumn = ReportingGridColumnBase<ReportingGridField>;

type GridDataOf<T> = Partial<Record<Quantities, {
  [normalizedValueKey]: Partial<Record<ReportingSerieType, T[]>>;
  [measuredValueKey]: Partial<Record<ReportingSerieType, T[]>>;
}>>;

export interface ReportingGridColumnGroupBase<Field extends string> {
  quantityId: Quantities | null;
  isNormalized: boolean;
  unit: string;
  series: ReportingGridColumnBase<Field>[][];
}

export type ReportingGridColumnGroup = ReportingGridColumnGroupBase<ReportingGridField>;

export interface ReportingGridData {
  values: GridDataOf<ReportSeriesDataPoint>,
  periodName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportingGridService {
  public constructor(
    private readonly periodLabelService: PeriodLabelService
  ) {
  }

  public getColumns(
    consumptionData: ReportingSeriesByFacility,
    facilityIds: number[]
  ): Record<number, ReportingGridColumnGroup[]> {
    if (!consumptionData) {
      return {};
    }
    return facilityIds.toRecord(
      fId => fId,
      fId => {
        const facilityData: ReportingGridColumnGroup[] = [];

        const data = consumptionData[fId].flatMap(
          // Temperature is shown on all charts, but data is the same; show it only once
          (serieCollection, index) => index === 0
            ? serieCollection.series
            : serieCollection.series.filter(y => y.options.serieType !== 'temperature')
        );
        /** inspectionPeriodName is used only while exporting excel to show change periods title
         * eg. 2023 -> 2024(absoulteChange title) or 2023->2024%(relativeChange title)
         */
        const inspectionPeriodName = data.filter(
          s => s.options.isInspectionPeriod && s.options.serieType === 'consumption'
        ).map(s => s.gridOptions.gridTitle)[0];

        const normalizedData = data
          .filter(serie => serie.options.isNormalized)
          .toGroupsBy(s => s.options.quantityId ?? s.options.serieType);

        const measuredData = data
          .filter(serie => !serie.options.isNormalized)
          .toGroupsBy(s => s.options.quantityId ?? s.options.serieType);

        for (const dataset of [measuredData, normalizedData]) {
          for (const [serieId, series] of dataset.entries()) {
            const groups: ReportingGridColumn[][] = [];

            const quantitySeries = series
              .filter(s => s.isShownInGrid)
              .toGroupsBy(s => s.options.serieType);

            for (const [serieType, group] of quantitySeries.entries()) {
              groups.push(group.map((serie, index) => ({
                title: serie.gridTitle,
                field: `values.${serieId}.${getValueKey(series)}.${serieType}.${index}`,
                color: serie.gridColor,
                comparisonColor: serie.gridComparisonColor,
                showChange: serie.isChangeVisible,
                derivedId: serie.chartItemOptions?.derivedId,
                unitKey: serie.options.unitKey,
                isPercentSerie: serie.options.isPercentSerie,
                isRelatedSerie: serie.isRelatedSerie,
                quantityId: serie.options.quantityId,
                inspectionPeriodName
              })));
            }

            const options = series[0].options;
            facilityData.push({
              quantityId: options.quantityId,
              isNormalized: options.isNormalized,
              unit: options.unit,
              series: groups
            });
          }
        }

        return facilityData.sortBy(group => group.quantityId ?? Number.MAX_VALUE);
      }
    );
  }

  public getData(
    consumptionData: ReportingSeriesByFacility,
    searchParams: ReportingSearchParams,
    facilityIds: number[]
  ): Record<number, ReportingGridData[]> {
    if (!consumptionData) {
      return {};
    }
    return facilityIds.toRecord(
      fId => fId,
      fId => {
        const periodIntervals = searchParams.periods
          .map(p => getIntervalsByResolution({
            start: p,
            resolution: searchParams.resolution,
            timeframe: searchParams.duration
          }));
        const groupedPeriodIntervals = getGroupedPeriodIntervals(periodIntervals);

        const serieIds = consumptionData[fId]
          .flatMap(s => s.series)
          .unique(serie => serie.options.quantityId ?? serie.options.serieType);

        return groupedPeriodIntervals.map((periods, index) => {
          const values = serieIds.toRecord(
            id => id,
            id => ({
              [measuredValueKey]: getGridValues(consumptionData[fId], id, index, false),
              [normalizedValueKey]: getGridValues(consumptionData[fId], id, index, true),
            })
          );
          const periodName = this.periodLabelService.getChartCategoryLabel({
            timestamps: periods.map(p => p?.start),
            resolution: searchParams.resolution,
            index,
            amountOfPeriods: searchParams.periods.length,
            useShortFormat: searchParams.resolution !== RequestResolution.PT15M,
            useIndexForHour: true
          });
          return {
            values,
            periodName
          };
        });
      }
    );
  }

  public getAggregates(
    facilityIds: number[],
    columnsByFacility: Record<number, ReportingGridColumnGroup[]>,
    gridData: Record<number, ReportingGridData[]>,
    reportType: ReportType,
    unsupportedAggregates: Quantities[],
    searchParams: ReportingSearchParams
  ): Record<number, AggregateResult> {
    const aggregateDescriptors = facilityIds.toRecord(
      fId => fId,
      fId => {
        const facilityColumns = columnsByFacility[fId];
        const series = facilityColumns?.flatMap(group => group.series.flat().flat()) ?? [];
        return series.flatMap(s => supportedAggregateTypes.flatMap(
          a => {
            const aggregatedValues = s.showChange
              ? ['visibleValue', relativeChangeKey, absoluteChangeKey]
              : ['visibleValue'];
            return aggregatedValues.map(
              k => ({
                field: `${s.field}.${k}`, aggregate: a
              })
            );
          }
        ));
      }
    );

    return facilityIds.toRecord(
      fId => fId,
      fId => {
        const aggregates = aggregateDescriptors[fId].some(d => d.field.endsWith(relativeChangeKey))
          ? this.calculateChangeAggregates(aggregateBy(gridData[fId],
            aggregateDescriptors[fId]), reportType)
          : aggregateBy(gridData[fId], aggregateDescriptors[fId]);
        const sanitizedAggregates = sanitizeAggregateResult(aggregates);

        return setUnsupportedAggregatesValue(sanitizedAggregates, unsupportedAggregates, searchParams);
      }
    );
  }

  public calculateChangeAggregates(aggregates: AggregateResult, reportType: ReportType): AggregateResult {
    aggregates = getAbsoluteChangeFromTotals(aggregates);

    // Sum of relative changes does not make any sense
    // Use absolute change sum of periods to calculate relative change sum
    Object.keys(aggregates).forEach(k => {
      if (k.endsWith(relativeChangeKey) && aggregates[k]?.sum && reportType !== ReportType.Trend) {
        const valuePointKey = k.substring(0, k.lastIndexOf('.'));
        const periodTotalValue = aggregates[`${valuePointKey}.visibleValue`].sum;
        const periodAbsoluteChange = aggregates[`${valuePointKey}.${absoluteChangeKey}`].sum;
        const periodRelativeChange = periodAbsoluteChange / periodTotalValue;
        aggregates[k].sum = periodRelativeChange;
      }
    });
    return aggregates;
  }
}

export function getAbsoluteChangeFromTotals(
  aggregates: AggregateResult,
  visibleValueKey: 'visibleValue' | 'value' = 'visibleValue'
): AggregateResult {
  const valueTypeKeys = Object.keys(aggregates).map(k => k.split('.').slice(0, -2).join('.')).unique();
  valueTypeKeys.forEach(k => {
    const visibleValues = Object.entries(aggregates)
      .filter(([key, _]) => key.startsWith(k) && key.endsWith(visibleValueKey));
    const currentValue = visibleValues[visibleValues.length - 1][1].sum;
    visibleValues.forEach(([key, aggs], index, array) => {
      if (index < array.length - 1) {
        const valuePointKey = key.substring(0, key.lastIndexOf('.'));
        if (aggregates[`${valuePointKey}.${absoluteChangeKey}`]) {
          const checkNull = currentValue === null && aggs.sum === null;
          aggregates[`${valuePointKey}.${absoluteChangeKey}`].sum = (!checkNull) ? currentValue - aggs.sum : null;
        }
      }
    });
  });
  return aggregates;
}

function getGridValues(
  data: ReportingSeriesCollection[],
  quantityId: Quantities | ReportingSerieType,
  index: number,
  isNormalized: boolean
): Partial<Record<ReportingSerieType, ReportSeriesDataPoint[]>> {
  const groups = data
    .filter(s => typeof quantityId !== 'number' || s.quantityId === quantityId)
    .flatMap(s => s.series)
    .filter(s => !!s.options.isNormalized === isNormalized && s.isShownInGrid)
    .toGroupsBy(s => s.options.serieType);

  const values: Partial<Record<ReportingSerieType, ReportSeriesDataPoint[]>> = {};

  for (const [serieType, group] of groups) {
    values[serieType] = group.map(s => s.values[index]);
  }

  return values;
}

function getValueKey(series: ReportingSeries[]): ValueKey {
  return series[0].options.isNormalized ? normalizedValueKey : measuredValueKey;
}

function getGroupedPeriodIntervals(periodIntervals: { start: Date, end: Date }[][]): { start: Date; end: Date; }[][] {
  const longestPeriodInterval = periodIntervals.sortBy(x => x.length).reverse()[0];
  return longestPeriodInterval.map((_, i) => periodIntervals.map(row => row[i] ?? { start: null, end: null }));
}

function setUnsupportedAggregatesValue(
  aggregates: AggregateResult,
  unsupportedAggregates: Quantities[],
  searchParams: ReportingSearchParams
): AggregateResult {
  const aggregateResult: AggregateResult = {};
  for (const [key, value] of Object.entries(aggregates)) {
    aggregateResult[key] = {};
    const [, quantityId, , serieType] = key.split('.');

    const isAverageCostSerie = isAverageCostSeries(serieType);
    const isPerCentSerie = isDistributionPerCentSerie(serieType, searchParams.distributionAsPercent);
    const isUnsupportedAggregates = isAverageQuantityAggregate(
      serieType,
      unsupportedAggregates.includes(Number(quantityId))
    );
    const isTempSeries = serieType === 'temperature';

    const isRelatedQuantitySeries = serieType.startsWith('relatedQuantityValues9');

    const hideTotals = isUnsupportedAggregates || isTempSeries
     || isPerCentSerie || isAverageCostSerie || isRelatedQuantitySeries;

    for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
      aggregateResult[key][k] = hideTotals && k === 'sum' ? null : v;
    }
  }
  return aggregateResult;
}

export function isDistributionPerCentSerie(serieType: string, isPercentSerie: boolean): boolean {
  return (serieType === 'distribution1' || serieType === 'distribution2') && isPercentSerie;
}

export function isAverageQuantityAggregate(seriesType: string, isAggregateUnsupported: boolean): boolean {
  return (isAggregateUnsupported && hiddenSumSeriesTypes.includes(seriesType)) ||
    seriesType?.startsWith('relatedQuantityValues9');
}

/** Checks if the provided string extracted from grid field name identifies an average cost series */
export function isAverageCostSeries(seriesType: string): boolean {
  if (!seriesType?.startsWith('derived')) { return false; }

  const derivedId = Number(seriesType.split('derived')[1]);
  return isAverageCost(derivedId);
}
