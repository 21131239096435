import moment from 'moment';

import templateModule from 'raw-loader!../templates/mrc-meter.html';
import { Quantities } from '@enerkey/clients/metering';

mrcMeter.$inject = [
  'RecursionHelper', 'mrcapi', 'utils', 'mrcInputValidation', 'mrcDataParser', 'MrcConstants'
];

const quantitiesToConvert = [
  Quantities.DistrictHeating,
  Quantities.DistrictCooling,
  Quantities.Steam,
  Quantities.PelletMWh,
  Quantities.NaturalGasMWh,
  Quantities.OilMWh,
  Quantities.LiquidGasMWh,
  Quantities.NaturalGasOtherMWh,
  Quantities.BiogasMWh,
  Quantities.WoodChipsMWh,
  Quantities.HeavyFuelOilMWh,
  Quantities.HeatingOwnProduction,
];

export function mrcMeter(
  RecursionHelper, mrcapi, utils, mrcInputValidation, mrcDataParser, MrcConstants
) {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      facility: "=",
      quantity: "=",
      meter: "=",
      depth: "=",
      mrcState: "=",
      commonDate: "=",
      months: "="
    },
    compile: function (element) {
      return RecursionHelper.compile(element, function (scope, iElement, iAttrs, controller, transcludeFn) {
        scope.MrcConstants = MrcConstants;
        scope.meterTypesToShowInConsumptions = [MrcConstants.METERING_TYPE.HOURLY,
                                                MrcConstants.METERING_TYPE.MANUAL_READING,
                                                MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION];

        scope.showMeter = false;
        scope.dateFormat = 'dd.MM.yyyy';
        var unitDecimalMappings = {
          kWh: 0,
          MWh: 2,
          'm³': 2,
          defaultValue: 0
        };

        scope.meter.convertValues = false;
        scope.meter.WithoutReading = false;
        scope.meter.blockingOperationInProgress = false;

        var readingValueWatcher = function() {}
        var readingDateWatcher = function() {}

        if (
          scope.mrcState.activeTab === 'mrc.consumptions'
            && angular.isDefined(scope.meter.MeteringType)
            && !hideMeterInConsumptionsView()
            && quantitiesToConvert.includes(scope.meter.QuantityId)
        ) {
          scope.meter.convertValues = true;
        }

        scope.options = {
          facilityId: scope.facility.FacilityId,
          meter: scope.meter,
          decimals: 0,
          consumptionDisplayFactor: 1 //the multiplier/divider factor to use in the UI for values. Defaults to 1 (no action)
        };

        scope.meter.tabs = {
          change: { active: false },
          info: { active: !scope.mrcState.isMobile },
          history: { active: false },
          readings: { active: false },
          intervals: { active: false },
          location: { active: false },
          consumptionsinputhistory: { active: false }
        };

        function validateInput() {
          mrcInputValidation.resetValidationErrors(scope.meter);
          mrcInputValidation.validateMeterInput(scope.meter).then(function(res) {
            if(res.success) {
              scope.facility.invalidNewReadings[scope.meter.Id] = false;
            } else {
              scope.facility.invalidNewReadings[scope.meter.Id] = true;
            }
          }, function() {
            scope.facility.invalidNewReadings[scope.meter.Id] = true;
          });
        }

        // MRC-379
        scope.onDateChange = function (event) {
          validateInput();
        };

        scope.onReadingValueChange = function() {
          validateInput();
        }

        function refresh() {
          var getOptions = {
            reportingObjectId: scope.facility.FacilityId,
            meterId: scope.meter.Id
          };
          mrcapi.getMeter(getOptions).then(
            function (updatedMeter) {
              scope.meter.Name = updatedMeter.Name;
              scope.meter.Factor = updatedMeter.Factor;
              scope.meter.Description = updatedMeter.Description;
              scope.meter.CustomerMeterIdentifier = updatedMeter.CustomerMeterIdentifier;
              scope.meter.TwoTimeMeasurement = updatedMeter.TwoTimeMeasurement;
              scope.meter.Readings = updatedMeter.Readings;
              scope.meter.Changes = updatedMeter.Changes;
              scope.meter.MeteringType = updatedMeter.MeteringType;

              updateDerivateValues();
              registerToFacility();

              scope.$broadcast('meterDataUpdated');
            },
            function () {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_UPDATING_METER_INFO', true);
            }
          ).finally(function() {
            scope.meter.blockingOperationInProgress = false;
          });
        }

        scope.refresh = refresh;

        function updateDerivateValues() {
          var newReadings;

          if (angular.isUndefined(scope.meter.Readings) || scope.meter.Readings === null || scope.meter.Readings.length === 0) {
            scope.meter.WithoutReading = true;
            newReadings = [{ Type: 1, Value: 0, newValue: null }];
            if (scope.meter.TwoTimeMeasurement) {
              newReadings.push({ Type: 2, Value: 0, newValue: null, hasError: false });
            }
          } else {
            scope.meter.WithoutReading = false;
            newReadings = angular.copy(scope.meter.Readings);
            _.each(newReadings, function (readingItem) {
              readingItem.newValue = null;
              readingItem.hasError = false;
            });
          }

          var newReadingDate = new Date();
          newReadingDate.setHours(0);
          newReadingDate.setMinutes(0);
          newReadingDate.setSeconds(0);
          newReadingDate.setMilliseconds(0);

          var newReadingItem = {
            readingDate: newReadingDate,
            readingValues: newReadings
          };

          if (scope.meter.Readings && scope.meter.Readings[0].Date) {
            var minDate = new Date(scope.meter.Readings[0].Date);
            minDate.setDate(minDate.getDate() + 1);
            minDate.setHours(0);
            minDate.setMinutes(0);
            minDate.setSeconds(0);
            minDate.setMilliseconds(0);

            newReadingItem.minDate = minDate;
          }
          if (newReadingItem.readingDate < newReadingItem.minDate) {
            newReadingItem.readingDate = angular.copy(newReadingItem.minDate);
          }

          scope.meter.newReading = newReadingItem;

          scope.meter.validationErrors = {
            Date: false
          };
        }

        function registerToFacility() {
          if(scope.mrcState.activeTab == 'mrc.readings' && scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING) {
            //init as true since there are no new reading values at this point.
            scope.facility.invalidNewReadings[scope.meter.Id] = true;
            scope.showMeter = true;
          }
          else if(scope.mrcState.activeTab == 'mrc.consumptions' && (scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION ||
                                                                     (scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING &&
                                                                      scope.mrcState.listReadingInputMeters) ||
                                                                     (scope.meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY
                                                                      && scope.mrcState.listAutomaticMeters))) {
            scope.showMeter = true;
          }
          else {
            scope.showMeter = false;
          }
          if(scope.showMeter) {
            scope.quantity.hasCumulativeMeters = true;
            scope.quantity.QuantityUnit = scope.quantity.Units.Default.Unit;
            scope.options.decimals = scope.quantity.Units.Default.DecimalsToShow;
            scope.options.consumptionDisplayFactor = quantitiesToConvert.includes(scope.quantity.QuantityId) ? 1000 : 1;

            scope.meter.QuantityId = scope.quantity.QuantityId;

            scope.$emit('registerVisibleMeter', {
              facilityId: scope.facility.FacilityId,
              meter: scope.meter
            });
          }
          else {
            scope.$emit('removeVisibleMeter', {
              facilityId: scope.facility.FacilityId,
              meter: scope.meter
            });
          }
        }

        scope.$watch('commonDate', function (newValue, oldValue) {
          if (scope.meter.newReading && scope.meter.newReading.readingDate) {
            scope.meter.newReading.readingDate = angular.copy(scope.commonDate);
            validateInput();
          }
        });

        scope.nextDepth = (scope.depth || 0) + 1;
        // http://stackoverflow.com/questions/16824853/way-to-ng-repeat-defined-number-of-times-instead-of-repeating-over-array
        scope.getNumber = function (num) {
          return new Array(num);
        };

        function calculateCurrentTimePeriod() {
          if (scope.meter.Readings && scope.meter.Readings[0]) {
            var prevRD = moment(angular.copy(scope.meter.Readings[0].Date));
            var newReadingDate = moment(angular.copy(scope.meter.newReading.readingDate));
            var timePeriod = Math.floor(newReadingDate.diff(prevRD) / (1000 * 3600 * 24));

            return timePeriod;
          } else {
            return null;
          }
        }

        function calculatePreviousTimePeriod() {
          if (scope.meter.Changes && scope.meter.Changes[0] && scope.meter.Readings && scope.meter.Readings[0]) {
            var prevRD = moment(angular.copy(scope.meter.Readings[0].Date));
            var earlierRD = moment(angular.copy(scope.meter.Changes[0].Date));
            var earlierTP = Math.floor(prevRD.diff(earlierRD) / (1000 * 3600 * 24));

            return earlierTP;
          } else {
            return null;
          }
        }

        scope.runCalculations = function () {
          // Current time period in days
          var timePeriod = calculateCurrentTimePeriod();
          // Relative consumption of the previous time range
          var earlierRelativeConsumption = scope.getPreviousConsumption();

          if (!scope.meter.TwoTimeMeasurement) {
            if (scope.meter.Readings && scope.meter.Readings[0]) {
              if (timePeriod && scope.meter.newReading.readingValues[0].newValue) {
                // Relative consumption of the current time period
                scope.consumption = ((
                  mrcDataParser.convertStringToFloat(scope.meter.newReading.readingValues[0].newValue) -
                  scope.meter.newReading.readingValues[0].Value
                ) * scope.meter.Factor) / timePeriod;
              } else {
                scope.consumption = 0;
              }

              if (scope.meter.Changes
                && scope.meter.Changes[0]
                && earlierRelativeConsumption > 0
                && scope.consumption) {
                scope.diffToPrev = ((scope.consumption - earlierRelativeConsumption) / earlierRelativeConsumption) * 100;
              } else if (scope.consumption > 0) {
                scope.diffToPrev = 100;
              } else {
                scope.diffToPrev = 0;
              }
            } else {
              scope.consumption = 0;
              scope.diffToPrev = 0;
            }
          } else {
            if (scope.meter.Readings) {
              var diffs = [];
              _.each(scope.meter.newReading.readingValues, function (item) {
                var newVal = mrcDataParser.convertStringToFloat(item.newValue);
                if(item.Value && newVal && newVal > 0) {
                  diffs.push(newVal - item.Value);
                }
              });

              if (diffs.length === scope.meter.Readings.length && timePeriod) {
                // Relative consumption of the current time period
                scope.consumption = (_.sum(diffs) * scope.meter.Factor) / timePeriod;

                if (angular.isArray(scope.meter.Changes)
                  && angular.isArray(scope.meter.Readings)
                  && scope.meter.Changes.length === scope.meter.Readings.length
                  && earlierRelativeConsumption > 0
                  && scope.consumption) {
                  scope.diffToPrev = ((scope.consumption - earlierRelativeConsumption) / earlierRelativeConsumption) * 100;
                } else {
                  scope.diffToPrev = 0;
                }
              } else {
                scope.consumption = 0;
                scope.diffToPrev = 0;
              }

            } else {
              scope.consumption = 0;
              scope.diffToPrev = 0;
            }
          }
        };

        scope.checkDifference = function () {
          if (angular.isDefined(scope.diffToPrev) && angular.isNumber(scope.diffToPrev)) {
            if (scope.diffToPrev <= -100 || scope.diffToPrev >= 100) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        };

        var meterWatcher = scope.$watch('meter', function (n, o) {
          if (!angular.isDefined(n.newReading) || n.newReading === null) {
            updateDerivateValues();
          }
          registerToFacility();
        });

        readingValueWatcher = scope.$watch('meter.newReading.readingValues', function (n, o) {
          scope.runCalculations();
        }, true);

        readingDateWatcher = scope.$watch('meter.newReading.readingDate', function (n, o) {
          scope.runCalculations();
        });

        scope.$on('$destroy', function() {
          readingValueWatcher();
          readingDateWatcher();
          meterWatcher();
        });

        // Get previous relative consumption
        scope.getPreviousConsumption = function () {
          var TP = calculatePreviousTimePeriod();

          if (TP) {
            if (!scope.meter.TwoTimeMeasurement) {
              if (scope.meter.Changes && scope.meter.Changes[0]) {
                return scope.meter.Changes[0].Value / TP;
              } else {
                return 0;
              }
            } else {
              if (angular.isArray(scope.meter.Changes)
                && angular.isArray(scope.meter.Readings)
                && scope.meter.Changes.length === scope.meter.Readings.length) {
                return _.sum(_.map(scope.meter.Changes, 'Value')) / TP;
              } else {
                return 0;
              }
            }
          } else {
            return 0;
          }
        };

        scope.previousConsumption = scope.getPreviousConsumption();

        scope.saveMeterValue = function () {
          mrcInputValidation.resetValidationErrors(scope.meter);

          mrcInputValidation.validateMeterInput(scope.meter).then(
            function (res) {
              if (res.success !== null) {
                mrcDataParser.parseNewReadings(scope.meter).then(
                  function (res) {
                    mrcapi.saveMeterValues(scope.facility.FacilityId, [res]).then(
                      function (res) {
                        utils.popUp('success', '', 'MRC.MEASUREMENTS_SAVED', true);
                        scope.facility.hasUnsavedReadings = false;
                        refresh();
                      },
                      function (err) {
                        utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_MEASUREMENTS', true);

                        // TODO: Error handling. What happens when input is rejected?
                      }
                    );
                  },
                  function (err) {
                    // wuuut!!! not possible
                  }
                );
              }
            },
            function (err) {
              console.log('Rejected', err);
            }
          );
        };

        function hideAutomaticMeter() {
          return !scope.mrcState.listAutomaticMeters && scope.meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY;
        }

        function hideReadingInputMeter() {
          return !scope.mrcState.listReadingInputMeters && scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING;
        }

        function hideMeterInConsumptionsView() {
          return !_.includes(scope.meterTypesToShowInConsumptions, scope.meter.MeteringType);
        }

        scope.meterHidden = function() {
          return (scope.mrcState.activeTab === 'mrc.readings'
                  && scope.meter.MeteringType !== MrcConstants.METERING_TYPE.MANUAL_READING) ||
              (scope.mrcState.activeTab === 'mrc.consumptions' &&
               (hideMeterInConsumptionsView() || hideReadingInputMeter() || hideAutomaticMeter()));
        };
      });
    }
  };
}
