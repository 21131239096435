import _ from 'lodash';

const $inject = [
  '$scope', '$q', 'facilities', 'meters', 'consumptions', 'utils', 'ERDataService2',
  'LoadingService', 'GridStateService', 'MetersTreeListService', 'erParamsService',
  'erReportSettingsService'
];

function MetersReportTreelistController(
  $scope, $q, facilities, meters, consumptions, utils, ERDataService2,
  LoadingService, GridStateService, MetersTreeListService, erParamsService,
  erReportSettingsService
) {

  const vm = this;

  $scope.defaultTimer = 200;
  vm.isLoading = () => LoadingService.isLoading();

  const erReportSettings = erReportSettingsService.getInstance();

  const savedData = {
    facilityMeters: null,
    metertrees: null,
    consumptions: null,
    series: null,
    payload: null,
    metertree: 'Default'
  };

  const extraColumns = MetersTreeListService.getExtraColumns();
  $scope.reportData = [];
  $scope.treelistElementPrefix = 'meterTreeList';

  const regenerateData = _.debounce(() => {
    getMeters()
      .then(result => {
        savedData.facilityMeters = result;
        return getMetertrees();
      })
      .then(result => {
        savedData.metertrees = result;
        return getConsumptions();
      })
      .then(result => {
        savedData.responseData = result;
        createTreelists();
      })
      .catch((error, title) => {
        utils.trackError(error, 'MetersReportTreelistController regenerate');
        erParamsService.handleError(error, title);
      })
    ;
  }, $scope.defaultTimer);

  function getMeterQuantities() {
    return _.uniq(_.map(getSelectedMeters(), 'QuantityId'));
  }

  function getMeters() {
    return savedData.facilityMeters ?
      $q.resolve(savedData.facilityMeters) :
      meters.getMetersForFacility(_.head($scope.params.facilityId));
  }

  function getSelectedMeters(quantityId) {
    return _.filter(
      savedData.facilityMeters,
      meter => _.includes($scope.params.meterId, meter.Id) && (quantityId ? meter.QuantityId === quantityId : true)
    );
  }

  function getFlatMetertree(quantity) {
    const result = [];

    function traverse(tree, parent, level) {
      level = level || 1;
      const hasParent = _.isObject(parent);

      _.each(tree, item => {
        const meter = _.find(savedData.facilityMeters, { Id: item.Id });
        if (meter) {
          result.push(_.extend({}, meter, {
            parentId: hasParent ? parent.Id : null,
            Level: {
              Number: level,
              Parent: hasParent ? parent.Name : quantity.Name
            }
          }));
        }
        const children = _.get(item, 'Navigation.Children');
        if (_.isArray(children)) {
          traverse(children, meter, level + 1);
        }
      });

      return result;
    }

    return traverse(_.get(getMetertree(), quantity.ID));
  }

  function getMetertree() {
    return _.get(savedData.metertrees, `MeterTrees.${savedData.metertree}`);
  }

  function getMetertrees() {
    return savedData.metertrees ?
      $q.resolve(savedData.metertrees) :
      facilities.getMetertree(_.head($scope.params.facilityId));
  }

  function getConsumptions() {
    const series = getSeries();
    const payload = getPayload(series);
    if (!payload || _.isEqual(payload, savedData.payload)) {
      return $q.resolve({});
    }
    savedData.series = series;
    savedData.payload = payload;
    return consumptions.getConsumptionsForMeters(savedData.payload);
  }

  function getSeries() {
    // meters does not want relational values
    return _.assign({}, $scope.params.series, { RelationalUnitIds: void 0 });
  }

  function getPayload(series) {
    // get all quantities determined in meters
    const quantityIds = getMeterQuantities();
    const facilityIds = $scope.params.facilityId.length ? [$scope.params.facilityId[0]] : [];

    if (erParamsService.validateSeries(series) && facilityIds.length) {
      const result = {
        Quantities: _.chain(quantityIds)
          .reduce((result, quantityId) => {
            const quantity = $scope.cache.quantitiesById[quantityId];
            if (quantity) {
              result.push({
                ID: quantityId,
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: erParamsService
                  .filterRelatedValueIdsForQuantity($scope.cache, series.RelatedValues, quantityId)
              });
            }
            return result;
          }, [])
          .value(),
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        MeterId: $scope.params.meterId,
        Unit: $scope.params.unitKey
      };

      return result;
    }
    return void 0;
  }

  function createTreelists() {
    const options = {
      idType: 'Meter',
      // series: added manually
      params: $scope.params,
      // cache: added manually
      api: {
        // requestData: added manually
        responseData: savedData.responseData
      },
      show: {
        distributionAndValue: true,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: false
      },
      grid: {
        gridOptionsProperty: 'treelistOptions',
        showCount: true,
        flatColumns: true,
        aggregates: ['sum'],
        excel: {},
        removeEmptyColumns: false,
        extraColumns: extraColumns,
        paging: false,
        extraGridOptions: {
          sortable: true,
          filterable: {
            extra: false,
            operators: {
              string: {
                contains: utils.localizedString('GRID.CONTAINS'),
                startswith: utils.localizedString('GRID.STARTS_WITH'),
                isnull: utils.localizedString('GRID.ISNULL'),
                isnotnull: utils.localizedString('GRID.ISNOTNULL')
              }
            }
          },
          columnMenu: false,
          resizable: true,
          filter: function(event) {
            syncTreelistState(event.sender);
          },
          sort: function(event) {
            syncTreelistState(event.sender);
          },
          columnHide: function(event) {
            syncTreelistState(event.sender);
          },
          columnShow: function(event) {
            syncTreelistState(event.sender);
          },
          columnReorder: function(event) {
            syncTreelistState(event.sender);
          },
          columnResize: function(event) {
            syncTreelistState(event.sender);
          }
        }
      }
    };

    _.each(getMetertree(), (quantityMetertree, quantityId) => {
      quantityId = parseInt(quantityId, 10);
      const quantity = $scope.cache.quantitiesById[quantityId];
      if (quantity && _.find(savedData.payload.Quantities, { ID: quantityId })) {
        // measured
        if (savedData.series.Measured || (savedData.series.Normalized && !quantity.Normalization)) {
          const measuredOptions = _.merge(getOptionsForQuantity(quantity, false), options);
          createTreelist(quantity, false, _.get(ERDataService2.getVisuals(measuredOptions), 'grids', {}));
        }
        // normalized
        if (savedData.series.Normalized && quantity.Normalization) {
          const normalizedOptions = _.merge(getOptionsForQuantity(quantity, true), options);
          createTreelist(quantity, true, _.get(ERDataService2.getVisuals(normalizedOptions), 'grids', {}));
        }
      }
    });
  }

  function getOptionsForQuantity(quantity, normalized) {
    const flatMetertree = getFlatMetertree(quantity);

    // select also parents
    const selectedMeterIds = $scope.params.meterId.slice(0);
    _.each(selectedMeterIds, meterId => {
      const treeItem = _.find(flatMetertree, { Id: meterId });
      if (treeItem) {
        let parentTreeItem = _.find(flatMetertree, { Id: treeItem.parentId });
        while (parentTreeItem) {
          if (!_.includes(selectedMeterIds, parentTreeItem.Id)) {
            selectedMeterIds.push(parentTreeItem.Id);
          }
          parentTreeItem = _.find(flatMetertree, { Id: parentTreeItem.parentId });
        }
      }
    });

    const selectedMeterIdsForQuantity = _.reduce(flatMetertree, (result, meter) => {
      if (_.includes(selectedMeterIds, meter.Id)) {
        result.push(meter.Id);
      }
      return result;
    }, []);

    return {
      cache: _.extend($scope.cache, { metersById: _.keyBy(flatMetertree, 'Id') }),
      api: {
        requestData: _.extend(_.cloneDeep(savedData.payload), {
          MeterId: selectedMeterIdsForQuantity,
          Quantities: _.filter(savedData.payload.Quantities, payloadQuantity => payloadQuantity.ID === quantity.ID)
        })
      },
      series: _.extend(_.cloneDeep(savedData.series), {
        Normalized: normalized,
        Measured: !normalized
      })
    };
  }

  function createTreelist(quantity, normalized, newTreelist) {
    let treelist = _.find($scope.reportData, { quantityId: quantity.ID, normalized: normalized });
    const unit = erReportSettings.getSettings().unitKey || 'Default';

    if (!treelist) {
      treelist = {
        id: `Q${quantity.ID}${normalized ? '_N' : '_V'}`,
        quantityId: quantity.ID,
        quantityKey: quantity.Key,
        normalized: normalized,
        unit: quantity.Units[unit].Unit,
        title: (normalized ? `${utils.localizedString('FACILITIES.NORMALIZED')} ` : '') + quantity.Name
      };

      $scope.reportData.push(treelist);
    }

    // extend schema
    _.merge(_.get(newTreelist, 'treelistOptions.dataSource.schema.model'), {
      expanded: true,
      id: 'Id',
      parentId: 'parentId',
      fields: {
        Factor: { type: 'number' },
        Level$Number: { type: 'number' }
      }
    });

    // load state and set new options
    loadTreelistState(newTreelist.treelistOptions, quantity.ID);
    treelist.treelistOptions = newTreelist.treelistOptions;
    treelist.rebind = !treelist.rebind;
  }

  function syncTreelistState(kendoTreelist) {
    const treelist = _.find($scope.reportData, { id: _.get(kendoTreelist, '$angular_scope.item.id') });
    if (treelist) {
      saveTreelistState(kendoTreelist, treelist.quantityId);
      loadTreelistState(treelist.treelistOptions, treelist.quantityId);
    }
  }

  function saveTreelistState(treelist, quantityId) {
    const newState = {};
    newState[quantityId] = {};
    GridStateService.saveState(treelist, void 0, newState[quantityId]);
    _.merge($scope.params.gridState, newState);
  }

  function loadTreelistState(treelistOptions, quantityId) {
    const state = _.get($scope.params.gridState, `${quantityId}.gridState`);
    // this will only merge column changes
    GridStateService.loadState(state, treelistOptions);
    // handle sort and filter
    _.extend(treelistOptions.dataSource, {
      sort: _.get(state, 'query.sort'),
      filter: _.get(state, 'query.filter')
    });
  }

  // Used when watching changes for 'facilities.paramsChanged'
  const parametersWithCallbacks = {
    meterId: onChangeMeterId,
    series: onChangeSeries,
    unitKey: onChangeUnitKey
  };

  function onChangeMeterId() {
    const includes = _.partial(_.includes, getMeterQuantities());

    _.remove($scope.reportData, reportItem => !includes(reportItem.quantityId));
  }

  function onChangeSeries() {
    const newSeries = getSeries();

    if (savedData.series.Normalized !== newSeries.Normalized || savedData.series.Measured !== newSeries.Measured) {
      $scope.reportData = [];
    } else {
      const oldPayload = getPayload(savedData.series);
      const newPayload = getPayload(newSeries);

      if (_.isEqual(newPayload, oldPayload)) {
        LoadingService.forceLoadingUntil();
        savedData.series = newSeries;
        createTreelists();
      }
    }
  }

  function onChangeUnitKey() {
    $scope.reportData = [];
  }

  vm.$onChanges = () => {
    if (!$scope.params) {
      return;
    }
    const paramsData = erParamsService.getChangedParamsConfig(vm.reportParams, $scope.params);
    $scope.params = vm.reportParams;
    const changedParams = _.without(_.get(paramsData, 'changedParams'), 'gridState', 'sections', 'view');

    if (changedParams.length || _.get(paramsData, 'forceUpdate')) {
      _.forEach(parametersWithCallbacks, (callback, paramName) => {
        if (_.includes(changedParams, paramName)) {
          return callback();
        }
      });

      regenerateData();
    }
  };

  vm.$onInit = () => {
    $scope.params = vm.reportParams;
    $scope.cache = vm.cache;
    regenerateData();
  };

  $scope.getGrid = function(id) {
    return angular.element(`#${$scope.treelistElementPrefix}${id}`).data('kendoTreeList');
  };
}

MetersReportTreelistController.$inject = $inject;

export default MetersReportTreelistController;
