import _ from 'lodash';
import moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { IAngularStatic, ITimeoutService } from 'angular';

import {
  BudgetFactorDto,
  BudgetType,
  CostFactorClient,
  CostFactorDto,
  CostFactorType
} from '@enerkey/clients/cost-factor';
import { MeterManagementMeter } from '@enerkey/clients/meter-management';

import {
  MeterCostSpreadsheetField,
  MeterSpreadsheetFieldsService
} from '../../services/meter-spreadsheet-fields-service';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';
import meterSpreadsheetConstants from '../../constants/meter-spreadsheet-constants';
import { MeterSpreadsheetService } from '../../services/meter-spreadsheet-service';
import { Factor } from './factor';
import { FieldTypeSelection } from './field-type-selection';
import { getRelevantFactors } from './factor-functions';
import { SpreadsheetFactor } from './spreadsheet-factor';
import { oleToDate } from '../../../../shared/date.functions';

declare const angular: IAngularStatic;

class MeterCostMassEditController extends MassImportSpreadsheet {
  public static override $inject = [
    'MeterSpreadsheetService',
    'MeterSpreadsheetFieldsService',
    'utils',
    '$timeout',
    '$element',
    '$window',
    'CostFactorClient',
    'KendoFunctions',
  ];

  public currentStep: WizardStep;
  public steps: WizardStep[];
  public spreadsheetElementId = 'spreadsheet';
  public fieldTypeSelections: FieldTypeSelection[];
  public selectedFieldType: FieldTypeSelection;
  public failedMeters: string[] = [];
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected messages: SpreadsheetMessages;
  protected numberOfColumns: number;
  protected spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  private sheetInitialized = false;
  private fields: MeterCostSpreadsheetField[];
  private subscription: Subscription;
  private processedIndexes: { id: number; type: 'save' | 'del' }[];
  private unchangedIndexes: number[];
  private selectedMeters: MeterManagementMeter[];
  private selectedMeterIds: number[];
  private resizeFn: (() => void) & { cancel: () => void };

  public constructor(
    private meterSpreadsheetService: MeterSpreadsheetService,
    private meterSpreadsheetFieldsService: MeterSpreadsheetFieldsService,
    private utils: any,
    protected $timeout: ITimeoutService,
    protected $element: JQuery,
    private $window: ng.IWindowService,
    private costFactorClient: CostFactorClient,
    private KendoFunctions: any
  ) {
    super();
  }

  public $onInit(): void {
    this.selectedMeterIds = this.selectedMeters.map(m => m.id);
    this.selectedMeters.forEach(meter => {
      if (meter.costFactorSourceMeterId !== null) {
        this.failedMeters.push(`${meter.name} (ID: ${meter.id}), `);
        this.selectedMeterIds.remove(meter.id);
      }
    });
    this.fieldTypeSelections = [
      {
        fieldType: CostFactorType.Consumption,
        translationKey: 'ADMIN.COST_FACTOR.CONSUMPTION_COST',
        budgetType: BudgetType.None
      },
      {
        fieldType: CostFactorType.Distribution,
        translationKey: 'ADMIN.COST_FACTOR.DISTRIBUTION_COST',
        budgetType: BudgetType.None
      },
      {
        fieldType: CostFactorType.Consumption,
        translationKey: 'ADMIN.BUDGET_FACTOR.CONSUMPTION_BUDGET_A',
        budgetType: BudgetType.BudgetA
      },
      {
        fieldType: CostFactorType.Consumption,
        translationKey: 'ADMIN.BUDGET_FACTOR.CONSUMPTION_BUDGET_B',
        budgetType: BudgetType.BudgetB
      },
      {
        fieldType: CostFactorType.Distribution,
        translationKey: 'ADMIN.BUDGET_FACTOR.DISTRIBUTION_BUDGET_A',
        budgetType: BudgetType.BudgetA
      },
      {
        fieldType: CostFactorType.Distribution,
        translationKey: 'ADMIN.BUDGET_FACTOR.DISTRIBUTION_BUDGET_B',
        budgetType: BudgetType.BudgetB
      },
    ];
    this.selectedFieldType = this.fieldTypeSelections[0];

    this.messages = {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE'),
      deletedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.DELETED'),
    };

    this.steps = [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.TYPE_SELECT'),
        isReturnable: true,
        clickFn: this.createSpreadsheet.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        buttonDisabledFn: () => !this.sheetInitialized,
        clickFn: this.saveValues.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING'),
        clickFn: this.stopSaving.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];

    this.$timeout(() => this.resizeKendo());
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    angular.element(this.$window).off('resize', this.resizeFn);
  }

  public isFirstStep(): boolean {
    return this.steps.indexOf(this.currentStep) === 0;
  }

  private createSpreadsheet(): void {
    if (Array.hasItems(this.selectedMeterIds)) {
      this.getAndSetFactors();
    } else {
      this.setSpreadsheet([]);
    }

    this.fields = this.meterSpreadsheetFieldsService.getFields(this.selectedFieldType.fieldType);
    this.numberOfColumns = this.fields.length;
    this.totalRowCount = meterSpreadsheetConstants.DEFAULT_ROW_COUNT;
  }

  private getAndSetFactors(): void {
    const observable: Observable<Factor[]> = this.selectedFieldType.budgetType
      ? this.costFactorClient.getBudgetFactors(this.selectedMeterIds)
      : this.costFactorClient.retrieveManyCostFactors(this.selectedMeterIds);

    observable
      .pipe(
        tap(factors =>
          this.setSpreadsheet(getRelevantFactors(factors, this.selectedFieldType)))
      )
      .subscribe(
        () => { },
        err => this.handleErrorForSpreadsheetGetData(err)
      );
  }

  private handleErrorForSpreadsheetGetData(result: any): void {
    const reason = result.status === 404 ? 'NOT_FOUND' : 'UNKNOWN';
    this.utils.popUp(ToasterType.ERROR, null, `ADMIN.COST_FACTOR.ERROR.${reason}`, true);
  }

  private setSpreadsheet(costFactorData: SpreadsheetFactor[]): void {
    this.meterSpreadsheetService.getCostSpreadsheetOptions(
      this.selectedFieldType.fieldType,
      costFactorData.sortByMany('meterId', 'fromDate')
    ).then(
      (result: kendo.ui.SpreadsheetOptions) => {
        this.spreadsheetOptions = result;
        this.sheetInitialized = true;
        this.spreadsheet = null;
      }
    );
  }

  private saveValues(): void {
    if (!this.validateSheet()) {
      this.utils.popUp(ToasterType.ERROR, null, 'ADMIN.METERS.ERRORS.INVALID_SPREADSHEET', true);
      this.enableSheet();
      this.setImportStep();
    } else {
      this.processedIndexes = [];
      this.unchangedIndexes = [];
      const factorUpdates: Factor[] = [];
      const factorDeletes: Factor[] = [];
      this.getDataRows().forEach((row, index) => {
        if (row.every(item => item !== 0 && !item)) {
          return;
        } else if (!row[0] || !row[1]) {
          this.unchangedIndexes.push(index);
          return; // Don't try to save rows missing meterId or fromDate, but add note to spreadsheet
        }

        const factorUpdateUnit = this.getFactorUpdateUnit(row);
        if (!Number.isFinite(row[2] as number) && !Number.isFinite(row[3] as number)) {
          factorDeletes.push(factorUpdateUnit as Factor);
          this.processedIndexes.push({ id: index, type: 'del' });
        } else {
          factorUpdates.push(factorUpdateUnit as Factor);
          this.processedIndexes.push({ id: index, type: 'save' });
        }
      });

      const observable = Array.hasItems(factorUpdates) ? this.addOrUpdateFactors(factorUpdates) : of(null);
      const delObservables = Array.hasItems(factorDeletes) ? this.deleteFactors(factorDeletes) : of(null);

      observable.pipe(
        switchMap(() => delObservables),
        finalize(() => {
          this.handleUnchanged();
          this.setFinalStep();
        })
      ).subscribe(
        () => this.handleSuccess(),
        () => this.handleError()
      );
    }
  }

  private addOrUpdateFactors(factorUpdates: Factor[]): Observable<void> {
    return this.selectedFieldType.budgetType ?
      this.costFactorClient.bulkAddOrUpdateBudgetFactors(factorUpdates as BudgetFactorDto[]) :
      this.costFactorClient.bulkAddCostFactors(factorUpdates as CostFactorDto[]);
  }

  private deleteFactors(factorDeletes: Factor[]): Observable<void> {
    return this.selectedFieldType.budgetType ?
      this.costFactorClient.deleteBudgetFactors(factorDeletes as BudgetFactorDto[]) :
      this.costFactorClient.deleteCostFactors(factorDeletes as CostFactorDto[]);
  }

  private getFactorUpdateUnit(row: (number | string)[]): Partial<Factor> {
    return this.fields.reduce(
      (result: Partial<BudgetFactorDto>, { value }, fIndex) => {
        switch (value) {
          case 'status':
            return result;
          case 'fromDate':
            return { ...result, fromDate: moment.utc(oleToDate(row[fIndex] as number)) };
          case 'monthlyFeePerDay':
          case 'price':
            return { ...result, [value]: Number.isFinite(row[fIndex] as number) ? row[fIndex].toString() : '' };
          default:
            return { ...result, [value]: row[fIndex] };
        }
      },
      this.selectedFieldType.budgetType ?
        { costFactorType: this.selectedFieldType.fieldType, budgetType: this.selectedFieldType.budgetType } :
        { costFactorType: this.selectedFieldType.fieldType }
    );
  }

  private handleUnchanged(): void {
    this.unchangedIndexes.forEach(id => this.setNoChanges(id));
  }

  private handleSuccess(): void {
    this.processedIndexes.forEach(
      status => {
        if (status.type === 'save') {
          this.setSuccessStatus(status.id);
        } else if (status.type === 'del') {
          this.setDeleteSuccessStatus(status.id);
        }
      }
    );
    this.utils.popUp(ToasterType.SUCCESS, null, 'ADMIN.COST_FACTOR.SUCCESS', true);
  }

  private handleError(): void {
    this.processedIndexes.forEach(status => this.setErrorStatus(status.id));
    this.utils.popUp(ToasterType.ERROR, null, 'ADMIN.COST_FACTOR.ERROR.SAVE', true);
  }

  private setImportStep(): void {
    this.$timeout(() => {
      this.currentStep = this.steps[1];
    });
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private stopSaving(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.processedIndexes.forEach(value => this.setCanceledStatus(value.id));
    }
  }

  private resizeKendo(): void {
    const resizeFn = (): void => this.KendoFunctions.resizeKendoComponent(this.spreadsheet, '.k-spreadsheet');
    this.spreadsheet = this.getSpreadsheetInstance();
    this.resizeFn = _.debounce(resizeFn, 200);
    angular.element(this.$window).on('resize', this.resizeFn);
    this.$timeout(resizeFn);
  }
}

export default MeterCostMassEditController;
