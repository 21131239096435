import { isObject, keyBy } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import {
  DistributionTypev2,
  ErrorCode,
  FacilityInformationItem,
  QuantityItem,
  RelatedValue,
  SpecificConsumptionConfiguration,
} from '@enerkey/clients/energy-reporting';
import { $InjectArgs } from '@enerkey/ts-utils';

import { ConsumptionTargetType } from '../../energy-management/constants/consumption-target-constant';
import {
  FacilitiesProperties,
  FacilityColumnsProperties,
} from '../../../shared/interfaces/facility-columns-properties';
import { RelationalValuesService } from '../../../shared/services/relational-values.service';
import { LegacyFacilityService } from '../../reportingobjects/models/facilities';

interface ErCache {
  facilitiesById: Record<number, FacilityInformationItem>;
  facilitiesProperties: readonly FacilityColumnsProperties[];
  metersById: Record<keyof unknown, unknown>;
  quantitiesById: Record<number, QuantityItem>;
  relationalValuesById: Record<number, SpecificConsumptionConfiguration>;
  distributionTypesById: Record<number, DistributionTypev2>;
  relatedValuesByQuantityId: Record<number, RelatedValue[]>;
  errorCodesByCode: Record<number, ErrorCode>;
  forecastTypesById: Record<number, unknown>;
  targetSeriesType: Record<number, ConsumptionTargetType>;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

class ErCacheService {

  protected static readonly $inject: $InjectArgs<typeof ErCacheService> = [
    '$q',
    'configurationApi',
    'ConsumptionTargetSeriesTypes',
    'facilities',
    'forecastTypes',
    'relationalValuesService'
  ];

  public constructor(
    private readonly $q: ng.IQService,
    private readonly configurationApi: any,
    private readonly ConsumptionTargetSeriesTypes: any,
    private readonly facilities: LegacyFacilityService,
    private readonly forecastTypes: any,
    private readonly relationalValuesService: RelationalValuesService
  ) { }

  public getCache(): ng.IPromise<ErCache> {
    const deferred = this.$q.defer<ErCache>();

    const cache: ErCache = {
      facilitiesById: {},
      facilitiesProperties: [],
      metersById: {},
      quantitiesById: {},
      relationalValuesById: {},
      distributionTypesById: {},
      relatedValuesByQuantityId: {},
      errorCodesByCode: {},
      forecastTypesById: keyBy(this.forecastTypes.getForecastTypes(), 'Id'),
      targetSeriesType: keyBy(this.ConsumptionTargetSeriesTypes.get(), 'Id')
    };

    this.$q.all([
      this.getFacilitiesProperties(cache),
      this.getFacilities(cache),
      this.getQuantities(cache),
      this.getRelationalValues(cache),
      this.getDistributionTypes(cache),
      this.getRelatedValues(cache),
      this.getErrorCodes(cache)
    ]).then(() => {
      deferred.resolve(cache);
    });

    return deferred.promise;
  }

  private getFacilities(cache: ErCache): ng.IPromise<void> {
    return this.facilities.getFacilitiesInformation()
      .then((result: {}[]) => {
        cache.facilitiesById = keyBy(result, 'FacilityId');
      })
    ;
  }

  private getFacilitiesProperties(cache: ErCache): ng.IPromise<void> {
    return this.facilities.getFacilityGroupingDataForCurrentProfile()
      .then((result: FacilitiesProperties) => {
        cache.facilitiesProperties = isObject(result) ? result.Groups : [];
      });
  }

  private getQuantities(cache: ErCache): ng.IPromise<void> {
    return this.configurationApi.quantities.getQuantities()
      .then((result: {}[]) => {
        cache.quantitiesById = keyBy(result, 'ID');
      });
  }

  private getRelationalValues(cache: ErCache): ng.IPromise<void> {
    return firstValueFrom(this.relationalValuesService.relationalValues$.pipe(
      take(1)
    )).then(result => {
      cache.relationalValuesById = keyBy(result, 'Id');
    });
  }

  private getDistributionTypes(cache: ErCache): ng.IPromise<void> {
    return this.configurationApi.distributionTypes.getDistributionTypes()
      .then((result: {}[]) => {
        cache.distributionTypesById = keyBy(result, 'Id');
      });
  }

  private getRelatedValues(cache: ErCache): ng.IPromise<void> {
    return this.configurationApi.relatedValues.getRelatedValues()
      .then((result: { [key: number]: {}[] }) => {
        cache.relatedValuesByQuantityId = result;
      });
  }

  private getErrorCodes(cache: ErCache): ng.IPromise<void> {
    return this.configurationApi.errorCodes.getErrorCodes()
      .then((result: {}[]) => {
        cache.errorCodesByCode = keyBy(result, 'Code');
      });
  }
}

export { ErCacheService };
export default ErCacheService;
