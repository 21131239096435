import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { map, Observable } from 'rxjs';

import { NgfTabChangeEvent, NgfTabsetComponent } from '@enerkey/foundation-angular';

import { ReportModalService } from '../../services/report-modal.service';
import { ProfileService } from '../../../../shared/services/profile.service';
import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { ReportingTab } from '../../models/reporting-tab.model';

@Component({
  selector: 'modal-report-type',
  templateUrl: './modal-report-type.component.html',
  styleUrls: ['./modal-report-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalReportTypeComponent implements AfterViewInit {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public readonly ReportType = ReportingTab;

  public readonly reportType$: Observable<ReportingTab>;

  public readonly isMeterGroupEnabled$: Observable<boolean>;

  public constructor(
    private readonly reportModalService: ReportModalService,
    profileService: ProfileService,
    userService: UserService
  ) {
    this.reportType$ = reportModalService.reportingTab$;
    this.isMeterGroupEnabled$ = profileService.profile$.pipe(
      map(() => userService.hasService(Service.MeterGroups))
    );
  }

  public ngAfterViewInit(): void {
    this.tabset.tabChange.subscribe({
      next: (tab: NgfTabChangeEvent) => {
        this.reportModalService.changeReportingTab(tab.nextId as ReportingTab);
      }
    });
  }
}
