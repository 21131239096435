import { Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { ReportModalService } from './report-modal.service';
import { ReportTypeOptionsService } from './report-type-options.service';
import { ReportingTab } from '../models/reporting-tab.model';

@Injectable()
export class ReportTypeOptionsModalService extends ReportTypeOptionsService {
  public constructor(
    reportModalService: ReportModalService
  ) {
    const reportType$ = combineLatest({
      reportingTab: reportModalService.reportingTab$,
      reportType: reportModalService.reportType$,
      meterReportType: reportModalService.meterReportType$,
      meterGroupReportType: reportModalService.meterGroupReportType$
    }).pipe(
      map(({ reportingTab, reportType, meterReportType, meterGroupReportType }) => {
        switch (reportingTab) {
          case ReportingTab.Quantity: return reportType;
          case ReportingTab.Meter: return meterReportType;
          case ReportingTab.MeterGroup: return meterGroupReportType;
          default: throw new Error(`Unknown reportingTab "${reportingTab}"`);
        }
      })
    );
    super(reportType$);
  }
}
