/* eslint-disable @typescript-eslint/no-explicit-any */
import angular, { ITimeoutService } from 'angular';
import { from, Observable, of, Subscription } from 'rxjs';
import _ from 'lodash';
import { catchError, concatMap, map } from 'rxjs/operators';

import CcMassImportConfirmationTemplate from 'raw-loader!./cc-mass-import-confirmation-modal.html';

import { ConfigurationControlClient, ConfigurationCreate, LegacyMapping, LegacyMappingRequest, Terminal }
  from '@enerkey/clients/configuration-control';

import { getNumericEnumValues } from '@enerkey/ts-utils';

import { ColumnIndex } from '../../constants/column-indices';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';

import CcMassImportConfirmationController from './cc-mass-import-confirmation-modal-controller';
import SpreadsheetFormat from './cc-mass-import-format';
import { indexToColumnCharacter } from '../../../../shared/spreadsheet.functions';
import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';
import { PropertyService } from '../../services/property-service';

const inject = [
  'utils', '$element', '$window', '$modal', 'KendoFunctions', '$filter', '$timeout',
  'PropertyService', 'ccSpreadsheetService', 'ConfigurationControlClient'
];

class CCMassImportController {
  public readerTypeId: number;
  public groupIdentifier: string;
  public cronExpression: string;
  public currentStep: WizardStep;
  private spreadsheet: kendo.ui.Spreadsheet;
  private rowCount = 200;
  private lastColumnIndex = 0;
  private readerBaseProperties: any[]; // Contains only those properties in columns
  private readerSpecificProperties: any[];
  private isConfigurationSavingErrors = false;
  private isTerminalSavingErrors = false;
  private firstEmptyRowIndex = 1;
  private steps: WizardStep[] = [];

  private maxInt = 2147483647;
  private terminalIndexRangeBegin: number;
  private reservedColumnIndices = getNumericEnumValues(ColumnIndex);
  private resizeFn: (() => void) & { cancel: () => void };
  private subscription: Subscription;
  private isUndefinedTerminals: boolean;
  private existingTerminals: Terminal[] = [];

  public constructor(
    private utils: any,
    private $element: any,
    private $window: any,
    private $modal: any,
    private KendoFunctions: any,
    private $filter: any,
    protected $timeout: ITimeoutService,
    private propertyService: PropertyService,
    private ccSpreadsheetService: any,
    private configurationControlClient: ConfigurationControlClient
  ) {
  }

  public $onInit(): void {
    this.steps = this.getImportSteps();
    this.currentStep = this.steps[0];
    this.subscription = this.configurationControlClient.getSchema(this.readerTypeId)
      .subscribe(
        response => this.handleInitialization(response),
        () => this.showErrorPopup('CONFIGURATION_CONTROL.ERROR_TEXT_GET_SCHEMA_FAIL')
      );
  }

  public $onDestroy(): void {
    this.unsubscribe();
    if (this.spreadsheet) {
      this.spreadsheet.destroy();
    }
    removeSpreadsheetOrphans();
    angular.element(this.$window).off('resize', this.resizeFn);
  }

  private getImportSteps(): WizardStep[] {
    const steps =
    [
      {
        text: this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.IMPORT'),
        isReturnable: true,
        clickFn: this.fillMeterIds.bind(this)
      },
      {
        text: this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ADD_METER_ID'),
        isReturnable: true,
        clickFn: this.saveConfigurations.bind(this)
      },
      {
        text: this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.SAVE'),
        isReturnable: true,
        clickFn: this.handleTerminals.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.TERMINAL.ADD_METERS_TO_TERMINAL'),
        isReturnable: true,
        clickFn: null
      },
      {
        text: this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.READY'),
        hideButton: true
      }
    ];
    return steps;
  }

  private handleInitialization(schema: string): void {
    const readerBaseProperties = this.propertyService.getReaderBaseProperties(schema);
    this.readerBaseProperties = this.sortBaseProperties(readerBaseProperties);
    this.readerSpecificProperties = this.propertyService.getReaderSpecificProperties(schema);
    this.createColumnIndices();
    this.createSpreadsheet();
  }

  private createSpreadsheet(): void {
    const element = this.$element.find('#spreadsheet');
    if (this.spreadsheet) {
      this.spreadsheet.destroy();
      element.empty();
    }
    const spreadsheetOptions = this.ccSpreadsheetService.getSpreadsheetOptions(
      this.readerBaseProperties, this.readerSpecificProperties, this.rowCount
    );
    element.kendoSpreadsheet(spreadsheetOptions);
    this.spreadsheet = element.data('kendoSpreadsheet');
    this.resizeFn = _.debounce(() => {
      _.partial(this.KendoFunctions.resizeKendoComponent, this.spreadsheet, '#spreadsheetContainer');
    }, 200);
    angular.element(this.$window).on('resize', this.resizeFn);
    this.$timeout(() => this.KendoFunctions.resizeKendoComponent(this.spreadsheet, '#spreadsheetContainer'));
    this.createColumns();
  }

  private sortBaseProperties(readerBaseProperties: object[]): object[] {
    const indexOrder = [
      'meterId', 'externalMeterId', 'hoursBackWards',
      'connectionString', 'userName', 'password'
    ];
    const sortedReaderBaseProperties: object[] = [];
    indexOrder.forEach(name => {
      const property = this.$filter('filter')(readerBaseProperties, { name: name }, true)[0];
      if (property) {
        sortedReaderBaseProperties.push(property);
      }
    });
    return sortedReaderBaseProperties;
  }

  private createColumnIndices(): void {
    let index = 0;
    this.readerBaseProperties.forEach(property => {
      while (this.reservedColumnIndices.includes(index)) {
        index++;
      }
      property['index'] = index;
      index++;
    });
    this.readerSpecificProperties.forEach(property => {
      while (this.reservedColumnIndices.includes(index)) {
        index++;
      }
      property['index'] = index;
      index++;
    });

    // Terminal indices
    this.terminalIndexRangeBegin = index;
    index += 6;

    this.lastColumnIndex = index - 1;
  }

  private createColumns(): void {
    // Base properties
    this.readerBaseProperties.forEach((property: object) => {
      this.createColumn(property);
    });
    // Specific properties
    this.readerSpecificProperties.forEach((property: object) => {
      this.createColumn(property);
    });
    // Additional data
    this.createTextColumn(indexToColumnCharacter(ColumnIndex.FACILITY_NAME), false);
    this.createTextColumn(indexToColumnCharacter(ColumnIndex.METER_NAME), false);
    this.createNumberColumn(indexToColumnCharacter(ColumnIndex.ENEGIA_ID), 0, this.maxInt, false);
    this.createNumberColumn(indexToColumnCharacter(ColumnIndex.TREE_ID), 0, this.maxInt, false);
    this.createNumberColumn(indexToColumnCharacter(ColumnIndex.NODE_ID), 0, this.maxInt, false);

    // Terminal
    this.createNumberColumn(
      indexToColumnCharacter(this.terminalIndexRangeBegin), 0, this.maxInt, false
    ); // Terminal iIdentifier
    this.createTextColumn(indexToColumnCharacter(this.terminalIndexRangeBegin + 1), false); // Terminal name
    this.createTextColumn(indexToColumnCharacter(this.terminalIndexRangeBegin + 2), false); // Terminal description
    this.createTextColumn(indexToColumnCharacter(this.terminalIndexRangeBegin + 3), false); // Connection info
    this.createTextColumn(indexToColumnCharacter(this.terminalIndexRangeBegin + 4), false); // Terminal username
    this.createTextColumn(indexToColumnCharacter(this.terminalIndexRangeBegin + 5), false); // Terminal password

    const sheet = this.spreadsheet.activeSheet();
    const headerRange = sheet.range(`${indexToColumnCharacter(0)}1:${indexToColumnCharacter(this.lastColumnIndex)}1`);
    headerRange.validation(null);
  }

  private createColumn(property: any): void {
    if (property.type === 'string') {
      this.createTextColumn(indexToColumnCharacter(property.index), property.isRequired);
    } else if (property.type === 'integer') {
      const minimum = property.minimum ? property.minimum : 0;
      const maximum = property.maximum ? property.maximum : this.maxInt;
      this.createNumberColumn(indexToColumnCharacter(property.index), minimum, maximum, property.isRequired);
    } else if (property.type === 'boolean') {
      this.createBooleanColumn(indexToColumnCharacter(property.index), property.isRequired);
    } else {
      this.createTextColumn(indexToColumnCharacter(property.index), property.isRequired);
    }
  }

  private createTextColumn(column: string, isRequired: boolean): void {
    const sheet = this.spreadsheet.activeSheet();
    const range = sheet.range(this.createColumnRange(column));
    range.format('@');
    range.validation({
      dataType: 'text',
      from: angular.toJson(''),
      comparerType: 'greaterThan',
      type: 'reject',
      allowNulls: !isRequired
    });
  }

  private createNumberColumn(column: string, min: number, max: number, isRequired: boolean): void {
    const sheet = this.spreadsheet.activeSheet();
    sheet.range(this.createColumnRange(column)).validation({
      dataType: 'number',
      comparerType: 'between',
      from: min,
      to: max,
      type: 'reject',
      allowNulls: !isRequired
    });
  }

  private createBooleanColumn(column: string, isRequired: boolean): void {
    const sheet = this.spreadsheet.activeSheet();
    sheet.range(this.createColumnRange(column)).validation({
      dataType: 'list',
      comparerType: 'greaterThan',
      from: 'BooleanValues!A$1:B$1',
      type: 'reject',
      allowNulls: !isRequired
    });
  }

  private createColumnRange(column: string): string {
    return `${column}:${column}`;
  }

  private fillMeterIds(): void {
    this.isConfigurationSavingErrors = false;
    this.isTerminalSavingErrors = false;
    if (this.spreadsheet !== null) {
      const filledRows = this.getRowsWithData();

      if (filledRows.length === 0) {
        this.currentStep = this.steps[0];
      }

      const legacyMappingRequests = this.createMappingRequests(filledRows);
      if (legacyMappingRequests.length > 0) {
        this.unsubscribe();
        this.subscription = this.getMeterIdsForRows(filledRows)
          .subscribe(
            (response: LegacyMapping[]) => this.updateMeterIdsToTable(filledRows, response),
            () => this.showErrorPopup('CONFIGURATION_CONTROL.ERROR_TEXT_EXECUTE_FAIL')
          );
      }
    }
  }

  private getRowsWithData(): any[] {
    const sheet = this.spreadsheet.activeSheet();
    const allRows = sheet.range(
      `${indexToColumnCharacter(0)}1:${indexToColumnCharacter(this.lastColumnIndex)}${this.rowCount}`
    ).values();
    this.firstEmptyRowIndex = 1;
    for (let i = 1; i < this.rowCount; i++) {
      if (this.isRowEmpty(allRows[i])) {
        this.firstEmptyRowIndex = i;
        break;
      }
    }
    if (this.firstEmptyRowIndex - 1 > 0) {
      return allRows.slice(1, this.firstEmptyRowIndex);
    } else {
      return [];
    }
  }

  private isRowEmpty(row: string[]): boolean {
    return _.every(row, _.isNull);
  }

  private createMappingRequests(filledRows: any[]): LegacyMappingRequest[] {
    const requestedLegacyMappings: LegacyMappingRequest[] = [];
    filledRows.forEach((row: any[]) => {
      if (row[ColumnIndex.ENEGIA_ID] > 0 && row[ColumnIndex.TREE_ID] > -1 && row[ColumnIndex.NODE_ID] > -1) {
        requestedLegacyMappings.push(
          {
            enegiaId: row[ColumnIndex.ENEGIA_ID],
            puuId: row[ColumnIndex.TREE_ID],
            solmuId: row[ColumnIndex.NODE_ID]
          }
        );
      }
    });
    return requestedLegacyMappings;
  }

  private getMeterIdsForRows(filledRows: any[]): Observable<LegacyMapping[]> {
    const requestedLegacyMappings: LegacyMappingRequest[] = [];
    filledRows.forEach((row: any) => {
      if (row[ColumnIndex.ENEGIA_ID] > 0 && row[ColumnIndex.TREE_ID] > -1 && row[ColumnIndex.NODE_ID] > -1) {
        requestedLegacyMappings.push(
          {
            enegiaId: row[ColumnIndex.ENEGIA_ID],
            puuId: row[ColumnIndex.TREE_ID],
            solmuId: row[ColumnIndex.NODE_ID]
          }
        );
      }
    });
    return this.configurationControlClient.getMappings(requestedLegacyMappings);
  }

  private updateMeterIdsToTable(filledRows: any[], legacyMappings: LegacyMapping[]): void {
    let areAllMeterIdsOk = true;
    for (let rowIndex = 0; rowIndex < filledRows.length; rowIndex++) {
      const row = filledRows[rowIndex];
      const legacyMapping = _.find(legacyMappings, {
        enegiaId: row[ColumnIndex.ENEGIA_ID],
        puuId: row[ColumnIndex.TREE_ID],
        solmuId: row[ColumnIndex.NODE_ID]
      });
      const sheet = this.spreadsheet.activeSheet();
      const sheetRowIndex = rowIndex + 2; // First data row in the sheet is 2
      const meterIdColIndex = this.$filter('filter')(this.readerBaseProperties, { name: 'meterId' }, true)[0].index;
      const range = sheet.range(
        `${indexToColumnCharacter(meterIdColIndex)}${sheetRowIndex}:` +
        `${indexToColumnCharacter(meterIdColIndex)}${sheetRowIndex}`
      );
      if (angular.isDefined(legacyMapping)) {
        range.value(legacyMapping.meterId);
      } else {
        areAllMeterIdsOk = false;
        const errorTextRange = sheet.range(
          `${indexToColumnCharacter(this.lastColumnIndex + 1)}${sheetRowIndex}:` +
          `${indexToColumnCharacter(this.lastColumnIndex + 1)}${sheetRowIndex}`
        );
        errorTextRange.value(
          this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_TEXT_CHECK_METER_ID')
        );
        errorTextRange.format(SpreadsheetFormat.ERROR_TEXT);
      }
    }
    if (!areAllMeterIdsOk) {
      this.showErrorPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_TEXT_CHECK_METER_ID');
    }
  }

  private saveConfigurations(): void {
    if (this.isSpreadsheetValid()) {
      this.unsubscribe();
      const rows = this.getRowsWithData();
      const configurations = this.createConfigurations(rows);

      from(configurations)
        .pipe(
          concatMap(configuration => this.saveConfiguration(configuration))
        )
        .subscribe(
          {
            complete: () => {
              if (this.isConfigurationSavingErrors) {
                this.showErrorPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_CHECK_SAVE_ERRORS');
              } else {
                this.showInfoPopup('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED');
              }
            }
          }
        );
    } else {
      this.showErrorPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_CHECK_CONFIGURATIONS');
      this.currentStep = this.steps[0];
    }
  }

  private saveConfiguration(configuration: ConfigurationCreate): Observable<void> {
    return this.configurationControlClient.createConfiguration(this.readerTypeId, configuration)
      .pipe(
        map(() => this.addConfigurationOk(configuration.meterId)),
        catchError(response => {
          this.addConfigurationError(configuration.meterId, response);
          return of(null);
        })
      );
  }

  private handleTerminals(): void {
    this.unsubscribe();
    const rows = this.getRowsWithData();
    const terminals = this.createTerminals(rows);

    if (terminals.length === 0) {
      this.currentStep = this.steps[3];
      return;
    }

    // A new terminal will be created if terminal's identifier field is empty and
    // user approves the terminal creation
    if (this.isUndefinedTerminals) {
      this.saveTerminals(terminals, 'CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.INFO_NO_TERMINAL_IDENTIFIER');
    } else {
      this.saveTerminals(terminals, 'CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.INFO_CREATE_TERMINALS');
    }
    this.currentStep = this.steps[4];
  }

  private saveTerminals(terminals: Terminal[], confirmationText: string): void {
    this.confirmTerminalCreation(confirmationText)
      .then(() => {
        this.configurationControlClient.getTerminals()
          .subscribe(
            response => {
              this.existingTerminals = response;

              from(terminals)
                .pipe(
                  concatMap(terminal => this.saveTerminal(terminal))
                )
                .subscribe(
                  {
                    complete: () => {
                      if (this.isTerminalSavingErrors) {
                        this.showErrorPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_SAVE_TERMINAL');
                      } else {
                        this.showInfoPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.INFO_SAVE_TERMINAL');
                      }
                    }
                  }
                );
            },
            () => this.showErrorPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_TERMINAL_SEARCH_FAIL')
          );
      })
      .catch(() => {
        this.showInfoPopup('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.INFO_SAVE_COMPLETED');
      });
  }

  private saveTerminal(terminal: Terminal): Observable<void> {
    const existingTerminal = this.existingTerminals.find(t => t.identifier === terminal.identifier);

    if (existingTerminal !== undefined) {
      terminal.terminalMeters.forEach(meter => {
        existingTerminal.terminalMeters.push(meter);
      });
    }

    const request = (existingTerminal !== undefined) ?
      this.configurationControlClient.updateTerminal(existingTerminal) :
      this.configurationControlClient.createTerminal(terminal)
    ;

    return request.pipe(
      map(() => this.addTerminalOk(terminal)),
      catchError(response => {
        this.addTerminalError(terminal, response);
        return of(null);
      })
    );
  }

  private isSpreadsheetValid(): boolean {
    const sheet = this.spreadsheet.activeSheet();
    const range = sheet.range(
      `${indexToColumnCharacter(0)}2:${indexToColumnCharacter(this.lastColumnIndex)}${this.firstEmptyRowIndex}`
    );
    let isValid = true;

    range.forEachCell((_row: number, _column: number, cell: any) => {
      if (cell.validation && !cell.validation.value) {
        isValid = false;
      }
    });
    return isValid;
  }

  private createConfigurations(rows: any[]): ConfigurationCreate[] {
    const configurations: ConfigurationCreate[] = [];

    // Base configurations
    rows.forEach((row: any) => {
      const configuration: any = {
        groupIdentifier: this.groupIdentifier,
        cronExpression: this.cronExpression
      };
      this.readerBaseProperties.forEach(property => {
        configuration[property.name] = row[property.index];
      });

      // Reader specific configurations to json
      const specificConfig: any = {};
      this.readerSpecificProperties.forEach(property => {
        let value = row[property.index];
        if (property.type === 'boolean' && value === null) {
          value = false;
        }
        specificConfig[property.name] = value;
      });

      configuration['jsonConfiguration'] = angular.toJson(specificConfig);
      configurations.push(configuration);
    });
    return configurations;
  }

  private createTerminals(rows: object[]): Terminal[] {
    const terminals: Terminal[] = [];
    const meterIdColumnIndex = 2;

    rows.forEach((row: any) => {
      const meterId = row[meterIdColumnIndex];
      const identifier = row[this.terminalIndexRangeBegin];
      const terminalName = row[this.terminalIndexRangeBegin + 1];
      const description = row[this.terminalIndexRangeBegin + 2];
      const connectionInformation = row[this.terminalIndexRangeBegin + 3];
      const username = row[this.terminalIndexRangeBegin + 4];
      const password = row[this.terminalIndexRangeBegin + 5];

      const existingTerminal = terminals.find(terminal =>
        terminal.identifier === identifier &&
        terminal.name === terminalName);

      if (existingTerminal !== undefined) {
        existingTerminal.terminalMeters.push({ meterId: meterId });
      } else {
        const terminal =
        {
          // Terminal identifier is generated automatically in backend, when terminal identifier field is left empty.
          identifier: identifier,
          name: terminalName,
          description: description,
          connectionInformation: connectionInformation,
          userName: username,
          password: password,
          terminalMeters: [{ meterId: meterId }],
        };

        terminals.push(terminal);
      }
    });

    // Check if there is some terminal without given identifier.
    const terminalWithoutId = terminals.find(terminal => terminal.identifier === null);
    this.isUndefinedTerminals = terminalWithoutId !== undefined;
    return terminals;
  }

  private showInfoPopup(text: string): void {
    this.utils.popUp(ToasterType.INFO, null, text, true);
  }

  private showErrorPopup(text: string): void {
    this.utils.popUp(ToasterType.ERROR, null, text, true);
  }

  private addConfigurationOk(meterId: number): void {
    const rowIndex = this.findRowIndexForMeter(meterId);
    const text = this.utils.localizedString('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED');
    this.addConfigurationStatus(rowIndex, text, SpreadsheetFormat.SAVE_OK);
  }

  private addConfigurationError(meterId: number, error: string): void {
    this.isConfigurationSavingErrors = true;
    const rowIndex = this.findRowIndexForMeter(meterId);
    const errorText = this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_TEXT_SAVE_FAILED');
    const text = `${errorText}: ${error}`;
    this.addConfigurationStatus(rowIndex, text, SpreadsheetFormat.SAVE_FAILURE);
  }

  private findRowIndexForMeter(meterId: number): number {
    const meterIdColIndex = this.$filter('filter')(this.readerBaseProperties, { name: 'meterId' }, true)[0].index;
    const sheet = this.spreadsheet.activeSheet();
    const range = sheet.range(
      `${indexToColumnCharacter(meterIdColIndex)}${0}:${indexToColumnCharacter(meterIdColIndex)}${this.rowCount}`
    );

    let rowIndex = -1;
    range.forEachCell((row: number, _column: number, cell: kendo.ui.SpreadsheetSheetRowCell) => {
      if (cell.value === meterId) {
        rowIndex = row + 1; // Header index is 0
      }
    });
    return rowIndex;
  }

  private addConfigurationStatus(rowIndex: number, text: string, format: string): void {
    const sheet = this.spreadsheet.activeSheet();
    const statusColumnIndex = this.lastColumnIndex + 1;
    const textRange = sheet.range(
      `${indexToColumnCharacter(statusColumnIndex)}${rowIndex}:${indexToColumnCharacter(statusColumnIndex)}${rowIndex}`
    );
    textRange.value(text);
    const colorRange = sheet.range(
      `${indexToColumnCharacter(0)}${rowIndex}:${indexToColumnCharacter(statusColumnIndex)}${rowIndex}`
    );
    colorRange.format(format);
  }

  private addTerminalOk(terminal: Terminal): void {
    const rowIndices = this.findRowIndicesForMeters(terminal.terminalMeters.map(tm => tm.meterId));
    const text = this.utils.localizedString('CONFIGURATION_CONTROL.INFO_TEXT_TERMINAL_SAVED');
    rowIndices.forEach(rowIndex => {
      this.addTerminalStatus(rowIndex, text, SpreadsheetFormat.SAVE_OK);
    });
  }

  private addTerminalError(terminal: Terminal, response: object): void {
    this.isTerminalSavingErrors = true;
    const errors: string[] = [];
    angular.forEach(response, value => {
      errors.push(value);
    });

    const rowIndices = this.findRowIndicesForMeters(terminal.terminalMeters.map(tm => tm.meterId));
    const errorText = this.utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ERROR_TEXT_SAVE_FAILED');
    const text = `${errorText}:${errors.join()}`;
    rowIndices.forEach(rowIndex => {
      this.addTerminalStatus(rowIndex, text, SpreadsheetFormat.SAVE_FAILURE);
    });
  }

  private findRowIndicesForMeters(meterIds: number[]): number[] {
    const meterIdColIndex = this.$filter('filter')(this.readerBaseProperties, { name: 'meterId' }, true)[0].index;
    const sheet = this.spreadsheet.activeSheet();
    const range = sheet.range(
      `${indexToColumnCharacter(meterIdColIndex)}${0}:${indexToColumnCharacter(meterIdColIndex)}${this.rowCount}`
    );
    const rowIndices: number[] = [];
    meterIds.forEach(meterId => {
      range.forEachCell((row: number, _column: number, cell: kendo.ui.SpreadsheetSheetRowCell) => {
        if (cell.value === meterId) {
          rowIndices.push(row + 1); // Header index is 0
        }
      });
    });
    return rowIndices;
  }

  private addTerminalStatus(rowIndex: number, text: string, format: string): void {
    const sheet = this.spreadsheet.activeSheet();
    const statusColumnIndex = this.lastColumnIndex + 2;
    const textRange = sheet.range(
      `${indexToColumnCharacter(statusColumnIndex)}${rowIndex}:${indexToColumnCharacter(statusColumnIndex)}${rowIndex}`
    );
    textRange.value(text);
    const colorRange = sheet.range(
      `${indexToColumnCharacter(0)}${rowIndex}:${indexToColumnCharacter(statusColumnIndex)}${rowIndex}`
    );
    colorRange.format(format);
  }

  private confirmTerminalCreation(confirmationText: string): any {
    const text = this.utils.localizedString(confirmationText);
    const modalInstance = this.$modal.open({
      template: CcMassImportConfirmationTemplate,
      controller: CcMassImportConfirmationController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'tiny',
      resolve: {
        confirmationText: function() {
          return text;
        }
      }
    });
    return modalInstance.result;
  }

  private unsubscribe(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

CCMassImportController.$inject = inject;

export default CCMassImportController;
