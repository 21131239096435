import { camelCase } from 'lodash';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  Injector,
  Input, OnDestroy, OnInit
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';
import { FacilityProperty, PropertyType, Unit } from '@enerkey/clients/facility';

import { FacilityEditService } from '../../services/facility-edit.service';
import {
  facilityPropertyEnums,
  facilityPropertyEnumTranslations,
  shareOfTemperatureFixingTranslations,
  unitTranslations
} from '../../../../constants/facility.constant';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { FacilityPropertyHistoryModalComponent } from '../facility-property-history-modal/facility-property-history-modal.component';
import { QuantityService } from '../../../../shared/services/quantity.service';

export enum FacilityDataType {
  Properties,
  CO2Factor
}

export type Data = {
  name: string,
  value: string,
  property: FacilityProperty
};

type AllTypes = {
  id: number,
  type?: PropertyType,
  name?: string,
  translation: string,
  unit?: Unit
}

@Component({
  selector: 'facility-property-table',
  templateUrl: './facility-property-table.component.html',
  styleUrls: ['./facility-property-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityPropertyTableComponent implements OnInit, OnDestroy {
  @Input() public data: FacilityProperty[] | undefined;
  @Input() public dataType = FacilityDataType.Properties;

  public readonly propertyListField = new UntypedFormControl(null);
  public readonly propertyList$: Observable<ComboItem<number>[]>;
  public readonly FacilityDataType = FacilityDataType;
  public readonly facilityPropertyData$: Observable<Data[][]>;
  public readonly modifiedPropertyIds$: Observable<Record<number, boolean>>;
  public readonly facilityLoading$: Observable<boolean>;
  public readonly propertiesLoading$: Observable<boolean>;

  private readonly _allTypes$ = new ReplaySubject<Record<number, AllTypes>>(1);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityEditService: FacilityEditService,
    private readonly quantityService: QuantityService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly injector: Injector,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.facilityLoading$ = this.facilityEditService.facilityLoading$;
    this.propertiesLoading$ = this.facilityEditService.propertiesLoading$;
    this.modifiedPropertyIds$ = this.facilityEditService.modifiedPropertyIds$.pipe(
      map(ids => ids.toRecord(id => id, () => true))
    );
    this.propertyList$ = this.getPropertyList();
    this.propertyListField.valueChanges.subscribe(value => {
      if (value) {
        this.addProperty(value);
      }
    });
    this.facilityPropertyData$ = combineLatest([
      this._allTypes$,
      this.facilityEditService.facilityProperties$
    ]).pipe(
      map(([allTypes, properties]) =>
        this.handleFacilityProperties(properties, allTypes)),
      takeUntil(this._destroy$)
    );
  }

  public ngOnInit(): void {
    if (this.dataType === FacilityDataType.Properties) {
      this.facilityEditService.allProperties$.pipe(
        map(properties => {
          const result: Record<number, AllTypes> = {};
          Object.keys(properties).forEach((key: unknown) => {
            result[key as number] = {
              id: properties[key as number].id,
              type: properties[key as number].type,
              name: properties[key as number].name,
              translation: properties[key as number].translation,
              unit: properties[key as number].unit
            };
          });
          return result;
        }),
        shareReplay(1),
        takeUntil(this._destroy$)
      ).subscribe(properties => {
        this._allTypes$.next(properties);
      });
    }
    if (this.dataType === FacilityDataType.CO2Factor) {
      this.quantityService.allQuantities$.pipe(
        map(quantities => quantities.toRecord(q => q.ID, q => (
          {
            id: q.ID,
            translation: q.Name,
            unit: Unit.None,
            type: PropertyType.QuantityDecimal
          }
        ))),
        shareReplay(1),
        takeUntil(this._destroy$)
      ).subscribe(quantities => {
        this._allTypes$.next(quantities);
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._allTypes$.complete();
  }

  public openEditModal(data: Data): void {
    const modalRef = this.modalService.open(
      FacilityPropertyHistoryModalComponent,
      { injector: this.injector }
    );
    const propertyId = this.dataType === FacilityDataType.Properties
      ? data.property.propertyId
      : data.property.quantityId;
    modalRef.componentInstance.propertyId = propertyId;
    modalRef.componentInstance.propertyName = data.name;
    this._allTypes$.subscribe(types => {
      modalRef.componentInstance.propertyInfo = {
        name: types[propertyId].name,
        type: types[propertyId].type
      };
    });
  }

  private addProperty(item: ComboItem<number>): void {
    const modalRef = this.modalService.open(
      FacilityPropertyHistoryModalComponent,
      { injector: this.injector }
    );
    modalRef.componentInstance.newPropertyMode = true;
    modalRef.componentInstance.propertyId = item.value;
    modalRef.componentInstance.propertyName = item.text;
    this._allTypes$.subscribe(types => {
      modalRef.componentInstance.propertyInfo = {
        name: types[item.value].name,
        type: types[item.value].type
      };
    });
    this.propertyListField.reset();
  }

  private handleFacilityProperties(properties: FacilityProperty[], allTypes: Record<number, AllTypes>): Data[][] {
    const data: Data[] = [];
    let key: keyof FacilityProperty = null;
    if (this.dataType === FacilityDataType.Properties) {
      key = 'propertyId';
      properties = properties.filter(p => !p.quantityId);
    }
    if (this.dataType === FacilityDataType.CO2Factor) {
      key = 'quantityId';
      properties = properties.filter(p => p.quantityId);
    }
    if (properties?.length) {
      const filtered = properties
        .toGroupsBy(key).getEntries()
        .map(group => group[1].sortBy('fromDate', 'desc')[0])
        .sortBy(key);
      filtered.forEach(item => {
        const property = allTypes[item[key] as number];
        let unit: string = unitTranslations[property?.unit];
        unit = unit.length > 12 ? this.translateService.instant(unit) : unit;
        data.push({
          name: property.translation,
          value: `${this.getValueTranslation(item, property)} ${unit}`,
          property: new FacilityProperty(item)
        });
      });
      return this.formatPropertyList(data);
    }
    return [];
  }

  private getValueTranslation(fProperty: FacilityProperty, property: AllTypes): string {
    if (property.type === PropertyType.QuantityDecimal ||
        property.type === PropertyType.Decimal) {
      return fProperty?.value;
    }
    if (property.type === PropertyType.CustomType) {
      const customType = this.translateService.instant(
        shareOfTemperatureFixingTranslations[fProperty.customType]
      );
      return `${fProperty.value}, ${customType}`;
    }
    const enumId: number = facilityPropertyEnums[camelCase(property.name)]
      ?.find(x => x[0] === fProperty.value)[1];
    return this.translateService.instant(
      facilityPropertyEnumTranslations[property.name][enumId]
    );
  }

  private formatPropertyList(data: Data[]): Data[][] {
    const newData: Data[][] = [];
    for (let i = 0; i < data.length; i += 2) {
      if (data[i + 1]) {
        newData.push([
          data[i],
          data[i + 1]
        ]);
      } else {
        newData.push([data[i]]);
      }
    }
    this.changeDetectorRef.detectChanges();
    return newData;
  }

  private getPropertyList(): Observable<ComboItem<number>[]> {
    return this._allTypes$.pipe(
      switchMap(allTypes => this.facilityEditService.facilityProperties$.pipe(
        map(properties => {
          let existing: number[] = null;
          if (this.dataType === FacilityDataType.Properties) {
            existing = properties.map(d => d.propertyId);
          }
          if (this.dataType === FacilityDataType.CO2Factor) {
            existing = properties.map(d => d.quantityId);
          }
          return Object.values(allTypes).sortBy('translation').filterMap(
            x => !existing.includes(x.id),
            x => ({
              value: x.id,
              text: x.translation
            })
          );
        }),
        takeUntil(this._destroy$)
      ))
    );
  }
}
