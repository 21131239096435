import _ from 'lodash';
import { take } from 'rxjs/operators';
import { getClassNameForFilename } from 'font-awesome-filetypes';

import { firstValueFrom } from 'rxjs';
import { getCurrencySymbol } from '@angular/common';

import { DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { ACTION_OPERATIONS } from '../../constants/em-actions.constants';
import { actionBasis } from '../../constants/em-action-basis';
import { investigations } from '../../constants/em-investigations';
import { actionClasses } from '../../constants/em-action-classes';
import { actionGroups } from '../../constants/em-action-groups';
import { actionTypes } from '../../constants/em-action-types';
import { executionPhases } from '../../constants/em-execution-phases';
import { investmentSupports } from '../../constants/em-investment-supports';
import { customCategories } from '../../constants/em-custom-categories';
import EmValidationService from '../../services/em-validations-service';
import { ACTION_TYPE_IDS, COMMENT_TYPE_IDS } from '../../constants/em-shared.constant';
import { Service } from '../../../../constants/service';
import { localToUtc } from '../../../../shared/date.functions';
import { DocumentsPreviewComponent } from '../../../documents/components/documents-preview/documents-preview.component';

const $inject = [
  '$',
  '$scope',
  '$timeout',
  'actions',
  'facilities',
  'utils',
  'LoadingService',
  'filterService',
  'UserService',
  'quantities',
  'meters',
  '$window',
  'emActionsFieldService',
  'ToasterService',
  'AttachmentsClient',
  'modalServiceAngular'
];

function EmActionEditController(
  $,
  $scope,
  $timeout,
  actions,
  facilities,
  utils,
  LoadingService,
  filterService,
  UserService,
  quantities,
  meters,
  $window,
  emActionsFieldService,
  toaster,
  attachmentsClient,
  modalServiceAngular
) {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias

  vm.isLoading = false;

  function setModalTitle() {
    if (vm.action.new) {
      vm.title = utils.localizedString('ACTIONS.ADD_ACTION_MODAL_TITLE');
    } else {
      vm.title = vm.action.reportedDescription || vm.action.internalDescription || '--';
      const actionId = vm.action.id || '-';
      const externalId = vm.action.externalActionId ? ` (EXT ID: ${vm.action.externalActionId})` : '';
      vm.title += ` (ID: ${actionId})${externalId}`;
    }
  }

  // Make this function available to the template
  vm.isComment = () => EmValidationService.isComment(vm.action);

  vm.hasFacility = () => actions.hasFacility(vm.action);

  vm.shouldDocumentsBeVisible = () => {
    if (vm.canViewDocuments === false) {
      return false;
    }

    if (vm.canEditDocuments === false) {
      if (vm.action.new) {
        return false;
      } else if (vm.action.documents.length === 0) {
        return false;
      }
    }

    return true;
  };

  vm.ownerPrefill = [];

  // Is the given field considered mandatory?  Mandatory states
  // depend on the value of actionType and executionPhase.
  //
  //   vm.isMandatory("reportedDescription") // => true
  //
  vm.isMandatory = field => getMandatoryFields()[field];

  function getMandatoryFields() {
    const mandatory = {};
    // Always mandatory
    _.extend(mandatory, {
      reportingObjectId: true,
      actionType: true,
      investigation: true,
      reportedDescription: true
    });

    // Meter implies quantity
    if (!_.isEmpty(vm.action.meterIds)) {
      _.extend(mandatory, {
        quantityIds: true
      });
    }

    // Depending on action type
    if (EmValidationService.isComment(vm.action)) {
      if (vm.action.actionType === 5) {
        _.extend(mandatory, {
          effectStartsAt: true
        });
      }
    } else {
      _.extend(mandatory, { executionPhase: true });
      // Depending on execution phase
      // Fall through is intentional, these requrements cumulate
      // during the process of phase maturing 1 -> 2 -> 3.
      switch (vm.action.executionPhase) {
        case 3: // Implemented
          _.extend(mandatory, {
            reportingYear: true,
            investment: true,
            paybackTime: true,
            effectStartsAt: true
          });
          // eslint-disable-next-line no-fallthrough
        case 2: // ImplementationDecided
          _.extend(mandatory, {
            executionYear: true,
            actionBasis: true
          });
          break;
      }
    }
    return mandatory;
  }

  // Should the field be considered required in client side
  // validations?  It should when it is mandatory and the field has
  // been touched.
  vm.isRequired = fieldName => {
    if (vm.actionsEditForm) {
      return vm.isMandatory(fieldName) &&
      vm.actionsEditForm[fieldName] &&
      vm.actionsEditForm[fieldName].$touched;
    }
  };

  vm.reportedDescriptionCharacterCount = () => {
    if (vm.actionsEditForm) {
      return (vm.actionsEditForm.reportedDescription.$viewValue || '').length;
    }
  };

  // Hint message for user how to fix ReportedDescription overrun.
  vm.reportedDescriptionOverrunHint = () =>
    utils.localizedString('ACTIONS.SHORTEN_BY', { count: vm.reportedDescriptionCharacterCount() - 255 });

  // Hint message for user how to fix ReportedDescription overrun.
  vm.isReportedDescriptionOverrun = () => vm.reportedDescriptionCharacterCount() > 255;

  vm.viewDocument = document => {
    const modal = modalServiceAngular.open(DocumentsPreviewComponent);
    modal.componentInstance.selectedDocument = new DocumentCompleteViewModel({
      id: document.id,
      fileNameOrUrl: document.filename
    });
  };

  vm.removeAddedDocument = documentToRemove => {
    _.remove(vm.documents, document => document === documentToRemove);
  };

  vm.onSelectReportingObject = reportingObject => {
    if (vm.action.reportingObjectId !== reportingObject.Id) {
      vm.action.reportingObjectId = reportingObject.Id;
      vm.errorStateOff('reportingObjectId');
      vm.action.quantityIds = [];
      updateQuantityPicker();
      vm.action.meterIds = [];
      updateMeterPicker();
      updateCurrencyForFacility();
    }
  };

  // Set vm.action.QuantityIds according to quantity picker state
  // (vm.quantitiesSelected), weed out .MeterIds not belonging to
  // the new quantities and propagate change to meter picker.
  vm.updateQuantityIds = () => {
    const newQuantityIds = _.chain(vm.quantitiesSelected).map('ID').sort().value();
    if (!angular.equals(vm.action.quantityIds, newQuantityIds)) {
      vm.action.quantityIds = newQuantityIds;
      // deselect meters for which the respective quantity is not selected (anymore)
      const includeMeterIds = _.chain(vm.meters)
        .filter(meter => _.includes(vm.action.quantityIds, meter.QuantityId))
        .map('Id').value();
      vm.action.meterIds = _.intersection(vm.action.meterIds, includeMeterIds);
      updateMeterPicker();
    }
  };

  // Set vm.action.MeterIds and .QuantityIds (add quantities of
  // selected meters) according to meter picker state
  // (vm.metersSelected) and propagate change to quantity picker.
  vm.updateMeterIds = () => {
    const newMeterIds = _.chain(vm.metersSelected).map('Id').sort().value();
    const oldMeterIds = vm.action.meterIds;
    if (!angular.equals(vm.action.meterIds, newMeterIds)) {
      vm.action.meterIds = newMeterIds;
      vm.action.meterName = actions.getMeterName(vm.action, _.keyBy(vm.meters, 'Id'));
      if (!_.isEmpty(_.difference(newMeterIds, oldMeterIds))) {
        // new meters were added, refresh quantity ids
        vm.action.quantityIds = _.union(vm.action.quantityIds,
          _.map(vm.metersSelected, 'QuantityId'));
        updateQuantityPicker();
      }
    }
  };

  vm.getFileClass = function(name) {
    return name && getClassNameForFilename(name) || 'fa-file';
  };

  // For metertree picker component
  const multiselect = 'MULTISELECT.NOTHING_SELECTED';
  vm.getMeterPickerTitle = () =>
    _.isEmpty(vm.action.meterName) ? utils.localizedString(multiselect) : vm.action.meterName;

  // Set quantity picker contents according to vm.action.QuantityIds
  function updateQuantityPicker() {
    vm.quantitiesSelected = [];
    vm.quantities = [];
    quantities.getQuantitiesForFacility(vm.action.reportingObjectId)
      .then(quantitiesForFacility => {
        vm.quantities = _.map(quantitiesForFacility, quantity => ({
          ID: quantity.ID,
          Name: quantity.Name,
          selected: _.includes(vm.action.quantityIds, quantity.ID),
        }));
      });
  }

  function updateCurrencyForFacility() {
    facilities.getFacilitiesInformation().then(reportingObjects => {
      getCurrencySymbolForFacility(reportingObjects);
    });
  }

  // Set meter picker contents according to vm.action.MeterIds
  function updateMeterPicker() {
    vm.metersSelected = [];
    vm.meters = [];
    // rely on getMetersForFacility being cached
    meters.getMetersForFacility(vm.action.reportingObjectId)
      .then(metersForFacility => {
        vm.meters = _.chain(metersForFacility)
          .groupBy('QuantityId')
          .map((metersForQuantity, quantityId) => {
            const pickerMetersForQuantity = metersForQuantity.map(meter => {
              const pickerMeter = {
                Id: meter.Id,
                CustomerMeterIdentifier: `${meter.CustomerMeterIdentifier || ''} – `,
                Name: meter.Name,
                QuantityId: meter.QuantityId,
                selected: _.includes(vm.action.meterIds, meter.Id),
              };
              return pickerMeter;
            });
            const group = { meterGroup: true, Name: `Quantity ${quantityId}` };
            quantities.getQuantity(quantityId).then(quantity => {
              group.Name = (quantity && quantity.Name) || '';
            });
            return [[group], pickerMetersForQuantity, [{ meterGroup: false }]];
          })
          .flatten().flatten().value();
      });
  }

  // Sole purpose of this watch contraption is to hook
  // getAccordionSectionErrorCount() memoization cache busting to
  // angular form validation.  Buster listens to changes in $valid
  // properties of all the angular form controls.  setErrorState()
  // hooks cache busting to server validation errors and to controls
  // having no ng-model (and thus not appearing in
  // vm.actionsEditForm).  It would be possible to extend this to
  // use vm.isInErrorState and cover all the controls, but I'm
  // hesitant to put jQuery DOM traversal in watchers.
  vm.getControlValidities = () => {
    const controlValidities = {};
    _.each(vm.actionsEditForm, (control, name) => {
      if (_.isObject(control) && control.hasOwnProperty('$valid')) { // eslint-disable-line no-prototype-builtins
        controlValidities[name] = control.$valid;
      }
    });
    return controlValidities;
  };

  const bustErrorCountCache = () => {
    // bust getAccordionSectionErrorCount() memoization cache
    vm.editFormSections.errorCounts = {};
  };

  // Find representative element for given field having a form
  // control that does not expose itself via Angular's form.
  vm.nonAngularControlElement = fieldName =>
    angular.element(`input[name='${fieldName}']`).parent().parent() // kendo date
      .add(`.select--multiple.actions-edit-form__${fieldName}`); // isteven

  // Observe the error state (considers overall validation, not only
  // server errors) of given form field.
  vm.isInErrorState = fieldName => {
    let hasError = false;
    if (vm.actionsEditForm[fieldName]) {
      hasError = !vm.actionsEditForm[fieldName].$valid;
    } else {
      // Kendo date pickers (and possibly other Kendo controls too)
      // don't appear in Angular form because they do not have
      // ng-model attribute set.  Need to handle observe the error
      // state manually.
      hasError = vm.nonAngularControlElement(fieldName).hasClass('ng-invalid');
    }
    return hasError;
  };

  // Set the server error state of given form field.
  vm.setErrorState = (fieldName, state) => {
    // just go home if we aren't gonna change anything
    if (vm.isInErrorState(fieldName) === state) {
      return;
    }
    // bust getAccordionSectionErrorCount() memoization cache
    bustErrorCountCache();

    if (vm.actionsEditForm[fieldName]) {
      vm.actionsEditForm[fieldName].$setValidity('server', !state);
    } else {
      // Kendo date pickers (and possibly other Kendo controls too)
      // don't appear in Angular form because they do not have
      // ng-model attribute set.  Need to handle setting the error
      // state manually.
      const el = vm.nonAngularControlElement(fieldName);
      const functionName = state ? 'addClass' : 'removeClass';
      el[functionName]('ng-invalid ng-invalid-server');
    }
  };

  // Clear server error state for given form field and all the
  // fields for which mandatory flag has changed since last setting
  // on server error states.
  vm.errorStateOff = fieldName => {
    vm.setErrorState(fieldName, false);
    errorStateOffIfMandatoryRelaxed();
  };

  vm.effectStartChange = () => {
    vm.errorStateOff('effectStartsAt');
    vm.action.effectStartsAt = localToUtc(vm.action.effectStartsAt);
  };

  // Fields that were invalid and mandatory on last time when set of
  // validation errors were returned from server
  const invalidAndMandatory = {};

  // Clear server error state for all fields for which mandatory
  // flag has been relaxed since last time server errors were
  // received.  These fields are highly likely not in error state
  // anymore.
  function errorStateOffIfMandatoryRelaxed() {
    _.forOwn(invalidAndMandatory, (isMandatory, fieldName) => {
      if (vm.isMandatory(fieldName) !== isMandatory) {
        vm.setErrorState(fieldName, false);
      }
    });
  }

  // Count form controls of given form section that don't pass
  // client or server validation.
  vm.getAccordionSectionErrorCount = sectionName => {
    // memoized
    if (angular.isDefined(vm.editFormSections.errorCounts[sectionName])) {
      return vm.editFormSections.errorCounts[sectionName];
    }

    const fieldsFor = EmValidationService.isComment(vm.action) ? 'comment' : 'other';
    const res = _.reduce(
      _.map(
        vm.editFormSections[sectionName].fields[fieldsFor],
        fieldName => vm.isInErrorState(fieldName) ? 1 : 0
      ),
      (errorCount, errorNum) => errorCount + errorNum,
      0
    );

    vm.editFormSections.errorCounts[sectionName] = res;
    return res;
  };

  vm.isSectionInvalid = sectionName => {
    if (vm.actionsEditForm) {
      return vm.getAccordionSectionErrorCount(sectionName) !== 0;
    }
  };

  // Based on given action, what would be the autofilled value for lifeTime
  vm.getAutofillLifeTime = action => {
    switch (action.actionBasis) {
      case 0: // TEK
        switch (action.actionClass) {
          case 19: // Tiivistäminen
            return 5;
          case 12: // Muu toimenpide
          case 13: // Ohjaustapamuutos
          case 15: // Perussäätö
          case 23: // Virtaumien ja painetasojen alennus
            return 10;
          case 6: // Hyötysuhdemuutos
          case 17: // Tarpeenmukainen käyttö
            return 12;
          case 14: // Palvelualuemuutos
          case 16: // Talteenoton lisääminen
          case 18: // Tehomuutos
          case 20: // Uusiminen
            return 15;
          case 22: // Vapaajäähdytys
            return 17;
          case 9: // Lauhdelämmön hyödyntäminen
          case 10: // Lisäeristys
          case 21: // Uusiutuvan energian hyödyntäminen
            return 20;
          case 4: // Aurinkosuojaus, lämpökuormien vähentäminen
            return 25;
          case 11: // Muu johtumishäviöiden pienentäminen
            return 30;
          default:
            return null;
        }
      case 1: // KTEK
        switch (action.actionClass) {
          case 0: // Aikaohjelmamuutos
          case 1: // Asetusarvomuutos
          case 2: // Käyttötapamuutos
          case 3: // Olosuhdemuutos
          case 5: // Energiatavoitteet
          case 7: // Koulutus
          case 8: // Kulutusseuranta
          case 12: // Muu toimenpide
          case 13: // Ohjaustapamuutos
          case 17: // Tarpeenmukainen käyttö
          case 18: // Tehomuutos
          case 22: // Vapaajäähdytys
          case 23: // Virtaumien ja painetasojen alennus
            return 5;
          default:
            return null;
        }
      default: // Basis (TEK/KTEK) not selected
        return null;
    }
  };

  // Field is autofillable if
  // - there is no value (i.e. value is null or undefined),
  // - value is deemed clearable (see .isAutoclearable())
  // - or we are adding a new action and the field has not been
  //   touched yet
  vm.isAutofillable = fieldName => {
    const value = vm.action[fieldName];
    return angular.isUndefined(value) || value === null || // clear
      vm.isAutoclearable(fieldName) || // clearable
      !!(vm.action.new && // new & untouched
          vm.actionsEditForm[fieldName] &&
          vm.actionsEditForm[fieldName].$pristine);
  };

  // Should the field value be cleared in case the new autofill
  // value is null?
  vm.isAutoclearable = fieldName =>
    !!(vm.actionsEditForm[fieldName] &&
    vm.actionsEditForm[fieldName].$autofilled);

  // Check if lifeTime is autofillable and set new value.  If the
  // new value is null, check clearability and empty the value
  // accordingly.
  const autofillLifeTime = () => {
    if (vm.isAutofillable('lifeTime')) {
      const newLifeTime = vm.getAutofillLifeTime(vm.action);
      if (newLifeTime !== null) {
        vm.action.lifeTime = newLifeTime;
        vm.actionsEditForm.lifeTime.$autofilled = true;
        vm.setErrorState('lifeTime', false);
      } else {
        if (vm.isAutoclearable('lifeTime')) {
          vm.action.lifeTime = newLifeTime;
          vm.actionsEditForm.lifeTime.$autofilled = false;
        }
      }
    }
  };

  // Call this from ng-change of the form fields that might be
  // autofilled.
  vm.autofilledOff = fieldName => {
    vm.actionsEditForm[fieldName].$autofilled = false;
  };

  vm.deleteAction = () => {
    if (LoadingService.isLoading()) {
      // prevent invoking twice.
      return;
    }

    if ($window.confirm(utils.localizedString('ACTIONS.DELETE_CONFIRMATION')) === false) {
      return;
    }

    vm.isLoading = true;

    actions.deleteAction(vm.action.id).then(() => {
      vm.modalClose({
        operation: ACTION_OPERATIONS.DELETE,
        action: vm.action
      });
      utils.popUp('success', utils.localizedString('ACTIONS.ACTIONDELETED'));
    }).catch(() => {
      vm.isLoading = false;
      utils.popUpGeneralError('DELETE', EmValidationService.isComment(vm.action) ? 'COMMENT' : 'ACTION');
    });
  };

  vm.saveAction = () => {
    if (LoadingService.isLoading()) {
      // prevent invoking twice.
      return;
    }

    const saveFunction = vm.action.new ? ACTION_OPERATIONS.CREATE : ACTION_OPERATIONS.UPDATE;
    const successMessageKey = vm.action.new ? 'ACTIONS.ACTIONCREATED' : 'ACTIONS.ACTIONUPDATED';
    let actionToSave = _.cloneDeep(vm.action);

    actionToSave = actions.stripAwayNonCommentFields(actionToSave);
    actionToSave = actions.coerce(actionToSave); // ensure proper types

    const mandatoryFields = getMandatoryFields();
    let requestOk = true;
    _.each(mandatoryFields, (value, field) => {
      if (angular.isUndefined(vm.action[field]) || vm.action[field] === null) {
        vm.setErrorState(field, true);
        invalidAndMandatory[field] = true;
        requestOk = false;
      }
    });

    if (!requestOk) {
      utils.popUp('error', utils.localizedString('ACTIONS.ERRMSG_MISSINGFIELDS'));
      return;
    }

    vm.isLoading = true;

    actions[saveFunction](actionToSave, vm.documents).then(response => {
      vm.modalClose({
        operation: saveFunction,
        action: response.data
      });
      utils.popUp('success', utils.localizedString(successMessageKey));
    }).catch(response => {
      vm.isLoading = false;
      handleFailedRequest(response.data);
    });
  };

  function handleFailedRequest(response) {
    if (response.message) {
      handleDetectedVirus(response);
    } else if (angular.isArray(response)) {
      handleLockedFields(response);
    } else {
      utils.popUpGeneralError('SAVE', EmValidationService.isComment(vm.action) ? 'COMMENT' : 'ACTION');
    }
  }

  function handleDetectedVirus(response) {
    let errorMessage;

    try {
      errorMessage = angular.fromJson(response.message);
    } catch (e) {
      // Ignore
    }

    if (_.get(errorMessage, 'virusDetection')) {
      utils.popUp('error', utils.localizedString('DOCUMENTS.VIRUS_DETECTED_IN_UPLOADED_FILE', {
        filename: errorMessage.virusDetection.file
      }));
      utils.trackError('Document creation/update error', {
        message: `Virus detected in file: ${response}`
      });
    }
  }

  function handleLockedFields(response) {
    const actionFields = emActionsFieldService.getFieldsByName();
    const lockedFields = [];
    response.forEach(error => {
      if (error.errorMessage === 'Updating field is not allowed') {
        const fieldName = error.memberNames[0];
        const translationKey = actionFields[fieldName].localization;
        lockedFields.push(`<li>${utils.localizedString(translationKey)}</li>`);
      }
    });
    if (lockedFields.length > 0) {
      const suffix = utils.localizedString('ACTIONS.ERRMSG_LOCKED_FIELDS_SUFFIX');
      const errorBodyText = `<ul>${lockedFields.join('')}</ul><div>${suffix}</div>`;
      toaster.toast({
        type: 'error',
        title: utils.localizedString('ACTIONS.ERRMSG_LOCKED_FIELDS'),
        body: errorBodyText,
        allowHtml: true
      });
    } else {
      utils.popUpGeneralError('SAVE', 'ACTION');
    }
  }

  function getCurrencySymbolForFacility(reportingObjects) {
    vm.currency = '';
    const filteredFacility = reportingObjects.filter(facility => facility.facilityId === vm.action.reportingObjectId);
    vm.currency = getCurrencySymbol(filteredFacility[0].FacilityInformation.Currency, 'narrow');
  }

  vm.$onInit = function() {
    // HACK: add files to form control
    const _files = vm.action._files || [];
    delete vm.action._files;

    vm.action = actions.coerce(vm.action || {});
    if (!vm.action.id) {
      vm.action.new = true;
    }

    setModalTitle();

    // Form field configuration
    vm.editFormSections = _.cloneDeep(actions.actionAndCommentFieldsPerSection);
    // getAccordionSectionErrorCount() memoization cache
    vm.editFormSections.errorCounts = {};
    // The field configuration is also used to handle the sections,
    // set defaults
    vm.editFormSections.basicInfo.isOpen = true;
    // Needed at this point to attach a watcher, see isIASOpen
    vm.editFormSections.investmentAndSavings.isOpen = false;

    vm.istevenMultiSelectTranslation = utils.istevenMultiSelectTranslation();

    vm.canViewDocuments = UserService.hasRole('DocumentRead');
    vm.canEditDocuments = UserService.hasRole('DocumentWrite');

    // Selected new documents to be attached
    vm.documents = [];

    for (const file of _files) {
      if (file instanceof File) {
        vm.documents.push(file);
      }
    }

    vm.actionTypes = [];
    if (UserService.hasService(Service.Comments)) {
      vm.actionTypes.push(...actionTypes.filter(actionType => COMMENT_TYPE_IDS.includes(actionType.id)));
    }
    if (UserService.hasService(Service.Actions)) {
      vm.actionTypes.push(...actionTypes.filter(actionType => ACTION_TYPE_IDS.includes(actionType.id)));
    }

    vm.actionGroups = actionGroups;
    vm.actionClasses = actionClasses;
    vm.investigations = investigations;
    vm.actionBasis = actionBasis;
    vm.executionPhases = executionPhases;
    vm.investmentSupports = investmentSupports;
    vm.customCategories = customCategories;

    vm.reportingObjectsSelected = [];
    vm.metersSelected = [];
    vm.quantitiesSelected = [];

    filterService.filteredFacilityIds$
      .pipe(take(1))
      .subscribe(ids => {
        facilities.getFacilitiesInformation().then(reportingObjects => {
          // Filter the available set of reporting objects according to
          // topbar filter.  MultiSelect modifies selected objects
          // (selected property), create brand new ones to avoid messing
          // up original data.
          const byFilterService = facility => _.includes(ids, facility.FacilityId);
          vm.reportingObjects = _.map(
            !_.isArray(ids) ? reportingObjects : _.filter(reportingObjects, byFilterService),
            reportingObject => ({
              Id: reportingObject.FacilityId,
              RealEstateId: `${_.get(reportingObject, 'FacilityInformation.RealEstateId') || ''} – `,
              Name: reportingObject.Name,
              selected: reportingObject.FacilityId === vm.action.reportingObjectId,
            })
          );
          if (vm.hasFacility()) {
            updateQuantityPicker();
            updateMeterPicker();
            getCurrencySymbolForFacility(reportingObjects);
          }
        });

        // Get action owners
        const facilitySubset = ids || [];
        firstValueFrom(attachmentsClient.getOwners(UserService.profileId, facilitySubset))
          .then(result => {
            vm.ownerPrefill = result;
          })
          .catch(() => { /* Ignore for now */ });
      });

    // Bust getAccordionSectionErrorCount() memoization cache
    $scope.$watch(vm.getControlValidities, bustErrorCountCache, true);

    // LifeTime autofill is based on actionBasis & actionClass, watch them.
    $scope.$watch('ActionsEdit.action.actionBasis', autofillLifeTime);
    $scope.$watch('ActionsEdit.action.actionClass', autofillLifeTime);
  };
}

EmActionEditController.$inject = $inject;

export default EmActionEditController;
