import _ from 'lodash';

const $inject = [
  '$rootScope',
  '$scope',
  '$q',
  '$timeout',
  'ERDataService2',
  'LoadingService',
  'consumptions',
  'utils',
  'ERUtils',
  '$state',
  'modalService',
  'erReportSettingsService'
];

function FacilitiesReportComparisonController(
  $rootScope,
  $scope,
  $q,
  $timeout,
  ERDataService2,
  LoadingService,
  consumptions,
  utils,
  ERUtils,
  $state,
  modalService,
  erReportSettingsService
) {
  // settings and data
  $scope.settings = {
    showXAxis: _.isBoolean($scope.params.view.showXAxis) ? $scope.params.view.showXAxis : true,
    highlightedFacilities: [],
    maxFacilitiesCountForPeriodical: 8
  };
  $scope.reportData = {
    graphDataByQuantity: [],
    tableData: void 0,
    savedData: {}
  };

  // indicators for highlighting
  const indicators = [
    'indicator--light-blue',
    'indicator--pink',
    'indicator--dark-green',
    'indicator--lilac',
    'indicator--dark-yellow',
    'indicator--light-green',
    'indicator--dark-pink',
    'indicator--orange'
  ];

  // localized texts for multiselect
  $scope.localizedMultiSelectTexts = angular.extend(utils.istevenMultiSelectTranslation(), {
    nothingSelected: utils.localizedString('FACILITIES_REPORT.CHOOSE_FACILITIES')
  });

  $scope.regenerateData = _.debounce(callback => {
    const series = getSeries();
    let payload;

    $scope
      .parentInitialized()
      .then(() => {
        $scope.facilities = _.map($scope.getSelectedFacilities(), facility => {
          const realEstateId = _.get(facility, 'FacilityInformation.RealEstateId');
          const selected = _.includes(_.get($scope.params, 'view.highlightedFacilityIds'), facility.FacilityId);
          return _.extend(_.cloneDeep(facility), {
            selected: selected,
            realEstateId: realEstateId
              ? `<span class="real-estate">${facility.FacilityInformation.RealEstateId}</span>`
              : ''
          });
        });

        payload = getPayload(series);
        if ($scope.validateSeries(series) && payload) {
          return getData(payload);
        }
      })
      .then(data => createReport(data, series, payload))
      .catch($scope.handleError)
      .finally(() => {
        if (angular.isFunction(callback)) {
          callback();
        }
      });
  }, $scope.defaultTimer);

  function getSeries() {
    return _.assign({}, $scope.params.series);
  }

  function getPayload(series) {
    const facilityIds = _.map($scope.facilities, 'FacilityId');

    if ($scope.validateSeries(series) && facilityIds.length) {
      return {
        Quantities: _.chain($scope.params.quantityId)
          .reduce((result, quantityId) => {
            const quantity = $scope.getQuantity(quantityId);
            if (quantity) {
              result.push({
                ID: quantityId,
                RelationalUnitIds: quantity.Relational ? series.RelationalUnitIds : [],
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: $scope.filterRelatedValueIdsForQuantity(series.RelatedValues, quantityId)
              });
            }
            return result;
          }, [])
          .value(),
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        Unit: $scope.params.unitKey
      };
    }
  }

  function getData(payload) {
    return consumptions.getConsumptionsForFacilities(payload);
  }

  function createReport(responseData, series, payload) {
    responseData = responseData || $scope.reportData.savedData.rank;
    series = series || getSeries();
    payload = payload || getPayload(series);

    // save data
    $scope.reportData.savedData.rank = responseData;
    $scope.reportData.savedData.series = series;

    // real estate column
    const extraColumns = [];
    const facilityInformation = _.find($scope.cache.facilitiesProperties, { Property: 'FacilityInformation' });
    const realEstate = _.find(_.get(facilityInformation, 'Items'), { Property: 'RealEstateId' });
    if (realEstate) {
      extraColumns.push({
        field: `${facilityInformation.Property}$${realEstate.Property}`,
        title: realEstate.Name,
        groupable: realEstate.Groupable,
        width: 150
      });
    }

    const graphsCreatedOrUpdated = [];

    const options = {
      idType: 'Facility',
      series: series,
      params: $scope.params,
      cache: $scope.cache,
      api: {
        requestData: payload,
        responseData: responseData
      },
      show: {
        distributionAndValue: true,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: false
      },
      chart: {
        getCurrentChart: function(_options, data) {
          const propertyData = _.head(data.propertiesData);
          const property = _.get(propertyData, 'path');
          const graphDataForQuantity = _.find($scope.reportData.graphDataByQuantity, { id: data.quantityId });
          let chart = graphDataForQuantity ? _.find(graphDataForQuantity.graphData, { property: property }) : void 0;
          const title = `${_.get(propertyData, 'object.Name')} [${data.unit}]`;
          if (!chart) {
            chart = {
              id: data.quantityId,
              property: property,
              periodically: !!_.find($scope.params.view.periodically, {
                id: data.quantityId,
                property: property
              }),
              periodicallyChartOptions: void 0
            };
            if (graphDataForQuantity) {
              graphDataForQuantity.graphData.push(chart);
            }
          }
          chart.title = title;
          graphsCreatedOrUpdated.push(chart);
          return chart;
        },
        separateQuantityProperties: {
          sort: {
            order: 'desc',
            startKey: _.get(_.last(series.Start), 'key')
          }
        },
        showAverageLine: true,
        showRunningNumberInTooltip: true,
        extraChartOptions: {
          categoryAxis: {
            notes: {
              data: [],
              visual: function(e) {
                // number and index
                const number = parseInt(e.text, 10);
                const index = isNaN(number) ? 0 : number - 1;
                const indicator = indicators[index % indicators.length];
                const colors = {
                  text: utils.getStyleRuleValue('color', `.${indicator}`) || 'white',
                  background: utils.getStyleRuleValue('background-color', `.${indicator}`) || 'black'
                };

                // rounded rect
                const rect = new kendo.geometry.Rect(
                  [e.rect.origin.x + 0.5 * e.rect.size.width - 9, e.rect.origin.y - 16],
                  [18, 27]
                );
                const roundedRectPath = new kendo.drawing.Path({
                  fill: {
                    color: colors.background
                  },
                  stroke: {
                    color: 'white'
                  }
                });
                const curveDistance = 10;
                const x = rect.origin.x;
                const y = rect.origin.y;
                const bottomRight = rect.bottomRight();

                roundedRectPath
                  .moveTo(bottomRight.x - curveDistance, y)
                  .curveTo([bottomRight.x, y], [bottomRight.x, y], [bottomRight.x, y + curveDistance])
                  .lineTo([bottomRight.x, bottomRight.y - curveDistance])
                  .curveTo(bottomRight, bottomRight, [bottomRight.x - curveDistance, bottomRight.y])
                  .lineTo([x + curveDistance, bottomRight.y])
                  .curveTo([x, bottomRight.y], [x, bottomRight.y], [x, bottomRight.y - curveDistance])
                  .lineTo(x, y + curveDistance)
                  .curveTo([x, y], [x, y], [x + curveDistance, y])
                  .close();

                // text
                const targetPoint = { x: e.rect.origin.x + 0.5 * e.rect.size.width, y: e.rect.origin.y };
                const text = new kendo.drawing.Text(number, [0, 0], {
                  font: 'bold 13px Arial',
                  fill: { color: colors.text }
                });
                const bbox = text.bbox();
                text.position([
                  targetPoint.x - 20 + (40 - bbox.width()) / 2,
                  targetPoint.y - 13 + (20 - bbox.height()) / 2
                ]);

                // return group of line, rounded rect and text
                return new kendo.drawing.Group({ zIndex: 2 }).append(roundedRectPath, text);
              }
            }
          }
        },
        printHeight: 500
      },
      grid: {
        getCurrentGrid: function() {
          if (!$scope.reportData.tableData) {
            $scope.reportData.tableData = {
              gridContent: {
                hidden: false,
                modified: false
              }
            };
          }
          return $scope.reportData.tableData;
        },
        aggregates: ['sum', 'min', 'max', 'average'],
        addRowNumberColumn: {
          template: function(dataItem) {
            const template = kendo.template('<div><span class="row-number right"></span>#= highlight #</div>');
            const highlight = getHighlightIndicator(dataItem.key);
            return template({ highlight: highlight || '' });
          }
        },
        extraColumns: extraColumns,
        excel: {}, // todo
        extraGridOptions: {
          sortable: true,
          columnMenu: false
        }
      }
    };

    function serieOptionsChanger(serie) {
      if (serie.type === 'area') {
        serie.type = 'column';
      }

      serie.gap = 0;

      return serie;
    }

    // Apply first function to extendChartSeriesOptions and create new function with pre-defined parameter.
    const extendChartSeriesOptions = _.partial(ERUtils.extendChartSeriesOptions, serieOptionsChanger);
    const charts = _.forIn(_.get(ERDataService2.getVisuals(options), 'charts', {}), extendChartSeriesOptions);

    // handle grid sorting
    handleGridSort();

    _.each($scope.params.quantityId, quantityId => {
      const quantity = $scope.cache.quantitiesById[quantityId];
      const graphDataForQuantity = _.find($scope.reportData.graphDataByQuantity, { id: quantityId });
      if (!graphDataForQuantity) {
        $scope.reportData.graphDataByQuantity.push({
          id: quantity.ID,
          quantity: quantity,
          graphData: charts[quantityId]
        });
        _.each(charts[quantityId], graph => {
          // trigger handle periodically
          if (graph.periodically) {
            $timeout(() => {
              $scope.handlePeriodically(graph);
            });
          }
        });
      }
    });

    // remove charts that where not created/updated or does not have series
    _.each($scope.reportData.graphDataByQuantity, graphDataForQuantity => {
      graphDataForQuantity.graphData = _.filter(
        graphDataForQuantity.graphData,
        graph => _.includes(graphsCreatedOrUpdated, graph) && _.get(graph, 'chartOptions.series', []).length
      );
    });

    // highlight facilities
    $scope.highlightFacilities();
  }

  $scope.$watch('settings.showXAxis', newValue => {
    $timeout(() => {
      if (angular.isDefined(newValue)) {
        // save scope param
        _.merge($scope.params.view, { showXAxis: $scope.settings.showXAxis });
        if ($scope.reportData.savedData.rank) {
          // force loading indication
          LoadingService.forceLoadingUntil();
          createReport();
        } else {
          $scope.regenerateData();
        }
      }
    });
  });

  function handleGridSort() {
    const dataSource = $scope.grid ? $scope.grid.dataSource : void 0;
    const sort = dataSource ? $scope.grid.dataSource.sort() : void 0;
    if (
      !sort ||
      !_.findDeep(_.get($scope.reportData.tableData.gridOptions, 'columns'), { field: _.get(sort, '0.field') })
    ) {
      const quantityId = _.head($scope.params.quantityId);
      const quantity = $scope.cache.quantitiesById[quantityId];
      if (quantity) {
        const normalized = $scope.params.series.Normalized && !$scope.params.series.Measured && quantity.Normalization;
        const lastStart = $scope.params.series.Start.length - 1;
        const normalizedValue = normalized ? '$Normalized' : '$';
        $scope.reportData.tableData.gridOptions.dataSource.sort = [
          {
            field: `$${quantityId}$0$${lastStart}${normalizedValue}Reading$Value`,
            dir: 'desc'
          }
        ];
      }
    } else {
      $scope.reportData.tableData.gridOptions.dataSource.sort = sort;
    }
  }

  function getHighlightIndicator(facilityName) {
    let indicator;
    const highlightedKeys = _.map($scope.settings.highlightedFacilities, 'Name');
    const index = _.indexOf(highlightedKeys, facilityName);
    if (index !== -1) {
      const template = '<span class="indicator {0}">{1}</span>';
      indicator = kendo.format(template, indicators[index % indicators.length], index + 1);
    }
    return indicator;
  }

  $scope.highlightFacilities = function(graphDataByQuantity) {
    // if there is no graphDataByQuantity, use reportData
    graphDataByQuantity = graphDataByQuantity || $scope.reportData.graphDataByQuantity;
    const highlightedKeys = _.map($scope.settings.highlightedFacilities, 'Name');

    // highlight multiselect
    _.each($scope.facilities, facility => {
      facility.icon = facility.selected ? getHighlightIndicator(facility.Name) : void 0;
    });

    // highlight graphs
    _.each(graphDataByQuantity, graphDataForQuantity => {
      _.each(graphDataForQuantity.graphData, graph => {
        if (_.get(graph.chartOptions, 'categoryAxis')) {
          // create new chart options with notes data
          const newChartOptions = _.cloneDeep(graph.chartOptions);

          // remove old notes
          newChartOptions.categoryAxis.notes.data.splice(0, newChartOptions.categoryAxis.notes.data.length);

          // add new notes
          const lastSerie = _.last(newChartOptions.series);
          if (lastSerie) {
            _.each(lastSerie.data, (item, keyIndex) => {
              const index = _.indexOf(highlightedKeys, item.key);
              if (index !== -1) {
                newChartOptions.categoryAxis.notes.data.push({ value: keyIndex, label: { text: index + 1 } });
              }
            });
          }

          // set new chart options
          graph.chartOptions = newChartOptions;
        }
      });
    });

    // highlight grid by refreshing
    if ($scope.grid) {
      $scope.grid.refresh();
    }

    // save scope param
    _.extend($scope.params.view, {
      highlightedFacilityIds: _.map($scope.settings.highlightedFacilities, 'FacilityId')
    });
  };

  function getPeriodicalPayload(series) {
    series = series || getSeries();
    const payload = getPayload(series);
    payload.FacilityId = $scope.params.view.highlightedFacilityIds;
    payload.Resolution = series.Resolution;
    return payload;
  }

  function getPeriodicalData(payload) {
    if (!$scope.reportData.savedData.periodicalDeferred) {
      $scope.reportData.savedData.periodicalDeferred = $q.defer();
      const series = getSeries();
      payload = payload || getPeriodicalPayload(series);

      if (series.ExportOnly) {
        // check ExportOnly and do not fetch data
        $scope.reportData.savedData.periodical = {};
        $scope.reportData.savedData.periodicalDeferred.resolve();
      } else {
        // get periodical data
        const promises = [
          consumptions.getAggregateConsumptions(
            _.extend({}, payload, {
              FacilityId: $scope.params.facilityId,
              Comparables: $scope.params.series.Comparables || 'None'
            }),
            'average'
          )
        ];
        if (
          _.isArray($scope.params.view.highlightedFacilityIds) &&
          $scope.params.view.highlightedFacilityIds.length &&
          $scope.params.view.highlightedFacilityIds.length <= $scope.settings.maxFacilitiesCountForPeriodical
        ) {
          promises.push(getData(payload));
        }

        $q.all(promises)
          .then(results => {
            $scope.reportData.savedData.periodical = results.length > 1 ? results[1] : void 0;
            $scope.reportData.savedData.periodicalAverage = results[0];
            if ($scope.reportData.savedData.periodicalDeferred) {
              $scope.reportData.savedData.periodicalDeferred.resolve();
            }
          })
          .catch(err => {
            if ($scope.reportData.savedData.periodicalDeferred) {
              $scope.reportData.savedData.periodicalDeferred.reject();
              utils.trackError(err, 'FacilitiesReportComparisonController periodical fail');
              $scope.handleError();
              $timeout(() => {
                $scope.reportData.savedData.periodicalDeferred = void 0;
              });
            }
          });
      }
    }
    return $scope.reportData.savedData.periodicalDeferred.promise;
  }

  $scope.handlePeriodically = function(graph) {
    if ($scope.reportData.savedData.periodical) {
      doFlipGraph(graph);
    } else {
      getPeriodicalData().then(() => {
        doFlipGraph(graph);
      });
    }
  };

  function doFlipGraph(graph, series) {
    series = series || getSeries();

    const oldPeriodicalChartOptions = graph.periodicallyChartOptions || {};
    if ((_.isEmpty(oldPeriodicalChartOptions) || oldPeriodicalChartOptions.invalidated) && !series.ExportOnly) {
      // options for charts
      const payload = getPeriodicalPayload(series);
      const options = {
        series: series,
        params: $scope.params,
        cache: $scope.cache,
        api: {
          requestData: payload
        },
        show: {
          distributionAndValue: true,
          relationalValueOnlyForFollowUp: false,
          relatedValueOnlyForFollowUp: false
        },
        chart: {
          serieClick: {
            enabled: true
          }
        }
      };

      // create highlighted facilities charts and average chart
      const chartsForAverage = _.get(
        ERDataService2.getVisuals(
          _.merge(
            {
              idType: 'Quantity',
              api: {
                responseData: $scope.reportData.savedData.periodicalAverage
              }
            },
            options
          )
        ),
        'charts',
        []
      );
      let chartsByFacility = {};
      if ($scope.settings.highlightedFacilities.length) {
        chartsByFacility = _.get(
          ERDataService2.getVisuals(
            _.merge(
              {
                idType: 'Facility',
                api: {
                  responseData: $scope.reportData.savedData.periodical
                }
              },
              options
            )
          ),
          'charts',
          {}
        );
      }

      // extend serie funstion
      const extendSerie = function(serie, namePrefix, color) {
        const oldSerieData = _.find(_.get(graph.periodicallyChartOptions), { name: serie.name });
        const isFollowUp = serie.startIndex === series.Start.length - 1;
        const isFirstComparisonPeriod = serie.startIndex === series.Start.length - 2;
        const dash = isFirstComparisonPeriod ? 'longDash' : 'dot';
        _.extend(serie, {
          name: `${namePrefix} ${serie.name}`,
          color: color,
          type: 'line',
          dashType: isFollowUp ? 'solid' : dash,
          visible: oldSerieData && oldSerieData.userSelected ? oldSerieData.visible : isFollowUp,
          userSelected: oldSerieData ? oldSerieData.userSelected || false : false
        });
      };

      // combine charts into same graph
      if (chartsForAverage.length) {
        const periodicallyChartOptions = _.get(_.find(chartsForAverage, { quantityId: graph.id }), 'chartOptions');

        if (periodicallyChartOptions) {
          // keep options in sync with series visibilities
          periodicallyChartOptions.legendItemClick = function(e) {
            const serie = periodicallyChartOptions.series[e.seriesIndex];
            if (serie) {
              serie.visible = !serie.visible;
              serie.userSelected = true;
            }
          };

          // get only correct property to average
          periodicallyChartOptions.series = _.reduce(
            periodicallyChartOptions.series,
            (result, serie) => {
              if (serie.property === graph.property) {
                extendSerie(serie, utils.localizedString('FACILITIES_REPORT.AVERAGE'), '#8a91ae');
                result.push(serie);
              }
              return result;
            },
            []
          );

          // get facilities series with correct property
          _.each(payload.FacilityId, (facilityId, index) => {
            const facility = $scope.cache.facilitiesById[facilityId];
            if (facility && _.isArray(chartsByFacility[facilityId]) && chartsByFacility[facilityId].length) {
              const indicator = indicators[index % indicators.length];
              const color = utils.getStyleRuleValue('background-color', `.${indicator}`, true) || '#000000';
              _.each(chartsByFacility[facilityId], graphForFacility => {
                _.each(_.get(graphForFacility, 'chartOptions.series'), serie => {
                  if (serie.property === graph.property && graphForFacility.quantityId === graph.id) {
                    extendSerie(serie, facility.Name, color);
                    periodicallyChartOptions.series.splice(periodicallyChartOptions.series.length - 1, 0, serie);
                  }
                });
              });
            }
          });

          // set value axises correctly
          const valueAxis = _.head(periodicallyChartOptions.valueAxis);
          if (_.isObject(valueAxis)) {
            periodicallyChartOptions.valueAxis = [valueAxis];
            _.each(periodicallyChartOptions.series, serie => {
              serie.axis = valueAxis.name;
            });
          }

          graph.periodicallyChartOptions = periodicallyChartOptions;
        }
      }
    }

    // save state
    const periodicallyGraphs = _.reduce(
      $scope.reportData.graphDataByQuantity,
      (result, graphDataForQuantity) => {
        result.push.apply(result, _.filter(graphDataForQuantity.graphData, { periodically: true }));
        return result;
      },
      []
    );
    _.extend($scope.params.view, {
      periodically: periodicallyGraphs.map(({ id, property }) => ({ id, property }))
    });
  }

  $scope.invalidatePeriodicalData = function(recreateCharts) {
    $scope.reportData.savedData.periodical = void 0;
    $scope.reportData.savedData.periodicalAverage = void 0;
    $scope.reportData.savedData.periodicalDeferred = void 0;

    if (recreateCharts) {
      const periodicallyGraphs = [];
      _.each($scope.reportData.graphDataByQuantity, graphDataForQuantity => {
        _.each(graphDataForQuantity.graphData, graph => {
          if (graph.periodicallyChartOptions) {
            graph.periodicallyChartOptions.invalidated = true;
          }
          if (graph.periodically) {
            periodicallyGraphs.push(graph);
          }
        });
      });
      if (periodicallyGraphs.length) {
        getPeriodicalData().then(() => {
          _.each(periodicallyGraphs, graph => {
            doFlipGraph(graph);
          });
        });
      }
    }
  };

  // listen to params changed event
  $scope.$on('facilities.paramsChanged', (event, paramsData) => {
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections', 'view');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      let callback;
      if (_.includes(interestedParams, 'quantityId')) {
        // remove unnecessary charts and columns
        _.remove(
          $scope.reportData.graphDataByQuantity,
          graphDataForQuantity => !_.includes($scope.params.quantityId, graphDataForQuantity.id)
        );
        $scope.reportData.tableData.gridOptions.columns = _.filter(
          $scope.reportData.tableData.gridOptions.columns,
          column => (column.quantityId ? _.includes($scope.params.quantityId, column.quantityId) : true)
        );
        if (_.difference(_.get(paramsData, 'oldParams.quantityId'), $scope.params.quantityId).length) {
          // quantities was just removed, so nothing to do except check grid sort
          handleGridSort();
          return;
        } else {
          // new quantities, so invalidate periodical data in callback
          const recreateCharts = $scope.params.series.Comparables === 'ByQuantities';
          callback = function() {
            $scope.invalidatePeriodicalData(recreateCharts);
          };
        }
      }
      if (_.includes(interestedParams, 'series') && $scope.reportData.savedData.series) {
        // check if time settings are changed and invalida periodical data in callback if needed
        const recreateCharts =
          !_.isEqual($scope.params.series.TimeFrame, $scope.reportData.savedData.series.TimeFrame) ||
          !_.isEqual($scope.params.series.Resolution, $scope.reportData.savedData.series.Resolution) ||
          !_.isEqual($scope.params.series.Start, $scope.reportData.savedData.series.Start) ||
          !_.isEqual($scope.params.series.Comparables, $scope.reportData.savedData.series.Comparables);
        const oldPayload = getPeriodicalPayload($scope.reportData.savedData.series);
        const newPayload = getPeriodicalPayload();
        if (recreateCharts || !_.isEqual(oldPayload, newPayload)) {
          callback = function() {
            $scope.invalidatePeriodicalData(recreateCharts);
          };
        }
      }
      if (_.includes(interestedParams, 'unitKey')) {
        callback = function() {
          $scope.invalidatePeriodicalData(true);
        };
      }
      $scope.regenerateData(callback);
    }
  });

  // listen to print starting event
  const printStartingUnbind = $rootScope.$on('print.starting', () => {
    // eslint-disable-next-line angular/window-service
    $timeout(window.print, 500);
  });

  $scope.$on('$destroy', () => {
    printStartingUnbind();
  });

  $scope.openModal = id => {
    const { changeType, series, unitKey } = $state.params;
    const reportParams = {
      changeType,
      facilityId: [id],
      series,
      unitKey
    };

    const { params, parentInitialized, defaultTimer, cache } = $scope;
    const modalParams = {
      cache,
      defaultTimer,
      parentInitialized,
      reportParams,
      scopeParams: params
    };

    $scope.parentInitialized().then(() => {
      erReportSettingsService.setActiveInstance('er-modal-report');
      modalService.getModalWithComponent('report-modal', modalParams);
    });
  };

  // initialize
  $scope.regenerateData();
}

FacilitiesReportComparisonController.$inject = $inject;

export default FacilitiesReportComparisonController;
