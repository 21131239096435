import _ from 'lodash';

import { ServiceLevel } from '@enerkey/clients/facility';

import { Roles } from '../../admin/constants/roles';
import { ErReportType } from '../constants/er-report-type';

const $inject = [
  '$scope',
  '$state',
  'energyReportingStates',
  'utils',
  'UserService',
  'appStatusService',
  '$transitions',
  'erReportSettingsService',
  'serviceLevelService',
];

function FacilitiesReportTypeController(
  $scope,
  $state,
  energyReportingStates,
  utils,
  UserService,
  appStatusService,
  $transitions,
  erReportSettingsService,
  serviceLevelService
) {
  const vm = this;

  // state params (for watching changes)
  const erReportSettings = erReportSettingsService.getInstance();
  $scope.stateParams = erReportSettings.getSettings();

  // check whether modal or facilities controller
  const parentState = $state.current.name.split('.')[0];

  function isMeterState(stateName) {
    const splitted = stateName.split('.');
    return splitted.length > 1 ? _.startsWith(splitted[1], 'meter') : false;
  }

  function isFacilityState(stateName) {
    const splitted = stateName.split('.');
    return splitted.length > 1 ? splitted[0] === 'modal' && !isMeterState(stateName) : false;
  }

  function isFacilitiesState(stateName) {
    const splitted = stateName.split('.');
    return splitted.length > 1 ? splitted[0] !== 'modal' && !isMeterState(stateName) : false;
  }

  // facilities, facility and meter states
  const energyReportingStateNames = _.map(energyReportingStates, 'name');
  const meterStates = _.filter(energyReportingStateNames, isMeterState);
  const facilityStates = _.filter(energyReportingStateNames, isFacilityState);
  const facilitiesStates = _.filter(energyReportingStateNames, isFacilitiesState);

  function validateFunction() {
    const param = isMeterState($state.current.name) ? 'meterId' : 'facilityId';
    return !!(_.isArray($scope.stateParams[param]) && $scope.stateParams[param].length);
  }

  function setEnegiaId() {
    // Async wait here is for reload case
    UserService.isInitializedWithInitialProfileAsync().then(() => {
      $scope.isEnegiaIdShown =
        UserService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES) &&
        (isMeterState($state.current.name) || isFacilityState($state.current.name));
      $scope.enegiaIdToShow = $scope.isEnegiaIdShown ? $state.params.EnegiaId : '';
    });
  }

  // report types
  vm.reportTypeConfig = {
    objectType: void 0,
    objectCount: 0,
    selectedType: void 0,
    types: [
      {
        titleKey: 'FACILITIES_REPORT.OVERVIEW.NAME',
        childStates: ['facility-overview'],
        visibleInStates: _.union(facilityStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: function() {
          return !isMeterState($state.current.name) ? true : validateFunction();
        }
      },
      {
        titleKey: 'FACILITIES.GRID',
        childStates: ['grid', 'metertree-treelist'],
        visibleInStates: _.union(facilitiesStates, meterStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: function() {
          return !isMeterState($state.current.name) ? true : validateFunction();
        }
      },
      {
        titleKey: 'FACILITIES.REPORT',
        childStates: ['report', 'metertree'],
        visibleInStates: _.union(facilitiesStates, facilityStates, meterStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: function() {
          return isMeterState($state.current.name) ? true : validateFunction();
        }
      },
      {
        titleKey: 'FACILITIES.SUM',
        childStates: ['report-sum', 'metertree-sum'],
        visibleInStates: _.union(facilitiesStates, meterStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: validateFunction
      },
      {
        titleKey: 'FACILITIES.TREND',
        childStates: ['report-trend', 'report-trend-sum', 'metertree-trend', 'metertree-trend-sum'],
        visibleInStates: _.union(facilitiesStates, facilityStates, meterStates),
        requiresRole: [],
        requiresServiceLevel: ServiceLevel.Large,
        visible: false,
        selected: false,
        disabled: false,
        validate: validateFunction
      },
      {
        titleKey: 'FACILITIES.COMPARISON',
        childStates: ['report-comparison', 'metertree-comparison'],
        visibleInStates: _.union(facilitiesStates, meterStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: validateFunction
      },
      {
        titleKey: 'FACILITIES.FORECAST',
        childStates: ['report-forecast', 'report-forecast-sum', 'metertree-forecast', 'metertree-forecast-sum'],
        visibleInStates: _.union(facilitiesStates, facilityStates, meterStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: validateFunction
      },
      {
        titleKey: 'YEARLY_TREND.TAB_TITLE',
        childStates: ['yearly-trend'],
        visibleInStates: _.union(facilitiesStates, facilityStates),
        requiresRole: [],
        visible: false,
        selected: false,
        disabled: false,
        validate: validateFunction
      }
    ]
  };

  vm.ServiceLevel = ServiceLevel;

  function resolveReportType(stateName) {
    const childState = _.last(stateName.split('.'));
    return _.find(vm.reportTypeConfig.types, reportType => _.includes(reportType.childStates, childState));
  }

  function refreshVisibilities() {
    _.each(vm.reportTypeConfig.types, reportType => {
      let visible = false;

      _.each(reportType.visibleInStates, state => {
        if ($state.includes(state)) {
          visible = true;
          return false;
        }
      });

      if (
        reportType.requiresServiceLevel !== undefined && reportType.requiresServiceLevel !== null &&
          !serviceLevelService.hasAtLeastServiceLevel(reportType.requiresServiceLevel)
      ) {
        visible = false;
      }

      // check if user has a needed roles
      if (reportType.requiresRole.length) {
        _.each(reportType.requiresRole, role => {
          if (!UserService.hasRole(role)) {
            visible = false;
            return false;
          }
        });
      }

      reportType.visible = visible;
    });
  }

  function refreshDisableStates() {
    _.each(vm.reportTypeConfig.types, reportType => {
      if (_.isFunction(reportType.validate)) {
        reportType.disabled = !reportType.validate();
      }
    });
  }

  function refreshObjectCount() {
    const param = isMeterState($state.current.name) ? 'meterId' : 'facilityId';
    vm.reportTypeConfig.objectCount = _.get($scope.stateParams, param, []).length;

    switch (param) {
      case 'meterId':
        vm.reportTypeConfig.objectType = 'METERS';
        break;
      case 'facilityId':
        vm.reportTypeConfig.objectType = !appStatusService.inModal ? 'FACILITIES' : void 0;
        break;
      default:
        break;
    }
  }

  function setSelectedReportType(selectedReportType) {
    vm.reportTypeConfig.selectedType = selectedReportType;
    _.each(vm.reportTypeConfig.types, reportType => {
      reportType.selected = reportType === selectedReportType;
    });
    if (selectedReportType.titleKey === 'FACILITIES.SUM') {
      $scope.params.reportType = ErReportType.DEFAULT_REPORT;
    }
  }

  function changeReport() {
    let states;

    if (isMeterState($state.current.name)) {
      states = meterStates;
    } else if (isFacilityState($state.current.name)) {
      states = facilityStates;
    } else {
      states = facilitiesStates;
    }

    const state = _.find(states, object =>
      _.includes(_.get(vm.reportTypeConfig.selectedType, 'childStates'), _.last(object.split('.'))));

    if ($state.get(state) && $state.current.name !== state) {
      $state.go(state, $scope.stateParams);
    }
  }

  vm.reportTypeChanged = function(selectedReportType) {
    if (!selectedReportType.disabled) {
      setSelectedReportType(selectedReportType);
      changeReport();
    }
  };

  vm.getTitleForReportType = function(reportType) {
    let title;
    if (reportType.disabled) {
      title = utils.localizedString(
        `FACILITIES_REPORT.CHOOSE_${isMeterState($state.current.name) ? 'METERS' : 'FACILITIES'}`
      );
    }
    return title;
  };

  vm.onFacilitiesEdited = function() {
    $state.reload();
  };

  const stateChangeSuccessUnbind = $transitions.onSuccess({}, transition => {
    const toState = transition.to().name;
    if (toState.includes(parentState)) {
      refreshVisibilities();
      refreshDisableStates();
      refreshObjectCount();
      setSelectedReportType(resolveReportType(toState));
    }
  });

  $scope.$on('$destroy', () => {
    stateChangeSuccessUnbind();
  });

  // refresh visibilities, disabled, object count and enegiaId
  refreshVisibilities();
  refreshDisableStates();
  refreshObjectCount();
  setEnegiaId();

  // handle setting report type
  setSelectedReportType(resolveReportType($state.$current.name));
}

FacilitiesReportTypeController.$inject = $inject;

export default FacilitiesReportTypeController;
