import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';

import { AttachmentsClient, DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../components/report-modal/report-modal.component';
import { ReportType } from '../shared/report-type';
import { UserService } from '../../../services/user-service';
import { BookmarkService } from '../../../services/bookmark.service';
import { ReportingSearchService } from './reporting-search.service';
import { ReportingParams } from '../reporting.states';
import { ReportModalMetersService } from './report-modal-meters.service';
import { ReportingTab } from '../models/reporting-tab.model';

@Injectable()
export class ReportModalService implements OnDestroy {
  public readonly reportType$: Observable<ReportType>;
  public readonly meterReportType$: Observable<ReportType>;
  public readonly meterGroupReportType$: Observable<ReportType>;
  public readonly reportingTab$: Observable<ReportingTab>;
  public readonly facilityMeterMaps$: Observable<DocumentCompleteViewModel[]>;

  private readonly _reportType$ = new ReplaySubject<ReportType>(1);
  private readonly _meterReportType$ = new BehaviorSubject<ReportType>(ReportType.Period);
  private readonly _meterGroupReportType$ = new BehaviorSubject(ReportType.Period);
  private readonly _reportingTab$ = new BehaviorSubject(ReportingTab.Quantity);
  private readonly _destroy$ = new Subject<void>();

  private readonly reportingParams$: Observable<ReportingParams>;

  public constructor(
    attachmentsClient: AttachmentsClient,
    userService: UserService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    bookmarkService: BookmarkService,
    reportingSearchService: ReportingSearchService,
    reportModalMetersService: ReportModalMetersService
  ) {
    this.reportType$ = this._reportType$.asObservable();
    this.meterReportType$ = this._meterReportType$.asObservable();
    this.meterGroupReportType$ = this._meterGroupReportType$.asObservable();
    this.reportingTab$ = this._reportingTab$.asObservable();

    this.facilityMeterMaps$ = attachmentsClient.getMetermap(userService.profileId, modalParams.facilityId).pipe(
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this._reportType$.next(modalParams.initialReportType);

    this.reportingParams$ = combineLatest([
      reportingSearchService.searchParameters$,
      reportingSearchService.visibleSections$,
      reportingSearchService.chartVisibility$,
      reportingSearchService.gridVisibility$,
      reportingSearchService.meterInfoVisibility$,
      reportingSearchService.facilityIds$,
      reportModalMetersService.selectedMeters$
    ]).pipe(
      switchMap(([searchParams, sections, charts, grids, meterInfo, facilityIds, meters]) =>
        of({
          ...searchParams.formValue,
          periods: searchParams.formValue.periods.map(p => p.toISOString()),
          sections: [...sections, ...(meterInfo ? ['MeterDetails'] : [])],
          charts,
          grids,
          facilityIds,
          meterIds: meters.meterIds,
        })),
      takeUntil(this._destroy$)
    );

    combineLatest([
      this.reportType$,
      this.meterReportType$,
      this.meterGroupReportType$,
      this.reportingTab$,
      this.reportingParams$
    ]).pipe(
      map(([quantityReportType, meterReportType, meterGroupReportType, reportingTab, reportingParams]) => {
        const reportType = this.getReportTypeByReportingTab(
          reportingTab, meterReportType, meterGroupReportType, quantityReportType
        );
        return { reportType, reportingParams, reportingTab };
      }),
      tap(({ reportType, reportingParams, reportingTab }) => {
        bookmarkService.handleReportingModalChange({
          name: `reporting.${reportType}`,
          modal: true,
          stateParams: reportingParams
        }, reportingTab);
      }),
      takeUntil(this._destroy$)
    ).subscribe();

    if (modalParams.meterIds?.length) {
      this.changeReportingTab(ReportingTab.Meter);
      this.moveToMeterReportOfType(modalParams.initialReportType);
    }

    if (modalParams.presentation) {
      reportingSearchService.toggleSections(modalParams.presentation.sections);
      reportingSearchService.setChartsVisibility(modalParams.presentation.charts);
      reportingSearchService.setGridsVisibility(modalParams.presentation.grids);
      reportingSearchService.setMeterInfoVisibility(modalParams.presentation.meterInfo);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public moveToReportOfType(reportType: ReportType): void {
    this._reportType$.next(reportType);
  }

  public moveToMeterReportOfType(reportType: ReportType): void {
    this._meterReportType$.next(reportType);
  }

  public moveToMeterGroupReportOfType(reportType: ReportType): void {
    this._meterGroupReportType$.next(reportType);
  }

  public changeReportingTab(reportType: ReportingTab): void {
    this._reportingTab$.next(reportType);
  }

  private getReportTypeByReportingTab(
    reportingTab: ReportingTab,
    meterReportType: ReportType,
    meterGroupReportType: ReportType,
    quantityReportType: ReportType
  ): ReportType {
    switch (reportingTab) {
      case ReportingTab.Meter: return meterReportType;
      case ReportingTab.Quantity: return quantityReportType;
      case ReportingTab.MeterGroup: return meterGroupReportType;
      default: throw new Error(`Unknown reporting tab "${reportingTab}"`);
    }
  }
}
