import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { UserResponse } from '@enerkey/clients/customer-admin';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionNotificationService {
  public readonly selectedUserId$: Observable<number>;
  public readonly selectedFacilityId$: Observable<number>;
  public readonly userGridViewChange$: Observable<UserResponse[]>;

  private readonly _selectedUserId$ = new BehaviorSubject<number>(null);
  private readonly _selectedFacilityId$ = new BehaviorSubject<number>(undefined);
  private readonly _userGridViewChange$ = new BehaviorSubject<UserResponse[]>([]);

  public constructor() {
    this.selectedUserId$ = this._selectedUserId$.asObservable();
    this.selectedFacilityId$ = this._selectedFacilityId$.asObservable();
    this.userGridViewChange$ = this._userGridViewChange$.asObservable();
  }

  public setSelectedUserId(userId: number): void {
    this._selectedUserId$.next(userId);
  }

  public setSelectedFacilityId(facilityId: number): void {
    this._selectedFacilityId$.next(facilityId);
  }

  public setUserGridViewChange(users: UserResponse[]): void {
    this._userGridViewChange$.next(users);
  }

  public getUserGridViewChange(): UserResponse[] {
    return this._userGridViewChange$.getValue();
  }

  public clearSelectedFacilityId(): void {
    this._selectedFacilityId$.next(undefined);
  }
}
