import { camelCase } from 'lodash';
import moment from 'moment';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { formGroupFrom } from '@enerkey/ts-utils';
import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { FacilityProperty, PropertyType, WaterHeatingEnergyCalculation } from '@enerkey/clients/facility';

import { FacilityEditService } from '../../services/facility-edit.service';
import {
  facilityPropertyEnums,
  facilityPropertyEnumTranslations,
  shareOfTemperatureFixingTranslations
} from '../../../../constants/facility.constant';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { localToUtc } from '../../../../shared/date.functions';

type PropertyForm = {
  value: number,
  date: Date,
  dropdown: number
}

type FilterParams = {
  propertyId: number,
  propertyType: PropertyType,
  newPropertyMode: boolean
}

type PropertyInfo = {
  name: string,
  type: PropertyType
}

@Component({
  templateUrl: './facility-property-history-modal.component.html',
  styleUrls: ['./facility-property-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityPropertyHistoryModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public newPropertyMode = false;
  public propertyName: string | undefined;
  public propertyId: number | undefined;
  public rowInEdit: number | undefined;
  public propertyInfo: PropertyInfo | undefined;

  public readonly facilityProperties$: Observable<FacilityProperty[]>;
  public readonly propertyForm: UntypedFormGroup;
  public readonly shareOfTemperatureFixingTranslations = shareOfTemperatureFixingTranslations;
  public readonly PropertyType = PropertyType;
  public readonly customTypeDropdown: ComboItem<number>[] = [];
  public readonly enumDropdown: ComboItem<string>[] = [];
  public readonly enumTranslations: Record<string, string> = {};
  public readonly modifiedPropertyIds$: Observable<Record<number, boolean>>;

  private newProperty: FacilityProperty | undefined;

  private readonly _filterParams$ = new ReplaySubject<FilterParams>(1);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityEditService: FacilityEditService,
    private readonly translateService: TranslateService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.propertyForm = formGroupFrom<PropertyForm>({
      value: new UntypedFormControl(null, Validators.required),
      date: new UntypedFormControl(new Date(), Validators.required),
      dropdown: new UntypedFormControl(null, Validators.required)
    });
    this.facilityProperties$ = this._filterParams$.pipe(
      switchMap(filterParams => this.facilityEditService.facilityProperties$.pipe(
        map(properties => {
          if (filterParams.newPropertyMode) {
            this.newProperty = this.getEmptyProperty();
            this.rowInEdit = 0;
            return [this.newProperty];
          }
          if (filterParams.propertyType === PropertyType.QuantityDecimal) {
            return properties
              .filter(p => p.quantityId === filterParams.propertyId)
              .sortBy('fromDate', 'desc');
          } else {
            return properties
              .filter(p => p.propertyId === filterParams.propertyId)
              .sortBy('fromDate', 'desc');
          }
        })
      )),
      takeUntil(this._destroy$)
    );
    this.modifiedPropertyIds$ = this.facilityEditService.modifiedPropertyIds$.pipe(
      map(ids => ids.toRecord(id => id, _ => true))
    );
  }

  public ngOnInit(): void {
    this._filterParams$.next({
      propertyId: this.propertyId,
      propertyType: this.propertyInfo.type,
      newPropertyMode: this.newPropertyMode
    });
    if (this.propertyInfo.type === PropertyType.Enum) {
      const items: ComboItem<string>[] = [];
      facilityPropertyEnums[camelCase(this.propertyInfo.name)].forEach(x => {
        const [name, key] = x;
        this.enumTranslations[name] = facilityPropertyEnumTranslations[this.propertyInfo.name][key];
        items.push({
          value: name,
          text: this.translateService.instant(
            facilityPropertyEnumTranslations[this.propertyInfo.name][key]
          )
        });
      });
      this.enumDropdown.push(...items);
    }
    if (this.propertyInfo.type === PropertyType.CustomType) {
      this.customTypeDropdown.push(...Object.keys(
        shareOfTemperatureFixingTranslations
      ).map(x => ({
        value: parseInt(x),
        text: this.translateService.instant(
          shareOfTemperatureFixingTranslations[parseInt(x) as WaterHeatingEnergyCalculation]
        )
      })));
    } else {
      this.propertyForm.get('dropdown').disable();
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._filterParams$.complete();
  }

  public addNewProperty(properties: FacilityProperty[], index: number = 0): void {
    const newProperty = this.getEmptyProperty();
    properties.splice(index, 0, newProperty);
    this.rowInEdit = index;
    this.newProperty = newProperty;
    this.propertyForm.patchValue({
      value: null,
      date: index === 0
        ? new Date(new Date().setHours(0, 0, 0, 0))
        : this.getMaxDate(properties, index),
      dropdown: null
    });
  }

  public cancelOperation(properties: FacilityProperty[], edit: boolean): void {
    if (!edit) {
      // User cancels new value creation, remove created row
      properties.splice(this.rowInEdit, 1);
      if (properties.length === 0) {
        super.dismissModal();
      }
    }
    // User cancels value edit, stop row editing
    this.rowInEdit = undefined;
  }

  public saveNewProperty(): void {
    if (this.newPropertyMode) {
      this.newPropertyMode = false;
      this._filterParams$.next({
        propertyId: this.propertyId,
        propertyType: this.propertyInfo?.type,
        newPropertyMode: this.newPropertyMode
      });
    }
    const data: PropertyForm = this.propertyForm.value;
    this.facilityEditService.addNewProperty(
      new FacilityProperty({
        id: null,
        fromDate: localToUtc(data.date),
        value: data.value.toString(),
        propertyId: this.newProperty.propertyId,
        quantityId: this.newProperty.quantityId,
        customType: data.dropdown
      })
    );
    this.newProperty = undefined;
    this.rowInEdit = undefined;
  }

  public saveChanges(property: FacilityProperty): void {
    const data: PropertyForm = this.propertyForm.value;
    this.facilityEditService.editPropertyValue(property, data.value.toString(), data.dropdown);
    this.rowInEdit = undefined;
  }

  public editProperty(property: FacilityProperty, index: number): void {
    this.rowInEdit = index;
    this.propertyForm.patchValue({
      date: property.fromDate,
      value: this.propertyInfo.type === PropertyType.Enum
        ? property.value
        : parseInt(property.value),
      dropdown: property.customType
    });
  }

  public removeProperty(properties: FacilityProperty[], index: number): void {
    this.facilityEditService.removeProperty(properties[index]);
    if (properties.length === 1) {
      super.dismissModal();
    }
  }

  public checkDateRange(properties: FacilityProperty[], index: number): boolean {
    const start = properties[index]?.fromDate;
    const end = properties[index + 1]?.fromDate;
    if (!end) {
      return true;
    }
    return moment.utc(start).diff(moment.utc(end), 'days') > 1;
  }

  public getMaxDate(properties: FacilityProperty[], index: number): Date {
    index -= 1;
    if (index < 0 || index >= properties.length) {
      return undefined;
    }
    return moment.utc(properties[index]?.fromDate).subtract(1, 'd').toDate();
  }

  public getMinDate(properties: FacilityProperty[], index: number): Date {
    index += 1;
    if (index < 0 || index >= properties.length) {
      return undefined;
    }
    return moment.utc(properties[index]?.fromDate).add(1, 'd').toDate();
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getEmptyProperty(): FacilityProperty {
    return new FacilityProperty({
      id: null,
      fromDate: new Date(),
      value: null,
      propertyId: this.propertyInfo.type !== PropertyType.QuantityDecimal
        ? this.propertyId
        : 26,
      quantityId: this.propertyInfo.type === PropertyType.QuantityDecimal
        ? this.propertyId
        : null
    });
  }
}
