import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, Subject, take, takeUntil, tap } from 'rxjs';

import {
  MeterGroupTreeItem,
  ReportModalMeterGroupService
} from '../../services/report-modal-meter-group.service';

@Component({
  selector: 'meter-group-tree',
  templateUrl: './meter-group-tree.component.html',
  styleUrl: './meter-group-tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterGroupTreeComponent implements OnDestroy {

  public readonly nodes$: Observable<MeterGroupTreeItem[]>;
  public readonly expandedKeys$: Observable<string[]>;
  public readonly checkedKeys$: Observable<string[]>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _expandedKeys$ = new BehaviorSubject<string[]>([]);

  public constructor(
    private readonly reportMeterGroupService: ReportModalMeterGroupService
  ) {
    this.expandedKeys$ = this._expandedKeys$.asObservable();
    this.nodes$ = this.reportMeterGroupService.meterGroupsTree$.pipe(shareReplay(1));
    this.checkedKeys$ = this.reportMeterGroupService.checkedKeys$;

    // Expand all root nodes
    this.nodes$.pipe(
      tap(nodes => this.setExpandedKeys(nodes.map((_, index) => `${index}`))),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onExpandedKeysChange(keys: string[]): void {
    this.setExpandedKeys(keys);
  }

  public onCheckedKeysChange(keys: string[]): void {
    this.setCheckedKeys(keys);
  }

  private setExpandedKeys(keys: string[]): void {
    this._expandedKeys$.next(keys);
  }
  private setCheckedKeys(keys: string[]): void {
    this.reportMeterGroupService.setCheckedKeys(keys);
    this.updateSelectedMeters(keys);
  }

  private updateSelectedMeters(keys: string[]): void {
    this.nodes$.pipe(take(1)).subscribe(nodes => {
      const selectedGroups: MeterGroupTreeItem[] = [];
      nodes.forEach((group, groupIndex) => {
        const selectedMeters = group.meters.filter((_, meterIndex) => keys.includes(`${groupIndex}_${meterIndex}`));
        if (selectedMeters.length > 0) {
          const selectedGroup = new MeterGroupTreeItem(
            group.id,
            group.name,
            selectedMeters,
            group.description,
            group.facilityId
          );
          selectedGroups.push(selectedGroup);
        }
      });
      this.reportMeterGroupService.setSelectedMeterGroups(selectedGroups);
    });
  }
}
