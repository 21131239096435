import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { NgfTabChangeEvent, NgfTabsetComponent } from '@enerkey/foundation-angular';

import { ReportType } from '../../shared/report-type';

import { ReportModalService } from '../../services/report-modal.service';

@Component({
  selector: 'modal-metergroup-reports',
  templateUrl: './modal-metergroup-reports.component.html',
  styleUrl: './modal-metergroup-reports.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMeterGroupReportsComponent implements OnDestroy {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public readonly ReportType = ReportType;

  public readonly reportType$: Observable<ReportType>;

  private _destroy$ = new Subject<void>();

  public constructor(
    private readonly reportModalService: ReportModalService
  ) {
    this.reportType$ = this.reportModalService.meterGroupReportType$;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
  public tabChange(tab: NgfTabChangeEvent): void {
    this.reportModalService.moveToMeterGroupReportOfType(tab.nextId as ReportType);
  }

}
