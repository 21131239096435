import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { combineLatest, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';

import { TranslateService } from '@ngx-translate/core';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { anyOf, indicate, LoadingSubject } from '@enerkey/rxjs';
import { FacilityFrequencyBase } from '@enerkey/clients/email-consumption-report';

import {
  AddOrUpdateEmailSubscriptionSettingsRequest,
  CustomerAdminClient, FacilityFrequency
} from '@enerkey/clients/customer-admin';

import { FacilityService } from '../../../../shared/services/facility.service';
import { EkKendoModule } from '../../../../shared/ek-kendo/ek-kendo.module';
import { SharedModule } from '../../../../shared/shared.module';
import { EmailConsumptionReportService } from '../../services/email-consumption-report.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

export interface EmailNotificationModel {
  facilityId: number,
  name: string,
  weekly: boolean,
  monthly: boolean
}

@Component({
  selector: 'enerkey-email-notification-settings',
  templateUrl: './email-notification-settings.component.html',
  styleUrls: ['./email-notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [EkKendoModule, GridModule, SharedModule, InputsModule],
  providers: [EmailConsumptionReportService]
})
@ModalOptions({ size: 'tiny' })
export class EmailNotificationSettingsComponent extends ModalBase<void> implements OnInit, OnDestroy {
  @Input() public isCustomerAdmin: boolean = false;
  @Input() public userName: string;
  public readonly loading$: Observable<boolean>;

  public gridView: EmailNotificationModel[];
  public modalTitle: string;
  public isConsumptionEdited: boolean = false;
  private initialGridView: EmailNotificationModel[];

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly facilityService: FacilityService,
    private readonly emailConsumptionReportService: EmailConsumptionReportService,
    private readonly toasterService: ToasterService,
    private readonly customerAdminClient: CustomerAdminClient,
    private readonly translate: TranslateService
  ) {
    super(currentModal);

    this.loading$ = anyOf(
      this._loading$.asObservable(),
      this.emailConsumptionReportService.consumptionSubscriptionLoading$
    );
  }

  public ngOnInit(): void {
    this.modalTitle = this.isCustomerAdmin
      ? `${this.translate.instant('EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_TITLE')} - ${this.userName}`
      : this.translate.instant('EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_TITLE');

    if (this.isCustomerAdmin) {
      this.customerAdminEmailSubscription();
    } else {
      this.defaultEmailSubscription();
    }
  }

  public get infoTextTranslationParams(): { userName: string, customerPrivacyUrl: string } {
    return { userName: this.emailConsumptionReportService.userName, customerPrivacyUrl: 'https://global.eg.dk/about-eg/treatment-of-data/' };
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(): void {
    if (this.isCustomerAdmin) {
      this.updateCustomerAdminEmailSubscription();
    } else {
      this.updateDefaultEmailSubscription();
    }
  }

  public close(): void {
    super.closeModal();
  }

  private customerAdminEmailSubscription(): void {
    this.emailConsumptionReportService.customerConsumptionSubscriptions$.pipe(
      map(facilities => facilities.map(facility => ({
        facilityId: facility.facilityId,
        name: facility.facilityName,
        weekly: facility.weekly,
        monthly: facility.monthly,
      }) as EmailNotificationModel).sortBy('name')),
      takeUntil(this._destroy$)
    ).subscribe({
      next: data => {
        this.gridView = data;
        this.initialGridView = this.gridView.map(g => ({ ...g }));
      }
    });
  }

  private defaultEmailSubscription(): void {
    combineLatest([
      this.facilityService.profileFacilities$,
      this.emailConsumptionReportService.consumptionEmailSubscriptions$
    ]).pipe(
      map(([facilities, consumptionSubscriptions]) => facilities.map(f => {
        const { weekly, monthly } = consumptionSubscriptions.find(sub => sub.facility === f.facilityId) ??
        { weekly: false, monthly: false };
        return { name: f.name, facilityId: f.facilityId, weekly, monthly } as EmailNotificationModel;
      }).sortBy('name')),
      takeUntil(this._destroy$)
    ).subscribe({
      next: data => {
        this.gridView = data;
      }
    });
  }

  private updateDefaultEmailSubscription(): void {
    this.emailConsumptionReportService.addEmailConsumptionSubscription(this.gridView.map(g =>
      new FacilityFrequencyBase({
        facility: g.facilityId,
        monthly: g.monthly,
        weekly: g.weekly
      })))
      .pipe(
        indicate(this._loading$),
        takeUntil(this._destroy$)
      ).subscribe({
        next: () => {
          this.toasterService.success('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_SUCCESS');
          this.close();
        },
        error: () => {
          this.toasterService.error('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_ERROR');
          this.isConsumptionEdited = false;
        }
      });
  }

  private updateCustomerAdminEmailSubscription(): void {
    const changedSubscriptions = this.gridView.filter(item => {
      const original = this.initialGridView.find(o => o.facilityId === item.facilityId);
      return original && (original.weekly !== item.weekly || original.monthly !== item.monthly);
    });

    const modifiedSubscriptions: FacilityFrequency[] = changedSubscriptions.map(g => ({
      facilityId: g.facilityId,
      weekly: g.weekly,
      monthly: g.monthly
    }));
    this.emailConsumptionReportService.selectedUserId$.pipe(
      switchMap(userId => {
        const subscriptions: AddOrUpdateEmailSubscriptionSettingsRequest = {
          userId,
          facilityFrequencies: modifiedSubscriptions
        };
        return this.customerAdminClient.insertOrUpdateSubscription(subscriptions);
      })
    )
      .pipe(
        indicate(this._loading$),
        takeUntil(this._destroy$)
      ).subscribe({
        next: () => {
          this.toasterService.success('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_SUCCESS');
          this.close();
        },
        error: () => {
          this.toasterService.error('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_ERROR');
          this.isConsumptionEdited = false;
        }
      });
  }
}
