import { AlarmTypeEnum } from '@enerkey/clients/alarm';
import { FunctionType } from '@enerkey/clients/contact';

import { AlarmExecutePreset } from '../modules/alarm-management/interfaces/alarm-execute-preset';

export const alarmTypeTranslation: Readonly<Record<AlarmTypeEnum, string>> = {
  [AlarmTypeEnum.None]: '-',
  [AlarmTypeEnum.ConsumptionChangeMonth]: 'ALARM.ALARM_TYPE.CONSUMPTION_CHANGE_MONTH',
  [AlarmTypeEnum.ConsumptionChangeFromTheYearBefore]: 'ALARM.ALARM_TYPE.CONSUMPTION_CHANGE_FROM_THE_YEAR_BEFORE',
  [AlarmTypeEnum.ConsumptionChangeWeek]: 'ALARM.ALARM_TYPE.CONSUMPTION_CHANGE_WEEK',
  [AlarmTypeEnum.ConsumptionChangeDay]: 'ALARM.ALARM_TYPE.CONSUMPTION_CHANGE_DAY',
  [AlarmTypeEnum.ExceedingTotalMonthlyConsumptionLimit]: 'ALARM.ALARM_TYPE.EXCEEDING_TOTAL_MONTHLY_CONSUMPTION_LIMIT',
  [AlarmTypeEnum.ExceedingTotalWeeklyConsumptionLimit]: 'ALARM.ALARM_TYPE.EXCEEDING_TOTAL_WEEKLY_CONSUMPTION_LIMIT',
  [AlarmTypeEnum.ExceedingTotalDailyConsumptionLimit]: 'ALARM.ALARM_TYPE.EXCEEDING_TOTAL_DAILY_CONSUMPTION_LIMIT',
  [AlarmTypeEnum.ExceedingReactivePowerFreeShare]: 'ALARM.ALARM_TYPE.EXCEEDING_REACTIVE_POWER_FREE_SHARE',
  [AlarmTypeEnum.MaxHourlyConsumptionMonth]: 'ALARM.ALARM_TYPE.MAX_HOURLY_CONSUMPTION_MONTH',
  [AlarmTypeEnum.MaxHourlyConsumptionWeek]: 'ALARM.ALARM_TYPE.MAX_HOURLY_CONSUMPTIO_NWEEK',
  [AlarmTypeEnum.MaxHourlyConsumptionDay]: 'ALARM.ALARM_TYPE.MAX_HOURLY_CONSUMPTION_DAY',
  [AlarmTypeEnum.MinHourlyConsumptionMonth]: 'ALARM.ALARM_TYPE.MIN_HOURLY_CONSUMPTION_MONTH',
  [AlarmTypeEnum.MinHourlyConsumptionWeek]: 'ALARM.ALARM_TYPE.MIN_HOURLY_CONSUMPTION_WEEK',
  [AlarmTypeEnum.MinHourlyConsumptionDay]: 'ALARM.ALARM_TYPE.MIN_HOURLY_CONSUMPTION_DAY',
  [AlarmTypeEnum.CoolingLimitMonth]: 'ALARM.ALARM_TYPE.COOLING_LIMIT_MONTH',
  [AlarmTypeEnum.CoolingLimitWeek]: 'ALARM.ALARM_TYPE.COOLING_LIMIT_WEEK',
  [AlarmTypeEnum.CoolingLimitDay]: 'ALARM.ALARM_TYPE.COOLING_LIMIT_DAY',
  [AlarmTypeEnum.NormalizedConsumptionChangeYear]: 'ALARM.ALARM_TYPE.NORMALIZED_CONSUMPTION_CHANGE_YEAR',
  [AlarmTypeEnum.WarmingLimitMonth]: 'ALARM.ALARM_TYPE.WARMING_LIMIT_MONTH',
  [AlarmTypeEnum.WarmingLimitWeek]: 'ALARM.ALARM_TYPE.WARMING_LIMIT_WEEK',
  [AlarmTypeEnum.WarmingLimitDay]: 'ALARM.ALARM_TYPE.WARMING_LIMIT_DAY',
  [AlarmTypeEnum.ExternalAlarm]: 'ALARM.ALARM_TYPE.EXTERNAL_ALARM',
  [AlarmTypeEnum.EtCurveDeviationDay]: 'ALARM.ALARM_TYPE.ETCURVE_DEVIATION_DAY',
  [AlarmTypeEnum.EtCurveDeviationWeek]: 'ALARM.ALARM_TYPE.ETCURVE_DEVIATION_WEEK',

};

export const etCurveAlarmTypes: readonly Readonly<AlarmTypeEnum>[] = [
  AlarmTypeEnum.EtCurveDeviationDay,
  AlarmTypeEnum.EtCurveDeviationWeek
];

type HookTypeNames = keyof typeof FunctionType;

export const hookTypeTranslation = new Map<HookTypeNames, string>([
  ['Superintendent', 'ADMIN.CONTACTS.FUNCTION.SUPERINTENDENT'],
  ['Manager', 'ADMIN.CONTACTS.FUNCTION.MANAGER'],
  ['Service', 'ADMIN.CONTACTS.FUNCTION.SERVICE'],
  ['Owner', 'ADMIN.CONTACTS.FUNCTION.OWNER'],
  ['Alarm', 'ADMIN.CONTACTS.FUNCTION.ALARM']
]) as ReadonlyMap<string, string>;

export const execDaily: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.DAILY',
  cron: '0 9 * * *'
};

const execWorkingDays: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.WORKING_DAYS',
  cron: '0 9 * * 1-5'
};

const execWeekends: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.WEEKENDS',
  cron: '0 9 * * 0,6'
};

export const execOnMonday: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.ON_MONDAY',
  cron: '0 9 * * 1'
};

const execOnWednesday: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.ON_WEDNESDAY',
  cron: '0 9 * * 3'
};

export const execOnMondayWinter: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.ON_MONDAY_WINTER',
  cron: '0 9 * 1-5,11-12 1'
};

export const execMonthFifth: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.MONTH_FIFTH',
  cron: '0 10 5 * *'
};

const execMonthFifteenth: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.MONTH_FIFTEENTH',
  cron: '0 10 15 * *'
};

export const execMonthlyWinter: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.MONTHLY_WINTER',
  cron: '0 10 5 1-5,11-12 *'
};

const execMonthlySummer: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.MONTHLY_SUMMER',
  cron: '0 10 5 7-9 *'
};

const execFourTimePerMonth: Readonly<AlarmExecutePreset> = {
  name: 'ALARM.CRON_EXECUTE.FOUR_TIMES_PER_MONTH',
  cron: '0 10 7,14,21,28 * *'
};

export const alarmExecutePresets: readonly Readonly<AlarmExecutePreset>[] = [
  execDaily,
  execWorkingDays,
  execWeekends,
  execOnMonday,
  execOnWednesday,
  execOnMondayWinter,
  execMonthFifth,
  execMonthFifteenth,
  execMonthlyWinter,
  execMonthlySummer,
  execFourTimePerMonth
];
