import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  Co2EmissionRequest,
  CostReportingRequest,
  EnergyTargetsRequest,
  ExcludeIncomplete,
  FacilityConsumptionRequest,
  FacilityDerivedResponse,
  FacilityRelatedQuantityRequest,
  FacilitySpecificConsumptionRequest,
  QuantityCo2EmissionRequest,
  QuantityDerivedResponse,
  QuantityDistributionRequest,
  QuantitySpecificConsumptionRequest,
  ReportingClient,
  ReportingUnit,
  RequestDuration,
  RequestResolution,
  SumConsumption,
  SumConsumptionRequest,
  SumConsumptionResponse,
  SumConsumptionValue,
  TimeSeriesResponse,
} from '@enerkey/clients/reporting';
import {
  WeatherClient,
  WeatherResponseSumReport,
  WeatherSearchRequest
} from '@enerkey/clients/weather';

import { Quantities, Quantity } from '@enerkey/clients/metering';

import { ColorService } from '../../../shared/services/color.service';
import { QuantityService } from '../../../shared/services/quantity.service';
import { RelationalValuesService } from '../../../shared/services/relational-values.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { targetSeriesDefinitions } from '../shared/target-series-definitions';
import { durationToString } from '../shared/duration-to-string';
import { ReportingDistributionType } from '../shared/reporting-search-form-value';
import { ToasterService } from '../../../shared/services/toaster.service';
import {
  ChartLineOpacity,
  defaultChartLineWidth,
  ReportingData,
  ReportingDataServiceBase,
  ReportingGraphColorType,
  ReportingSeriesById,
  SerieChartOptions,
  TitleParams
} from './reporting-data-service-base';
import { sumKey } from './sum-report.service';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { createCostSeriesData, createReportingSeries } from './costs-report.functions';
import {
  meterBasedCostsIdMatch,
  meterBasedCostsNameMatch,
  nationalCostsIdMatch,
  nationalCostsNameMatch
} from '../constants/costs-report-constants';
import { ReportType } from '../shared/report-type';
import { localToUtc } from '../../../shared/date.functions';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { quantityTranslations } from '../../../constants/quantity.constant';

type DistributionTypes = Exclude<ReportingDistributionType, ReportingDistributionType.None>;
type RelatedQuantity = Quantity & { quantityId: number }
const distributionTranslations: Record<DistributionTypes, [string, string]> = {
  [ReportingDistributionType.DayNight]: ['REPORTING.DISTRIBUTION_TYPE.DAY', 'REPORTING.DISTRIBUTION_TYPE.NIGHT'],
  [ReportingDistributionType.Weekday]: ['REPORTING.DISTRIBUTION_TYPE.WORKDAY', 'REPORTING.DISTRIBUTION_TYPE.HOLIDAY'],
};

@Injectable({ providedIn: 'root' })
export class ReportingDataService extends ReportingDataServiceBase {
  public constructor(
    colorService: ColorService,
    translateService: TranslateService,
    toasterService: ToasterService,
    quantityService: QuantityService,
    private readonly reportingClient: ReportingClient,
    private readonly weatherClient: WeatherClient,
    private readonly relationalValuesService: RelationalValuesService
  ) {
    super(
      colorService, translateService, toasterService, quantityService
    );
  }

  public getMeasuredValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);
    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          quantityIds.map(qId => forkJoin({
            series: this.getQuantityData({
              reportType,
              params,
              facilityIds,
              quantityId: qId,
              normalization: false,
              chartOptions,
              isCumulative,
              titleParams
            }),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getNormalizedValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!params.normalized) {
      return of([]);
    }
    return this.getMeaningfulNormalizedQuantityIds(params.quantityIds).pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }

        return forkJoin(quantityIds.map(
          qId => forkJoin({
            series: this.getQuantityData({
              reportType,
              params,
              facilityIds,
              quantityId: qId,
              normalization: true,
              chartOptions,
              isCumulative,
              titleParams
            }),
            quantityId: of(qId)
          })
        ));
      })
    );
  }

  public getMeasuredDerivedValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    averageQuantities: Quantities[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params.derivedIds)) {
      return of([]);
    }

    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);

    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        const quantityIdsWithoutAverage = quantityIds.filter(qId => !averageQuantities?.includes(qId));
        return forkJoin(
          quantityIdsWithoutAverage.flatMap(qId => params.derivedIds.map(dId => forkJoin({
            series: this.getQuantityDerivedData(
              reportType,
              params,
              facilityIds,
              qId,
              false,
              dId,
              chartOptions,
              isCumulative,
              titleParams,
              isSumReport,
              false
            ),
            quantityId: of(qId),
            derivedId: of(dId),
          })))
        );
      })
    );
  }

  public getNormalizedDerivedValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    averageQuantities: Quantities[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!(Array.hasItems(params.derivedIds) && params.normalized)) {
      return of([]);
    }
    return this.getMeaningfulNormalizedQuantityIds(params.quantityIds).pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        const quantityIdsWithoutAverage = quantityIds.filter(qId => !averageQuantities?.includes(qId));
        return forkJoin(quantityIdsWithoutAverage.flatMap(qId => params.derivedIds.map(dId => forkJoin({
          series: this.getQuantityDerivedData(
            reportType,
            params,
            facilityIds,
            qId,
            true,
            dId,
            chartOptions,
            isCumulative,
            titleParams,
            isSumReport,
            false
          ),
          quantityId: of(qId),
          derivedId: of(dId),
        }))));
      })
    );
  }

  public getMeasuredEmissionsValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params.emissionIds)) {
      return of([]);
    }

    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);

    return quantityIds$.pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(
          quantityIds.flatMap(qId => params.emissionIds.map(eId => forkJoin({
            series: this.getQuantityDerivedData(
              reportType,
              params,
              facilityIds,
              qId,
              false,
              eId,
              chartOptions,
              isCumulative,
              titleParams,
              isSumReport,
              true
            ),
            quantityId: of(qId),
            derivedId: of(eId),
          })))
        );
      })
    );
  }

  public getNormalizedEmissionsValues(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!(Array.hasItems(params.emissionIds) && params.normalized)) {
      return of([]);
    }
    return this.getMeaningfulNormalizedQuantityIds(params.quantityIds).pipe(
      switchMap(quantityIds => {
        if (!Array.hasItems(quantityIds)) {
          return of([]);
        }
        return forkJoin(quantityIds.flatMap(qId => params.emissionIds.map(eID => forkJoin({
          series: this.getQuantityDerivedData(
            reportType,
            params,
            facilityIds,
            qId,
            true,
            eID,
            chartOptions,
            isCumulative,
            titleParams,
            isSumReport,
            true
          ),
          quantityId: of(qId),
          derivedId: of(eID),
        }))));
      })
    );
  }

  public getSumReportData(
    params: ReportingSearchParams,
    facilityIds: number[],
    threshold: number,
    normalized: boolean
  ): Observable<ReportingData[]> {
    const isHourResolution = params.resolution === RequestResolution.PT1H;
    return this.getSumConsumptionResponse(params, facilityIds, threshold, normalized).pipe(
      map(values => {
        if (values === null) {
          return [];
        }
        const sumConsumptions = Object.values(values.consumptionsBySeries);
        if (!checkHasValues(sumConsumptions)) {
          return [];
        }
        const series: ReportingData[] = [];
        for (const consumptionSeries of sumConsumptions) {
          const index = sumConsumptions.indexOf(consumptionSeries);
          const lastIndex = sumConsumptions.length - 1;
          const periodName = this.getPeriodName(params, { duration: params.duration, periods: params.periods }, index);
          const isInspectionPeriod = index === lastIndex;
          const isComparisonPeriod = (index !== lastIndex) && sumConsumptions.length > 1;

          for (const [quantity, consumption] of Object.entries(consumptionSeries)) {
            const graphColors = this.getConsumptionGraphColors(
              +quantity,
              sumConsumptions.length,
              isHourResolution ? ReportingGraphColorType.Alternative : undefined
            );
            const gridColors = this.colorService.getPrimaryGraphColors(+quantity, sumConsumptions.length);
            const normalization = normalized;
            series.push({
              series: {
                [sumKey]: [new ReportingSeries({
                  chartOptions: {
                    serieType: isHourResolution ? this.getHourSeriesType(isInspectionPeriod) : 'column',
                    lineOpacity: (isHourResolution && isInspectionPeriod)
                      ? ChartLineOpacity.Less
                      : ChartLineOpacity.More,
                    lineWidth: (isHourResolution && isComparisonPeriod) ? defaultChartLineWidth : undefined,
                    hideInChart: !params.showConsumption || (params.showDistribution && !normalization),
                  },
                  gridOptions: {
                    gridColor: gridColors[index],
                    comparisonColor: gridColors[gridColors.length - 1],
                    hideInGrid: !params.showConsumption || (params.showDistribution && !normalization),
                    gridTitle: periodName,
                  },
                  options: {
                    quantityId: +quantity,
                    color: normalization ? this.colorService.shadeColor(graphColors[index], 10) : graphColors[index],
                    serieTitle: normalization
                      ? `${this.translateService.instant('FACILITIES.NORMALIZED')} ${periodName}`
                      : periodName,
                    unit: consumption.unit,
                    isComparisonPeriod,
                    isInspectionPeriod,
                    unitKey: params.unit,
                    isNormalized: normalization,
                    serieType: 'consumption',
                  },
                  consumptions: this.mapSumConsumptions(consumption.values),
                  isCumulative: false,
                  comparisonConsumptions: this.mapSumConsumptions(sumConsumptions[lastIndex][+quantity].values),
                  comparisonPeriodAsBase: true,
                }, {
                  resolution: params.resolution,
                  searchPeriods: params.searchPeriods,
                  durationLength: params.formValue.durationLength,
                })]
              },
              quantityId: +quantity,
              facilitiesIncluded: consumption.facilitiesIncluded ?? [],
              facilitiesExcluded: consumption.facilitiesExcluded ?? [],
            });
          }
        }
        return series;
      }),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return of([]);
      })
    );
  }

  public getMeasuredTargets(
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params.targetTypes) || isLessThanMonth(params.resolution)) {
      return of([]);
    }

    const quantityIds$ = this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);
    return quantityIds$.pipe(
      switchMap(qIds => {
        if (!Array.hasItems(qIds)) {
          return of([]);
        }
        return forkJoin(
          qIds.map(qId => forkJoin({
            series: this.getTargetsForQuantity(
              facilityIds,
              params.searchPeriods[params.searchPeriods.length - 1],
              qId,
              params,
              isCumulative,
              isSumReport,
              false
            ),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getNormalizedTargets(
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    isSumReport = false
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params.targetTypes) || isLessThanMonth(params.resolution)) {
      return of([]);
    }

    const quantityIds$ = this.getMeaningfulNormalizedQuantityIds(params.quantityIds);
    return quantityIds$.pipe(
      switchMap(qIds => {
        if (!Array.hasItems(qIds)) {
          return of([]);
        }
        return forkJoin(
          qIds.map(qId => forkJoin({
            series: this.getTargetsForQuantity(
              facilityIds,
              params.searchPeriods[params.searchPeriods.length - 1],
              qId,
              params,
              isCumulative,
              isSumReport,
              true
            ),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getCostsSumReportData(
    params: ReportingSearchParams,
    facilityIds: number[],
    isNationalCosts: boolean,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if ((!Array.hasItems(params.nationalCostIds) && isNationalCosts)
      || (!Array.hasItems(params.meterBasedCostIds) && !isNationalCosts)) {
      return of([]);
    }

    return forkJoin(
      params.quantityIds.map(qId => forkJoin({
        series: this.getCostsSumReportForQuantity(
          facilityIds,
          qId,
          params,
          chartOptions,
          titleParams,
          isNationalCosts
        ),
        quantityId: of(qId)
      }))
    );
  }

  public getCostsSumReportForQuantity(
    facilityIds: number[],
    quantityId: Quantities,
    params: ReportingSearchParams,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isNationalCosts?: boolean
  ): Observable<ReportingSeriesById> {
    const selectedCosts = isNationalCosts
      ? nationalCostsIdMatch.filter(cost => params.nationalCostIds.includes(cost.id))
      : meterBasedCostsIdMatch.filter(cost => params.meterBasedCostIds.includes(cost.id));
    const methodName = isNationalCosts ? 'getNationalCostsByQuantity' : 'getMeterBasedCostsByQuantity';
    const requests = params.searchPeriods.map(period =>
      this.reportingClient[methodName](
        new CostReportingRequest({
          facilityIds,
          quantityId,
          unit: params.unit,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
        })
      ));
    return forkJoin(requests).pipe(
      map(res => res.map(costsSumReportResponse => createCostSeriesData(
        costsSumReportResponse.costsTimeline?.values ?? [],
        selectedCosts,
        costsSumReportResponse.costsTimeline?.currencySymbol,
        quantityId,
        true,
        costsSumReportResponse.costsTimeline?.averageCostsUnit
      ))),
      map(res => {
        const series: ReportingSeries[] = res.flatMap((data, index, arr) => Object.entries(data)
          .filter(([_, costData]) => costData.hasValue)
          .map(([costid, costData]) => {
            const name = isNationalCosts
              ? nationalCostsIdMatch.find(costmap => costmap.id === +costid)
              : meterBasedCostsIdMatch.find(costmap => costmap.id === +costid);
            const costName = isNationalCosts
              ? this.translateService.instant(
                nationalCostsNameMatch.find(item => item.name === name?.name)?.translationKey
              )
              : this.translateService.instant(
                meterBasedCostsNameMatch.find(item => item.name === name?.name)?.translationKey
              );
            const graphColors = this.colorService.colorsForRelationalValue(+costid, params.periods.length);
            const serieTitle = this.getPeriodName(params, titleParams, index);
            const serieSettings = chartOptions?.serieSettings?.[index];
            const comparisonConsumption = arr[arr.length - 1][costid].costs;
            const setUnit = costData.hasSingleCurrency ? costData.currency : '¤';

            return new ReportingSeries({
              chartOptions: {
                serieType: serieSettings?.serieType,
                lineOpacity: serieSettings?.lineOpacity
              },
              gridOptions: {
                gridTitle: `${costName} ${serieTitle} [${setUnit}]`,
                comparisonColor: this.colorService.firstComparisonPeriodColor
              },
              options: {
                quantityId: quantityId,
                color: graphColors[index],
                isComparisonPeriod: index === 0 && arr.length > 1,
                isInspectionPeriod: index === arr.length - 1,
                serieTitle: `${costName} ${serieTitle} [${setUnit}]`,
                unit: costData.currency,
                isNormalized: false,
                serieType: `derived${+costid}`,
              },
              chartItemOptions: {
                derivedId: +costid,
                positionInTooltip: chartOptions?.positionInTooltip,
                title: costName,
              },
              consumptions: costData.costs as ConsumptionLike[],
              comparisonConsumptions: comparisonConsumption as ConsumptionLike[],
              isCumulative: false,
              comparisonPeriodAsBase: true
            }, {
              resolution: params.resolution,
              searchPeriods: params.searchPeriods,
              durationLength: params.formValue.durationLength
            });
          }));
        return { [sumKey]: series };
      }),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return of([]);
      })
    );
  }

  public getDistributions(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    isSumReport: boolean = false,
    normalized: boolean = false,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    const isExcludedResolution = [RequestResolution.PT15M, RequestResolution.PT1H].includes(params.resolution);

    if (
      !params.showDistribution ||
      isExcludedResolution ||
      (normalized && !params.normalized) ||
      (!normalized && !params.measured)
    ) {
      return of([]);
    }

    const quantityIds$ = normalized
      ? this.getMeaningfulNormalizedQuantityIds(params.quantityIds)
      : this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);
    return quantityIds$.pipe(
      switchMap(qIds => {
        if (!Array.hasItems(qIds)) {
          return of([]);
        }
        return forkJoin(
          qIds.map(qId => forkJoin({
            series: isSumReport
              ? this.getSumDistributionsForQuantity(facilityIds, qId, params, normalized)
              : this.getDistributionsForQuantity(reportType, facilityIds, qId, params, normalized, titleParams),
            quantityId: of(qId)
          }))
        );
      })
    );
  }

  public getTemperature(
    params: ReportingSearchParams,
    facilityIds: number[],
    isSumReport: boolean = false
  ): Observable<ReportingData[]> {
    if (!params.showTemperature) {
      return of([]);
    }

    if (isSumReport) {
      const sumReportRequest = params.searchPeriods.map(period => forkJoin({
        period: of(period),
        result: this.weatherClient.getWeatherReadingsForSumReport(new WeatherSearchRequest({
          facilityIds: facilityIds,
          resolution: params.resolution,
          start: localToUtc(period.start),
          end: localToUtc(period.end),
        })),
      }));

      return forkJoin(sumReportRequest).pipe(
        map(results => results.map(
          ({ period, result }, index): ReportingData => {
            const title = this.translateService.instant('FACILITIES.SIDEBAR.TEMPERATURE');
            const periodName = durationToString(period.start, params.duration, index, true);
            const series: ReportingSeries[] = [];
            series.push(new ReportingSeries({
              consumptions: result.map((v: WeatherResponseSumReport) => ({
                value: v.value,
                timestamp: v.timestamp
              })),
              options: {
                serieType: 'temperature',
                color: this.colorService.shadeColor('#4ddd4d', index * -20),
                quantityId: null,
                serieTitle: `${title} ${periodName}`,
                unit: '°C',
              },
              chartOptions: { serieType: 'line', dashType: 'solid' },
              gridOptions: { gridTitle: periodName },
              chartItemOptions: { positionInTooltip: 100 },
            }, {
              resolution: params.resolution,
              durationLength: params.formValue.durationLength,
              searchPeriods: params.searchPeriods
            }));
            return {
              quantityId: null,
              series: { [sumKey]: series }
            };
          }
        ))
      );
    }

    const requests = params.searchPeriods.map(period => forkJoin({
      period: of(period),
      result: this.weatherClient.getWeatherReadings(new WeatherSearchRequest({
        facilityIds: facilityIds,
        resolution: params.resolution,
        start: localToUtc(period.start),
        end: localToUtc(period.end),
      })),
    }));
    return forkJoin(requests).pipe(
      map(results => results.map(
        ({ period, result }, index): ReportingData => {
          const title = this.translateService.instant('FACILITIES.SIDEBAR.TEMPERATURE');
          const periodName = durationToString(period.start, params.duration, index, true);
          return {
            quantityId: null,
            series: Object.entries(result).toRecord(
              ([facilityId, _]) => facilityId,
              ([_, values]) => [
                new ReportingSeries({
                  consumptions: values.map(v => ({
                    value: v.value,
                    timestamp: v.timestamp
                  })),
                  options: {
                    serieType: 'temperature',
                    color: this.colorService.shadeColor('#4ddd4d', index * -20),
                    quantityId: null,
                    serieTitle: `${title} ${periodName}`,
                    unit: '°C',
                  },
                  chartOptions: { serieType: 'line', dashType: 'solid' },
                  gridOptions: { gridTitle: periodName },
                  chartItemOptions: { positionInTooltip: 100 },
                }, {
                  resolution: params.resolution,
                  durationLength: params.formValue.durationLength,
                  searchPeriods: params.searchPeriods
                }),
              ]
            ),
          };
        }
      )),
      catchError(() => {
        this.toasterService.error('REPORTING.ERRORS.TEMPERATURE');
        return of([]);
      })
    );
  }

  public getSumConsumptionResponse(
    params: ReportingSearchParams,
    facilityIds: number[],
    threshold: number,
    normalized: boolean
  ): Observable<SumConsumptionResponse> {
    const quantityIds$ = normalized
      ? this.getMeaningfulNormalizedQuantityIds(params.quantityIds)
      : this.getMeaningfulMeasuredQuantityIds(params.quantityIds, params.measured);

    return quantityIds$.pipe(
      switchMap(qIds => {
        if (!Array.hasItems(qIds)) {
          return of(null);
        }

        const request = new SumConsumptionRequest({
          starts: params.periods.map(p => localToUtc(p)),
          duration: params.duration,
          resolution: params.resolution,
          quantityIds: qIds,
          facilityIds: facilityIds,
          threshold: threshold,
          excludeIncomplete: this.getExcludeIncomplete(params),
          unit: params.unit
        });

        if (!normalized) {
          return this.reportingClient.getSumConsumptionsGroupedByQuantity(request);
        }
        if (
          params.normalized &&
          !isLessThanMonth(params.resolution)
        ) {
          return this.reportingClient.getNormalizedSumConsumptionsGroupedByQuantity(request);
        }
        return of(null);
      })
    );
  }

  public getPeriodNamePublic(
    params: ReportingSearchParams,
    titleParams: TitleParams,
    index: number
  ): string {
    return this.getPeriodName(params, titleParams, index);
  }

  public getNationalCostsReportingData(
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params?.nationalCostIds)) {
      return of([]);
    }

    return forkJoin(
      params.quantityIds.map(qId => forkJoin({
        series: this.getCostsReportingSeriesById(
          facilityIds,
          qId,
          params,
          isCumulative,
          chartOptions,
          titleParams,
          true
        ),
        quantityId: of(qId)
      }))
    );
  }

  public getMeterBasedCostsReportingData(
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params?.meterBasedCostIds)) {
      return of([]);
    }

    return forkJoin(
      params.quantityIds.map(qId => forkJoin({
        series: this.getCostsReportingSeriesById(
          facilityIds,
          qId,
          params,
          isCumulative,
          chartOptions,
          titleParams,
          false
        ),
        quantityId: of(qId)
      }))
    );
  }

  public getCostsReportingSeriesById(
    facilityIds: number[],
    quantityId: Quantities,
    params: ReportingSearchParams,
    isCumulative: boolean,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams,
    isNationalCosts?: boolean
  ): Observable<ReportingSeriesById> {
    const selectedCosts = isNationalCosts
      ? nationalCostsIdMatch.filter(cost => params.nationalCostIds.includes(cost.id))
      : meterBasedCostsIdMatch.filter(cost => params.meterBasedCostIds.includes(cost.id));

    const endpoint: keyof ReportingClient = isNationalCosts
      ? 'getNationalCostsForFacilities'
      : 'getMeterBasedCostsForFacilities';

    const requests = params.searchPeriods.map(period =>
      this.reportingClient[endpoint](
        new CostReportingRequest({
          facilityIds,
          quantityId,
          unit: params.unit,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
        })
      ));

    return forkJoin(requests).pipe(
      map(response => facilityIds.toRecord(
        fId => fId,
        fId => response
          .filter(res => res.costsPerFacility[fId] && Array.isArray(res.costsPerFacility[fId].values))
          .map(res => createCostSeriesData(
            res.costsPerFacility[fId]?.values,
            selectedCosts,
            res.costsPerFacility[fId].currencySymbol,
            quantityId,
            res.responseHasSingleCurrency,
            res.costsPerFacility[fId].averageCostsUnit
          ))
      )),
      map(res => facilityIds.toRecord(
        fId => fId,
        fId => createReportingSeries(
          res,
          fId,
          params,
          quantityId,
          this.translateService,
          this.colorService,
          isCumulative,
          this.getPeriodNamePublic.bind(this),
          chartOptions,
          titleParams,
          isNationalCosts
        )
      )),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return of([]);
      })
    );

  }

  public getFacilityQuantityRelatedValues(
    params: ReportingSearchParams,
    facilityIds: number[],
    isCumulative = false,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingData[]> {
    if (!Array.hasItems(params?.relatedQuantities) || !Array.hasItems(params?.quantityIds)) {
      return of([]);
    }

    return this.quantityService.relatedQuantityValues$.pipe(
      switchMap(relatedValues => params.quantityIds.map(qId => relatedValues[qId]
        ?.filter(relatedQuantities => params.relatedQuantities
          ?.includes(relatedQuantities.name as unknown as Quantities))
        ?.map(rel => ({ ...rel, quantityId: qId })))),
      mergeMap(filteredQuantityValues => {
        if (!Array.hasItems(filteredQuantityValues)) {
          return of([]);
        }
        return forkJoin(
          filteredQuantityValues.map(values =>
            forkJoin({
              series: this.getFacilityQuantityRelatedValuesSeriesById(
                values as RelatedQuantity,
                facilityIds,
                params,
                values.quantityId,
                isCumulative,
                chartOptions,
                titleParams
              ),
              quantityId: of(values.quantityId),
            }))
        );
      })
    );
  }

  public getFacilityQuantityRelatedValuesSeriesById(
    relatedQuantity: RelatedQuantity,
    facilityIds: number[],
    params: ReportingSearchParams,
    quantityId: number,
    isCumulative: boolean,
    chartOptions?: SerieChartOptions,
    titleParams?: TitleParams
  ): Observable<ReportingSeriesById> {

    const requests = params.searchPeriods.map(period =>
      this.reportingClient.getRelatedQuantityConsumptions(
        new FacilityRelatedQuantityRequest({
          facilityIds,
          relatedQuantityName: relatedQuantity.name,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
        })
      ));

    return forkJoin(requests).pipe(
      map(response => facilityIds.toRecord(
        fId => fId,
        fId => {

          const graphColors = this.colorService.colorsForRelationalValue(quantityId, params.periods.length);
          const comparisonPeriodValues = response[response.length - 1].consumptions[fId].values;
          const series: ReportingSeries[] = [];

          response.forEach((r, index, array) => {
            if (r.consumptions[fId].hasValues) {
              const values = r.consumptions[fId].values;
              const name = this.translateService.instant(quantityTranslations[relatedQuantity.id as Quantities]);
              const serieSettings = chartOptions?.serieSettings?.[index];
              const periodName = this.getPeriodName(params, titleParams, index);
              series.push(new ReportingSeries({
                chartOptions: {
                  serieType: serieSettings?.serieType,
                  lineOpacity: serieSettings?.lineOpacity
                },
                gridOptions: {
                  gridTitle: `${name} ${periodName} [${r.unit}]`,
                  comparisonColor: this.colorService.firstComparisonPeriodColor
                },
                options: {
                  quantityId: quantityId,
                  color: graphColors[index],
                  isComparisonPeriod: index === 0 && array.length > 1,
                  isInspectionPeriod: index === array.length - 1,
                  serieTitle: `${name} ${periodName} `,
                  unit: r.unit,
                  unitKey: params.unit,
                  isNormalized: false,
                  serieType: `relatedQuantityValues${relatedQuantity.unitId}${relatedQuantity.id}`,
                },
                chartItemOptions: {
                  positionInTooltip: chartOptions?.positionInTooltip
                },
                consumptions: values,
                comparisonConsumptions: comparisonPeriodValues,
                isCumulative: isCumulative,
                comparisonPeriodAsBase: true
              },
              {
                resolution: params.resolution,
                searchPeriods: params.searchPeriods,
                durationLength: params.formValue.durationLength
              }));
            }
          });

          return series;
        }
      )),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return of([]);
      })
    );
  }

  protected consumptionRequest(
    quantityId: Quantities,
    isNormalized: boolean,
    facilityIds: number[],
    resolution: RequestResolution,
    start: Date,
    duration: RequestDuration,
    unit: ReportingUnit
  ): Observable<{ unit: string; relatedUnit: string; values: { [key: string]: TimeSeriesResponse; } }> {
    const endpoint: keyof ReportingClient = isNormalized
      ? 'getNormalizedConsumptionsForFacilities'
      : 'getConsumptionsForFacilities';

    const requestParams = new FacilityConsumptionRequest({
      facilityIds,
      quantityId,
      resolution: resolution,
      start: localToUtc(start),
      duration: duration,
      unit: unit
    });
    return this.reportingClient[endpoint](requestParams).pipe(
      map(r => ({
        values: r.consumptions,
        unit: r.unit,
        relatedUnit: r.relatedUnit,
      }))
    );
  }

  private getExcludeIncomplete(params: ReportingSearchParams): ExcludeIncomplete {
    switch (params.comparability) {
      case Comparability.ByQuantities:
        return ExcludeIncomplete.All;
      case Comparability.ByQuantity:
        return ExcludeIncomplete.Quantity;
      default:
        return ExcludeIncomplete.None;
    }
  }

  private mapSumConsumptions(values: SumConsumptionValue[]): ConsumptionLike[] {
    return values.map(v => ({
      ...v,
      timestamp: new Date(v.timestamp),
    }));
  }

  private getQuantityDerivedData(
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    quantityId: Quantities,
    normalization: boolean,
    derivedId: number,
    chartOptions: SerieChartOptions,
    isCumulative: boolean,
    titleParams: TitleParams,
    isSumReport: boolean,
    isEmissionBased: boolean
  ): Observable<ReportingSeriesById> {
    const requests = this.getQuantityDataBasedOnType(
      normalization,
      isSumReport,
      quantityId,
      facilityIds,
      derivedId,
      params,
      isEmissionBased
    );

    return forkJoin({
      response: forkJoin(requests),
      name: this.relationalValuesService.getRelationalValueName(derivedId)
    }).pipe(
      map(({ response, name }) => {
        const graphColors = this.colorService.colorsForRelationalValue(derivedId, params.periods.length);
        return facilityIds.toRecord(
          fId => isSumReport ? sumKey : fId,
          fId => {
            const idKey = isSumReport ? quantityId : fId;
            if (!response.some(r => r.consumptions[idKey].hasValues) ||
              this.hasFutureTrendPeriods(reportType, params.searchPeriods[0].start)) {
              return null;
            }

            const comparisonPeriodValues = response[response.length - 1].consumptions[idKey].values;
            const series: ReportingSeries[] = [];

            const normalizationTranslation = this.translateService.instant('FACILITIES.NORMALIZED');
            const prefix = normalization ? `${normalizationTranslation} ` : '';
            response.forEach((r, index, array) => {
              if (r.consumptions[idKey].hasValues) {
                const values = r.consumptions[idKey].values;
                const serieTitle = this.getPeriodName(params, titleParams, index);
                const serieSettings = chartOptions?.serieSettings?.[index];
                if (!params.showSummedConsumption) {
                  values.forEach(v => {
                    const seriesName = this.getMonthlyAndQuarterlyTitle(v.timestamp, params.resolution);
                    series.push(new ReportingSeries({
                      chartOptions: { serieType: null },
                      gridOptions: {
                        gridTitle: `${name} [${seriesName}] [${r.unit}]`,
                      },
                      options: {
                        quantityId: quantityId,
                        color: this.colorService.shadeColor(graphColors[index], -10),
                        isComparisonPeriod: index === 0 && array.length > 1,
                        isInspectionPeriod: index === array.length - 1,
                        serieTitle: `${prefix}${name} ${seriesName}`,
                        unit: r.relatedUnit,
                        unitKey: params.unit,
                        isNormalized: normalization,
                        serieType: `derived${derivedId}`,
                      },
                      chartItemOptions: {
                        derivedId
                      },
                      consumptions: [v]
                    }));
                  });
                } else {
                  series.push(new ReportingSeries({
                    chartOptions: {
                      serieType: serieSettings?.serieType,
                      lineOpacity: serieSettings?.lineOpacity
                    },
                    gridOptions: {
                      gridTitle: `${name} ${serieTitle} [${r.unit}]`,
                      comparisonColor: this.colorService.firstComparisonPeriodColor
                    },
                    options: {
                      quantityId: quantityId,
                      color: graphColors[index],
                      isComparisonPeriod: index === 0 && array.length > 1,
                      isInspectionPeriod: index === array.length - 1,
                      serieTitle: `${prefix}${name} ${serieTitle}`,
                      unit: r.unit,
                      unitKey: params.unit,
                      isNormalized: normalization,
                      serieType: `derived${derivedId}`,
                    },
                    chartItemOptions: {
                      derivedId,
                      positionInTooltip: chartOptions?.positionInTooltip,
                      title: `${prefix}${name}`,
                    },
                    consumptions: values,
                    comparisonConsumptions: comparisonPeriodValues,
                    isCumulative: isCumulative,
                    comparisonPeriodAsBase: true
                  },
                  {
                    resolution: params.resolution,
                    searchPeriods: params.searchPeriods,
                    durationLength: params.formValue.durationLength
                  }));
                }
              }
            });

            return series;
          }
        );
      }),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return this.getEmptyResponse(facilityIds);
      })
    );
  }

  private getTargetsForQuantity(
    facilityIds: number[],
    period: { start: Date },
    quantityId: Quantities,
    params: ReportingSearchParams,
    isCumulative: boolean,
    isSumReport: boolean,
    normalization: boolean
  ): Observable<ReportingSeriesById> {
    const targetTypes = params.targetTypes.filter(t => {
      const targetDefinition = targetSeriesDefinitions[t];
      return targetDefinition.applicableQuantities?.includes(quantityId) ?? true;
    });
    if (!Array.hasItems(targetTypes)) {
      return of(null);
    }
    const requests = isSumReport
      ? targetTypes.flatMap(targetType => this.reportingClient.getEnergyTargetsForQuantity(
        new EnergyTargetsRequest({
          facilityIds,
          quantityId,
          targetType,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
          unit: params.unit
        })
      ))
      : targetTypes.flatMap(targetType => this.reportingClient.getEnergyTargets(
        new EnergyTargetsRequest({
          facilityIds,
          quantityId,
          targetType,
          resolution: params.resolution,
          start: localToUtc(period.start),
          duration: params.duration,
          unit: params.unit
        })
      ));
    const comparisonColor = this.colorService.getQuantityColor(quantityId);
    return forkJoin(requests).pipe(
      map(response => facilityIds.toRecord(
        fId => isSumReport ? sumKey : fId,
        fId => {
          const idKey = isSumReport ? quantityId : fId;
          const periodSeries: ReportingSeries[] = response.filterMap(
            r => r.targets[idKey].hasValues,
            r => {
              const values = r.targets[idKey].values;
              const seriesDefinition = targetSeriesDefinitions[r.targetType];
              const targetName = this.translateService.instant(seriesDefinition.translationKey);
              const periodName = durationToString(values[0].timestamp, params.duration, 0, true);
              if (!params.showSummedConsumption) {
                const monthlySeries: ReportingSeries[] = [];
                for (const v of values) {
                  const seriesName = this.getMonthlyAndQuarterlyTitle(v.timestamp, params.resolution);
                  monthlySeries.push(new ReportingSeries({
                    chartOptions: {
                      serieType: null
                    },
                    gridOptions: { comparisonColor },
                    options: {
                      quantityId: quantityId,
                      color: seriesDefinition.color,
                      serieTitle: `${targetName} [${seriesName}]`,
                      unit: r.unit,
                      unitKey: params.unit,
                      serieType: seriesDefinition.targetType,
                    },
                    chartItemOptions: {
                      title: targetName
                    },
                    consumptions: [{
                      value: v.value,
                      incomplete: 0,
                      modeled: 0,
                      timestamp: v.timestamp
                    }],
                    isCumulative
                  }));
                }
                return monthlySeries;
              } else {
                return new ReportingSeries({
                  chartOptions: {
                    lineWidth: defaultChartLineWidth,
                    dashType: seriesDefinition.dashType,
                    serieType: 'line'
                  },
                  gridOptions: {
                    comparisonColor,
                    hideInGrid: !!(normalization && (params.formValue.valueType === ValueType.Measured))
                  },
                  options: {
                    quantityId: quantityId,
                    color: seriesDefinition.color,
                    serieTitle: `${targetName} ${periodName}`,
                    unit: r.unit,
                    unitKey: params.unit,
                    serieType: seriesDefinition.targetType,
                    isNormalized: normalization
                  },
                  chartItemOptions: {
                    title: targetName,
                    positionInTooltip: 0
                  },
                  consumptions: values.map(v => ({
                    value: v.value,
                    incomplete: 0,
                    modeled: 0,
                    timestamp: v.timestamp
                  })),
                  isCumulative
                },
                {
                  resolution: params.resolution,
                  searchPeriods: params.searchPeriods,
                  durationLength: params.formValue.durationLength
                });
              }
            }
          ).flat();
          return periodSeries;
        }
      )),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return this.getEmptyResponse(facilityIds);
      })
    );
  }

  private getSumDistributionsForQuantity(
    facilityIds: number[],
    quantityId: Quantities,
    params: ReportingSearchParams,
    normalization: boolean
  ): Observable<ReportingSeriesById> {
    return forkJoin(params.searchPeriods.map(period => {
      const requestParams = new QuantityDistributionRequest({
        start: localToUtc(period.start),
        duration: params.duration,
        resolution: params.resolution,
        quantityId: quantityId,
        unit: params.unit,
        facilityIds
      });
      return this.reportingClient.getDistributedConsumptionsByQuantity(requestParams);
    })).pipe(
      map(response => {
        const gridColors = this.colorService.getPrimaryGraphColors(quantityId, response.length);
        return response.toRecord(
          () => sumKey,
          () => {
            const hasDistributionValuesField = params.hasDistributionValuesField;
            if (!response.some(r => r.distributionsByQuantities[quantityId][hasDistributionValuesField])) {
              return null;
            }

            const series: ReportingSeries[] = [];
            const distributionFields = params.distributionFields;

            response.forEach((r, index, array) => {
              const periodName = this.getPeriodName(params, undefined, index);
              if (params.distributionType !== ReportingDistributionType.None
                && r.distributionsByQuantities[quantityId]) {
                const values = r.distributionsByQuantities[quantityId].values;
                const firstDistributionTranslation = this.translateService.instant(
                  distributionTranslations[params.distributionType][0]
                );
                const secondDistributionTranslation = this.translateService.instant(
                  distributionTranslations[params.distributionType][1]
                );
                const comparisonPeriodValues1 = response[response.length - 1]
                  .distributionsByQuantities[quantityId].values.map(v => ({
                    value: v[distributionFields[0]],
                    timestamp: v.timestamp,
                  }));
                const comparisonPeriodValues2 = response[response.length - 1]
                  .distributionsByQuantities[quantityId].values.map(v => ({
                    value: v[distributionFields[1]],
                    timestamp: v.timestamp,
                  }));
                series.push(new ReportingSeries({
                  chartOptions: {
                    serieType: 'column',
                    lineOpacity: ChartLineOpacity.More,
                    hideInChart: !params.showConsumption,
                    stackGroup: `dist${index}`,
                  },
                  gridOptions: {
                    gridColor: gridColors[index],
                    comparisonColor: gridColors[gridColors.length - 1],
                    hideInGrid: !params.showConsumption,
                  },
                  options: {
                    quantityId: quantityId,
                    color: gridColors[index],
                    serieTitle: `${firstDistributionTranslation} ${periodName}`,
                    unit: r.distributionsByQuantities[quantityId].unit,
                    isComparisonPeriod: index === 0 && array.length > 1,
                    isInspectionPeriod: index === array.length - 1,
                    unitKey: params.unit,
                    serieType: 'distribution1',
                    isPercentSerie: params.distributionAsPercent,
                    isNormalized: normalization
                  },
                  chartItemOptions: {
                    title: firstDistributionTranslation,
                    translateTitle: true,
                  },
                  consumptions: values.map<ConsumptionLike>(v => ({
                    timestamp: v.timestamp,
                    value: v[distributionFields[0]],
                    counterpartValue: v[distributionFields[1]]
                  })),
                  comparisonConsumptions: comparisonPeriodValues1,
                  comparisonPeriodAsBase: true,
                }));
                series.push(new ReportingSeries({
                  chartOptions: {
                    serieType: 'column',
                    lineOpacity: ChartLineOpacity.More,
                    hideInChart: !params.showConsumption,
                    stackGroup: `dist${index}`,
                  },
                  gridOptions: {
                    gridColor: gridColors[index],
                    comparisonColor: gridColors[gridColors.length - 1],
                    hideInGrid: !params.showConsumption,
                  },
                  options: {
                    quantityId: quantityId,
                    color: this.colorService.shadeColor(gridColors[index], 25),
                    serieTitle: `${secondDistributionTranslation} ${periodName}`,
                    unit: r.distributionsByQuantities[quantityId].unit,
                    isComparisonPeriod: index === 0 && array.length > 1,
                    isInspectionPeriod: index === array.length - 1,
                    unitKey: params.unit,
                    serieType: 'distribution2',
                    isPercentSerie: params.distributionAsPercent,
                    isNormalized: normalization
                  },
                  chartItemOptions: {
                    title: secondDistributionTranslation,
                    translateTitle: true,
                  },
                  consumptions: values.map<ConsumptionLike>(v => ({
                    timestamp: v.timestamp,
                    value: v[distributionFields[1]],
                    counterpartValue: v[distributionFields[0]]
                  })),
                  comparisonConsumptions: comparisonPeriodValues2,
                  comparisonPeriodAsBase: true,
                }));
              }
            });

            return series;
          }
        );
      })
    );
  }

  private getDistributionsForQuantity(
    reportType: ReportType,
    facilityIds: number[],
    quantityId: Quantities,
    params: ReportingSearchParams,
    normalization: boolean,
    titleParams?: TitleParams
  ): Observable<ReportingSeriesById> {
    return forkJoin(params.searchPeriods.map(period => {
      const requestParams = new FacilityConsumptionRequest({
        facilityIds,
        quantityId,
        resolution: params.resolution,
        start: localToUtc(period.start),
        duration: params.duration,
        unit: params.unit
      });
      return this.reportingClient.getDistributedConsumptions(requestParams);
    }))
      .pipe(
        map(response => {
          const gridColors = this.colorService.getPrimaryGraphColors(quantityId, response.length);
          return facilityIds.toRecord(
            fId => fId,
            fId => {
              const hasDistributionValuesField = params.hasDistributionValuesField;
              if (!response.some(r => r.consumptions[fId][hasDistributionValuesField]) ||
                this.hasFutureTrendPeriods(reportType, params.searchPeriods[0].start)) {
                return null;
              }

              const series: ReportingSeries[] = [];
              const distributionFields = params.distributionFields;

              response.forEach((r, index, array) => {
                const periodName = this.getPeriodName(params, titleParams, index);
                if (params.distributionType !== ReportingDistributionType.None && r.consumptions[fId]) {
                  const values = r.consumptions[fId].values;
                  const firstDistributionTranslation = this.translateService.instant(
                    distributionTranslations[params.distributionType][0]
                  );
                  const secondDistributionTranslation = this.translateService.instant(
                    distributionTranslations[params.distributionType][1]
                  );
                  const comparisonPeriodValues1 = response[response.length - 1].consumptions[fId].values.map(v => ({
                    value: v[distributionFields[0]],
                    timestamp: v.timestamp,
                  }));
                  const comparisonPeriodValues2 = response[response.length - 1].consumptions[fId].values.map(v => ({
                    value: v[distributionFields[1]],
                    timestamp: v.timestamp,
                  }));
                  if (!params.showSummedConsumption) {
                    values.forEach(v => {
                      const seriesName = this.getMonthlyAndQuarterlyTitle(v.timestamp, params.resolution);
                      series.push(new ReportingSeries({
                        chartOptions: { serieType: null },
                        gridOptions: {
                          gridColor: gridColors[index],
                          comparisonColor: gridColors[gridColors.length - 1],
                          hideInGrid: !params.showConsumption,
                        },
                        options: {
                          quantityId: quantityId,
                          color: gridColors[index],
                          isComparisonPeriod: index === 0 && array.length > 1,
                          isInspectionPeriod: index === array.length - 1,
                          serieTitle: `${firstDistributionTranslation} [${seriesName}]`,
                          unit: r.unit,
                          unitKey: params.unit,
                          serieType: 'distribution1',
                          isPercentSerie: params.distributionAsPercent,
                          isNormalized: normalization
                        },
                        consumptions: [{
                          timestamp: v.timestamp,
                          value: v[distributionFields[0]],
                          counterpartValue: v[distributionFields[1]]
                        }],
                      }));
                      series.push(new ReportingSeries({
                        chartOptions: { serieType: null },
                        gridOptions: {
                          gridColor: gridColors[index],
                          comparisonColor: gridColors[gridColors.length - 1],
                          hideInGrid: !params.showConsumption,
                        },
                        options: {
                          quantityId: quantityId,
                          color: this.colorService.shadeColor(gridColors[index], 25),
                          isComparisonPeriod: index === 0 && array.length > 1,
                          isInspectionPeriod: index === array.length - 1,
                          serieTitle: `${secondDistributionTranslation} [${seriesName}]`,
                          unit: r.unit,
                          unitKey: params.unit,
                          serieType: 'distribution2',
                          isPercentSerie: params.distributionAsPercent,
                          isNormalized: normalization
                        },
                        consumptions: [{
                          timestamp: v.timestamp,
                          value: v[distributionFields[1]],
                          counterpartValue: v[distributionFields[0]]
                        }],
                      }));
                    });
                  } else {
                    series.push(new ReportingSeries({
                      chartOptions: {
                        serieType: 'column',
                        lineOpacity: ChartLineOpacity.More,
                        hideInChart: !params.showConsumption,
                        stackGroup: `dist${index}`,
                      },
                      gridOptions: {
                        gridColor: gridColors[index],
                        comparisonColor: gridColors[gridColors.length - 1],
                        hideInGrid: !params.showConsumption,
                      },
                      options: {
                        quantityId: quantityId,
                        color: gridColors[index],
                        serieTitle: `${firstDistributionTranslation} ${periodName}`,
                        unit: r.unit,
                        isComparisonPeriod: index === 0 && array.length > 1,
                        isInspectionPeriod: index === array.length - 1,
                        unitKey: params.unit,
                        serieType: 'distribution1',
                        isPercentSerie: params.distributionAsPercent,
                        isNormalized: normalization
                      },
                      chartItemOptions: {
                        title: firstDistributionTranslation,
                        translateTitle: true,
                      },
                      consumptions: values.map<ConsumptionLike>(v => ({
                        timestamp: v.timestamp,
                        value: v[distributionFields[0]],
                        counterpartValue: v[distributionFields[1]]
                      })),
                      comparisonConsumptions: comparisonPeriodValues1,
                      comparisonPeriodAsBase: true,
                    }, {
                      resolution: params.resolution,
                      searchPeriods: params.searchPeriods,
                      durationLength: params.formValue.durationLength
                    }));
                    series.push(new ReportingSeries({
                      chartOptions: {
                        serieType: 'column',
                        lineOpacity: ChartLineOpacity.More,
                        hideInChart: !params.showConsumption,
                        stackGroup: `dist${index}`,
                      },
                      gridOptions: {
                        gridColor: gridColors[index],
                        comparisonColor: gridColors[gridColors.length - 1],
                        hideInGrid: !params.showConsumption,
                      },
                      options: {
                        quantityId: quantityId,
                        color: this.colorService.shadeColor(gridColors[index], 25),
                        serieTitle: `${secondDistributionTranslation} ${periodName}`,
                        unit: r.unit,
                        isComparisonPeriod: index === 0 && array.length > 1,
                        isInspectionPeriod: index === array.length - 1,
                        unitKey: params.unit,
                        serieType: 'distribution2',
                        isPercentSerie: params.distributionAsPercent,
                        isNormalized: normalization
                      },
                      chartItemOptions: {
                        title: secondDistributionTranslation,
                        translateTitle: true,
                      },
                      consumptions: values.map<ConsumptionLike>(v => ({
                        timestamp: v.timestamp,
                        value: v[distributionFields[1]],
                        counterpartValue: v[distributionFields[0]]
                      })),
                      comparisonConsumptions: comparisonPeriodValues2,
                      comparisonPeriodAsBase: true,
                    },
                    {
                      resolution: params.resolution,
                      searchPeriods: params.searchPeriods,
                      durationLength: params.formValue.durationLength
                    }));
                  }
                }
              });

              return series;
            }
          );
        }),
        catchError(() => {
          this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
          return this.getEmptyResponse(facilityIds);
        })
      );
  }

  private getQuantityDataBasedOnType(
    normalization: boolean,
    isSumReport: boolean,
    quantityId: Quantities,
    facilityIds: number[],
    derivedId: number,
    params: ReportingSearchParams,
    isEmissionBased: boolean
  ): Observable<QuantityDerivedResponse | FacilityDerivedResponse>[] {
    if (!isEmissionBased) {
      const sumDerivedEndpoint: keyof ReportingClient = normalization
        ? 'getNormalizedSpecificConsumptionsByQuantity'
        : 'getSpecificConsumptionsByQuantity';
      const derivedEndpoint: keyof ReportingClient = normalization
        ? 'getNormalizedSpecificConsumptionsForFacilities'
        : 'getSpecificConsumptionsForFacilities';

      return params.searchPeriods.map(period =>
        isSumReport
          ? this.reportingClient[sumDerivedEndpoint](
            new QuantitySpecificConsumptionRequest({
              start: localToUtc(period.start),
              duration: params.duration,
              resolution: params.resolution,
              quantityId: quantityId,
              unit: params.unit,
              facilityIds: facilityIds,
              specificConsumptionId: derivedId,
            })
          )
          : this.reportingClient[derivedEndpoint](
            new FacilitySpecificConsumptionRequest({
              facilityIds: facilityIds,
              quantityId: quantityId,
              start: localToUtc(period.start),
              duration: params.duration,
              unit: params.unit,
              resolution: params.resolution,
              specificConsumptionId: derivedId,
            })
          ));
    } else {
      const sumEmissionEndPoint: keyof ReportingClient = normalization
        ? 'getNormalizedCo2EmissionsByQuantity'
        : 'getCo2EmissionsByQuantity';
      const emissionEndPoint: keyof ReportingClient = normalization
        ? 'getNormalizedCo2EmissionsForFacilities'
        : 'getCo2EmissionsForFacilities';

      return params.searchPeriods.map(period =>
        isSumReport
          ? this.reportingClient[sumEmissionEndPoint](
            new QuantityCo2EmissionRequest({
              start: localToUtc(period.start),
              duration: params.duration,
              resolution: params.resolution,
              quantityId: quantityId,
              unit: params.unit,
              facilityIds: facilityIds,
              co2EmissionTypeId: derivedId
            })
          )
          : this.reportingClient[emissionEndPoint](
            new Co2EmissionRequest({
              start: localToUtc(period.start),
              duration: params.duration,
              resolution: params.resolution,
              quantityId: quantityId,
              unit: params.unit,
              facilityIds: facilityIds,
              co2EmissionTypeId: derivedId
            })
          ));
    }
  }
}

function isLessThanMonth(resolution: RequestResolution): boolean {
  return [
    RequestResolution.PT15M,
    RequestResolution.PT1H,
    RequestResolution.P1D,
    RequestResolution.P7D
  ].includes(resolution);
}

function checkHasValues(
  data: {
    [key: string]: SumConsumption;
  }[]
): boolean {
  for (const item of data) {
    for (const key in item) {
      if (item[key].hasValues) {
        return true;
      }
    }
  }
  return false;
}
