import _ from 'lodash';

import template from 'raw-loader!../templates/toggle-grid-columns.html';
import buttonOnlytemplate from 'raw-loader!../templates/toggle-grid-columns-button-only.html';

function toggleGridColumnsController($scope, $element, $timeout) {
  let toggleGridColumnsTemp = {};
  let handling = false;

  // tree options
  $scope.treeOptions = {
    checkboxes: {
      checkChildren: true,
    },
    loadOnDemand: false,
    dataTextField: 'title',
    dataSource: {
      data: [],
      schema: {
        model: {
          children: 'columns'
        }
      }
    },
    check: function(e) {
      function handleToggling(treeItem) {
        const children = treeItem.hasChildren ? treeItem.children.data() : [];

        // show/hide column
        if (treeItem.checked || _.filter(children, { checked: true }).length > 0) {
          $scope.grid.showColumn(treeItem.field);
        } else {
          const existingFilters = $scope.grid.dataSource.filter();
          if (existingFilters && Array.hasItems(existingFilters.filters)) {
            const filtersWithoutColumnToHide = existingFilters.filters.filter(item => item.field !== treeItem.field);
            if (filtersWithoutColumnToHide.length !== existingFilters.filter) {
              $scope.grid.dataSource.filter({
                ...existingFilters,
                filters: filtersWithoutColumnToHide
              });
            }
          }
          $scope.grid.hideColumn(treeItem.field);
        }

        // handle children
        _.each(children, childTreeItem => {
          handleToggling(childTreeItem);
        });
      }

      $timeout(() => {
        const treeItem = e.sender.dataItem(e.node);
        if ($scope.grid && treeItem) {
          handling = true;
          disableReflow();
          handleToggling(treeItem);
          enableAndExecuteReflow();
          $timeout(() => {
            handling = false;
          });
        }
      });
    }
  };

  function createTreeItem(column, parentTreeItem) {
    const childColumns = column.columns || [];
    // If this column has child columns, it should have
    // visibility checkbox checked when all the children are
    // visible.  If not, simply see if the column is hidden or
    // not.
    const checked = childColumns.length
      ? childColumns.length === _.filter(childColumns, { hidden: false }).length
      : !column.hidden;
    const treeItem = { title: column.title, field: column.field, checked: checked, columns: [] };
    parentTreeItem = parentTreeItem ? parentTreeItem.columns : $scope.treeOptions.dataSource.data;
    parentTreeItem.push(treeItem);
    _.each(column.columns, childColumn => {
      createTreeItem(childColumn, treeItem);
    });
  }

  function createTreeView() {
    $scope.treeOptions.dataSource.data = [];
    _.each($scope.grid.columns, column => {
      if (column.toggleable) {
        createTreeItem(column);
      }
    });
    // create temporary reflow object (see below)
    createReflowObject();
  }

  function createReflowObject() {
    // API for showing/hiding colums on kendo grid is column by column and
    // there is no method to do it for several at once.
    // When using locked table, Kendo tries to calculate dimensions of table
    // content (rows and columns) for every show/hide call. Let's store
    // these long lasting methods, replace them by noop-functions
    // and restore + call manually these functions afterwards
    toggleGridColumnsTemp = {
      _updateTablesWidth: $scope.grid._updateTablesWidth,
      _applyLockedContainersWidth: $scope.grid._applyLockedContainersWidth,
      _syncLockedContentHeight: $scope.grid._syncLockedContentHeight,
      _syncLockedHeaderHeight: $scope.grid._syncLockedHeaderHeight,
      _syncLockedFooterHeight: $scope.grid._syncLockedFooterHeight
    };
  }

  function enableAndExecuteReflow() {
    if ($scope.grid.lockedTable) {
      $scope.grid._updateTablesWidth = toggleGridColumnsTemp._updateTablesWidth;
      $scope.grid._applyLockedContainersWidth = toggleGridColumnsTemp._applyLockedContainersWidth;
      $scope.grid._syncLockedContentHeight = toggleGridColumnsTemp._syncLockedContentHeight;
      $scope.grid._syncLockedHeaderHeight = toggleGridColumnsTemp._syncLockedHeaderHeight;
      $scope.grid._syncLockedFooterHeight = toggleGridColumnsTemp._syncLockedFooterHeight;

      if (_.isFunction($scope.grid._updateTablesWidth)) {
        $scope.grid._updateTablesWidth();
      }
      if (_.isFunction($scope.grid._applyLockedContainersWidth)) {
        $scope.grid._applyLockedContainersWidth();
      }
      if (_.isFunction($scope.grid._syncLockedContentHeight)) {
        $scope.grid._syncLockedContentHeight();
      }
      if (_.isFunction($scope.grid._syncLockedHeaderHeight)) {
        $scope.grid._syncLockedHeaderHeight();
      }
      if (_.isFunction($scope.grid._syncLockedFooterHeight)) {
        $scope.grid._syncLockedFooterHeight();
      }
    }
  }

  function disableReflow() {
    if ($scope.grid.lockedTable) {
      const noop = function() {};
      $scope.grid._updateTablesWidth = noop;
      $scope.grid._applyLockedContainersWidth = noop;
      $scope.grid._syncLockedContentHeight = noop;
      $scope.grid._syncLockedHeaderHeight = noop;
      $scope.grid._syncLockedFooterHeight = noop;
    }
  }

  $scope.$watch('grid.groupable', () => {
    if ($scope.grid) {
      $scope.isGroupable = $scope.grid.groupable;
    }
  });

  $scope.$on('kendoWidgetCreated', (event, widget) => {
    if (widget === $scope.grid) {
      $timeout(() => {
        createTreeView();
      });
    }
  });

  $scope.positionStyle = undefined;

  $scope.$watchGroup(['isGroupable', 'static', 'absolute'], () => {
    if (($scope.isGroupable && !$scope.static) || $scope.absolute) {
      $scope.positionStyle = 'absolute';
    } else {
      $scope.positionStyle = 'static';
    }
  });

  $scope.$watchCollection('grid.columns', columns => {
    if (!handling && $scope.grid && columns && columns.length) {
      $timeout(() => {
        createTreeView();
      });
    }
  });
}

toggleGridColumnsController.$inject = ['$scope', '$element', '$timeout'];

export function toggleGridColumns() {
  return {
    restrict: 'E',
    template: template,
    replace: true,
    scope: {
      static: '<',
      absolute: '<',
      grid: '<'
    },
    controller: toggleGridColumnsController
  };
}

export function toggleGridColumnsButtonOnly() {
  return {
    restrict: 'E',
    template: buttonOnlytemplate,
    scope: {
      grid: '<'
    },
    controller: toggleGridColumnsController
  };
}
