import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, Observable, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { ReportBase, ReportingGridConfig } from '../report-base';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { TrendReportService } from '../../services/trend-report.service';
import { ReportType } from '../../shared/report-type';
import { ReportingGridData, ReportingGridService } from '../../services/reporting-grid.service';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'trend-report',
  templateUrl: '../report-base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendReportComponent extends ReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<Record<number, ReportingGridConfig>>;

  public override readonly modalReportType = ReportType.Trend;

  protected readonly reportType = ReportType.Trend;

  public constructor(
    private readonly trendReportService: TrendReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly quantityService: QuantityService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService,
    private readonly toasterService: ToasterService
  ) {
    super(facilityService, reportingSearchService, reportEventService);

    this.consumptionData$ = combineLatest({
      searchParams: this.searchParams$,
      averageQuantities: this.quantityService.averageAggregateQuantityIds$
    }).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([{ searchParams, averageQuantities }, facilityIds]) => {
        if (searchParams.quantityIds.some(id => averageQuantities.includes(id))) {
          this.toasterService.info('REPORTING.ERRORS.CURR_UNSUPPORTED_AVGQUANT_REPORT');
          searchParams.quantityIds.removeBy(id => averageQuantities.includes(id));
        }
        return this.trendReportService.getData(
          searchParams, facilityIds, averageQuantities
        ).pipe(
          indicate(this._loading$)
        );
      }),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = combineLatest({
      data: this.consumptionData$,
      unsupportedQuantitiesAggregate: this.quantityService.averageAggregateQuantityIds$
    }).pipe(
      withLatestFrom(this.facilityIds$, this.searchParams$),
      map(([{ data, unsupportedQuantitiesAggregate }, facilityIds, searchParams]) => {
        const gridColumns = this.reportingGridService.getColumns(data, facilityIds);
        const gridData = this.reportingGridService.getData(data, searchParams, facilityIds);
        const aggregates = this.reportingGridService.getAggregates(
          facilityIds,
          gridColumns,
          gridData,
          ReportType.Trend,
          unsupportedQuantitiesAggregate,
          searchParams
        );
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        const seriesYear = searchParams.periods[0].getFullYear();
        return facilityIds.toRecord(
          fId => fId,
          fId => {
            const filteredGridData: ReportingGridData[] = [];
            if (gridData[fId]) {
              gridData[fId]?.forEach(gData => {
                const seriesMonth = parseInt(gData.periodName.split('/')[0]);
                if (seriesYear < currentYear || (seriesYear === currentYear && seriesMonth < currentMonth)) {
                  filteredGridData.push(gData);
                }
              });
            }
            return ({
              data: filteredGridData.length !== 0 ? filteredGridData : gridData[fId],
              columns: gridColumns[fId],
              aggregates: aggregates[fId]
            });
          }
        );
      })
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return new ReportingSearchParams({
      ...params.formValue,
      periods: [params.formValue.periods[0]]
    });
  }
}
