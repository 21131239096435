import _ from 'lodash';
import { forkJoin } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { Service } from '../../../constants/service';
import { isCost, isEmission } from '../../reportingobjects/shared/relational-value-functions';
import { EventType } from '../constants/event-type';

const $inject = [
  '$scope', '$q', '$transitions', 'configurationApi', 'utils',
  'AdditionalSettingService', 'ConsumptionTargetSeriesTypes', 'UserService', 'appStatusService',
  'erReportSettingsService', 'erStateService', 'EnergyReportingClientCacheService',
  'relationalValuesService'
];

function FacilitiesSidebarAdditionalSettingsController(
  $scope, $q, $transitions, configurationApi, utils,
  AdditionalSettingService, ConsumptionTargetSeriesTypes, UserService, appStatusService,
  erReportSettingsService, erStateService, energyReportingClientCacheService,
  relationalValuesService
) {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias
  const erReportSettings = erReportSettingsService.getInstance();
  let stateChangeListenerUnbind = () => {};
  let settingChangeListenerUnbind = () => {};
  $scope.params = erReportSettings.getSettings();

  vm.appStatusService = appStatusService;
  vm.data = {
    localizedMultiSelectTexts: utils.istevenMultiSelectTranslation(),
    quantities: [],
    settings: AdditionalSettingService.getSettings(),
    loading: false
  };

  function initialize() {
    vm.data.loading = true;
    getConsumptionTargets();
    getEventTypes();
    forkJoin([getQuantities(), getRelationalValues(), getDistributionTypes()]).pipe(
      switchMap(() => getRelatedValues())
    ).subscribe(() => {
      initializeSelections();
      updateView();
      setupStateChangeListener();
      vm.data.loading = false;
    });
  }

  function getConsumptionTargets() {
    const rvSetting = _.find(vm.data.settings, { param: 'series.ConsumptionTargetSeriesTypes' });

    if (rvSetting) {
      rvSetting.options = ConsumptionTargetSeriesTypes.get(UserService.hasService(Service.ConsumptionTargets));
    }
  }

  function getEventTypes() {
    const rvSetting = vm.data.settings.find(({ param }) => param === 'series.EventTypes');
    if (rvSetting) {
      if (UserService.hasService(Service.Actions)) {
        rvSetting.options.push(
          {
            Name: utils.localizedString('FACILITIES_REPORT.OVERVIEW.ACTIONS'),
            Id: EventType.Actions
          }
        );
      }
      if (UserService.hasService(Service.Comments)) {
        rvSetting.options.push(
          {
            Name: utils.localizedString('FACILITIES_REPORT.OVERVIEW.COMMENTS'),
            Id: EventType.Comments
          }
        );
      }
      if (UserService.hasService(Service.Alarms)) {
        rvSetting.options.push({
          Name: utils.localizedString('FACILITIES_REPORT.OVERVIEW.ALARMS'),
          Id: EventType.Alarms
        });
      }
    }
  }

  function getQuantities() {
    return configurationApi.quantities.getQuantities().then(result => {
      vm.data.quantities = result;
      const unitSetting = _.find(vm.data.settings, { param: 'unitKey' });
      if (unitSetting) {
        // unit options
        unitSetting.options = _.reduce(
          result,
          (units, quantity) => {
            _.each(quantity.Units, (Value, key) => {
              let translated = utils.localizedString(key);
              translated = translated === key.toUpperCase() ? key : translated;
              if (!_.find(units, { Value: key })) {
                units.push({
                  Name: translated,
                  Value: key
                });
              }
            });
            return units;
          },
          []
        );
      }
    });
  }

  function getRelationalValues() {
    return relationalValuesService.relationalValues$.pipe(
      take(1),
      tap(result => {
        const rvSetting = vm.data.settings.find(({ param }) => param === 'series.RelationalUnitIds');
        if (rvSetting) {
          const emissionsTranslationKey = 'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_EMISSIONS';
          const costsTranslationKey = 'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_COSTS';
          const relationalValuesTranslationKey = 'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_RELATIONAL_VALUES';

          const filteredResult = erStateService.isMeterReport() ? result.filter(({ Id }) => isCost(Id)) : result;
          const groupedByTranslationKey = _.groupBy(
            filteredResult,
            relationalValue => {
              if (isEmission(relationalValue.Id)) {
                return emissionsTranslationKey;
              }
              if (isCost(relationalValue.Id)) {
                return costsTranslationKey;
              }
              return relationalValuesTranslationKey;
            }
          );

          const pushGroupedOptions = (...translationKeys) => {
            translationKeys.forEach(translationKey => {
              if (groupedByTranslationKey[translationKey]) {
                rvSetting.options.push({
                  msGroup: true,
                  Name: `<b>${utils.localizedString(translationKey)}</b>`
                });
                rvSetting.options.push(...groupedByTranslationKey[translationKey]);
                rvSetting.options.push({
                  msGroup: false
                });
              }
            });
          };

          rvSetting.options = [];
          if (UserService.hasService(Service.EmissionReporting)) {
            pushGroupedOptions(emissionsTranslationKey);
          }
          if (
            UserService.hasService(Service.CostReportingLocationBased) ||
              UserService.hasService(Service.CostReportingMeterBased)
          ) {
            pushGroupedOptions(costsTranslationKey);
          }
          pushGroupedOptions(relationalValuesTranslationKey);
        }
      })
    );
  }

  function getDistributionTypes() {
    return energyReportingClientCacheService.getDistributionTypes().pipe(tap(result => {
      const dtSetting = _.find(vm.data.settings, { param: 'series.DistributionId' });
      if (dtSetting) {
        dtSetting.options = [{ Id: void 0, Name: utils.localizedString('FACILITIES.SIDEBAR.NONE') }].concat(result);
      }
    }));
  }

  function getRelatedValues() {
    return energyReportingClientCacheService.getRelatedValues().pipe(tap(result => {
      const rvSetting = _.find(vm.data.settings, { param: 'series.RelatedValues' });
      if (rvSetting) {
        _.each(result, (relatedValuesForQuantity, quantityId) => {
          const quantity = _.find(vm.data.quantities, { ID: parseInt(quantityId, 10) });
          if (quantity) {
            rvSetting.options.push({ msGroup: true, Name: `<b>${quantity.Name}</b>` });
            rvSetting.options.push(...relatedValuesForQuantity);
            rvSetting.options.push({ msGroup: false });
          }
        });
      }
    }));
  }

  function getProperty(setting) {
    return setting.property || 'Value';
  }

  function initializeSelections() {
    _.each(vm.data.settings, setting => {
      const value = _.get($scope.params, setting.param);
      switch (setting.type) {
        case 'select':
          _.each(setting.options, option => {
            const optionValue = _.get(option, getProperty(setting));
            if (
              (setting.extendSeriesWithValue && _.isMatch($scope.params.series, optionValue)) ||
              optionValue === value
            ) {
              setting.selected = option;
              return false;
            }
          });
          if (!setting.selected && setting.options.length) {
            const defaultOption = _.find(setting.options, { Default: true }) || setting.options[0];
            setting.selected = defaultOption;
            vm.onSettingChanged(setting);
          }
          break;
        case 'multiselect':
          _.each(setting.options, option => {
            const optionValue = _.get(option, getProperty(setting));
            option.selected = _.includes(value, optionValue);
          });
          break;
        case 'checkbox':
          setting.selected = value === undefined ? setting.selected : !!value;
          vm.onSettingChanged(setting);
          break;
      }
    });
  }

  function getSettingValue(setting) {
    let value;

    switch (setting.type) {
      case 'select':
        value = _.get(setting, `selected.${getProperty(setting)}`);
        break;
      case 'multiselect':
        value = _.map(setting.selectedOptions, getProperty(setting));
        break;
      case 'checkbox':
        value = !!setting.selected;
        break;
    }

    return value;
  }

  vm.onSettingChanged = setting => {
    const value = getSettingValue(setting);
    if (setting.extendSeriesWithValue) {
      let series = angular.copy(erReportSettings.getSettings().series);
      series = _.extend({}, series, value);
      erReportSettings.changeSetting('series', series);
    } else {
      erReportSettings.changeSetting(setting.param, value);
    }

    updateDisabledSettings();
  };

  function updateDisabledSettings() {
    const disabledSettings = AdditionalSettingService.getDisabledSettings(erReportSettings.getSettings().series);
    vm.data.settings.forEach(setting => {
      setting.disabled = disabledSettings.includes(setting.id);
    });

    setDisabledRelationalValues();
  }

  /**
   * Disabled costs values when resolution is one hour
   */
  function setDisabledRelationalValues() {
    const relationalValuesSetting = vm.data.settings.find(setting => setting.id === 'relational-values');
    if (!relationalValuesSetting) {
      return;
    }

    const isHourResolution = erReportSettings.getSettings().series.Resolution === 'PT1H';

    for (const setting of relationalValuesSetting.options) {
      setting.disabled = isHourResolution && isCost(setting.Id);
      if (setting.disabled) {
        setting.selected = false;
      }
    }
  }

  function updateVisibleSettings() {
    // Helper method to check multiple services.
    const isNotServiceRestricted = services =>
      services.length ? services.filter(service => UserService.hasService(service)).length !== 0 : true;

    const mapperFn = setting => {
      setting.isVisible = isNotServiceRestricted(setting.services);
      return setting;
    };

    vm.data.settings = _.map(vm.data.settings, mapperFn);
  }

  function updateView() {
    updateVisibleSettings();
    updateDisabledSettings();
  }

  function setupStateChangeListener() {
    stateChangeListenerUnbind = erStateService.isModalOpen()
      ? erReportSettings.setCallback(updateView, 'name')
      : $transitions.onSuccess({ entering: 'facilities.**' }, updateView);

    settingChangeListenerUnbind = erReportSettings.setCallback(updateView, 'series');
  }

  $scope.$on('$destroy', () => {
    stateChangeListenerUnbind();
    settingChangeListenerUnbind();
  });

  initialize();
}

FacilitiesSidebarAdditionalSettingsController.$inject = $inject;

export default FacilitiesSidebarAdditionalSettingsController;
