import _ from 'lodash';
import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';

import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import * as ERField from './er-field.functions';
import { sort } from './er-data-grid-sorter';
import { alarmStatusToActionStatus, ReportNote } from '../../shared/report-note';

ERGridService.$inject = [
  '$rootScope', 'utils', 'ERDataFunctions', 'ERReportType', 'ColorService', 'ERUtils', 'ERActionsImpactService',
  'ErGridQuantityColumns', 'EREventsService', 'erStateService', 'alarmService', 'dateFormatService'
];

/* eslint-disable @typescript-eslint/naming-convention */
export function ERGridService(
  $rootScope, utils, ERDataFunctions, ERReportType, ColorService, ERUtils, ERActionsImpactService,
  ErGridQuantityColumns, EREventsService, erStateService, alarmService, dateFormatService
  /* eslint-enable @typescript-eslint/naming-convention */
) {

  function getGrids(options) {
    if (ERDataFunctions.isAggregated(options)) {
      return getGrid(options, {});
    } else {
      switch (options.idType) {
        case 'Facility':
          return _.reduce(ERDataFunctions.getFacilityIds(options), (result, facilityId) => {
            result[facilityId] = getGrid(options, { facilityId });
            return result;
          }, {});
        case 'Meter':
          return _.reduce(ERDataFunctions.getMeterIds(options), (result, meterId) => {
            result[meterId] = getGrid(options, { meterId });
            return result;
          }, {});
        case 'Quantity':
          return getGrid(options, {});
        default:
          break;
      }
    }
  }

  function getGrid(options, data) {
    // create chart and chartOptions
    const grid = options.grid.getCurrentGrid(options, data);
    const gridOptions = getGridOptions(options, data);
    const isAggregatedQuantityGrid = (ERDataFunctions.isAggregated(options) && options.idType === 'Quantity');
    let dataForWeather = null;

    _.extend(data, {
      gridOptions: gridOptions,
      wideColumns: gridOptions.filterable || gridOptions.sortable,
      isAggregatedQuantityGrid: isAggregatedQuantityGrid
    });

    // create key column
    createKeyColumns(options, data);

    // create quantity columns and rows
    _.each(ERDataFunctions.getQuantityIds(options), quantityId => {
      const dataForQuantity = ERDataFunctions.getDataForQuantity(options, data, quantityId);

      _.each(dataForQuantity.consumptions, (consumption, apiIndex) => {
        const dataForApi = _.extend({ apiIndex }, dataForQuantity);

        if (ERDataFunctions.isWeatherRequest(options, apiIndex)) {
          dataForWeather = dataForApi;
        } else {
          // measured quantity category column
          if (options.series.Measured || (options.series.Normalized && !dataForQuantity.quantity.Normalization)) {
            ErGridQuantityColumns
              .createQuantityCategoryColumn(options, _.extend({}, dataForApi, { normalized: false }));
          }
          // normalized quantity category column
          if (options.series.Normalized && dataForQuantity.quantity.Normalization) {
            ErGridQuantityColumns
              .createQuantityCategoryColumn(options, _.extend({}, dataForApi, { normalized: true }));
          }
        }

        // create row cells (make readings usable by the grid)
        createRowCells(options, dataForApi);
      });
    });

    // create weather category column
    if (dataForWeather) {
      ErGridQuantityColumns.createWeatherCategoryColumn(options, dataForWeather);
    }

    // create actions impact category column and create row cells
    if (_.size(_.get(options, 'supplementaryApi.actionsImpact.responseData')) > 0) {
      const actionsImpactColumn = ERActionsImpactService.createCategoryColumn(options, data);
      data.gridOptions.columns.push(actionsImpactColumn);
      _.each(actionsImpactColumn.columns, column => {
        ERDataFunctions.addAggregates(options, data, column, 'n0');
      });

      // Make values usable to Kendo grid
      _.each(data.gridOptions.dataSource.data, facility => {
        const actionsImpactValues = _.get(options.supplementaryApi.actionsImpact.responseData, facility.Id);
        _.extend(facility, flattenObject({ actionsImpactValues }, '$', false));
      });
    }

    // create event category columns
    const latestComments = _.get(options, 'supplementaryApi.latestComments.responseData', {});
    if (_.size(latestComments) > 0) {
      const field = 'comment';
      data.gridOptions.dataSource.data.forEach(facility => {
        const comments = _.get(latestComments, facility.Id, []);
        const { effectStartsAt, reportedDescription, investigation } = _.get(comments, 0, {});
        const investigationName = investigation !== undefined
          ? utils.localizedString(`ACTIONS.INVESTIGATION_${investigation}`)
          : null;
        const count = comments.length;
        Object.assign(facility, flattenObject({
          [field]: { effectStartsAt, reportedDescription, investigationName, count }
        }, '$', false));
        facility.allComments = comments.map(comment => ReportNote.parse(comment));
      });
      data.gridOptions.columns.push(EREventsService.createCategoryColumn(field));
    }

    const latestActions = _.get(options, 'supplementaryApi.latestActions.responseData', {});
    if (_.size(latestActions) > 0) {
      const field = 'action';
      data.gridOptions.dataSource.data.forEach(facility => {
        const actions = _.get(latestActions, facility.Id, []);
        const { effectStartsAt, reportedDescription, investigation } = _.get(actions, 0, {});
        const investigationName = investigation !== undefined
          ? utils.localizedString(`ACTIONS.INVESTIGATION_${investigation}`)
          : null;
        const count = actions.length;
        Object.assign(facility, flattenObject({
          [field]: { effectStartsAt, reportedDescription, investigationName, count }
        }, '$', false));
        facility.allActions = actions.map(action => ReportNote.parse(action));
      });
      data.gridOptions.columns.push(EREventsService.createCategoryColumn(field));
    }

    const latestAlarms = _.get(options, 'supplementaryApi.latestAlarms.responseData', {});
    if (_.size(latestAlarms) > 0) {
      const field = 'alarm';
      data.gridOptions.dataSource.data.forEach(facility => {
        const alarms = latestAlarms[facility.Id] || [];
        const count = alarms.length;
        const latestAlarm = alarms[0];
        if (latestAlarm) {
          const executedAt = latestAlarm.executedAt.toISOString();
          const type = alarmService.getAlarmTypeName(latestAlarm.alarmTypeId);
          const status = alarmStatusToActionStatus(latestAlarm.status);
          const statusName = Number.isInteger(status)
            ? utils.localizedString(`ACTIONS.INVESTIGATION_${status}`)
            : null;
          Object.assign(facility, flattenObject({ [field]: { executedAt, type, statusName, count } }, '$', false));
        } else {
          Object.assign(facility, flattenObject({ [field]: { count } }, '$', false));
        }
        facility.allAlarms = alarms.map(alarm => ReportNote.parse(alarm));
      });
      data.gridOptions.columns.push(EREventsService.createCategoryColumn(field));
    }

    // post process grid options and set it
    postProcessGridOptions(gridOptions, options, data);
    grid[options.grid.gridOptionsProperty || 'gridOptions'] = gridOptions;
    return grid;
  }

  function getGridOptions(options, data) {
    const removeFooter = !(erStateService.isFacilityGridReport() || options.isWidget);
    const isExcelInGridToolbar = !(erStateService.isFacilityGridReport() || options.isWidget);
    const gridOptions = _.merge({
      excel: {
        title: options.grid.excel.getTitle(options, data),
        allPages: true
      },
      excelExport: event => {
        if (erStateService.shouldMergeExcelSheetsToOneFile()) {
          event.preventDefault();
        }
        event.workbook.fileName = `EnerKey_${dateFormatService.fileNameFormattedDate()}.xlsx`;
        const workbook = utils.modifyWorkbook(event.workbook, removeFooter);
        if (_.isFunction(_.get(options, 'grid.excel.postProcessWorkbook'))) {
          options.grid.excel.postProcessWorkbook(workbook);
        }
        $rootScope.$emit('export.finished', event.sender, workbook);
      },
      scrollable: true,
      columns: [],
      dataSource: {
        data: [],
        aggregate: [],
        schema: {
          model: {
            fields: {}
          }
        }
      },
      resizable: true,
      sortable: true,
      toolbar: isExcelInGridToolbar ? [{ name: 'excel' }] : '',
    }, options.grid.extraGridOptions);

    // pageSize
    if (options.grid.paging) {
      const pageSize = _.get(options.grid, 'paging.pageSize') || getPageSize(options);
      const rowCount = getRowCount(options);
      gridOptions.pageable = rowCount > pageSize;
      gridOptions.dataSource.pageSize = pageSize;
    }

    return gridOptions;
  }

  function createKeyColumns(options, data) {
    // check if aggregated quantity and add color column
    if (data.isAggregatedQuantityGrid) {
      data.gridOptions.columns.push({
        title: ' ',
        field: 'color',
        width: 5,
        template: ' ',
        attributes: {
          style: 'padding: 0; background-color: #=color#'
        }
      });
    }
    // check if row number column is needed
    if (options.grid.addRowNumberColumn) {
      data.gridOptions.dataBound = function(event) {
        // eslint-disable-next-line no-invalid-this
        const rows = this.items();
        const page = _.get(event, 'sender.dataSource._page') || 1;
        const pageSize = _.get(event, 'sender.dataSource._pageSize') || 0;
        angular.element(rows).each(function() {
          // eslint-disable-next-line no-invalid-this
          const rowNumber = angular.element(this).index() + 1 + ((page - 1) * pageSize);
          // eslint-disable-next-line no-invalid-this
          const rowLabel = angular.element(this).find('.row-number');
          angular.element(rowLabel).html(`${rowNumber}.`);
          // eslint-disable-next-line no-invalid-this
          const dataItem = event.sender.dataItem(this);
          if (dataItem) {
            dataItem['rowNumber'] = rowNumber;
          }
        });
      };
      data.gridOptions.columns.push({
        field: 'rowNumber',
        title: ' ',
        template: _.get(options, 'grid.addRowNumberColumn.template') || '<span class="right row-number"></span>',
        filterable: false,
        sortable: false,
        locked: !options.isWidget,
        width: 60
      });
    }

    const keyColumn = {
      field: 'key',
      locked: !options.isWidget
    };

    if (ERDataFunctions.isAggregated(options)) {
      _.extend(keyColumn, {
        template: row => {
          if (ERDataFunctions.isAggregated(options) && options.idType === 'Facility') {
            const result = row.key;
            const facility = options.cache.facilitiesById[row.Id];
            let realEstate = '';
            if (facility.FacilityInformation.RealEstateId) {
              realEstate = `${facility.FacilityInformation.RealEstateId} - `;
            }

            const toolTipText = `${realEstate + result} - ${facility.FacilityInformation.EnegiaId}`;
            return `
              <a ng-click='openModal(${row.Id})'>
                <span title="${AllHtmlEntities.encode(toolTipText)}" ng-non-bindable>
                  ${AllHtmlEntities.encode(result)}
                </span>
              </a>`;
          } else {
            return `<span ng-non-bindable>${AllHtmlEntities.encode(row.key)}</span>`;
          }
        },
        width: 265

      });
    } else {
      _.extend(keyColumn, {
        width: 113,
        attributes: {
          class: 'cell-number'
        }
      });
    }

    // title and data for aggregated
    if (ERDataFunctions.isAggregated(options)) {
      switch (options.idType) {
        case 'Facility':
          keyColumn.title = utils.localizedString('FACILITIES.FACILITIES');
          data.gridOptions.dataSource.data = _.map(ERDataFunctions.getFacilityIds(options), facilityId => {
            const facility = options.cache.facilitiesById[facilityId];
            return _.extend({
              Id: facilityId,
              key: _.get(facility, 'Name')
            }, flattenObject(facility, '$', false));
          });
          break;
        case 'Meter':
          keyColumn.title = utils.localizedString('METERS.METERS');
          data.gridOptions.dataSource.data = _.map(ERDataFunctions.getMeterIds(options), meterId => {
            const meter = options.cache.metersById[meterId];
            return _.extend({
              Id: meterId,
              key: _.get(meter, 'Name')
            }, flattenObject(meter, '$', false));
          });
          break;
        case 'Quantity':
          keyColumn.title = utils.localizedString('FACILITIES.QUANTITIES');
          data.gridOptions.dataSource.data = _.map(ERDataFunctions.getQuantityIds(options), quantityId => {
            const quantity = options.cache.quantitiesById[quantityId];
            return _.extend({
              Id: quantityId,
              key: _.get(quantity, 'Name'),
              color: ColorService.getMainPrimaryGraphColor(quantityId)
            }, flattenObject(quantity, '$', false));
          });
          break;
        default:
          break;
      }
    } else {
      keyColumn.title = ERUtils.resolutionTitle(options.series.Resolution);
    }

    // footer
    keyColumn.footerTemplate = keyColumnData => {
      let footerText = '';
      let first = true;
      _.each(options.grid.aggregates, aggregate => {
        const suffix = aggregate === 'sum' && keyColumnData.count ? ` (${keyColumnData.count})` : '';
        footerText += (first ? '' : '</br>')
          + utils.localizedString(`FACILITIES_REPORT.${aggregate.toUpperCase()}`)
          + suffix;
        first = false;
      });
      return footerText;
    };

    // add aggregate for key column if 'count' is defined
    if (options.grid.showCount) {
      keyColumn.aggregates = ['count'];
      data.gridOptions.dataSource.aggregate.push({
        field: keyColumn.field,
        aggregate: 'count'
      });
    }

    // add key column to columns
    data.gridOptions.columns.push(keyColumn);

    // add extra columns
    const extraColumns = options.grid.extraColumns || [];
    data.gridOptions.columns = data.gridOptions.columns.concat(extraColumns);
    const childColumns = _.compact(_.flatten(_.map(extraColumns, 'columns')));
    _.each(childColumns, column => {
      if (column.type) {
        data.gridOptions.dataSource.schema.model.fields[column.field] = { type: column.type };
        // add aggregates for column
        const columnNameLowerCase = _.get(column, 'field', '').toLowerCase();
        if (column.type === 'number' && !_.includes(columnNameLowerCase, 'year')) {
          ERDataFunctions.addAggregates(options, data, column, 'n0');
        }
      }
    });
  }

  function createRowCells(options, data) {
    const rows = data.gridOptions.dataSource.data;
    let previousDataForStart;
    const reportType = ERDataFunctions.getReportType(options, data.apiIndex);
    const aggregated = ERDataFunctions.isAggregated(options);

    _.eachRight(options.series.Start, (start, startIndex) => {
      const dataForStart = ERDataFunctions.getDataForStart(options, data, startIndex, { index: true });
      _.each(dataForStart.startInfo.keys, (key, value) => {
        if (aggregated) {
          value = parseInt(value, 10);
        }
        let rowIndex = aggregated ?
          _.findIndex(data.gridOptions.dataSource.data, { Id: value }) :
          _.findIndex(data.gridOptions.dataSource.data, { key });

        // create row if it doesn't exist
        if (rowIndex === -1) {
          const newRow = { key };
          if (aggregated) {
            newRow.Id = value;
          }

          if (ERDataFunctions.isPeriodReportWithMonthResolutionAndComparisionPeriod(options)) {
            rowIndex = _.findIndex(rows, row => parseInt(row.key, 10) > parseInt(key, 10));

            if (rowIndex === -1) {
              rows.push(newRow);
              rowIndex = rows.length - 1;
            } else {
              rows.splice(rowIndex, 0, newRow);
            }
          } else {
            rows.push(newRow);
            rowIndex = rows.length - 1;
          }
        }

        // create row values data
        const rowQuantityStartData = {};
        rowQuantityStartData[`${
          data.isAggregatedQuantityGrid ? '' : `$${data.quantityId}`
        }$${data.apiIndex}$${startIndex}`] =
          _.get(dataForStart, `startInfo.consumptions.${data.apiIndex}["${value}"]`);
        _.extend(rows[rowIndex], flattenObject(rowQuantityStartData, '$', true));

        const targetSeriesType = ERDataFunctions.getTargetSeriesType(options, data);
        const distributions = _.get(
          options.cache,
          `distributionTypesById.${options.series.DistributionId}.Distributions`,
          null
        );
        const relationalValueIds = options.cache.relationalValuesById && options.series.RelationalUnitIds
          ? Object
            .keys(options.cache.relationalValuesById)
            .filter(id => options.series.RelationalUnitIds.includes(Number(id)))
          : [];
        // create comparison data
        switch (reportType) {
          case ERReportType.TREND_REPORT:
            if (rowIndex > 0) {
              // Trend: Measured and normalized percentage and change compare column data
              _.each([false].concat(_.get(data, 'quantity.Normalization') ? [true] : []), normalized => {
                dataForStart.normalized = normalized;
                const readingField = ERDataFunctions.getReadingField(options, dataForStart);
                const readingCompareField = ERField.getTrendCompareField(options, dataForStart, 'Change');
                const readingCompareFieldPercent = ERField.getTrendCompareField(options, dataForStart, 'Percent');
                const reading = rows[rowIndex][readingField];
                const previousValue = rows[rowIndex - 1][readingField];
                rows[rowIndex][readingCompareField] =
                  _.isNumber(reading) && _.isNumber(previousValue) ? reading - previousValue : void 0;
                rows[rowIndex][readingCompareFieldPercent] = utils.percentageChange(reading, previousValue);
                const readingFieldValue = rows[rowIndex][ERField.getFlagField(readingField)] ||
                  rows[rowIndex - 1][ERField.getFlagField(readingField)];
                rows[rowIndex][ERField.getFlagField(readingCompareField)] = readingFieldValue;
                rows[rowIndex][ERField.getFlagField(readingCompareFieldPercent)] = readingFieldValue;
              });

              // Trend: Distribution percentage and change compare column data
              if (distributions) {
                _.each(distributions, (distribution, index) => {
                  const dataForDistribution = _.extend({
                    propertyData: {
                      object: distribution,
                      index: index
                    }
                  }, dataForStart);
                  const distributionField =
                    ERField.getDistributionField(dataForDistribution, 'Reading$Value');
                  const distributionCompareField =
                    ERField.getDistributionTrendCompareField(dataForDistribution, 'Change');
                  const distributionCompareFieldPercent =
                    ERField.getDistributionTrendCompareField(dataForDistribution, 'Percent');
                  const reading = rows[rowIndex][distributionField];
                  const previousValue = rows[rowIndex - 1][distributionField];
                  rows[rowIndex][distributionCompareField] =
                    _.isNumber(reading) && _.isNumber(previousValue) ? reading - previousValue : void 0;
                  rows[rowIndex][distributionCompareFieldPercent] = utils.percentageChange(reading, previousValue);
                  const distributionFieldValue = rows[rowIndex][ERField.getFlagField(distributionField)] ||
                    rows[rowIndex - 1][ERField.getFlagField(distributionField)];
                  rows[rowIndex][ERField.getFlagField(distributionCompareField)] = distributionFieldValue;
                  rows[rowIndex][ERField.getFlagField(distributionCompareFieldPercent)] = distributionFieldValue;
                });
              }
            }
            break;
          case ERReportType.FORECAST_REPORT:
          // let this fall into default
          // eslint-disable-next-line no-fallthrough
          default:
            if (_.get(previousDataForStart, 'previousStartInfo') || targetSeriesType) {
              _.each([false].concat(_.get(data, 'quantity.Normalization') ? [true] : []), normalized => {

                // Consumption target comparison column data (handled in different api index than default comparison)
                if (targetSeriesType) {
                  const isCost = ERDataFunctions.isCostTarget(targetSeriesType);
                  const clonedDataForStart = _.clone(dataForStart);
                  clonedDataForStart.normalized = normalized;

                  const targetCompareAgainstField =
                    ERDataFunctions.getReadingField(options, clonedDataForStart, clonedDataForStart.startInfo);
                  const targetComparedField =
                    ERDataFunctions.getReadingField(
                      options,
                      clonedDataForStart,
                      clonedDataForStart.startInfo,
                      ERDataFunctions.getApiIndexForReadingValues(options),
                      isCost ? RelationalValueId.Costs : undefined
                    );
                  const targetCompareField = ERField.getTargetCompareField(clonedDataForStart, 'Change');
                  const targetCompareFieldPercent = ERField.getTargetCompareField(clonedDataForStart, 'Percent');

                  createComparisonCell(rows[rowIndex], targetCompareField, targetCompareFieldPercent,
                    targetComparedField, targetCompareAgainstField);

                  // Default comparison column data
                } else {
                  previousDataForStart.normalized = normalized;
                  const defaultApiIndex = getApiIndexForDefaultReportType(options);

                  const readingField = previousDataForStart.apiIndex === defaultApiIndex ?
                    ERDataFunctions.getReadingField(
                      options, previousDataForStart, previousDataForStart.controlStartInfo
                    ) :
                    ERDataFunctions.getReadingField(
                      options, previousDataForStart, previousDataForStart.startInfo
                    );
                  const readingFieldPrevious = ERDataFunctions.getReadingField(options, previousDataForStart,
                    previousDataForStart.previousStartInfo, defaultApiIndex);
                  const readingCompareField = ERField.getCompareField(previousDataForStart, 'Change');
                  const readingCompareFieldPercent = ERField.getCompareField(previousDataForStart, 'Percent');

                  createComparisonCell(rows[rowIndex], readingCompareField, readingCompareFieldPercent,
                    readingField, readingFieldPrevious);

                  // Distribution comparison column data
                  if (distributions) {
                    _.each(distributions, (distribution, index) => {
                      const dataForDistribution = _.extend({
                        propertyData: {
                          object: distribution,
                          index: index
                        }
                      }, previousDataForStart);

                      const distributionField = ERField.getDistributionField(
                        dataForDistribution, 'Reading$Value', dataForDistribution.controlStartInfo
                      );
                      const distributionFieldPrevious = ERField.getDistributionField(
                        dataForDistribution, 'Reading$Value', dataForDistribution.previousStartInfo
                      );
                      const distributionCompareField = ERField.getDistributionCompareField(
                        dataForDistribution, 'Change'
                      );
                      const distributionCompareFieldPercent = ERField.getDistributionCompareField(
                        dataForDistribution, 'Percent'
                      );

                      createComparisonCell(
                        rows[rowIndex], distributionCompareField, distributionCompareFieldPercent,
                        distributionField, distributionFieldPrevious
                      );
                    });
                  }

                  // Relational value comparison column data
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  relationalValueIds.forEach(Id => {
                    const baseData = { propertyData: { object: { Id } }, ...previousDataForStart };
                    const { controlStartInfo, previousStartInfo } = previousDataForStart;
                    const relationalData = { ...baseData, startInfo: controlStartInfo };
                    const relationalDataPrevious = { ...baseData, startInfo: previousStartInfo };

                    const relationalCompareFieldChange = ERField.getCompareField(previousDataForStart, 'Change', Id);
                    const relationalCompareFieldPercent = ERField.getCompareField(previousDataForStart, 'Percent', Id);
                    const relationalField = ERField.getRelationalValueField(relationalData);
                    const relationalFieldPrevious = ERField.getRelationalValueField(relationalDataPrevious);

                    createComparisonCell(
                      rows[rowIndex],
                      relationalCompareFieldChange,
                      relationalCompareFieldPercent,
                      relationalField,
                      relationalFieldPrevious
                    );
                  });
                }
              });
            }
            break;
        }
      });
      previousDataForStart = dataForStart;
    });
  }

  function createComparisonCell(row, field, fieldPercent, compareAgainstField, comparedField) {
    const compareAgainstValue = row[compareAgainstField];
    const comparedValue = row[comparedField];

    row[field] =
      _.isNumber(compareAgainstValue) && _.isNumber(comparedValue) ? compareAgainstValue - comparedValue : void 0;
    row[fieldPercent] = utils.percentageChange(compareAgainstValue, comparedValue);

    const compareValue = row[ERField.getFlagField(compareAgainstField)] || row[ERField.getFlagField(comparedField)];
    row[ERField.getFlagField(field)] = compareValue;
    row[ERField.getFlagField(fieldPercent)] = compareValue;
  }

  function getApiIndexForDefaultReportType(options) {
    return _.findIndex(_.get(options, 'api'), api => !_.isNumber(_.get(api, 'requestData.ReportType')));
  }

  function getPageSize(options) {
    let result = 30;

    if (!ERDataFunctions.isAggregated(options)) {
      const resolutionDuration = moment.fromIsodurationCached(options.series.Resolution);

      if (resolutionDuration.years()) {
        result = 30;
      } else if (resolutionDuration.months()) {
        result = 24;
      } else if (resolutionDuration.days()) {
        result = 31;
      } else if (resolutionDuration.hours()) {
        result = 24;
      }
    }

    return result;
  }

  function getRowCount(options) {
    let result;

    if (ERDataFunctions.isAggregated(options)) {
      switch (options.idType) {
        case 'Facility':
          result = ERDataFunctions.getFacilityIds(options).length;
          break;
        case 'Meter':
          result = ERDataFunctions.getMeterIds(options).length;
          break;
        case 'Quantity':
          result = ERDataFunctions.getQuantityIds(options).length;
          break;
        default:
          break;
      }
    } else {
      result = ERUtils.timeFrameResolutionsCount(options.series.TimeFrame, options.series.Resolution);
    }

    return result;
  }

  // flatten object
  // source: http://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
  function flattenObject(object, delimeter, removeNotDefined) {
    delimeter = delimeter || '.';
    const result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur || cur instanceof Date) {
        // eslint-disable-next-line eqeqeq, no-eq-null
        if (!(removeNotDefined && cur == null)) {
          result[prop] = cur;
        }
      } else {
        let isEmpty = true;
        // eslint-disable-next-line guard-for-in
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + delimeter + p : p);
        }
        if (isEmpty && prop && !removeNotDefined) {
          result[prop] = {};
        }
      }
    }

    recurse(object, '');
    return result;
  }

  function postProcessGridOptions(gridOptions, options, data) {
    // Remove columns that does not have data
    if (options.grid.removeEmptyColumns) {
      for (let i = gridOptions.columns.length - 1; i >= 0; i--) {
        const categoryColumn = gridOptions.columns[i];
        if (angular.isArray(categoryColumn.columns)) {
          for (let j = categoryColumn.columns.length - 1; j >= 0; j--) {
            const column = categoryColumn.columns[j];
            const columnHasData = !!_.chain(gridOptions.dataSource.data)
              .map(column.field)
              .filter(value => angular.isNumber(value))
              .value().length;
            if (!columnHasData) {
              categoryColumn.columns.splice(j, 1);
            }
          }
          if (!categoryColumn.columns.length) {
            gridOptions.columns.splice(i, 1);
          }
        }
      }
    }

    gridOptions = sortGridColumns(gridOptions);

    // Create schema for quantity columns
    // This is used when row is toggled on or off (affects aggregate calculating)
    const quantityCategoryColumns = gridOptions.columns.filter(
      column => angular.isDefined(column.quantityId) || column.field === 'ActionsImpact'
    );
    quantityCategoryColumns.forEach(quantityCategoryColumn => {
      quantityCategoryColumn.columns.forEach(column => {
        gridOptions.dataSource.schema.model.fields[column.field] = { type: 'number' };
        if (gridOptions.filterable) {
          column.filterable = { extra: true };
        }
      });
    });

    // Flatten columns
    if (options.grid.flatColumns || data.isAggregatedQuantityGrid) {
      const columnsWithChilds = gridOptions.columns.filter(
        column => angular.isArray(column.columns)
      );
      const childColumns = _.flatten(columnsWithChilds.map(column => column.columns));
      gridOptions.columns = _.chain(gridOptions.columns)
        .difference(columnsWithChilds)
        .concat(childColumns)
        .reduce((result, column) => {
          if (!_.find(result, { field: column.field })) {
            result.push(column);
          }
          return result;
        }, [])
        .value();
    }

    // If all category columns were removed and only locked columns are visible,
    // remove locked property fron columns due to kendo will crash otherwise
    const lockedColumns = gridOptions.columns.filter(column => column.locked);
    if (lockedColumns.length === gridOptions.columns.length) {
      lockedColumns.forEach(column => {
        column.locked = false;
      });
    }

    handleComparabilityForGrid(gridOptions, options);
  }

  function sortGridColumns(gridOptions) {
    _.forEach(gridOptions.columns, column => {
      if (!column.columns) {
        return;
      }

      column.columns = sort(column.columns);
    });

    return gridOptions;
  }

  function handleComparabilityForGrid(gridOptions, options) {
    switch (options.series.Comparables) {
      case 'ByProperty':
        _.each(gridOptions.dataSource.data, (row, rowIndex) => {
          const newRow = _.reduce(row, (result, value, key) => {
            if (_.includes(key, 'Flags') && _.includes(row[key], 'Incomplete')) {
              const splitted = key.split('Flags')[0];
              if (_.has(row, `${splitted}Value`)) {
                row[`${splitted}Value`] = void 0;
              }
              if (_.has(row, `${splitted}ValueChange`)) {
                row[`${splitted}ValueChange`] = void 0;
              }
              if (_.has(row, `${splitted}ValuePercent`)) {
                row[`${splitted}ValuePercent`] = void 0;
              }
            }
            return row;
          }, {});
          gridOptions.dataSource.data[rowIndex] = newRow;
        });
        break;
      case 'ByQuantity':
        _.each(ERDataFunctions.getQuantityIds(options), quantityId => {
          _.each(gridOptions.dataSource.data, (row, rowIndex) => {
            const hasFlags = !!_.chain(row)
              .keys()
              .filter(key =>
                _.startsWith(key, `$${quantityId}$`) &&
                _.includes(key, 'Flags') &&
                _.includes(row[key], 'Incomplete'))
              .value().length;

            if (hasFlags) {
              const newRow = _.reduce(row, (result, value, key) => {
                // Trailing $ is important, so ie. Water (4) and DistrictHeating (46) are considered distinct
                row[key] = _.startsWith(key, `$${quantityId}$`) ? void 0 : value;
                return row;
              }, {});
              gridOptions.dataSource.data[rowIndex] = newRow;
            }
          });
        });
        break;
      case 'ByQuantities':
        _.each(gridOptions.dataSource.data, (row, rowIndex) => {
          const hasFlags = !!_.chain(row)
            .keys()
            .filter(key => _.includes(key, 'Flags') && _.includes(row[key], 'Incomplete'))
            .value().length
          ;
          if (hasFlags) {
            const newRow = _.reduce(row, (result, value, key) => {
              row[key] = _.startsWith(key, '$') ? void 0 : value;
              return row;
            }, {});
            gridOptions.dataSource.data[rowIndex] = newRow;
          }
        });
        break;
      default:
        // do nothing
        break;
    }
  }

  return {
    getGrids
  };
}
