import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'grid-array-row-filter',
  templateUrl: './grid-array-row-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridArrayRowFilterComponent<T> extends BaseFilterCellComponent implements OnInit {
  @Input() public field: string; // Mandatory field name
  @Input() public placeholder: string = ''; // Placeholder text for the input
  @Input() public options: T[] = []; // Array of possible filter values
  @Input() public showEmptyItems: boolean = false; // Whether to include rows with empty arrays
  @Input() public isKeyValueField: boolean = false; // Whether `options` is an array of objects
  @Input() public key?: string; // Key for object filtering (mandatory if `isKeyValueField` is true)

  public selectedValues: T[] = []; // Currently selected filter values

  public constructor(
    filterService: FilterService,
    private readonly translate: TranslateService
  ) {
    super(filterService);
  }

  public ngOnInit(): void {
    // Set the placeholder or use a default value from translations
    this.placeholder = this.placeholder || this.translate.instant('SEARCH');

    // Initialize selected values from the current filter, if any
    const filter = this.filterByField(this.field);
    if (filter?.value) {
      this.selectedValues = Array.isArray(filter.value) ? filter.value : [filter.value];
    }
  }

  public onFilterChange(): void {
    this.applyFilter(
      this.selectedValues.length > 0
        ? this.updateFilter({
          field: this.field,
          operator: this.createFilterOperator(),
          value: this.selectedValues,
        })
        : this.removeFilter(this.field)
    );
  }

  private createFilterOperator(): (rowValue: T[], selectedValue: T[]) => boolean {
    return (rowValue: T[], selectedValue: T[]): boolean => {
      if (!Array.isArray(rowValue)) {
        return false; // Ensure rowValue is an array
      }

      const matches = selectedValue.some(value =>
        this.isKeyValueField && this.key
          ? rowValue.some(row => (row)[this.key as keyof T] === value)
          : rowValue.includes(value)
      );

      // Include empty arrays if `showEmptyItems` is enabled
      return matches || (this.showEmptyItems && rowValue.length === 0);
    };
  }
}
