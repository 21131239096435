import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { WINDOW } from '@enerkey/angular-utils';

import { EnvironmentService } from './environment-service';

/**
 * Both angular and ajs infra try to create a new TelemetryService, so
 * share the AI object as initializing it twice causes problems
 */
let _applicationInsightsInstance: ApplicationInsights;

@Injectable({ providedIn: 'root' })
export class TelemetryService implements ErrorHandler {

  /** Whether Application Insights is enabled. */
  public readonly aiEnabled: boolean;

  private readonly applicationInsights: ApplicationInsights;

  public constructor(
    @Inject(WINDOW) private readonly _window: Window,
    private readonly environmentService: EnvironmentService
  ) {
    // Disable AI if not configured or in localhost
    const connectionString = ENERKEY_CONFIG.telemetry?.applicationInsights ?? '';
    const disableTelemetry = !connectionString || this._window.location.hostname.includes('localhost');
    const loggingLevelTelemetry = this.environmentService.isProduction() ? 2 : 1;

    _applicationInsightsInstance ??= new ApplicationInsights({
      config: {
        connectionString,
        disableTelemetry,
        loggingLevelTelemetry,
        maxBatchInterval: 30_000,
      },
    });

    this.applicationInsights = _applicationInsightsInstance;

    this.aiEnabled = !disableTelemetry;

    if (this.aiEnabled && !this.applicationInsights.core.isInitialized()) {
      this.applicationInsights.loadAppInsights();
    }
  }

  public setAuthenticatedUserContext(userId: number, organizationId: number): void {
    if (this.aiEnabled) {
      this.applicationInsights.setAuthenticatedUserContext(userId.toString(), `${organizationId}`);
    }
  }

  public trackEvent(eventName: string, payload: Record<string, unknown>): void {
    if (this.aiEnabled) {
      this.applicationInsights.trackEvent({ name: eventName, properties: payload });
    }
  }

  public trackPageView(stateName: string): void {
    if (this.aiEnabled) {
      this.applicationInsights.trackPageView({ name: stateName });
    }
  }

  /** Manually track error. */
  public trackError(error: Error, message?: string): void {
    if (!this.aiEnabled) {
      return;
    }

    this.applicationInsights.trackException({
      exception: error,
      severityLevel: 3,
      id: message || 'Unnamed manually tracked error',
    });
  }

  /**
   * Angular global error handling. Needs to be revisited after ajs no longer catching everything.
   * @deprecated Do not call this manually.
   */
  public handleError(error: Error): void {
    // Doesn't contain any useful info, most likely a dismissed modal
    if (error === undefined) {
      return;
    }

    console.error('Unhandled error', error); // eslint-disable-line no-console

    if (this.aiEnabled) {
      this.applicationInsights.trackException({
        exception: error,
        severityLevel: 3,
        id: 'Unhandled error',
      });
    }
  }

  /**
   * AngularJs global error handling.
   * @deprecated Do not call this manually.
   */
  public handleAjsError(exception: Error, cause?: string): void {
    if (cause) {
      console.error('Unhandled ajs error', exception, cause); // eslint-disable-line no-console
    } else {
      console.error('Unhandled ajs error', exception); // eslint-disable-line no-console
    }

    if (this.aiEnabled) {
      this.applicationInsights.trackException({
        exception,
        severityLevel: 3,
        id: 'Unhandled ajs error',
        properties: { cause },
      });
    }
  }
}
