import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingDataService } from './reporting-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { AmountsPerQuantity } from './sum-report.functions';
import { ReportType } from '../shared/report-type';
import { ReportingData } from './reporting-data-service-base';

export const sumKey = NaN;

@Injectable({
  providedIn: 'root'
})
export class SumReportService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityIds: number[],
    threshold: number,
    averageQuantities: Quantities[]
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingDataService.getSumReportData(params, facilityIds, threshold, false),
        this.reportingDataService.getDistributions(ReportType.Sum, params, facilityIds, true, false),
        this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Sum,
          params,
          facilityIds,
          averageQuantities,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
      ]),
      normalized: forkJoin([
        this.reportingDataService.getSumReportData(params, facilityIds, threshold, true),
        this.reportingDataService.getDistributions(ReportType.Sum, params, facilityIds, true, true),
        this.reportingDataService.getNormalizedDerivedValues(
          ReportType.Sum,
          params,
          facilityIds,
          averageQuantities,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
      ]),
      measuredTargets: this.reportingDataService.getMeasuredTargets(params, facilityIds, false),
      normalizedTargets: this.reportingDataService.getNormalizedTargets(params, facilityIds, false),
      temperatures: this.reportingDataService.getTemperature(params, facilityIds, true),
      nationalCosts: forkJoin([
        this.reportingDataService.getCostsSumReportData(
          params,
          facilityIds,
          true,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ]),
      meterBasedCosts: forkJoin([
        this.reportingDataService.getCostsSumReportData(
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ]),
      emission: forkJoin([
        this.reportingDataService.getMeasuredEmissionsValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        ),
        this.reportingDataService.getNormalizedEmissionsValues(
          ReportType.Sum,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          },
          undefined,
          true
        )
      ]),
    }).pipe(
      map(({
        measured,
        normalized,
        measuredTargets,
        normalizedTargets,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        emission,
      }) => this.reportingSeriesService.mapValuesById({
        ids: [sumKey],
        measured: measured.flat(),
        normalized: normalized.flat(),
        measuredTargets,
        normalizedTargets,
        temperatures: temperatures,
        nationalCosts: nationalCosts.flat(),
        meterBasedCosts: meterBasedCosts.flat(),
        emissions: emission.flat(),
        amountsPerQuantity: this.getFacilityAmountsByQuantity(measured, normalized),
        periods: params.periods,
        comparability: params.comparability,
        searchPeriods: params.searchPeriods,
        incompleteThreshold: threshold,
        resolution: params.resolution
      }))
    );
  }

  private getFacilityAmountsByQuantity(
    measured: ReportingData[][],
    normalized: ReportingData[][]
  ): AmountsPerQuantity {
    const facilitiesPerQuantity: AmountsPerQuantity = {};
    const grouped = [...measured, ...normalized].flat().toGroupsBy(x => x.quantityId);
    for (const [qId, dataArr] of grouped) {
      const includes = new Set<number>();
      const excludes = new Set<number>();
      for (const data of dataArr) {
        data.facilitiesExcluded?.forEach(fId => excludes.add(fId));
        data.facilitiesIncluded?.forEach(fId => includes.add(fId));
      }
      facilitiesPerQuantity[qId] = {
        applicableFacilities: includes.size,
        totalFacilities: includes.size + excludes.size,
      };
    }
    return facilitiesPerQuantity;
  }
}
