import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { IntlModule } from '@progress/kendo-angular-intl';

import { NgfTooltipModule } from '@enerkey/foundation-angular';

import { EkDropdownModule } from '../ek-dropdown/ek-dropdown.module';
import { GroupedColumnChooserComponent } from './components/grouped-column-chooser/grouped-column-chooser.component';
import { KendoGridCancelCommandDirective } from './directives/kendo-grid-cancel-command.directive';
import { KendoGridEditCommandDirective } from './directives/kendo-grid-edit-command.directive';
import { KendoGridExcelCommandDirective } from './directives/kendo-grid-excel-command.directive';
import { KendoGridExcelDirective } from './directives/kendo-grid-excel.directive';
import { KendoGridRemoveCommandDirective } from './directives/kendo-grid-remove-command.directive';
import { KendoGridSaveCommandDirective } from './directives/kendo-grid-save-command.directive';
import { KendoGridMultiSelectPrimitiveFilterComponent } from './components/kendo-grid-multi-select-primitive-filter/kendo-grid-multi-select-primitive-filter.component';
import { InCellTabDirective } from './directives/incell-tab.directive';
import { KendoGridCheckboxHeaderComponent } from './components/kendo-grid-checkbox-header/kendo-grid-checkbox-header.component';
import { BooleanMarkComponent } from './components/boolean-mark/boolean-mark.component';
import { FacilityPropertiesColumnGroupComponent } from './components/facility-properties-column-group/facility-properties-column-group.component';
import { GridArrayFilterComponent } from './components/grid-array-filter/grid-array-filter.component';
import { GridCheckboxFilterComponent } from './components/grid-checkbox-filter/grid-checkbox-filter.component';
import { GridMultiFilterTemplateDirective } from './components/grid-multi-filter-base/grid-multi-filter-template.directive';
import { GridMultiselectFilterComponent } from './components/grid-multiselect-filter/grid-multiselect-filter.component';
import { KendoGridFacilityPropertyColumnComponent } from './components/kendo-grid-facility-property-column/kendo-grid-facility-property-column.component';
import { KendoGridTableReportColumnGroupComponent } from './components/kendo-grid-table-report-column-group/kendo-grid-table-report-column-group.component';
import { FacilityPropertyFilterTypePipe } from './pipes/facility-property-filter-type.pipe';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { KendoGridManualColumnComponent } from './components/kendo-grid-manual-column/kendo-grid-manual-column.component';
import { ColumnTooltipsComponent } from './components/column-tooltips/column-tooltips.component';
import { FacilityInformationColumnGroupComponent } from './components/facility-information-column-group/facility-information-column-group.component';
import { KendoAutoFocusDirective } from './directives/kendo-auto-focus.directive';
import { KendoGridGroupHeaderCheckboxComponent } from './components/kendo-grid-group-header-checkbox/kendo-grid-group-header-checkbox.component';
import { GridInputSearchComponent } from './components/grid-input-search/grid-input-search.component';
import { GridChipListComponent } from './components/grid-chip-list/grid-chip-list.component';
import { GridArrayRowFilterComponent } from './components/grid-array-row-filter/grid-array-row-filter.component';

@NgModule({
  imports: [
    CommonModule,
    TreeViewModule,
    TranslateModule,
    GridModule,
    DropDownsModule,
    EkDropdownModule,
    InputsModule,
    IntlModule,
    FormsModule,
    CommonPipesModule,
    TooltipModule,
    ButtonsModule,
    NgfTooltipModule,
  ],
  declarations: [
    GroupedColumnChooserComponent,
    KendoGridCancelCommandDirective,
    KendoGridEditCommandDirective,
    KendoGridExcelCommandDirective,
    KendoGridExcelDirective,
    KendoGridRemoveCommandDirective,
    KendoGridSaveCommandDirective,
    KendoGridMultiSelectPrimitiveFilterComponent,
    InCellTabDirective,
    KendoGridCheckboxHeaderComponent,
    BooleanMarkComponent,
    FacilityPropertiesColumnGroupComponent,
    GridMultiFilterTemplateDirective,
    GridCheckboxFilterComponent,
    GridMultiselectFilterComponent,
    GridArrayFilterComponent,
    KendoGridFacilityPropertyColumnComponent,
    KendoGridTableReportColumnGroupComponent,
    FacilityPropertyFilterTypePipe,
    KendoGridManualColumnComponent,
    ColumnTooltipsComponent,
    FacilityInformationColumnGroupComponent,
    KendoAutoFocusDirective,
    KendoGridGroupHeaderCheckboxComponent,
    GridInputSearchComponent,
    GridChipListComponent,
    GridArrayRowFilterComponent
  ],
  exports: [
    GroupedColumnChooserComponent,
    KendoGridCancelCommandDirective,
    KendoGridEditCommandDirective,
    KendoGridExcelCommandDirective,
    KendoGridExcelDirective,
    KendoGridRemoveCommandDirective,
    KendoGridSaveCommandDirective,
    KendoGridMultiSelectPrimitiveFilterComponent,
    InCellTabDirective,
    KendoGridCheckboxHeaderComponent,
    BooleanMarkComponent,
    FacilityPropertiesColumnGroupComponent,
    GridMultiFilterTemplateDirective,
    GridCheckboxFilterComponent,
    GridMultiselectFilterComponent,
    GridArrayFilterComponent,
    KendoGridFacilityPropertyColumnComponent,
    KendoGridTableReportColumnGroupComponent,
    KendoGridManualColumnComponent,
    ColumnTooltipsComponent,
    FacilityInformationColumnGroupComponent,
    KendoAutoFocusDirective,
    KendoGridGroupHeaderCheckboxComponent,
    GridInputSearchComponent,
    GridChipListComponent,
    GridArrayRowFilterComponent
  ],
})
export class EkKendoModule { }
