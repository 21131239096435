import {
  IAngularStatic,
  IDocumentService,
  IFilterService,
  ILocationService,
} from 'angular';
import moment from 'moment';
import _ from 'lodash';

import { $InjectArgs, guid, percentageChange } from '@enerkey/ts-utils';

import { ToasterType } from '../constants/toaster-type';
import { ToasterService, ToastType } from '../shared/services/toaster.service';
import { TelemetryService } from './telemetry.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

declare const angular: IAngularStatic;

export class Utils {
  public static readonly $inject: $InjectArgs<typeof Utils> = [
    '$filter',
    '$location',
    '$document',
    '$translate',
    'ToasterService',
    'TelemetryService',
  ];

  public constructor(
    private readonly $filter: IFilterService,
    private readonly $location: ILocationService,
    private readonly $document: IDocumentService,
    private readonly $translate: any,
    private readonly toaster: ToasterService,
    private readonly telemetryService: TelemetryService
  ) { }

  public guid(isShortGuidRequired?: boolean): string {
    return guid(isShortGuidRequired);
  }

  public popUp(
    type: ToasterType | ToastType,
    title: string,
    text?: string,
    translate?: boolean,
    sticky?: boolean
  ): void {
    /* Valid types: success, info, warning, error */
    this.toaster.toast({
      type: type,
      title: title,
      message: text,
      translate: translate,
      disableTimeOut: sticky,
    });
  }

  public popUpGeneralError(
    type: Parameters<ToasterService['generalError']>[0],
    context: string,
    text?: string
  ): void {
    this.toaster.generalError(type, context as any, text);
  }

  public percentageChange(newValue: number, oldValue: number): number | undefined {
    return percentageChange(newValue, oldValue);
  }

  public isValidDate(value: string): boolean {
    return moment(new Date(value)).isValid();
  }

  public utcDateToDate(utcDateString: string): Date {
    const utcDate = new Date(utcDateString);
    return new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes(),
      utcDate.getUTCSeconds(),
      utcDate.getUTCMilliseconds()
    );
  }

  public dateToUtcDate(date: Date): Date {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
      )
    );
  }

  public getCurrentMonthFirstDate(): string {
    return moment().startOf('month').format();
  }

  public getCurrentYearFirstDate(): string {
    return moment().startOf('year').format();
  }

  public getMomentEndDate(
    from: Date | moment.Moment | string,
    isoDuration: unknown
  ): moment.Moment {
    return moment(from).add(moment.fromIsodurationCached(isoDuration));
  }

  public isoDuration(
    from: moment.Moment | Date,
    to: moment.Moment | Date,
    resolution: unknown
  ): string {
    const resolutionDuration = moment.fromIsodurationCached(resolution);
    let measurement = 'years';

    if (resolutionDuration.months()) {
      measurement = 'months';
    } else if (resolutionDuration.days()) {
      measurement = 'days';
    } else if (resolutionDuration.hours()) {
      measurement = 'hours';
    }

    return moment.duration(moment(to).diff(from as any, measurement), measurement).toJSON();
  }

  public durationToObject(
    startDate: Date,
    timeFrame: any,
    maxMomentEndDate?: moment.Moment
  ): unknown {
    const timeFrameDuration = moment.fromIsodurationCached(timeFrame);
    const format = timeFrameDuration.asHours() < 24 ? 'g' : 'd';
    let endDateMoment = moment(startDate).add(timeFrameDuration);
    if (_.isObject(maxMomentEndDate) && endDateMoment.isAfter(maxMomentEndDate)) {
      endDateMoment = maxMomentEndDate;
    }
    const endDate = endDateMoment.toDate();

    const fromString = kendo.toString(startDate, format);
    const toString = kendo.toString(
      format !== 'g' ? moment(endDate).subtract(1, 'seconds').toDate() : endDate, format
    );

    return {
      from: startDate,
      to: endDate,
      fromString: fromString,
      toString: toString,
      string: (fromString !== toString ? (`${fromString} - ${toString}`) : fromString)
    };
  }

  public durationToString(
    startDate: Date,
    timeFrame: unknown,
    maxMomentEndDate?: moment.Moment
  ): string {
    return (this.durationToObject(startDate, timeFrame, maxMomentEndDate) as any).string;
  }

  public formatForQuantity(
    quantity?: any,
    unitDefOrKey?: string | any,
    isHourOrDayRes?: unknown
  ): string {
    let unitDef = unitDefOrKey;
    if (angular.isString(unitDefOrKey) && _.isObject(quantity.Units)) {
      unitDef = quantity.Units[unitDefOrKey];
    }
    const additionalDecimals = isHourOrDayRes ? 2 : 0;
    const decs = _.isObject(unitDef) ? (unitDef as any).DecimalsToShow + additionalDecimals : 0;
    return `n${decs}`;
  }

  /* Source: http://stackoverflow.com/a/16966533 */
  public getStyleRuleValue(
    style: string,
    selector: string,
    asHex: boolean,
    sheet?: any // StyleSheet & CSSGroupingRule & CSSPageRule
  ): string {
    // Get our main bundle, a bit blindly trusting it's name
    if (!sheet) {
      // Note: this tries to find the main bundle file when it is hashed. Usually while developing
      // devs don't have it hashed, so it is not found. Therefore there is the second check below
      // which makes sure we only handle css files from the same domain.
      sheet = _.find(
        this.$document[0].styleSheets,
        styleSheet => styleSheet.href
          ? styleSheet.href.indexOf('/hashed-styles-') !== -1
          : false
      );
    }

    const sheets = angular.isDefined(sheet) ? [sheet] : this.$document[0].styleSheets;
    for (let i = 0, l = sheets.length; i < l; i++) {
      sheet = sheets[i];

      if (sheet.href && sheet.href.indexOf(this.$location.host()) < 0) {
        // This is not the CSS we're interested in because it is not from our domain
        continue;
      }

      if (!sheet.cssRules) {
        // Make sure we are able to access the CSS rules of the sheet
        continue;
      }

      for (let j = 0, k = sheet.cssRules.length; j < k; j++) {
        const rule = sheet.cssRules[j];
        if (rule.selectorText && rule.selectorText.replace(/\s/g, '').split(',').indexOf(selector) !== -1) {
          return asHex ? this.rgb2hex(rule.style[style]) : rule.style[style];
        }
      }
    }
    return null;
  }

  public rgb2hex(rgb: string): string {
    if (!rgb) {
      return '#000000';
    }

    const rgbMatch = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    if (rgbMatch && rgbMatch.length === 4) {
      // eslint-disable-next-line prefer-template
      return '#' +
        Number.parseInt(rgbMatch[1], 10).toString(16).padStart(2, '0') +
        Number.parseInt(rgbMatch[2], 10).toString(16).padStart(2, '0') +
        Number.parseInt(rgbMatch[3], 10).toString(16).padStart(2, '0');
    } else {
      return '';
    }
  }

  public localizedString(str: string, params?: any): string {
    return this.$filter<Function>('translate')(this.$filter('uppercase')(str), params);
  }

  public trackError(error: Error | string, message: Error | string): void {
    if (typeof error === 'string') {
      [error, message] = [message, error];
    }
    if (!(error instanceof Error)) {
      error = new Error(error);
    }
    if (error) {
      this.telemetryService.trackError(error, message as string);
    }
  }

  /**
  * Modifies excel workbook
  * - replaces <br> elements with '\r\n'
  * - removes html tags
  * - formats number columns according to template or format specified to column
  * - replaces boolean values with localized 'yes' and 'no'
  * - adds 'Arial' as font for every cell
  * - removes aggregate rows if specified so
  *
  * @param {Object} workbook Excel workbook content.
  * @param {boolean} removeAggregateRows remove aggregate rows from workbook.
  * @returns {Object} Returns modified Excel workbook content.
  */
  public modifyWorkbook(workbook: any, removeAggregateRows?: boolean): unknown {
    if (workbook) {
      const styleRules: any = {};
      _.each(workbook.sheets, sheet => {
        sheet.rows = !removeAggregateRows
          ? sheet.rows
          : _.filter(sheet.rows, (row: any) => row.type !== 'footer');

        _.each(sheet.rows, row => {
          _.each(row.cells, cell => {
            // The extra.template, if it exists, contains the real value of the cell (result
            // of executing the template function), so use that if the value is otherwise empty.
            if (!cell.value && _.isObject(cell.extra) && cell.extra.template) {
              cell.value = cell.extra.template;
            }
            if (angular.isString(cell.value)) {
              // replace br elements with \r\n
              const count = (cell.value.match(/<br\/>/g) || []).length + (cell.value.match(/<br>/g) || []).length;
              if (count) {
                cell.value = cell.value.replace(/<br\/>/g, '\r\n');
                cell.value = cell.value.replace(/<br>/g, '\r\n');
                cell.rowSpan = count;
                cell.wrap = true;
              }
              // remove every html tag
              cell.value = cell.value.replace(/<\/?[^>]+(>|$)/g, '');
            }
            if (angular.isNumber(cell.value) && _.isObject(cell.extra)) {
              if (cell.extra.template) {
                const hasClass = (`${cell.extra.template}`).match(/class=(['"])([^'"]*)(['"])/);
                if (hasClass && hasClass.length > 2 && !_.isEmpty(hasClass[2])) {
                  const selector = hasClass[2];
                  const color = _.has(styleRules, selector)
                    ? styleRules[selector]
                    : this.getStyleRuleValue('color', `.${selector}`, true);
                  if (color) {
                    cell.color = color;
                  }
                  styleRules[selector] = color;
                }
              }
              if (cell.extra.template || cell.extra.format) {
                let text = cell.extra.template
                  ? (`${cell.extra.template}`).replace(/<(?:.|\n)*?>/gm, '')
                  : kendo.format(cell.extra.format, cell.value);
                const isPercentage = _.includes(text, '%');
                if (isPercentage) {
                  cell.format = '0.0 %';
                } else {
                  const culture = kendo.culture();
                  const numberFormat = _.isObject(culture) ? culture.numberFormat : void 0;
                  if (_.isObject(numberFormat)) {
                    text = text.split(numberFormat[',']).join('');
                    text = text.split(numberFormat['.']).join('.');
                    text = text.trim();
                    const value = parseFloat(text);
                    if (_.isNumber(value)) {
                      if (value === parseInt(value as any) && text.length === value.toString().length) {
                        cell.format = '0';
                      } else {
                        const decs = /\d*$/.exec(text)[0].length;
                        cell.format = `0.${Array(decs + 1).join('0')}`;
                      }
                    }
                  }
                }
              }
            }
            if (typeof cell.value === 'boolean') {
              cell.value = this.localizedString(cell.value ? 'YES' : 'NO');
            }
            // set font to Arial
            cell.fontFamily = 'Arial';
          });
        });
      });
    }
    return workbook;
  }

  public istevenMultiSelectTranslation(): object {
    return {
      selectAll: this.$translate.instant('MULTISELECT.SELECT_ALL'),
      selectNone: this.$translate.instant('MULTISELECT.SELECT_NONE'),
      reset: this.$translate.instant('MULTISELECT.RESET_SELECTION'),
      search: this.$translate.instant('MULTISELECT.SEARCH_SELECTABLE_ITEMS'),
      nothingSelected: this.$translate.instant('MULTISELECT.NOTHING_SELECTED')
    };
  }

  public efficientWatch(
    property: string,
    object: Record<any, any>,
    callback: Function
  ): unknown {
    const _property = `_${property}`;
    object[_property] = object[property];
    return Object.defineProperty(object, property, {
      get: function() {
        return this[_property];
      },
      set: function(newValue) {
        const oldValue = this[_property];
        this[_property] = newValue;
        if (angular.isFunction(callback)) {
          callback(newValue, oldValue);
        }
      }
    });
  }

  public getDummyEntity(Id: number): { Id: number; Name: string } {
    return { Id: Id, Name: `id: ${Id}` };
  }

  public keysToUpperCamelCase(convertable: object): object {
    return this.iterateKeys(
      convertable,
      (key: string) => key.capitalize(),
      new WeakMap<any, any>()
    );
  }

  public mapLocalizationTagArrayToLocalizedObject(translations: string[]): any {
    return translations.reduce(
      (result, translation) => ({ ...result, [translation]: this.localizedString(translation) }),
      {}
    );
  }

  private iterateKeys(
    source: Record<string, any> | any[],
    keyConvertFn: (key: string) => string,
    refs: WeakMap<any, any>
  ): object {
    if (_.isArray(source)) {
      if (refs.has(source)) {
        return refs.get(source);
      }

      const converted: any[] = [];
      refs.set(source, converted);

      converted.push(...source.map(item => this.iterateKeys(item, keyConvertFn, refs)));
      return converted;
    } else if (_.isObject(source)) {
      if (refs.has(source)) {
        return refs.get(source);
      }

      const converted: Record<string, any> = {};
      refs.set(source, converted);

      _.forEach(source, (value: any, key: string) => {
        if (key !== '$promise' && key !== '$resolved') {
          const convertedKey = keyConvertFn(key);
          const convertedValue = this.iterateKeys(value, keyConvertFn, refs);
          converted[convertedKey] = convertedValue;
        }
      });

      return converted;
    } else {
      return source;
    }
  }
}
