import facilitiesSectionsTemplate from 'ngtemplate-loader!raw-loader!../../templates/facilities-sections.html';

import _ from 'lodash';
import moment from 'moment';
import { startOfDay, subMinutes } from 'date-fns';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ActionType, DocumentCompleteViewModel, ExecutionPhase } from '@enerkey/clients/attachments';

import { Roles } from '../../../admin/constants/roles';
import {
  ACTION_TYPE_IDS,
  COMMENT_TYPE_IDS,
  getNewActionDefaults
} from '../../../energy-management/constants/em-shared.constant';
import { ErReportType } from '../../constants/er-report-type';
import { Service } from '../../../../constants/service';
import { isCost } from '../../../reportingobjects/shared/relational-value-functions';
import SearchTypes from '../../../manual-qa/constants/search-types';
import TimeFrame from '../../../../services/time-frame-service';
import { adjustByTimezoneOffset } from '../../../../shared/date.functions';
import { DocumentsPreviewComponent } from '../../../documents/components/documents-preview/documents-preview.component';

const $inject = [
  '$q', '$scope', '$rootScope', '$state', 'ERDataService2', 'facilities', 'consumptions', 'meters',
  'actions', 'utils', 'documents', 'UserService', 'ERReportType', 'forecastTypes',
  'ActionsToTimeseriesMapper', 'weather', 'AdditionalSettingService',
  'ConsumptionTargetDataService', 'MeterInterfaceService', 'appStatusService',
  'emActionEditService', 'erParamsService', 'erReportSettingsService', 'Scroll', 'erStateService',
  'ManualQaInspectModalService', 'AlarmClient', 'alarmService', 'AttachmentsClient', 'quantities', 'LoadingService',
  'modalServiceAngular'
];

function MetersReportController(
  $q, $scope, $rootScope, $state, ERDataService2, facilities, consumptions, meters,
  actionService, utils, documents, UserService, ERReportType, forecastTypes,
  ActionsToTimeseriesMapper, weather, AdditionalSettingService,
  ConsumptionTargetDataService, MeterInterfaceService, appStatusService,
  emActionEditService, erParamsService, erReportSettingsService, Scroll, erStateService,
  ManualQaInspectModalService, alarmClient, alarmService, attachmentsClient, quantityService, LoadingService,
  modalServiceAngular
) {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias

  vm.facilitiesSectionsTemplate = facilitiesSectionsTemplate;

  let reportType;

  vm.$onInit = function() {
    $scope.cache = vm.cache;
    $scope.params = vm.reportParams;
    reportType = vm.reportParams.reportType;

    // put forecast types to cache
    if ($scope.params.reportType === ErReportType.FORECAST_REPORT) {
      _.extend($scope.cache, { forecastTypesById: _.keyBy(forecastTypes.getForecastTypes(), 'Id') });
    }

    updateReportSections();
    getFacility()
      .then(getFacilityMeters)
      .then(() => $scope.regenerateData());
  };

  $scope.defaultTimer = 200;
  vm.isLoading = () => LoadingService.isLoading();
  vm.getAlarmTypeName = alarmType => alarmService.getAlarmTypeName(alarmType);

  const reportSettingCallbackUnbind = erReportSettingsService.getInstance().setCallback(() => {
    updateReportSections();
  });

  function updateReportSections() {
    $scope.reportSections = erParamsService.getFlatSectionConfigsWithTemplates(
      erReportSettingsService.getInstance().getSettings().sectionsConfig
    );
  }

  // report data
  $scope.facility = {};
  $scope.reportData = [];

  $scope.actionsAllowed = UserService.hasService(Service.Actions);
  $scope.commentsAllowed = UserService.hasService(Service.Comments);
  $scope.alarmsAllowed = UserService.hasService(Service.Alarms);
  $scope.isMeterManager = UserService.hasRole(Roles.METER_MANAGER_USER);
  $scope.isQaManager = UserService.hasRole(Roles.QUALITY_MANAGER);

  if ($scope.actionsAllowed || $scope.commentsAllowed) {
    $scope.showAction = action => {
      $q.all([
        firstValueFrom(attachmentsClient.getAction(action.id)),
        actionService.getDocumentsForAction(action.id)
      ])
        .then(([fullAction, actionDocuments]) => {
          fullAction.documents = utils.keysToUpperCamelCase(actionDocuments.data);
          emActionEditService.getModal(fullAction).then(() => {
            // $state.reload clears all data from a modal view, so a workaround is required.
            if (erStateService.isModalOpen()) {
              erReportSettingsService.getInstance('er-modal-report').reload();
            } else {
              $state.reload();
            }
          });
        });
    };

    // Give an action with default values as parameter. By default use the energy consumption comment type.
    // There are values for other fields as well, but those are coerced out when saving so only valid fields
    // get saved.
    $scope.addAction = (facility, graphData) => {
      const actionType = $scope.commentsAllowed ? ActionType.KE : ActionType.ES;
      const defaults = getNewActionDefaults(actionType);
      defaults.reportingObjectId = facility.FacilityId;
      defaults.quantityIds = graphData.quantityId ? [graphData.quantityId] : [];
      defaults.meterIds = graphData.id ? [graphData.id] : [];
      emActionEditService.getModal(defaults).then(() => {
        // $state.reload clears all data from a modal view, so a workaround is required.
        if (erStateService.isModalOpen()) {
          erReportSettingsService.getInstance('er-modal-report').reload();
        } else {
          $state.reload();
        }
      });
    };
  }

  $scope.meterInterfaceStatus = function(item) {
    return MeterInterfaceService.getModal(
      item.facility.Name,
      item.meter.Id,
      item.meter.Name
    );
  };

  $scope.showMeterMapImage = function(document) {
    const modal = modalServiceAngular.open(DocumentsPreviewComponent);
    modal.componentInstance.selectedDocument = new DocumentCompleteViewModel({
      id: document.Id,
      fileNameOrUrl: document.FileNameOrUrl
    });
  };

  $scope.regenerateData = _.debounce(direction => {
    const quantities = getScopeQuantities();
    const missingQuantities = quantities.filter(q => !($scope.cache.quantitiesById[q]));
    let missingQuantityPromise;

    if (missingQuantities.hasItems()) {
      missingQuantityPromise = firstValueFrom(quantityService.getAllQuantities()).then(all => {
        for (const missingId of missingQuantities) {
          $scope.cache.quantitiesById[missingId] = all.find(q => q.ID === missingId);
        }
      });
    } else {
      missingQuantityPromise = Promise.resolve();
    }

    missingQuantityPromise.then(() => {
      const series = getSeries();
      const payload = getPayload(series, direction);

      if (erParamsService.validateSeries(series) && payload) {
        getFacilityNoteData(series, payload)
          .then(() => getData(series, payload))
          .then(data => {
            createReport(data, series, payload);
          })
          .catch((error, title) => {
            utils.trackError(error, 'MetersReportController getFacilityNoteData');
            erParamsService.handleError(error, title);
          });
      }
    });
  }, $scope.defaultTimer);

  function getData(series, payload) {
    const deferred = $q.defer();
    let promises = [];

    $scope.errorTextKey = erParamsService.getSeriesErrorTextKey(series);

    if (!$scope.errorTextKey) {
      promises = promises
        .concat(getConsumptionData(series, payload))
        .concat(getWeatherData(series, payload))
        .concat(ConsumptionTargetDataService.getData(series, payload))
      ;
    }

    function onSuccess(result) {
      deferred.resolve(_.filter(result));
    }

    function onError() {
      deferred.reject();
    }

    $q.all(promises)
      .then(onSuccess)
      .catch(onError);

    return deferred.promise;
  }

  function getFacility() {
    const facilityId = $scope.params.facilityId[0];
    return facilities.getFacility(facilityId)
      .then(facility => {
        $scope.facility = _.cloneDeep(facility);

        documents.getMapImages({ reportingObjectId: facilityId })
          .then(mapImages => {
            $scope.facility.mapImages = mapImages;
          })
          .catch(() => {
            // This probably needs some actual error handling
            // eslint-disable-next-line angular/log, no-console
            console.log('Error getting map images...');
          })
        ;
      })
      .catch(() => {
        // This probably needs some actual error handling
        // eslint-disable-next-line angular/log, no-console
        console.log('Error getting facility...');
      })
    ;
  }

  function getFacilityMeters() {
    return meters.getMetersForFacility($scope.params.facilityId[0])
      .then(result => {
        $scope.facility.meters = result;
      })
      .catch(() => {
        // This probably needs some actual error handling
        // eslint-disable-next-line angular/log, no-console
        console.log('Error getting facility meters...');
      })
    ;
  }

  function getFacilityNoteData(series, payload) {
    const deferred = $q.defer();
    forkJoin(
      getAlarmsData(series, payload),
      getActionsData(series, payload)
    ).pipe(
      map(([alarms, actions]) => ({ alarms, actions }))
    ).subscribe(noteData => {
      $scope.noteData = noteData;
      deferred.resolve();
    });
    return deferred.promise;
  }

  function getAlarmsData(series, payload) {
    if (!$scope.alarmsAllowed) {
      return of($scope.facilityActions);
    }

    const searchParams = {
      meterIds: payload.MeterId,
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to
    };
    return alarmClient.getLiteLogsBySearchCriteria(UserService.getCurrentProfile().id, searchParams);
  }

  function getActionsData(series, payload) {
    if (!($scope.actionsAllowed || $scope.commentsAllowed) || $scope.params.facilityId.length === 0) {
      return of([]);
    }

    let actionTypes = [];

    if ($scope.actionsAllowed) {
      actionTypes = actionTypes.concat(ACTION_TYPE_IDS);
    }

    if ($scope.commentsAllowed) {
      actionTypes = actionTypes.concat(COMMENT_TYPE_IDS);
    }

    const searchParams = {
      facilityIds: payload.FacilityId,
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to,
      includedActionTypes: actionTypes,
      includedExecutionPhases: [ExecutionPhase.Implemented, ExecutionPhase.ImplementationDecided]
    };
    return attachmentsClient.getActionsFlatList(undefined, searchParams);
  }

  function getConsumptionData(series, payload) {
    if (!payload.MeterId.length) {
      return $q.resolve({
        requestData: payload,
        responseData: {}
      });
    }

    const promises = [];
    const consumptionPayload = _.extend({}, payload, {
      ReportType: $scope.params.reportType === ErReportType.FORECAST_REPORT ? undefined : reportType
    });
    const consumptionDeferred = $q.defer();

    function onSuccess(responseData) {
      consumptionDeferred.resolve({
        requestData: consumptionPayload,
        responseData: responseData
      });
    }

    consumptions
      .getConsumptionsForMeters(consumptionPayload)
      .then(onSuccess)
    ;

    promises.push(consumptionDeferred.promise);

    let selectedForecastTypeIds;
    switch (reportType) {
      case ERReportType.FORECAST_REPORT:
        selectedForecastTypeIds = _.map(forecastTypes.getForecastTypes(), 'Id');
        _.each(selectedForecastTypeIds, forecastTypeId => {
          const forecastPayload = _.extend({}, payload, {
            ReportType: ERReportType.FORECAST_REPORT,
            ForecastType: forecastTypeId
          });
          const forecastDeferred = $q.defer();

          function onForecastSuccess(responseData) {
            forecastDeferred.resolve({
              requestData: forecastPayload,
              responseData: responseData
            });
          }

          consumptions
            .getConsumptionsForMeters(forecastPayload)
            .then(onForecastSuccess);

          promises.push(forecastDeferred.promise);
        });
        break;
      default:
        break;
    }

    return promises;
  }

  function getWeatherData(series, payload) {
    if (!payload.Quantities.length || _.get(series, 'Temperature') === false) {
      return $q.resolve();
    }

    const deferred = $q.defer();

    const payloadForWeather = weather.getPayload(
      _.pick(series, ['TimeFrame', 'Resolution', 'Start']),
      [$scope.facility]
    );

    if (!payloadForWeather) {
      return $q.resolve();
    }

    function onSuccess(response) {
      deferred.resolve({
        requestData: payloadForWeather,
        responseData: response.data
      });
    }

    weather
      .getWeatherByMeters(payloadForWeather, $scope.facility, $scope.facility.meters)
      .then(onSuccess)
    ;

    return deferred.promise;
  }

  function getSeries() {
    const seriesForReport = _.cloneDeep($scope.params.series);
    if (seriesForReport.RelationalUnitIds) {
      seriesForReport.RelationalUnitIds = seriesForReport.RelationalUnitIds.filter(id => isCost(id));
    }

    switch (reportType) {
      case ERReportType.TREND_REPORT:
        _.extend(seriesForReport, { RelatedValues: undefined, Comparables: undefined });
        if (_.get($scope.params.series, 'Start', []).length) {
          _.extend(seriesForReport, { Start: [_.last($scope.params.series.Start)] });
        }
        break;
      case ERReportType.FORECAST_REPORT:
        _.extend(seriesForReport, { Comparables: 'ByProperty', Temperature: undefined });
        break;
      default:
        break;
    }

    return AdditionalSettingService.filterDisabled(seriesForReport);
  }

  /** Returns all quantities in report meters */
  function getScopeQuantities() {
    const filteredMeters = _.filter($scope.facility.meters, meter => _.includes($scope.params.meterId, meter.Id));
    const quantityIds = _.uniq(_.map(filteredMeters, 'QuantityId'));
    return quantityIds;
  }

  function getPayload(series, direction, allSelectedMeters) {
    const quantityIds = getScopeQuantities();
    const facilityIds = $scope.params.facilityId.length ? [$scope.params.facilityId[0]] : [];

    // meter ids and infinite scroll
    let meterIds = $scope.params.meterId;
    if (!allSelectedMeters) {
      const ISO = $scope.infiniteScrollOptions;
      const validData = _.filter($scope.reportData, { obsolete: false });
      const lastValidIndex = validData.length ? _.indexOf(meterIds, _.last(validData).Id) : -1;
      const firstValidIndex = validData.length ? _.indexOf(meterIds, _.head(validData).Id) : 0;
      switch (direction) {
        case -1:
          meterIds = meterIds.slice(Math.max(firstValidIndex - 1 - ISO.loadCount, 0), firstValidIndex);
          break;
        case 1:
          meterIds = meterIds.slice(lastValidIndex + 1, lastValidIndex + 1 + ISO.loadCount);
          break;
        default:
          meterIds = meterIds.slice(firstValidIndex, firstValidIndex + ISO.loadCount);
          break;
      }
    }

    if (erParamsService.validateSeries(series) && facilityIds.length && meterIds.length) {
      return {
        Quantities: _.chain(quantityIds)
          .reduce((result, quantityId) => {
            const quantity = $scope.cache.quantitiesById[quantityId];
            if (quantity) {
              result.push({
                ID: quantityId,
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: erParamsService
                  .filterRelatedValueIdsForQuantity($scope.cache, series.RelatedValues, quantityId),
                RelationalUnitIds: $scope.params.series.RelationalUnitIds
              });
            }
            return result;
          }, [])
          .value(),
        Resolution: series.Resolution,
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        MeterId: meterIds,
        Unit: $scope.params.unitKey,
        ReportType: reportType,
        Cumulative: $scope.params.reportType === ErReportType.FORECAST_REPORT
      };
    }
  }

  function createReport(responseDatas, series, payload) {
    // helper array
    const graphsCreatedOrUpdated = [];

    // options for er data service to create charts and grids
    const options = {
      idType: 'Meter',
      reportType: reportType,
      series: series,
      params: $scope.params,
      cache: _.extend($scope.cache, { metersById: _.keyBy($scope.facility.meters, 'Id') }),
      api: responseDatas,
      show: {
        distributionAndValue: false,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: true
      },
      chart: {
        getCurrentChart: function(chartOptions, data) {
          const reportDataForMeter = _.find($scope.reportData, { Id: data.meterId });
          const propertyData = _.head(data.propertiesData);
          const property = _.get(propertyData, 'path');
          let chart;
          let title;
          let changePercent;
          if (chartOptions.chart.separateQuantityProperties) {
            chart = _.find(_.get(reportDataForMeter, 'graphData'), { id: data.meterId, property: property });
            title = `${data.quantity.Name} - ${_.get(propertyData, 'object.Name')}`;
          } else {
            chart = reportDataForMeter ? _.find(reportDataForMeter.graphData, { id: data.meterId }) : undefined;
            title = `${_.get(data, 'quantity.Name')} [${data.unit}]`;
            const value = _.get(
              data,
              `consumptions.0.Aggregates["${_.get(payload, 'Start.0.key')}"].${propertyData.path}`
            );
            const previousValue = _.get(
              data,
              `consumptions.0.Aggregates["${_.get(payload, 'Start.1.key')}"].${propertyData.path}`
            );
            changePercent = utils.percentageChange(value, previousValue);
          }
          if (!chart) {
            chart = {
              id: data.meterId,
              property: property
            };
            if (reportDataForMeter) {
              reportDataForMeter.graphData.push(chart);
            }
          }
          _.extend(chart, {
            title: title,
            quantity: data.quantity,
            change: changePercent,
            changeTitle: `${_.get(payload, 'Start.1.key')} ➜ ${_.get(payload, 'Start.0.key')} %`
          });
          graphsCreatedOrUpdated.push(chart);
          return chart;
        },
        serieClick: {
          enabled: !reportType
        },
        separateQuantityProperties: $scope.params.reportType === ErReportType.FORECAST_REPORT
      },
      grid: {
        getCurrentGrid: function(_options, data) {
          const reportDataForMeter = _.find($scope.reportData, { Id: data.meterId });
          const tableData = _.get(reportDataForMeter, 'tableData');
          return tableData || {
            id: `${appStatusService.inModal ? 'modal-' : ''}grid-${data.meterId}`,
            gridContent: {
              hidden: false,
              modified: false
            }
          };
        },
        aggregates: $scope.params.reportType === ErReportType.FORECAST_REPORT ? [] : ['sum', 'min', 'max', 'average'],
        excel: {
          getTitle: function(excelOptions, data) {
            return _.get(excelOptions, `cache.metersById.${data.meterId}.Name`);
          }
        }
      }
    };

    const visuals = ERDataService2.getVisuals(options, {});
    const charts = _.get(visuals, 'charts', {});
    const grids = _.get(visuals, 'grids', {});

    // remove charts that where not created/updated
    if (options.chart.separateQuantityProperties) {
      _.each($scope.reportData, reportDataForMeter => {
        reportDataForMeter.graphData = _.filter(
          reportDataForMeter.graphData,
          graph => _.includes(graphsCreatedOrUpdated, graph)
        );
      });
    }

    // create report data for each meter
    _.each(payload.MeterId, meterId => {
      const meter = $scope.facility.meters.find(({ Id }) => Id === meterId);
      const notesForGraph = ActionsToTimeseriesMapper
        .getNotesRelevantToGraph($scope.noteData, series, meter.QuantityId, meter.Id);
      let reportDataForMeter = $scope.reportData.find(({ Id }) => Id === meterId);

      if (!reportDataForMeter) {
        reportDataForMeter = {
          Id: meterId,
          facility: $scope.facility,
          meter: _.cloneDeep(meter),
          graphData: charts[meterId],
          tableData: grids[meterId],
          notes: notesForGraph
        };
        $scope.reportData.push(reportDataForMeter);

        // fetch meter map images
        meters.getMeterMapImages(meterId)
          .then(mapImages => {
            // facility is guaranteed to receive map images before meter map requests fire
            reportDataForMeter.meter.mapImages = (
              _.isArray(mapImages) && mapImages.length ? mapImages : $scope.facility.mapImages
            );
          });
      }

      // Add action data to graphs
      if ($scope.alarmsAllowed || $scope.actionsAllowed || $scope.commentsAllowed) {
        reportDataForMeter.graphData.forEach(graph => {
          graph.graphNotes = ActionsToTimeseriesMapper.getNotesForTable(notesForGraph);
          graph.graphAlarms = ActionsToTimeseriesMapper.getAlarmsForTable(notesForGraph);
          ActionsToTimeseriesMapper.appendNotesOnTimeline(notesForGraph, graph, series);
        });
      }

      // calculate correctly change percent when comparability setting is defined
      if (series.Comparables && payload.Start.length > 1) {
        const sumFunction = function(serie) {
          return _.reduce(serie.data, (result, dataItem) => result + _.get(dataItem, serie.field, 0), 0);
        };
        _.each(reportDataForMeter.graphData, graph => {
          const quantity = _.get(graph, 'chartOptions.series.0.quantity');
          const property = `${series.Normalized && _.get(quantity, 'Normalization') ? 'Normalized' : ''}Reading.Value`;
          const followUpSerie = _.find(graph.chartOptions.series, {
            startIndex: payload.Start.length - 1, property: property
          }) || {};
          const comparisonSerie = _.find(graph.chartOptions.series, {
            startIndex: payload.Start.length - 2, property: property
          }) || {};
          graph.change = utils.percentageChange(sumFunction(followUpSerie), sumFunction(comparisonSerie));
        });
      }

      // handle obsolete
      if (reportDataForMeter) {
        reportDataForMeter.obsolete = false;
      }
    });
  }

  const exportStartingUnbind = $rootScope.$on('export.starting', () => {
    const series = $scope.params.series;
    const payload = getPayload(series, 0, true);
    if (!payload) {
      return;
    }

    if (payload.MeterId.length === 0) {
      utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString('EXCEL.NO_METERS_SELECTED_ERROR'));
      return;
    } else if (payload.MeterId.length > 1) {
      // if getting hour resolution for a period longer than one month stop and inform the user.
      if (
        moment.fromIsodurationCached(payload.Resolution).hours() &&
          (moment.fromIsodurationCached(payload.TimeFrame).years() ||
           moment.fromIsodurationCached(payload.TimeFrame).months() > 1)
      ) {
        utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString(
          'EXCEL.FOR_LONG_HOURRESOLUTION_REPORTS_ONLY_ONE_FACILITY_ALLOWED'
        ));
        return;
      }
    }

    const payloadCombined = {
      ConsumptionsRequest: payload,
      localizedStrings: ERDataService2.getLocalizedStringsForExcel(payload)
    };

    const filename = $scope.facility.Name;

    consumptions.createMetersExcelExport(payloadCombined, filename)
      .catch(() => {
        utils.popUp('error', 'EXCEL.EXCEL', 'EXCEL.EXCEL_REPORT_DOWNLOAD_FAILED', true);
      })
    ;
  });

  // infinite scroll options
  $scope.infiniteScrollOptions = {
    loadCount: 10,
    items: $scope.reportData,
    loadFunc: $scope.regenerateData,
    resetFunc: function() {},
    itemPrefix: 'meter-'
  };

  // handle params change
  vm.$onChanges = () => {
    if (!$scope.params) {
      return;
    }
    const paramsData = erParamsService.getChangedParamsConfig(vm.reportParams, $scope.params);
    $scope.params = vm.reportParams;
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      if (_.isEqual(interestedParams, ['meterId'])) {
        const difference = _.difference($scope.params.meterId, _.map($scope.reportData, 'Id'));
        if (difference.length === 0 || $scope.reportData.length >= $scope.infiniteScrollOptions.loadCount) {
          $scope.reportData = _.filter(
            $scope.reportData,
            reportDataForMeter => _.includes($scope.params.meterId, reportDataForMeter.Id)
          );
          return;
        }
      }
      $scope.infiniteScrollOptions.resetFunc();
      $scope.regenerateData();
    }
  };

  // handle meter click
  function handleMeterClick(meter) {
    const reportDataForMeter = _.find($scope.reportData, { Id: meter.Id });
    const anchorId = `#meter-anchor-${meter.Id}`;
    const meterIndex = _.indexOf($scope.params.meterId, meter.Id);

    // if anchor and reportdata is not obsolete, scroll to view it
    if (angular.element(anchorId).length && reportDataForMeter && !reportDataForMeter.obsolete) {
      // if meter found, scroll to view it
      Scroll.scrollTo(anchorId, { scrollContainer: '#er-modal-scrollable' });
      return;
    }

    if (!reportDataForMeter) {
      // if there is no data for meter, move meter so that it will get next loaded
      const lastIndex = _.indexOf($scope.params.meterId, _.last($scope.reportData).Id);
      if (meterIndex !== -1 && lastIndex !== -1) {
        $scope.params.meterId.splice(lastIndex + 1, 0, $scope.params.meterId.splice(meterIndex, 1)[0]);
      }
      // load new content, direction = 1
      $scope.regenerateData(1);
    } else {
      // meter's report data is obsoleted
      const validData = _.filter($scope.reportData, { obsolete: false });
      const firstValidIndex = validData.length ? _.indexOf($scope.params.meterId, _.head(validData).Id) : 0;
      if (meterIndex > firstValidIndex) {
        $scope.regenerateData(1);
      } else {
        $scope.regenerateData(-1);
      }
    }

    LoadingService.isLoading$
      .pipe(
        filter(loading => !loading),
        take(1)
      ).subscribe(() => {
        handleMeterClick(meter);
      });
  }

  const meterClickedUnbind = $rootScope.$on('meters.meterClicked', (event, meter) => {
    handleMeterClick(meter);
  });

  vm.$onDestroy = () => {
    reportSettingCallbackUnbind();
    exportStartingUnbind();
    meterClickedUnbind();
  };

  vm.onMqaButtonCurrentTimeClick = meterId => {
    const timeFrame = new TimeFrame(
      moment().subtract(1, 'month').startOf('month'),
      moment().add(1, 'day')
    );
    openMqaInspectModal(meterId, timeFrame);
  };

  vm.onMqaButtonUseSelectedTimeFrameClick = meterId => {
    const seriesSettings = erReportSettingsService.getInstance().getSettings().series;
    const timeFrame = TimeFrame.parse({
      fromDate: seriesSettings.Start[seriesSettings.Start.length - 1].value,
      durationTo: seriesSettings.TimeFrame
    });
    timeFrame.fromDate.startOf('day');
    timeFrame.toDate = moment(subMinutes(startOfDay(adjustByTimezoneOffset(timeFrame.toDate.toDate())), 1));
    openMqaInspectModal(meterId, timeFrame);
  };

  function openMqaInspectModal(meterId, timeFrame) {
    const meterIds = getMqaInspectMeterIdList(meterId);
    ManualQaInspectModalService.getInspectModal(
      meterIds,
      timeFrame,
      SearchTypes.STORAGE_TYPE.RAW,
      meterIds[0]
    );
  }

  function getMqaInspectMeterIdList(firstMeterId) {
    return [firstMeterId, ...$scope.facility.meters.map(meter => meter.Id)].unique();
  }
}

MetersReportController.$inject = $inject;

export default MetersReportController;
