'use strict';
import moment from 'moment';
import _ from 'lodash';

function mrcDataParser($q) {

  function convertMeterReading(reading, factor) {
    var ret = 0;
    if (angular.isDefined(reading) && reading !== null) {
      ret = reading * factor;
    } else {
      ret = reading;
    }
    return ret;
  }

  function convertMeterReadings(readings, factor) {
    var ret = [];
    if (readings && readings.length > 0) {
      readings.forEach(function(reading) {
        var converted = reading;
        converted.Value = convertMeterReading(converted.Value, factor);
        ret.push(converted);
      });
    }
    return ret;
  }

  function parseNewMeterReadingsForApi(meter) {
    return $q(function(resolve) {
      var newDate = moment(meter.newReading.readingDate).format('YYYY-MM-DD\THH:mm:ss');
      var readingArr = _.map(meter.newReading.readingValues, function(readingItem) {
        var newValue = convertStringToFloat(readingItem.newValue);

        return { Type: readingItem.Type, Value: newValue, Date: newDate };
      });
      var data = {
        MeterId: angular.copy(meter.Id),
        Readings: readingArr
      };

      resolve(data);
    });
  }

  function parseConsumptionInputHistoryForListing(inputHistory, unitConversionFactor) {
    return $q(function(resolve) {

      var parsed = [];
      var history = inputHistory.ConsumptionInputHistorySet;
      if (angular.isArray(history) && history.length > 0) {
        // eslint-disable-next-line no-undef
        var sorted = _.sortBy(history, 'Consumption.ValueDate');

        _.each(sorted, function(item) {
          parsed.push(
            {
              // eslint-disable-next-line no-undef
              ValueDate: moment.utc(item.Consumption.ValueDate, "YYYY-MM-DDTHH:mm:ssZ").format('DD.MM.YYYY'),
              // eslint-disable-next-line no-undef
              ValueEndDate: moment.utc(item.Consumption.ValueEndDate, "YYYY-MM-DDTHH:mm:ssZ").format('DD.MM.YYYY'),
              Consumption: convertMeterReading(item.Consumption.Value, unitConversionFactor),
              Calculated30DaysConsumption: convertMeterReading(item.Calculated30DaysConsumption, unitConversionFactor),
              IsMissingConsumption: item.IsMissingConsumption
            });
        });

      }

      resolve(parsed);

    });
  }

  function parseMeterReadingsForListing(readings, meteringType) {
    return $q(function(resolve) {
      if (angular.isArray(readings) && readings.length > 0) {

        var sorted = _.orderBy(readings, ['Date', 'Flags', 'Type'], ['desc', 'asc', 'asc']);
        var parsed = [];

        var lastIndicator = {
          Date: sorted[sorted.length - 1].Date,
          Flags: 0,
          Types: [sorted[sorted.length - 1]],
          indicator: true,
          lastIndicator: true
        };

        var prevItem = undefined;

        var resolved = $q(function(rslv) {
          _.each(sorted, function(item) {
            // Special handling for adding automatic readings start item
            if (item.automatic) {
              item.indicator = true;
              item.Types = [{
                Date: item.Date,
                Flags: 0,
                Type: 1
              }];
              parsed.push(item);
            } else {
              var tempItem = _.find(parsed, { Date: item.Date, Flags: item.Flags, indicator: false });
              if (tempItem) {
                tempItem.Types.push(item);
              } else {

                if (item.Flags === 2) {
                  if (angular.isUndefined(prevItem) && meteringType === 5) {
                    parsed.push({ Date: item.Date, Flags: 1, Types: [item], indicator: true });
                  } else if (angular.isDefined(prevItem) && (prevItem.Flags === 1 && (item.Date !== prevItem.Date))) {
                    parsed.push({ Date: prevItem.Date, Flags: 2, Types: [prevItem], indicator: true });
                    parsed.push({ Date: item.Date, Flags: 1, Types: [item], indicator: true });
                  }
                }
                prevItem = angular.copy(item);
                parsed.push({ Date: item.Date, Flags: item.Flags, Types: [item], indicator: false });
              }
            }
          });

          rslv();
        });

        resolved.then(function() {
          parsed[0].firstItem = true;
          if (!parsed[0].automatic) {
            parsed.push(lastIndicator);
          }
          resolve(parsed);
        });

      } else {
        resolve([]);
      }
    });
  }

  function convertStringToFloat(str) {
    if (angular.isString(str)) {
      var target = angular.copy(str);
      target = target.replace(',', '.');
      return parseFloat(target);
    } else {
      return str;
    }
  }

  return {
    convertReading: convertMeterReading,
    convertReadings: convertMeterReadings,
    parseNewReadings: parseNewMeterReadingsForApi,
    parseMeterReadingsForListing: parseMeterReadingsForListing,
    convertStringToFloat: convertStringToFloat,
    parseConsumptionInputHistoryForListing: parseConsumptionInputHistoryForListing
  };
}

mrcDataParser.$inject = ['$q'];

export default mrcDataParser;
