import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, map, Observable, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportBase, ReportingGridConfig } from '../report-base';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { PeriodReportService } from '../../services/period-report.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { ReportingDistributionType } from '../../shared/reporting-search-form-value';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'period-report',
  templateUrl: '../report-base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodReportComponent extends ReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<Record<number, ReportingGridConfig>>;

  protected override readonly reportType = ReportType.Period;
  protected override readonly allowZoom = true;

  public constructor(
    private readonly periodReportService: PeriodReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly quantityService: QuantityService,
    private readonly toasterService: ToasterService,
    thresholdService: ThresholdService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(facilityService, reportingSearchService, reportEventService);

    this.consumptionData$ = combineLatest({
      params: this.searchParams$,
      threshold: thresholdService.threshold$,
      averageQuantities: this.quantityService.averageAggregateQuantityIds$
    }).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([{ params, threshold, averageQuantities }, facilityIds]) => {
        if (params.quantityIds.some(id => averageQuantities.includes(id)) &&
          params.distributionType !== ReportingDistributionType.None) {
          this.toasterService.info('REPORTING.ERRORS.CURR_UNSUPPORTED_AVGQUANT_DISTRIBUTION');
          params.quantityIds.removeBy(id => averageQuantities.includes(id));
        }
        return this.periodReportService.getData(
          params, facilityIds, threshold, averageQuantities
        ).pipe(
          indicate(this._loading$)
        );
      }),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = combineLatest({
      data: this.consumptionData$,
      unsupportedQuantitiesAggregate: this.quantityService.averageAggregateQuantityIds$
    }).pipe(
      withLatestFrom(this.facilityIds$, this.searchParams$),
      map(([{ data, unsupportedQuantitiesAggregate }, facilityIds, searchParams]) => {
        const gridColumns = this.reportingGridService.getColumns(data, facilityIds);
        const gridData = this.reportingGridService.getData(data, searchParams, facilityIds);
        const aggregates = this.reportingGridService.getAggregates(
          facilityIds,
          gridColumns,
          gridData,
          ReportType.Period,
          unsupportedQuantitiesAggregate,
          searchParams
        );
        return facilityIds.toRecord(
          fId => fId,
          fId => ({
            data: gridData[fId],
            columns: gridColumns[fId],
            aggregates: aggregates[fId]
          })
        );
      })
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return params;
  }
}
