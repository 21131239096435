import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'grid-chip-list',
  templateUrl: './grid-chip-list.component.html',
  styleUrl: './grid-chip-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridChipListComponent<T> implements OnChanges {
  @Input() public columnData: T[];
  @Input() public isKeyValuePair = false;
  @Input() public key: string; // required only when columnData is an array of objects

  public displayData: string[] = [];
  public remainingCount: number = 0;
  public tooltipData: string = ''; // Tooltip content for remaining items

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnData) {
      this.processData();
    }
  }

  private processData(): void {
    if (this.isKeyValuePair && this.key) {
      const toStringArray = this.columnData.map(item => (item as Record<string, string>)[this.key])
        .filter(item => !!item);
      this.setupChipDisplay(toStringArray);
    } else {
      this.setupChipDisplay(this.columnData as string[]);
    }
  }

  private setupChipDisplay(data: string[]): void {
    if (data.length > 3) {
      this.displayData = data.slice(0, 2);
      this.remainingCount = data.length - 2;
      this.tooltipData = data.slice(2).join(', ');
    } else {
      this.displayData = data;
      this.remainingCount = 0;
      this.tooltipData = ''; // No tooltip if all data is displayed
    }
  }
}
