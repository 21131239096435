import moment from 'moment';
import _ from 'lodash';
import { skip, take } from 'rxjs/operators';

import { Roles } from '../../admin/constants/roles';

MrcController.$inject = [
  '$rootScope', '$scope', '$q', '$state', 'utils', '$window', 'mrcModals', 'facilities',
  'filterService', 'languageService', 'UserService', 'appStatusService', '$transitions'
];

export function MrcController(
  $rootScope, $scope, $q, $state, utils, $window, mrcModals, facilities,
  filterService, languageService, UserService, appStatusService, $transitions
) {

  $scope.activeFacility = void 0;
  $scope.facilities = [];
  $scope.facilityDataProperties = [];

  $scope.mrcState = {
    activeTab: $state.current.name,
    isMobile: appStatusService.isMobile,
    appStatusMobile: appStatusService.isMobile,
    maxIntervalInputDate: moment().endOf('day').toDate(),
    earliestConsumptionYear: 0,
    editableLimitDate: new Date(2015, 0, 0),
    userLang: languageService.getLanguage(),
    regexPattern: '',
    radix: '',
    minFactor: 0.00001,
    listReadingInputMeters: false,
    listAutomaticMeters: false
  };

  var currentYear = new Date().getFullYear();
  var availablePastYearsCount = 9;
  $scope.mrcState.earliestConsumptionYear = currentYear - availablePastYearsCount;

  if ($scope.mrcState.isMobile) {
    $scope.mrcState.intervalInputSwitch = true;
  }

  // This 'radix' is used throughout MRC to replace commas in number strings so that Javascript's parseFloat
  // can be used to parse the number from the string. parseFloat can only read in dots as decimal separator.
  // This is part of a larger dilemma inside MRC and should be fixed by using numbers as numbers, dah.
  $scope.mrcState.radix = '.';

  $scope.mrcState.isMobile = $window.innerWidth < appStatusService.mobileBreakpointWidth && appStatusService.isMobile;

  $scope.isMassImportAllowed = UserService.hasRole(Roles.READINGS_IMPORT);
  $scope.isEnegiaIdShown = UserService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES) && !$scope.mrcState.isMobile;

  $q.all([
    facilities.getFacilityInformationGroupingDataForCurrentProfile(),
    facilities.getFacilityContactsGroupingDataForCurrentProfile()
  ])
    .then(function(results) {
      const groupz = results[0].Groups.concat(results[1].Groups);
      return { Groups: _.cloneDeep(groupz) };
    })
    .then(function(res) {
      $scope.facilityDataProperties.push(_.find(res.Groups, { Property: 'FacilityInformation' }));
      $scope.facilityDataProperties.push(_.find(res.Groups, { Property: 'Properties' }));
      $scope.facilityDataProperties.push(_.find(res.Groups, { Property: 'MaintenanceCompany' }));
      $scope.facilityDataProperties.push(_.find(res.Groups, { Property: 'ManagerCompany' }));
      $scope.facilityDataProperties.push(_.find(res.Groups, { Property: 'OwnerCompany' }));
    })
    .catch(function() {
      utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_FACILITY_PROPERTIES', true);
    });

  function getFacilities() {
    $scope.loadingData = true;
    $q.all([facilities.getFacilitiesInformation(), facilities.getFacilitiesContacts()])
      .then(function(results) {
        var information = _.cloneDeep(results.shift());
        var contacts = _.cloneDeep(results.shift());
        var merged = _(information)
          .concat(contacts)
          .groupBy("FacilityId")
          .map(_.spread(_.merge))
          .value();
        return merged;
      })
      .then(function(res) {
        if (res.length > 0) {
          res = _.cloneDeep(res);
          $scope.facilities = _.orderBy(res, function(item) {
            return item['Name'].toLowerCase();
          }, ['asc']);
          $rootScope.$broadcast('facilitiesChanged');
        } else {
          utils.popUp('info', '', 'MRC.NO_FACILITIES_AVAILABLE', true);
        }
      }).catch(function() {
        utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_FACILITIES', true);
      })
      .finally(function() {
        filterService.filteredFacilityIds$.pipe(take(1)).subscribe(facilityIds => {
          updateVisibilityByFilters(facilityIds);
        });
      });
  }

  getFacilities();

  function updateVisibilityByFilters(filteredFacilities) {
    const visibleIds = [];
    _.each($scope.facilities, function(facility) {
      facility.isOpen = false;
      if (Array.hasItems(filteredFacilities)) {
        if (_.includes(filteredFacilities, facility.FacilityId)) {
          facility.showInView = true;
          visibleIds.push(facility.FacilityId);
        } else {
          facility.showInView = false;
        }
      } else {
        facility.showInView = true;
        visibleIds.push(facility.FacilityId);
      }
    });
    if (visibleIds.length === 1) {
      _.find($scope.facilities, function(facility) {
        if (facility.FacilityId === visibleIds[0]) {
          facility.isOpen = true;
        }
      });
    }
  }

  const filterFacilitiesSubscription = filterService.filteredFacilityIds$
    .pipe(skip(1))
    .subscribe(facilityIds => {
      updateVisibilityByFilters(facilityIds);
    });

  function hideFacilityControls() {
    if (angular.isDefined($scope.activeFacility)) {
      $scope.activeFacility.controlsVisible = false;
      $scope.$broadcast('removeControls');
    }
  }

  function updateRegexPattern() {
    if ($scope.mrcState.activeTab === 'mrc.consumptions') {
      $scope.mrcState.regexPattern = $scope.mrcState.userLang === "en" ?
        // eslint-disable-next-line no-useless-escape
        '^\-?([0-9]{1,3}?)(\,?[0-9]{3})*(\.[0-9]+)?$' : '\-?[0-9]+([,\.][0-9]+)?';
    } else {
      $scope.mrcState.regexPattern = $scope.mrcState.userLang === "en" ?
        // eslint-disable-next-line no-useless-escape
        '^([0-9]{1,3}?)(\,?[0-9]{3})*(\.[0-9]+)?$' : '[0-9]+([,\.][0-9]+)?';
    }
  }

  updateRegexPattern();

  $scope.tabChanged = function(tab) {
    if (tab !== $scope.mrcState.activeTab) {
      if (angular.isDefined($scope.activeFacility) && (($scope.activeFacility.hasUnsavedValues &&
        !$scope.activeFacility.savingNewConsumptions) || $scope.activeFacility.hasUnsavedReadings ||
          $scope.activeFacility.hasUnsavedIntervals)) {
        mrcModals.showUnsavedWarning('MRC.CONSUMPTIONS.UNSAVED_VALUES').then(
          function() {
          },
          function() {
            hideFacilityControls();
            updateRegexPattern();
            $state.go(tab);
          }
        );
      } else {
        hideFacilityControls();
        updateRegexPattern();
        $state.go(tab);
      }
    }
  };

  $scope.toggleAccordion = function(event, facility) {
    var setFacility = function(facility) {
      if ($scope.activeFacility) {
        $scope.activeFacility.isOpen = false;
      }
      hideFacilityControls();
      $scope.activeFacility = facility;
      $scope.activeFacility.isOpen = true;
    };
    if (angular.isDefined($scope.activeFacility) && (($scope.activeFacility.hasUnsavedValues &&
      !$scope.activeFacility.savingNewConsumptions) || $scope.activeFacility.hasUnsavedReadings ||
        $scope.activeFacility.hasUnsavedIntervals)) {
      mrcModals.showUnsavedWarning('MRC.CONSUMPTIONS.UNSAVED_VALUES').catch(
        function() {
          setFacility(facility);
        }
      );
    } else {
      facility.isOpen = !facility.isOpen;
      if (facility.isOpen) {
        setFacility(facility);
      } else {
        hideFacilityControls();
        $scope.activeFacility = void 0;
      }
    }
  };

  $scope.intervalInputModeChanged = function(evt) {
    var doChange = function() {
      $scope.mrcState.intervalInputSwitch = evt.target.checked;
    };
    showSelectionChanged(doChange, evt);
  };

  $scope.listReadingInputMetersChanged = function(evt) {
    var doChange = function() {
      $scope.mrcState.listReadingInputMeters = evt.target.checked;
    };
    showSelectionChanged(doChange, evt);
  };

  $scope.listAutomaticMetersChanged = function(evt) {
    var doChange = function() {
      $scope.mrcState.listAutomaticMeters = evt.target.checked;
    };
    showSelectionChanged(doChange, evt);
  };

  var showSelectionChanged = function(changeFunction, evt) {
    if (angular.isDefined($scope.activeFacility) && (($scope.activeFacility.hasUnsavedValues &&
      !$scope.activeFacility.savingNewConsumptions) || $scope.activeFacility.hasUnsavedIntervals)) {
      mrcModals.showUnsavedWarning('MRC.CONSUMPTIONS.UNSAVED_VALUES').then(
        function() {
          evt.target.checked = !evt.target.checked;
        }
      ).catch(
        function() {
          hideFacilityControls();
          changeFunction();
        }
      );
    } else {
      hideFacilityControls();
      changeFunction();
    }
  };

  $scope.$on('facilitySelected', function(event, facility) {
    $scope.toggleAccordion(event, facility);
  });

  const transitionSuccessUnbind = $transitions.onSuccess({}, function(transition) {
    if (transition.from().name.startsWith('mrc') && transition.to().name.startsWith('mrc')) {
      $scope.mrcState.activeTab = transition.to().name;
      $scope.mrcState.maxIntervalInputDate = moment().endOf('day').toDate();
    }
  });

  $scope.$on('$destroy', function() {
    transitionSuccessUnbind();
    filterFacilitiesSubscription.unsubscribe();
  });
}
