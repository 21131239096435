import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { ReportModalService } from '../../services/report-modal.service';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { ReportType } from '../../shared/report-type';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { LegacyFacilityService } from '../../../reportingobjects/models/facilities';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../../admin/constants/roles';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';
import { ReportTypeOptionsModalService } from '../../services/report-type-options-modal.service';
import { TimePeriodHistoryService } from '../../services/time-period-history.service';
import { MeterTableReportService } from '../../services/meter-table-report.service';
import { SidebarSize } from '../sidebar/sidebar.component';
import { TenantService } from '../../../../shared/services/tenant.service';
import { ReportModalMeterGroupService } from '../../services/report-modal-meter-group.service';
import { ReportingTab } from '../../models/reporting-tab.model';

export interface ReportingModalParams {
  facilityId: number;
  meterIds?: number[];
  initialReportType: ReportType;
  initialSearchParams: ReportingSearchParams;
  presentation?: { sections: string[]; charts: boolean; grids: boolean; meterInfo: boolean };
}

export const REPORT_MODAL_PARAMS = new InjectionToken<ReportingModalParams>('Params for report modal');

@Component({
  selector: 'report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ReportingSearchService,
    ReportModalMetersService,
    ReportModalMeterGroupService,
    ReportModalService,
    {
      provide: ReportTypeOptionsService,
      useClass: ReportTypeOptionsModalService,
    },
    TimePeriodHistoryService
  ],
  animations: [
    trigger('sidebarSize', [
      state('collapsed', style({
        marginLeft: 'var(--reporting-sidebar-button-width)'
      })),
      state('expanded', style({
        marginLeft: 'var(--reporting-sidebar-width)'
      })),
      transition('collapsed => expanded', [
        animate('250ms ease-in'),
      ]),
      transition('expanded => collapsed', [
        animate('250ms 100ms ease-in')
      ])
    ])
  ]
})
@ModalOptions({
  windowClass: 'semiFull modal-dialog-scrollable fixed-height'
})
export class ReportModalComponent extends ModalBase<void> implements OnDestroy {
  public readonly facilityId: number;
  public readonly reportingTab$: Observable<ReportingTab>;

  public readonly headingText$: Observable<string>;
  public readonly customClass: string;

  public sidebarSize: SidebarSize;

  public constructor(
    private meterTableReportService: MeterTableReportService,
    reportingSearchService: ReportingSearchService,
    jsFacilities: LegacyFacilityService,
    userService: UserService,
    reportModalService: ReportModalService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    currentModal: NgfActiveModal,
    private readonly tenantService: TenantService
  ) {
    super(currentModal);

    this.customClass = this.tenantService.isCustomTenant
      ? 'reporting-colors'
      : 'reporting-colors reporting-colors--enerkey';

    this.reportingTab$ = reportModalService.reportingTab$;

    this.headingText$ = from(jsFacilities.getFacility(modalParams.facilityId)).pipe(
      map(facility => {
        if (!userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES)) {
          return `${facility.Name}`;
        }

        return `${facility.Name} (${facility.FacilityInformation.EnegiaId})`;
      })
    );

    this.facilityId = modalParams.facilityId;

    reportingSearchService.setFacilities([modalParams.facilityId]);
    reportingSearchService.search(modalParams.initialSearchParams.formValue);
  }

  public ngOnDestroy(): void {
    this.meterTableReportService.resetGridState();
  }

  public toggleSidebarState(sidebarCollapsed: boolean): void {
    sidebarCollapsed ? this.sidebarSize = SidebarSize.COLLAPSED : this.sidebarSize = SidebarSize.EXPANDED;
  }
}
