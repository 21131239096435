import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, map, Observable, switchMap } from 'rxjs';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import {
  AddOrUpdateEmailSubscriptionSettingsRequest,
  EmailConsumptionReportClient,
  FacilityFrequencyBase
} from '@enerkey/clients/email-consumption-report';

import { CustomerAdminClient, FacilityNotificationSettingResponse } from '@enerkey/clients/customer-admin';

import { AuthenticationService } from '../../../shared/services/authentication.service';
import { ConsumptionNotificationService } from '../../customer-admin/services/consumption-notification.service';

@Injectable()
export class EmailConsumptionReportService {
  public readonly consumptionEmailSubscriptions$: Observable<FacilityFrequencyBase[]>;
  public readonly customerConsumptionSubscriptions$: Observable<FacilityNotificationSettingResponse[]>;
  public readonly selectedUserId$: Observable<number>;

  public readonly refreshConsumption$ = new BehaviorSubject<boolean>(true);
  public readonly consumptionSubscriptionLoading$: Observable<boolean>;
  public readonly userName: string;

  private readonly _consumptionSubscriptionLoading$ = new LoadingSubject();

  public constructor(
    private readonly emailConsumptionReportClient: EmailConsumptionReportClient,
    private readonly authenticationService: AuthenticationService,
    private readonly consumptionNotificationService: ConsumptionNotificationService,
    private readonly customerAdminClient: CustomerAdminClient
  ) {
    this.consumptionSubscriptionLoading$ = this._consumptionSubscriptionLoading$.asObservable();

    this.userName = this.authenticationService.getUsername();
    this.selectedUserId$ = this.consumptionNotificationService.selectedUserId$;

    this.customerConsumptionSubscriptions$ = this.refreshConsumption$.pipe(
      switchMap(() => this.selectedUserId$.pipe(
        switchMap(userId => this.customerAdminClient.getSubscriptionsForUser(userId)
          .pipe(
            indicate(this._consumptionSubscriptionLoading$)
          ))
      ))
    );

    this.consumptionEmailSubscriptions$ = this.refreshConsumption$.pipe(
      switchMap(() => this.emailConsumptionReportClient.getEmailSubscription(this.userId)
        .pipe(
          indicate(this._consumptionSubscriptionLoading$),
          map(result => result?.facilities)
        ))
    );
  }

  private get userId(): number {
    return this.authenticationService.getUserId();
  }

  public addEmailConsumptionSubscription(subscriptions: FacilityFrequencyBase[]): Observable<void> {
    return this.emailConsumptionReportClient
      .addOrUpdateEmailSubscription(new AddOrUpdateEmailSubscriptionSettingsRequest({
        facilities: subscriptions,
        userId: this.userId
      })).pipe(catchError(() => {
        this.refreshConsumption$.next(true);
        return EMPTY;
      }));
  }
}
