import { IAlarmClient } from './alarm-client';

export class AlarmClientMock implements IAlarmClient {
  public updateExternalLogId(): never {
    throw new Error('Method not implemented.');
  }
  public createEnergyConsumptionComment(): never {
    throw new Error('Method not implemented.');
  }
  public getLogById(): never {
    throw new Error('Method not implemented.');
  }
  public upsertExternalAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public updateLogsStatus(): never {
    throw new Error('Method not implemented.');
  }
  public synchronizeForProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public synchronize(): never {
    throw new Error('Method not implemented.');
  }
  public upsertAlarmHooks(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesAlarmsCount(): never {
    throw new Error('Method not implemented.');
  }
  public setScheduledAlarmsToRun(): never {
    throw new Error('Method not implemented.');
  }
  public runAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesAlarmHookTypes(): never {
    throw new Error('Method not implemented.');
  }
  public getAlarmsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public updateAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public insertAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public deleteAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public runScheduledAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public runContinuousAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public testAlarms(): never {
    throw new Error('Method not implemented.');
  }
  public getAllAlarmTypes(): never {
    throw new Error('Method not implemented.');
  }
  public validateCron(): never {
    throw new Error('Method not implemented.');
  }
  public getAllHookTypes(): never {
    throw new Error('Method not implemented.');
  }
  public addHookType(): never {
    throw new Error('Method not implemented.');
  }
  public getLogsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public getImageForLog(): never {
    throw new Error('Method not implemented.');
  }
  public getLiteLogsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public queryAlarmEvents(): never {
    throw new Error('Method not implemented.');
  }
  public searchAlarmRules(): never {
    throw new Error('Method not implemented.');
  }
  public insertAlarmRules(): never {
    throw new Error('Method not implemented.');
  }
  public updateAlarmRules(): never {
    throw new Error('Method not implemented.');
  }
  public deleteAlarmRules(): never {
    throw new Error('Method not implemented.');
  }
  public updateLogsStatusAll(): never {
    throw new Error('Method not implemented.');
  }
}
