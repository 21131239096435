<form
  [formGroup]="formGroup"
  class="no-border-on-invalid"
  (keydown.enter)="$event.preventDefault()"
>

  <accordion
    class="sidebar-accordion reporting-sidebar-accordion"
  >
    <!-- 1a. Quantity selection -->
    <accordion-group
      *ngIf="reportingTab !== ReportingTab.Meter && reportingTab !== ReportingTab.MeterGroup"
      [isOpen]="true"
    >
      <h2 accordion-heading>{{ 'FACILITIES.QUANTITIES' | translate }}</h2>
      <sidebar-quantity-picker
        formControlName="quantityIds"
        [facilityId]="modalParams?.facilityId"
      ></sidebar-quantity-picker>
    </accordion-group>

    <!-- 1b. Meter selection -->
    <accordion-group
      *ngIf="reportingTab === ReportingTab.Meter"
      [isOpen]="true"
    >
      <h2 accordion-heading>{{ 'FACILITIES_REPORT.METERTREE' | translate }}</h2>
      <meter-tree></meter-tree>
    </accordion-group>
    
    <!-- 1c. Meter group selection -->
    <accordion-group
      *ngIf="reportingTab === ReportingTab.MeterGroup"
      [isOpen]="true"
    >
      <h2 accordion-heading>{{ 'ADMIN.TABS.METER_GROUPS' | translate }}</h2>
      <meter-group-tree></meter-group-tree>
    </accordion-group>

    <!-- 2. Time period selection -->
    <accordion-group [isOpen]="true">
      <h2 accordion-heading>{{ 'FACILITIES.TIME_PERIOD' | translate }}</h2>
      <div>
        <ek-combo
          *labelBefore="'REPORTING.SEARCH.PERIOD_LENGTH' | translate"
          formControlName="durationName"
          [items]="periodSelectOptions"
          class="mb-7"
        ></ek-combo>
        <kendo-numerictextbox
          *labelBefore="'REPORTING.SEARCH.PERIOD_AMOUNT' | translate"
          [min]="1"
          formControlName="durationLength"
          integerInput
          class="mb-7"
        ></kendo-numerictextbox>
        <ek-combo
          *labelBefore="'FACILITIES_TOOLBAR.RESOLUTION' | translate"
          formControlName="resolution"
          [items]="resolutionSelectOptions"
          [disabledItems]="disabledResolutions$ | async"
          class="mb-7"
        ></ek-combo>
        <div *ngFor="let control of periods.controls; index as i, first as isFirst">
          <hr *ngIf="!isFirst">
          <p>
            <kendo-datepicker
              *labelBefore="('REPORTING.SEARCH.' + (isFirst ? 'PERIOD_START' : 'COMPARISON_PERIOD_START')) | translate"
              [formControl]="control"
              [activeView]="calendarView"
              [bottomView]="bottomView"
              [readOnlyInput]="true"
              [disabledDates]="disabledDatesFn"
            ></kendo-datepicker>
            <button
              kendoButton
              *ngIf="!isFirst"
              [icon]="'delete'"
              (click)="deletePeriod(i)"
              [title]="'FACILITIES_TOOLBAR.REMOVE_COMPARISON_PERIOD' | translate"
              class="remove-period-button"
            >
              {{ 'FACILITIES_TOOLBAR.REMOVE_COMPARISON_PERIOD' | translate }}
            </button>
          </p>
          <div *ngIf="isFirst">
            <input
              type="checkbox"
              kendoCheckBox
              *labelWrap="'GRID.SUM' | translate"
              formControlName="showSummedConsumption"
            >
          </div>
        </div>
        <div *ngIf="periods.controls.length < 3">
          <hr>
          <p>
            <kendo-datepicker
              #addPeriodPicker
              *labelBefore="'FACILITIES_TOOLBAR.ADD_SERIE' | translate"
              (valueChange)="addPeriod($event); addPeriodPicker.value = null"
              [activeView]="calendarView"
              [bottomView]="bottomView"
              [readOnlyInput]="true"
              [disabledDates]="disabledDatesFn"
              [focusedDate]="focusedDate"
            ></kendo-datepicker>
          </p>
        </div>
        <div class="next-previous-button-container">
          <button
            kendoButton
            (click)="changePeriod('previous')"
          >
            <span class="k-icon k-i-arrow-chevron-left"></span>
            {{ 'FACILITIES_TOOLBAR.PREVIOUS_PERIOD' | translate }}
          </button>
          <button
            kendoButton
            (click)="changePeriod('next')"
          >
            {{ 'FACILITIES_TOOLBAR.NEXT_PERIOD' | translate }}
            <span class="k-icon k-i-arrow-chevron-right"></span>
          </button>
        </div>
        <time-period-history-dropdown
          [formGroup]="formGroup"
          [debounceTime]="debounceTime"
          (setTimePeriodValuesFromHistory)="setTimePeriodValuesFromHistory($event)">
          #historyDropdown
        ></time-period-history-dropdown>
      </div>
    </accordion-group>

    <!-- 3. Additional settings -->
    <accordion-group [isOpen]="true">
      <h2 accordion-heading>{{ 'FACILITIES.ADDITIONAL_SETTINGS' | translate }}</h2>
      <div class="accordion-content-grid">
        <value-type-select
          *labelBefore="'FACILITIES_TOOLBAR.VALUE_OPTION' | translate"
          formControlName="valueType"
        ></value-type-select>
        <input
          type="checkbox"
          kendoCheckBox
          *labelWrap="'FACILITIES.SIDEBAR.SHOW_MEASUREMENT' | translate"
          formControlName="showConsumption"
        >
        <derived-value-select
          *labelBefore="'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_RELATIONAL_VALUES' | translate"
          formControlName="specificIds"
          derivedValueType="specificConsumption"
          [filterable]="true"
        ></derived-value-select>
        <ng-container *ngIf="additionalSettings.showEmissions">
          <derived-value-select
            *labelBefore="'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_EMISSIONS' | translate"
            formControlName="emissionIds"
            derivedValueType="emission"
            [filterable]="true"
          ></derived-value-select>
        </ng-container>
        <ng-container *ngIf="additionalSettings.showCosts">
          <derived-value-select
            *labelBefore="'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_COSTS' | translate"
            derivedValueType="cost"
            formControlName="costIds"
            [filterable]="true"
          ></derived-value-select>
        </ng-container>
        <ng-container *ngIf="additionalSettings.showTargets">
          <target-multi-select
            *labelBefore="'FACILITIES.SIDEBAR.CONSUMPTION_TARGET' | translate"
            formControlName="targetTypes"
          ></target-multi-select>
        </ng-container>
        <ng-container *ngIf="!isProd">
          <related-quantity-multi-select
            *labelBefore="'FACILITIES.SIDEBAR.QUANT_RELATED_VALUES' | translate"
            formControlName="relatedQuantities"
          ></related-quantity-multi-select>
        </ng-container>
        <div>
          <label>{{ 'FACILITIES.UNIT' | translate }}</label>
          <div class="checkbox-selection-area">
            <input
              type="radio"
              kendoRadioButton
              formControlName="reportingUnit"
              [value]="ReportingUnit.Default"
              *labelWrap="'DEFAULT' | translate"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="reportingUnit"
              [value]="ReportingUnit.KWh"
              *labelWrap="'kWh'"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="reportingUnit"
              [value]="ReportingUnit.MWh"
              *labelWrap="'MWh'"
            >
          </div>
        </div>
        <div>
          <label>
            {{ 'CHANGE.TITLE' | translate }}
          </label>
          <div
            formGroupName="change"
            class="checkbox-selection-area"
          >
            <input
              type="checkbox"
              kendoCheckBox
              formControlName="relative"
              *labelWrap="'CHANGE.RELATIVE' | translate"
            >
            <input
              type="checkbox"
              kendoCheckBox
              formControlName="absolute"
              *labelWrap="'CHANGE.ABSOLUTE' | translate"
            >
          </div>
        </div>
        <div>
          <label>
            {{ 'FACILITIES_TOOLBAR.DISTRIBUTION_TYPE' | translate }}
          </label>
          <div class="checkbox-selection-area">
            <input
              type="radio"
              kendoRadioButton
              formControlName="distributionType"
              [value]="ReportingDistributionType.None"
              *labelWrap="'REPORTING.SEARCH.DISTRIBUTION_TYPE.NONE' | translate"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="distributionType"
              [value]="ReportingDistributionType.DayNight"
              *labelWrap="'REPORTING.SEARCH.DISTRIBUTION_TYPE.DAYNIGHT' | translate"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="distributionType"
              [value]="ReportingDistributionType.Weekday"
              *labelWrap="'REPORTING.SEARCH.DISTRIBUTION_TYPE.WEEKDAY' | translate"
            >
            <div class="full-width-checkbox">
              <input
                type="checkbox"
                kendoCheckBox
                formControlName="distributionAsPercent"
                *labelWrap="'REPORTING.SEARCH.SHOW_DISTRIBUTION_AS_PERCENT' | translate"
              >
            </div>
          </div>
        </div>
        <div>
          <label>{{ 'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.TITLE' | translate }}</label>
          <div class="checkbox-selection-area">
            <input
              type="radio"
              kendoRadioButton
              formControlName="comparability"
              [value]="Comparability.All"
              *labelWrap="'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.NONE' | translate"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="comparability"
              [value]="Comparability.ByQuantity"
              *labelWrap="'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITY' | translate"
            >
            <input
              type="radio"
              kendoRadioButton
              formControlName="comparability"
              [value]="Comparability.ByQuantities"
              *labelWrap="'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITIES' | translate"
            >
          </div>
        </div>
        <div>
          <label>{{ 'FACILITIES_TOOLBAR.RELATEDVALUES' | translate }}</label>
          <div
            formGroupName="minMaxAvg"
            class="checkbox-selection-area"
          >
            <input
              type="checkbox"
              kendoCheckBox
              formControlName="min"
              *labelWrap="'FACILITIES_REPORT.MIN' | translate"
            >
            <input
              type="checkbox"
              kendoCheckBox
              formControlName="max"
              *labelWrap="'FACILITIES_REPORT.MAX' | translate"
            >
            <input
              type="checkbox"
              kendoCheckBox
              formControlName="average"
              *labelWrap="'FACILITIES_REPORT.AVERAGE' | translate"
            >
          </div>
        </div>
        <div>
          <label>{{ 'REPORTING.SEARCH.OTHERS' | translate }}</label>
          <div class="checkbox-selection-area">
            <input
              type="checkbox"
              class="full-width-checkbox"
              kendoCheckBox
              formControlName="temperature"
              *labelWrap="'FACILITIES.SIDEBAR.TEMPERATURE' | translate"
            >
          </div>
        </div>
      </div>
    </accordion-group>

    <!-- 4. Presentation selection -->
    <accordion-group
      *ngIf="showPresentation$ | async"
      [isOpen]="true"
    >
      <h2 accordion-heading>{{ 'FACILITIES.PRESENTATION' | translate }}</h2>
      <reporting-visible-sections-select [isMeterReport]="reportingTab === ReportingTab.Meter">
      </reporting-visible-sections-select>
    </accordion-group>
  </accordion>
</form>
