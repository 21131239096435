import _ from 'lodash';

ERDataChartService.$inject = ['utils'];

export function ERDataChartService(utils) {
  return {
    getCurrentChart: getCurrentChart,
    findChart: findChart
  };

  function findChart(reportData, options, data) {
    const propertyData = _.head(data.propertiesData);
    const property = propertyData.path;
    const findFn = _.partial(_.find, _.get(reportData, 'graphData'));

    return options.chart.separateQuantityProperties ?
      findFn({ id: data.quantityId, property: property }) :
      findFn({ id: data.quantityId })
    ;
  }

  function getCurrentChart(reportData, payload, options, data) {
    const propertyData = _.head(data.propertiesData);
    const property = propertyData.path;
    const unit = propertyData.unit ? propertyData.unit : data.unit;
    let chart = findChart(reportData, options, data);

    const templateParams = {
      quantityName: data.quantity.Name,
      propertyName: _.get(propertyData, 'object.Name'),
      unit: unit
    };

    chart = chart ? chart : createNewChart(data, property);

    const chartParams = {
      title: getChartTitle(templateParams, options.chart.separateQuantityProperties),
      change: getChangePercent(data, payload, propertyData),
      changeTitle: getChangeTitle(payload),
      quantity: data.quantity,
      unit: data.unit,
      format: data.format,
      count: (_.get(data, 'consumptions.0.FacilitiesIncluded') || []).length,
      totalCount: (
        _.get(data, 'consumptions.0.FacilitiesIncluded', []).length +
        _.get(data, 'consumptions.0.FacilitiesExcluded', []).length
      )
    };

    return _.extend(chart, chartParams);
  }

  function createNewChart(data, property) {
    return {
      id: data.quantityId,
      property: property
    };
  }

  function getChangePercent(data, payload, propertyData) {
    const periodStartKey = _.get(payload, 'Start.0.key');
    const comparisonStartKey = _.get(payload, 'Start.1.key');

    const value = _.get(data, `consumptions.0.Aggregates["${ periodStartKey }"].${ propertyData.path}`);
    const previousValue = _.get(data, `consumptions.0.Aggregates["${ comparisonStartKey }"].${ propertyData.path}`);

    return utils.percentageChange(value, previousValue);
  }

  function getChangeTitle(payload) {
    const params = {
      periodStartKey: _.get(payload, 'Start.0.key'),
      comparisonStartKey: _.get(payload, 'Start.1.key')
    };

    return _.template('<%= periodStartKey %> ➜ <%= comparisonStartKey %> %')(params);
  }

  function getChartTitle(params, separateQuantityProperties) {
    separateQuantityProperties = separateQuantityProperties || false;

    return separateQuantityProperties ?
      _.template('<%= quantityName %> - <%= propertyName %> [<%= unit %>]')(params) :
      _.template('<%= quantityName %> [<%= unit %>]')(params)
    ;
  }

}
