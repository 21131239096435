<div class="chip-list">
  <ng-container *ngFor="let item of displayData; ">
    <span [ngfTooltip]="item">{{ item }}</span>
  </ng-container>

  <!-- Show remaining count chip if there are more than 3 items -->
  <ng-container *ngIf="remainingCount > 0">
    <span [ngfTooltip]="tooltipData">
      {{ remainingCount }} {{'MORE'| translate}}...
    </span>
  </ng-container>
</div>
