import _ from 'lodash';
import moment from 'moment';

import templateModule from 'raw-loader!../templates/mrc-facility.html';

export function mrcFacility() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      facility: "=",
      properties: "=",
      mrcState: "="
    },
    controller: [
      '$scope', '$rootScope', 'utils', '$q', 'mrcapi', 'mrcModals', 'mrcInputValidation', 'mrcDataParser',
      '$timeout', 'quantities', 'MrcConstants', '$log', 'StateLocationService',
      function(
        $scope, $rootScope, utils, $q, mrcapi, mrcModals, mrcInputValidation, mrcDataParser,
        $timeout, quantities, MrcConstants, $log, StateLocationService
      ) {
        $scope.MrcConstants = MrcConstants;

        $scope.facility.showInfo = false;
        $scope.facility.hasUnsavedValues = false;
        $scope.facility.hasUnsavedReadings = false;
        $scope.facility.hasUnsavedIntervals = false;
        $scope.facility.savingNewConsumptions = false;
        $scope.facility.savingIndicatorVisible = false;
        $scope.facility.controlsVisible = false;
        $scope.facility.controlsReady = false;
        $scope.isDatePickerDisabled = true;
        $scope.quantities = [];
        $scope.visibleMeters = [];
        $scope.invalidMeterReadings = [];

        $scope.months = [];
        $scope.monthValues = {};
        $scope.pendingMonthValueRequests = [];
        $scope.pendingRequests = [];
        $scope.unsavedValues = [];
        $scope.viewReady = false;

        $scope.isSaveInProgress = false;
        $scope.isYearChangedDuringSave = false;

        $scope.commonInterval = {
          fromDate: null,
          toDate: null,
          fromDateValid: false,
          toDateValid: false
        };

        $scope.facility.invalidInputValues = 0;
        $scope.facility.invalidDateValues = 0;
        $scope.facility.selectedCell = -1;
        $scope.facility.selectedYear = new Date().getFullYear();
        $scope.facility.readingsHistoryPages = {};
        $scope.facility.canSaveAllMissingIntervals = false;
        $scope.facility.canSaveAllNewIntervals = false;
        $scope.facility.canSaveAllNewReadings = false;
        $scope.facility.intervalsTabsOpen = false;
        $scope.facility.invalidNewReadings = {};

        $scope.$on('unsavedValueChange', function(e, attr) {
          if ($scope.unsavedValues.length > 0) {
            const vals = _.find($scope.unsavedValues, function(elem) {
              return (elem.meterId === attr.meterId && elem.cellIndex === attr.cellIndex);
            });
            if (angular.isDefined(vals)) {
              const idx = $scope.unsavedValues.indexOf(vals);
              if ((angular.isDefined(attr.value) && attr.value.length === 0) || !attr.valid) {
                $scope.unsavedValues.splice(idx, 1);
              } else {
                if (attr.valid && angular.isDefined(attr.value) && attr.value.length > 0) {
                  $scope.unsavedValues[idx] = attr;
                }
              }
            } else {
              if (angular.isDefined(attr.value) && attr.valid && attr.value.length > 0) {
                $scope.unsavedValues.push(attr);
              }
            }
          } else {
            if (angular.isDefined(attr.value) && attr.value.length > 0 && attr.valid) {
              $scope.unsavedValues.push(attr);
            }
          }
          $scope.facility.hasUnsavedValues = $scope.unsavedValues.length > 0;
          updateMassInputAvailability();
        });

        (function() {
          _(_.range(0, 12))
            .forEach(function(month) {
              $scope.months.push(
                moment()
                  .date(1)
                  .month(month)
              );
            })
          ;
        })();

        $scope.dateFormat = 'dd.MM.yyyy';
        const commonDate = new Date();
        commonDate.setMilliseconds(0);
        commonDate.setSeconds(0);
        commonDate.setMinutes(0);
        commonDate.setHours(0);
        $scope.commonReadingDate = angular.copy(commonDate);

        $scope.hasEmptyCells = function() {
          let ret = false;
          Object.keys($scope.monthValues).forEach(function(key) {
            const meter = $scope.monthValues[key];
            if (meter) {
              if (
                angular.isUndefined(meter.values) ||
                  meter.values === null ||
                  meter.values.length < $scope.months.length
              ) {
                ret = true;
                return ret;
              }
            }
          });
          return ret;
        };

        // MRC-379
        $scope.onDateChange = function(attrs) {
          $scope.commonReadingDate = angular.copy(attrs.value);
        };

        $scope.activeIntervalsTabs = function() {
          let ret = false;
          _.each($scope.visibleMeters, function(meter) {
            if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION && meter.isOpen) {
              ret = meter.tabs.intervals.active;
            }
            if (ret) {
              return false;
            }
          });
          return ret;
        };

        $scope.onCommonIntervalChange = function(attrs) {
          $scope.commonInterval = mrcInputValidation.validateIntervalDateInput(attrs.picker, $scope.commonInterval);
          updateMassInputAvailability();
        };

        // Cancels meter and consumption requests, running multiple meter requests
        // simultaneously is not sensible and causes problems
        function cancelPendingMeterAndConsumptionRequests() {
          _.each($scope.pendingRequests, function(req) {
            req.$cancelRequest();
          });
        }

        function cancelPendingMonthValueRequests(year) {
          if (angular.isDefined($scope.pendingMonthValueRequests[year])
            && $scope.pendingMonthValueRequests[year].length > 0) {
            _.each($scope.pendingMonthValueRequests[year], function(req) {
              req.request.$cancelRequest();
            });
          }
        }

        $scope.getMonthValuesForMeters = function(timeframe, meterIds) {
          return $q(function(resolve) {
            if (meterIds.length > 0) {
              const options = {
                fromDate: timeframe.from.toJSON(),
                toDate: timeframe.to.toJSON(),
                facilityId: $scope.facility.FacilityId
              };
              const requestYear = timeframe.from.getFullYear();
              if (angular.isUndefined($scope.pendingMonthValueRequests[requestYear])) {
                $scope.pendingMonthValueRequests[requestYear] = [];
              }
              let requests = [];
              let counter = 0;
              const chunkSize = 20;
              _.each(meterIds, function(meterId) {
                requests.push(meterId);
                counter += 1;
                if (requests.length === chunkSize || counter === meterIds.length) {
                  $scope.pendingMonthValueRequests[requestYear].push({
                    request: mrcapi.getMonthValuesForMeters(options, angular.copy(requests)),
                    meterIds: angular.copy(requests),
                    facilityId: $scope.facility.FacilityId,
                    requestYear: requestYear
                  });
                  requests = [];
                }
              });
              $scope.pendingMonthValueRequests[requestYear] = $scope.pendingMonthValueRequests[requestYear].reverse();
              const start = $scope.pendingMonthValueRequests[requestYear].length - 1;
              for (let i = start; i >= 0; i--) {
                (function(req) {
                  req.request.$promise.then(
                    function(res) {
                      if (angular.isDefined(res)) {
                        if (
                          $scope.facility.selectedYear === req.requestYear &&
                            $scope.mrcState.activeTab === 'mrc.consumptions' &&
                            $scope.facility.FacilityId === req.facilityId
                        ) {
                          _.each(req.meterIds, function(meterId) {
                            if (angular.isDefined(res[meterId])) {
                              $scope.monthValues[meterId].values = res[meterId];
                            } else {
                              $scope.monthValues[meterId].values = [];
                            }
                            if ($scope.monthValues[meterId].convert) {
                              $scope.monthValues[meterId].values = mrcDataParser
                                .convertReadings($scope.monthValues[meterId].values, 0.001);
                            }
                            $scope.$broadcast('meterValuesReceived', {
                              id: meterId,
                              values: $scope.monthValues[meterId].values
                            });
                          });
                        }
                      } else {
                        utils.popUp('info', '', 'MRC.NO_VALUES_AVAILABLE', true);
                      }
                    },
                    function(err) {
                      // don't show the error popup if request was canceled by client
                      if (err.status >= 0) {
                        utils.popUp(
                          'error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METER_CONSUMPTIONS', true
                        );
                      }
                    }
                  ).finally(function() {
                    if ($scope.pendingMonthValueRequests[req.requestYear].length > 0) {
                      const reqIdx = _.findIndex($scope.pendingMonthValueRequests[req.requestYear], function(r) {
                        return r.meterId === req.meterId &&
                          r.facilityId === req.facilityId &&
                          r.requestYear === req.requestYear;
                      });
                      if (reqIdx >= 0) {
                        $scope.pendingMonthValueRequests[req.requestYear].splice(reqIdx, 1);
                      }
                    }
                    if ($scope.pendingMonthValueRequests[req.requestYear].length === 0) {
                      resolve();
                    }
                  });
                })($scope.pendingMonthValueRequests[requestYear][i]);
              }
            } else {
              resolve();
            }
          });
        };

        function hasInvalidNewIntervalValues() {
          let ret = false;
          _.each($scope.visibleMeters, function(meter) {
            if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
              ret = (
                angular.isUndefined(meter.newIntervalValue.value) ||
                  meter.newIntervalValue.value === null ||
                  angular.isUndefined(meter.newIntervalValue.fromDate) ||
                  meter.newIntervalValue.fromDate === null ||
                  angular.isUndefined(meter.newIntervalValue.toDate) ||
                  meter.newIntervalValue.toDate === null ||
                  meter.newIntervalValue.fromDate > meter.newIntervalValue.toDate ||
                  !meter.newIntervalValue.fromDateValid ||
                  !meter.newIntervalValue.toDateValid
              );
            }
            if (ret) {
              return false;
            }
          });
          return ret;
        }

        function hasNewIntervalValues() {
          let ret = false;
          _.each($scope.visibleMeters, function(meter) {
            if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
              ret = angular.isDefined(meter.newIntervalValue.value) && meter.newIntervalValue.value.length > 0;
            }
            if (ret) {
              return false;
            }
          });
          return ret;
        }

        function updateMassInputAvailability() {
          $scope.facility.intervalsTabsOpen = $scope.activeIntervalsTabs();
          if ($scope.facility.intervalsTabsOpen) {
            $scope.facility.canSaveAllMissingIntervals = checkCanSaveAllMissingIntervals();
          }
          if ($scope.mrcState.intervalInputSwitch) {
            $scope.facility.canSaveAllNewIntervals = hasNewIntervalValues() && !hasInvalidNewIntervalValues();
          }
        }

        function checkCanSaveAllMissingIntervals() {
          let result = false;
          _.each($scope.visibleMeters, function(meter) {
            if (
              meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION &&
                meter.tabs.intervals.active &&
                meter.isOpen
            ) {
              if (meter.canSaveMissingIntervals) {
                result = true;
                return false;
              }
            }
          });
          return result;
        }

        function checkForUnsavedReadings() {
          let ret = false;
          _.each($scope.visibleMeters, function(meter) {
            if (
              meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING &&
                angular.isDefined(meter.newReading) &&
                angular.isDefined(meter.newReading.readingValues)
            ) {
              _.each(meter.newReading.readingValues, function(readingValue) {
                if (
                  angular.isDefined(readingValue.newValue) &&
                    readingValue.newValue !== null &&
                    readingValue.newValue !== ''
                ) {
                  ret = true;
                  return false;
                }
              });
              if (ret) {
                return false;
              }
            }
          });
          return ret;
        }

        function checkForUnsavedIntervals() {
          let ret = false;
          _.each($scope.visibleMeters, function(meter) {
            if (
              meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION &&
                angular.isDefined(meter.newIntervalValue)
            ) {
              if (angular.isDefined(meter.newIntervalValue.value)
                && meter.newIntervalValue.value !== null
                && meter.newIntervalValue.value !== '') {
                ret = true;
                return false;
              }
            }
          });
          return ret;
        }

        $scope.$on('checkMissingIntervals', function() {
          if ($scope.facility.isOpen) {
            updateMassInputAvailability();
          }
        });

        $scope.$on('inputValueChanged', function(e, params) {
          if (params.name.startsWith('newIntervalInput')) {
            $scope.facility.hasUnsavedIntervals = checkForUnsavedIntervals();
            updateMassInputAvailability();
          }
          if (params.name.startsWith('inputField')) {
            updateMassInputAvailability();
          }
          if (params.name.startsWith('readingInput')) {
            $scope.facility.hasUnsavedReadings = checkForUnsavedReadings();
          }
        });
        $scope.$on('dateChanged', function() {
          updateMassInputAvailability();
        });

        $scope.$watchCollection('facility.invalidNewReadings', function(n) {
          let foundValid = false;
          _.each(n, function(hasInvalidValues) {
            if (!hasInvalidValues) {
              foundValid = true;
              return false;
            }
          });
          $scope.facility.canSaveAllNewReadings = foundValid;
        });

        $scope.getQuantities = function() {
          $scope.quantities = [];
          $scope.quantityInfo = [];
          $scope.monthValues = {};
          $scope.visibleMeters = [];
          $scope.unsavedValues = [];
          $scope.facility.hasUnsavedValues = false;
          const quantityIds = [];

          quantities.getQuantities().then(
            function(res) {
              if (res.length > 0) {
                $scope.quantityInfo = res;
              }
            }
          ).finally(function() {
            const meterRequest = mrcapi.getQuantitiesAndMeters($scope.facility.FacilityId);
            // Store request in an array so it can be cancelled elsewhere if needed
            $scope.pendingRequests.push(meterRequest);
            meterRequest.$promise.then(
              function(res) {
                if (res.length > 0) {
                  const includedQuantities = res.filter(item => !MrcConstants.EXCLUDED_QUANTITY_IDS.includes(item.QuantityId));
                  let counter = 0;
                  includedQuantities.forEach(function(item) {
                    quantityIds.push(item.QuantityId);
                    function initMeter(meter) {
                      if (
                        $scope.mrcState.activeTab === 'mrc.consumptions' && (
                          meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION || (
                            meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING &&
                              $scope.mrcState.listReadingInputMeters
                          ) || (
                            meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY &&
                              $scope.mrcState.listAutomaticMeters
                          )
                        )
                      ) {
                        $scope.monthValues[meter.Id] = {
                          values: [],
                          convert: false,
                          listIndex: counter,
                          QuantityId: item.QuantityId,
                          Id: meter.Id
                        };
                      }
                      if (meter.SubMeters.length > 0) {
                        _.each(meter.SubMeters, function(subMeter) {
                          initMeter(subMeter);
                        });
                      }
                      counter += 1;
                    }
                    const qInfo = _.find($scope.quantityInfo, function(q) {
                      return q.Key === item.QuantityName;
                    });
                    if (qInfo && qInfo.Name && qInfo.Units) {
                      item.localizedName = qInfo.Name;
                      item.Units = qInfo.Units;
                    }
                    function filterMeters(meter) {
                      _.remove(meter.SubMeters, function(s) {
                        return MrcConstants.EXCLUDED_QUANTITY_IDS.includes(s.QuantityId);
                      });
                      if (meter.SubMeters.length > 0) {
                        meter.SubMeters.forEach(subMeter => {
                          filterMeters(subMeter);
                        });
                      }
                    }
                    item.MainMeters.forEach(function(meter) {
                      filterMeters(meter);
                      initMeter(meter);
                    });
                  });
                  if ($scope.invalidMeterReadings.length > 0) {
                    applyInvalidReadings(includedQuantities).then(
                      function(extendedRes) {
                        $scope.invalidMeterReadings = [];
                        $scope.quantities = extendedRes;
                      }
                    );
                  } else {
                    $scope.quantities = includedQuantities;
                  }

                }
                $timeout(function() {
                  $scope.viewReady = true;
                  $scope.facility.controlsReady = true;
                }, 500);

              }
            ).catch(
              function(err) {
                // Don't show the error popup if request was cancelled by client
                if (err.status >= 0) {
                  utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METERING_TYPES', true);
                }
              }
            ).finally(function() {
              // Remove request from request array when it has completed
              const index = $scope.pendingRequests.indexOf(meterRequest);
              $scope.pendingRequests.splice(index, 1);
            });
          });
        };

        $scope.defineSameIntervalForEachMeter = function() {
          if (angular.isDefined($scope.commonInterval.fromDate) &&
            $scope.commonInterval.fromDate !== null &&
            angular.isDefined($scope.commonInterval.toDate) &&
            $scope.commonInterval.toDate !== null) {
            _.each($scope.visibleMeters, function(meter) {
              if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
                meter.newIntervalValue.fromDate = $scope.commonInterval.fromDate;
                meter.newIntervalValue.fromDateValid = true;
                meter.newIntervalValue.toDate = $scope.commonInterval.toDate;
                meter.newIntervalValue.toDateValid = true;
              }
            });
            $scope.facility.invalidDateValues = 0;
            updateMassInputAvailability();
          }
        };

        function getEarliestInputDatesForMeters(meterIds) {
          mrcapi.getEarliestInputDatesForMeters($scope.facility.FacilityId, meterIds).then(
            function(res) {
              if (res) {
                _.each(Object.keys(res), function(id) {
                  const meter = _.find($scope.visibleMeters, function(meter) {
                    return (
                      meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION ||
                        meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY
                    ) && id === meter.Id.toString();
                  });
                  if (meter) {
                    let date = new Date();
                    if (res[meter.Id] !== null) {
                      date = new Date(res[meter.Id]);
                    }
                    let endtime = new Date();
                    if (meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY) {
                      if (meter.AutomaticReadingStartTime) {
                        // If automatic meter has automatic reading start date defined,
                        // set enddate to day before it
                        endtime = new Date(meter.AutomaticReadingStartTime);
                        endtime.setDate(endtime.getDate() - 1);
                      } else {
                        // If automatic meter has no automatic reading start date defined,
                        // disable input
                        meter.inputDisabled = true;
                        return;
                      }
                    }
                    if (date.getTime() < endtime.getTime()) {
                      meter.newIntervalValue.fromDate = date;
                      meter.newIntervalValue.toDate = endtime;
                    } else {
                      meter.newIntervalValue.fromDate = endtime;
                      meter.newIntervalValue.toDate = endtime;
                    }
                  }
                });
              }
            },
            function() {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_UPDATING_METER_INFO', true);
            }
          ).finally(function() {
          });
        }

        $scope.$watch('mrcState.intervalInputSwitch', function(n, o) {
          if ($scope.facility.isOpen && n !== o) {
            if (n) {
              const switchToIntervalInputMode = function() {
                cancelPendingMonthValueRequests($scope.facility.selectedYear);
                $scope.$emit('removeControls');
                const ids = _.map(_.orderBy($scope.monthValues, ['listIndex'], ['asc']), 'Id');
                // Don't get earliest input dates if meters are currenly requested
                // registerVisibleMeter event will handle getting input dates for all meters
                if ($scope.pendingRequests.length === 0) {
                  getEarliestInputDatesForMeters(ids);
                }
              };
              if ($scope.unsavedValues.length > 0 && !$scope.facility.savingNewConsumptions) {
                mrcModals.showUnsavedWarning('MRC.CONSUMPTIONS.UNSAVED_VALUES').then(
                  function() {
                    $scope.unsavedValues = [];
                    $scope.facility.hasUnsavedValues = false;
                    switchToIntervalInputMode();
                  }
                );
              } else {
                switchToIntervalInputMode();
              }
            } else {
              $scope.$emit('removeControls');
              const timef = {
                from: new Date(Date.UTC($scope.facility.selectedYear, 0, 1, 0, 0, 0)),
                to: new Date(Date.UTC($scope.facility.selectedYear, 11, 31, 23, 59, 59))
              };
              const ids = _.map(_.orderBy($scope.monthValues, ['listIndex'], ['asc']), 'Id');
              $scope.getMonthValuesForMeters(timef, ids);
            }
          }
        });

        $scope.$watch('mrcState.listReadingInputMeters', function(n, o) {
          updateShownQuantities(n, o);
        });

        $scope.$watch('mrcState.listAutomaticMeters', function(n, o) {
          updateShownQuantities(n, o);
        });

        function updateShownQuantities(n, o) {
          if ($scope.facility.isOpen && n !== o) {
            cancelPendingMeterAndConsumptionRequests();
            cancelPendingMonthValueRequests($scope.facility.selectedYear);
            resetUnsaved();
            $scope.getQuantities();
          }
        }

        function resetUnsaved() {
          $scope.unsavedValues = [];
          $scope.facility.hasUnsavedValues = false;
          $scope.facility.hasUnsavedReadings = false;
          $scope.facility.hasUnsavedIntervals = false;
        }

        $scope.$on('$destroy', function() {
          resetUnsaved();
          selectedYearChangedUnbind();
        });

        $scope.$watch('mrcState.activeTab', function(n, o) {
          if (n !== o && $scope.facility.isOpen) {
            $scope.mrcState.activeTab = n;
            cancelPendingMeterAndConsumptionRequests();
            cancelPendingMonthValueRequests($scope.facility.selectedYear);
            resetUnsaved();
            $scope.getQuantities();
          }
        });

        $scope.$watch('facility.isOpen', function(n) {
          if (n) {
            $scope.getQuantities();
          } else {
            cancelPendingMeterAndConsumptionRequests();
            cancelPendingMonthValueRequests($scope.facility.selectedYear);
            resetUnsaved();
            $scope.viewReady = false;
          }
        });

        function setupConsumptionValuesForSaving(data) {
          const values = [];
          if (data.values.length > 0) {
            data.values.forEach(function(d) {
              let v = {};
              if (angular.isDefined(d.idx) && angular.isDefined(d.value)) {
                const timef = {
                  from: new Date(Date.UTC($scope.facility.selectedYear, d.idx, 1, 0, 0, 0)),
                  to: new Date(Date.UTC($scope.facility.selectedYear, d.idx + 1, 0, 0, 0, 0)) };
                if (data.properties.convert) {
                  d.value = mrcDataParser.convertReading(d.value, 1000);
                }
                v = { "Value": d.value, "ValueEndDate": timef.to.toJSON(), "ValueDate": timef.from.toJSON() };
              }
              values.push(v);
            });
          }
          return values;
        }

        function setupConsumptionIntervalValuesForSaving(data) {
          const values = [];
          if (data.values.length > 0) {
            data.values.forEach(function(d) {
              let v = {};
              if (angular.isDefined(d.timeFrame) && angular.isDefined(d.value)) {
                if (data.properties.convert) {
                  d.value = mrcDataParser.convertReading(d.value, 1000);
                }
                const end = moment.utc(d.timeFrame.to).toJSON();
                const beginning = moment.utc(d.timeFrame.from).toJSON();
                v = { "Value": d.value, "ValueEndDate": end, "ValueDate": beginning };
              }
              values.push(v);
            });
          }
          return values;
        }

        $scope.$on('monthlyValuesUpdate', function(e, data) {
          updateMonthlyValues(data, true);
        });

        /**
         * Sends updated array of monthly values to backend.
         *
         * @param {*}       data
         * @param {boolean} clearUnsaved
         */
        function updateMonthlyValues(data, clearUnsaved) {
          if (!_.isUndefined(data) && !_.isUndefined(data.properties) && !_.isUndefined(data.values)) {
            const groupFn = function(consumptionData) {
              return _.isNull(consumptionData.Value) || consumptionData.Value.length === 0 ? 'deleted' : 'updated';
            };

            const consumptionData = _.groupBy(setupConsumptionValuesForSaving(data), groupFn);

            handleMonthlyValuesUpdate(consumptionData, data, clearUnsaved);
          }
        }

        function handleFinally(data) {
          if ($scope.facility.savingNewConsumptions) {
            $scope.facility.savingNewConsumptions = $scope.facility.hasUnsavedValues;
          }

          if (!data.silent) {
            $timeout(function() {
              const timeFrame = {
                from: new Date(Date.UTC($scope.facility.selectedYear, 0, 1, 0, 0, 0)),
                to: new Date(Date.UTC($scope.facility.selectedYear, 11, 31, 23, 59, 59))
              };

              $scope
                .getMonthValuesForMeters(timeFrame, [data.properties.meterId])
                .finally(function() {
                  $scope.$broadcast('updateIntervalsListing', { meterId: data.properties.meterId });
                })
              ;
            }, 1000);
          }
          $scope.isSaveInProgress = false;
          $scope.isYearChangedDuringSave = false;
        }

        function handleMonthlyValuesUpdateError(data) {
          utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_CONSUMPTION_VALUES', true);

          if (data.silent) {
            return;
          }

          const broadcastError = function(value) {
            broadcastEvent('monthValueUpdateError', getBroadcastData(data, value));
          };

          _(data.values)
            .forEach(broadcastError)
            .value()
          ;
        }

        function clearUnsavedValues(meterId) {
          _.remove($scope.unsavedValues, { meterId: meterId });
          $scope.facility.hasUnsavedValues = $scope.unsavedValues.length > 0;
        }

        function handleMonthlyValuesUpdateSuccess(data, clearUnsaved, result) {
          // Do nothing if year selection has changed
          if ($scope.isYearChangedDuringSave) {
            return;
          }
          clearUnsaved ? clearUnsavedValues(data.properties.meterId) : angular.noop();

          // Check if returned value (saved value) is close enough to input
          const limit = 0.5; // Percentage limit
          for (let i = 0; i < Object.keys(result).length; i++) {
            if (data.values[i]) {
              let success = false;

              if (data.values[i].value === result[i].Value) {
                success = true;
              } else {
                const percentage = Math.abs((result[i].Value / data.values[i].value * 100) - 100);
                success = percentage <= limit;
              }

              success ?
                broadcastEvent('monthValueSaved', getBroadcastData(data, data.values[i], result[i])) :
                broadcastEvent('monthValueUpdateError', getBroadcastData(data, data.values[i]));
            }
          }
        }

        /**
         * Returns data object that can be used in monthValue* -event broadcasts.
         *
         * @param {*} data
         * @param {*} consumptionData
         * @param {*} [resultData]
         *
         * @returns {{
         *  meterId: number,
         *  cellIdx: number,
         *  value: number,
         *  targetYear: number
         * }}
         */
        function getBroadcastData(data, consumptionData, resultData) {
          resultData = resultData || {};

          return {
            meterId: data.properties.meterId,
            cellIdx: consumptionData.idx,
            value: _.isEmpty(resultData) ? consumptionData.value : resultData.Value,
            targetYear: angular.copy($scope.facility.selectedYear)
          };
        }

        /**
         * Simple wrapper for $scope.$broadcast so hopefully it's easier to replace someday
         *
         * @param {string} eventName
         * @param {*}      eventData
         */
        function broadcastEvent(eventName, eventData) {
          $scope.$broadcast(eventName, eventData);
        }

        function handleMonthlyValueDeleteSuccess(data, clearUnsaved) {
          clearUnsaved ? clearUnsavedValues(data.properties.meterId) : angular.noop();

          const broadcastSuccess = function(value) {
            broadcastEvent('monthValueSaved', getBroadcastData(data, value));
          };

          _(data.values)
            .forEach(broadcastSuccess)
            .value()
          ;
        }

        /**
         * Sends updated version of month's consumption data to backend.
         *
         * @param {*}       consumptionData
         * @param {*}       data
         * @param {boolean} clearUnsaved
         */
        function handleMonthlyValuesUpdate(consumptionData, data, clearUnsaved) {
          $scope.isSaveInProgress = true;
          const handleMonthlyValuesUpdateSuccessFn = _.partial(handleMonthlyValuesUpdateSuccess, data, clearUnsaved);
          const handleMonthlyValuesUpdateErrorFn = _.partial(handleMonthlyValuesUpdateError, data);
          const handleMonthlyValueDeleteSuccessFn = _.partial(handleMonthlyValueDeleteSuccess, data, clearUnsaved);
          const finallyFn = _.partial(handleFinally, data);

          // Handles monthly value deletion makes 1-n calls to backend.
          _.forEach(consumptionData.deleted, function(deletedConsumption) {
            mrcapi
              .deleteMonthlyConsumptionValues(
                $scope.facility.FacilityId, data.properties.meterId, deletedConsumption, data.silent
              )
              .then(handleMonthlyValueDeleteSuccessFn)
              .catch(handleMonthlyValuesUpdateErrorFn)
              .finally(finallyFn)
            ;
          });

          // Handles monthly value update makes one batch call for all updated values
          if (consumptionData.updated && consumptionData.updated.length > 0) {
            mrcapi
              .addMonthlyConsumptionValues(
                $scope.facility.FacilityId, data.properties.meterId, consumptionData.updated, data.silent
              )
              .then(handleMonthlyValuesUpdateSuccessFn)
              .catch(handleMonthlyValuesUpdateErrorFn)
              .finally(finallyFn)
            ;
          }
        }

        function saveNewIntervalValues(data) {
          return $q(function(resolve, reject) {

            const handleIntervalValuesUpdateError = function(err) {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_CONSUMPTION_VALUES', true);
              reject(err);
            };

            if (angular.isDefined(data) && angular.isDefined(data.properties) && angular.isDefined(data.values)) {
              const promises = [];
              let showQuery = false;
              _.each(data.values, function(value) {
                if (value.timeFrame.from.getTime() <= value.timeFrame.to.getTime()) {
                  promises.push($scope.isOverlappingInterval(value.timeFrame, data.properties.meterId));
                } else {
                  data.values.splice(data.values.indexOf(value), 1);
                }
              });
              $q.all(promises).then(function(res) {
                _.each(res, function(r) {
                  showQuery = r;
                  if (r) {
                    return false;
                  }
                });
              }, function(err) {
                reject(err);
              }).finally(function() {

                function doSave() {
                  const promises = [];
                  let ids = [];

                  const groupFn = function(consumptionData) {
                    return _.isNull(consumptionData.Value) ||
                      consumptionData.Value.length === 0 ? 'deleted' : 'updated';
                  };

                  const handleIntervalValuesUpdateSuccess = function() {
                    const meter = _.find($scope.visibleMeters, function(meter) {
                      return meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION &&
                        data.properties.meterId === meter.Id;
                    });

                    if (meter) {
                      meter.newIntervalValue.done = true;
                      meter.newIntervalValue.value = '';
                      $timeout(function() {
                        meter.newIntervalValue.done = false;
                      }, 2000);
                    }

                    ids.push(data.properties.meterId);
                  };

                  const consumptionData = _.groupBy(setupConsumptionIntervalValuesForSaving(data), groupFn);

                  // Handles monthly value deletion makes 1-n calls to backend.
                  _.forEach(consumptionData.deleted, function(deletedConsumption) {
                    const promise = mrcapi
                      .deleteMonthlyConsumptionValues(
                        $scope.facility.FacilityId, data.properties.meterId, deletedConsumption, data.silent
                      )
                      .then(handleIntervalValuesUpdateSuccess)
                      .catch(handleIntervalValuesUpdateError)
                    ;

                    promises.push(promise);
                  });

                  if (consumptionData.updated && consumptionData.updated.length > 0) {
                    const promise = mrcapi
                      .addConsumptionIntervalValues(
                        $scope.facility.FacilityId, data.properties.meterId, consumptionData.updated
                      )
                      .then(handleIntervalValuesUpdateSuccess)
                      .catch(handleIntervalValuesUpdateError)
                    ;

                    promises.push(promise);
                  }

                  $q.all(promises)
                    .finally(function() {
                      ids = _.uniq(ids);

                      if (ids.length > 0) {
                        getEarliestInputDatesForMeters(ids);
                      }

                      resolve(ids);
                    })
                  ;
                }

                if (showQuery) {
                  const start = data.values[0].timeFrame.from;
                  const end = data.values[0].timeFrame.to;
                  mrcModals.showConsumptionsOverwriteWarning(start, end).then(
                    function() {
                      reject();
                    },
                    function() {
                      doSave();
                    }
                  );
                } else {
                  doSave();
                }
              });
            }
          });
        }

        $scope.$on('newIntervalValuesUpdate', function(e, data) {
          saveNewIntervalValues(data).then(function() {
            // no action
          });
        });

        function saveMissingIntervalValues(data) {
          return $q(function(resolve, reject) {
            if (angular.isDefined(data) && angular.isDefined(data.properties) && angular.isDefined(data.values)) {
              const values = setupConsumptionIntervalValuesForSaving(data);
              if (values.length > 0) {
                mrcapi.addConsumptionIntervalValues($scope.facility.FacilityId, data.properties.meterId, values)
                  .then(function(res) {
                    const meter = _.find($scope.visibleMeters, function(m) {
                      return m.Id === data.properties.meterId;
                    });
                    const intervals = [];
                    _.each(res, function(obj) {
                      if (obj && obj.ValueDate && obj.ValueEndDate) {
                        const year = new Date(obj.ValueDate).getFullYear();
                        if (meter && angular.isDefined(meter.missingIntervals[year])) {
                          const interval = _.find(meter.missingIntervals[year], function(interval) {
                            return (
                              moment(interval.from).format('DD MM YYYY') ===
                                moment(obj.ValueDate).format('DD MM YYYY') &&
                                moment(interval.to).format('DD MM YYYY') ===
                                moment(obj.ValueEndDate).format('DD MM YYYY'));
                          });
                          if (interval) {
                            intervals.push(interval);
                          }
                        }
                      }
                    });
                    const last = intervals.length - 1;
                    _.each(intervals, function(interval, idx) {
                      interval.done = true;
                      $timeout(function() {
                        interval.done = false;
                        interval.disabled = true;
                        if (idx === last) {
                          $scope.$broadcast('updateIntervalsListing', { meterId: data.properties.meterId });
                        }
                      }, 2000);
                    });
                    const ids = [data.properties.meterId];
                    resolve(ids);
                  }).catch(function(err) {
                    utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_CONSUMPTION_VALUES', true);
                    reject(err);
                  });
              }
            }
          });
        }

        $scope.$on('missingIntervalValuesUpdate', function(e, data) {
          saveMissingIntervalValues(data).then(function(ids) {
            $timeout(function() {
              const timef = {
                from: new Date(Date.UTC($scope.facility.selectedYear, 0, 1, 0, 0, 0)),
                to: new Date(Date.UTC($scope.facility.selectedYear, 11, 31, 23, 59, 59))
              };
              $scope.getMonthValuesForMeters(timef, ids);
            }, 1000);
          });
        });

        const selectedYearChangedUnbind = $rootScope.$on('selectedYearChanged', function(e, params) {
          if ($scope.facility.isOpen && params.year !== $scope.facility.selectedYear) {
            const change = function() {
              // Indicate that year selection has changed if save is in progress
              if ($scope.isSaveInProgress) {
                $scope.isYearChangedDuringSave = true;
              }
              cancelPendingMonthValueRequests($scope.facility.selectedYear);
              $scope.facility.selectedYear = params.year;
              const timef = {
                from: moment().utc().year($scope.facility.selectedYear).startOf('year').toDate(),
                to: moment().utc().year($scope.facility.selectedYear).endOf('year').toDate()
              };
              const ids = _.map(_.orderBy($scope.monthValues, ['listIndex'], ['asc']), 'Id');
              $scope.getMonthValuesForMeters(timef, ids);
            };
            if ($scope.unsavedValues.length > 0 && !$scope.facility.savingNewConsumptions) {
              mrcModals.showUnsavedWarning('MRC.CONSUMPTIONS.UNSAVED_VALUES').then(
                function() {
                },
                function() {
                  $scope.unsavedValues = [];
                  $scope.facility.hasUnsavedValues = false;
                  change();
                }
              );
            } else {
              change();
            }
          }
        });

        $scope.$on('registerVisibleMeter', function(event, args) {
          if ($scope.facility.FacilityId === args.facilityId) {
            const isAdded = _.find($scope.visibleMeters, { Id: args.meter.Id });
            if (angular.isUndefined(isAdded)) {
              $scope.visibleMeters.push(args.meter);
              if ($scope.mrcState.activeTab === 'mrc.consumptions') {
                $scope.monthValues[args.meter.Id].convert = args.meter.convertValues;
                const ids = _.map(_.orderBy($scope.monthValues, ['listIndex'], ['asc']), 'Id');
                if ($scope.mrcState.intervalInputSwitch) {
                  getEarliestInputDatesForMeters(ids);
                } else {
                  if (Object.keys($scope.monthValues).length === $scope.visibleMeters.length) {
                    const timef = {
                      from: moment().utc().year($scope.facility.selectedYear).startOf('year').toDate(),
                      to: moment().utc().year($scope.facility.selectedYear).endOf('year').toDate()
                    };
                    $scope.getMonthValuesForMeters(timef, ids);
                  }
                }
              }
            } else {
              $scope.facility.hasUnsavedReadings = checkForUnsavedReadings();
            }
          }
        });

        $scope.$on('removeVisibleMeter', function(event, args) {
          if ($scope.facility.FacilityId === args.facilityId) {
            const removed = _.remove($scope.visibleMeters, { Id: args.meter.Id });
            if (angular.isDefined(removed) && removed.length > 0) {
              const found = _.find($scope.visibleMeters, { QuantityId: args.meter.QuantityId });
              if (angular.isUndefined(found)) {
                const quantity = _.find($scope.quantities, function(q) {
                  return q.QuantityId === args.meter.QuantityId;
                });
                if (angular.isDefined(quantity)) {
                  quantity.hasCumulativeMeters = false;
                }
              }
            }
          }
        });

        $scope.$watch('visibleMeters', function(n, o) {
          if (n !== o) {
            const isOpenArray = _.map(n, 'isOpen');
            if (_.includes(isOpenArray, true)) {
              $scope.isDatePickerDisabled = true;
            } else {
              $scope.isDatePickerDisabled = false;
            }
          }
        }, true);

        $scope.handlers = {
          refresh: $scope.getQuantities
        };

        function applyInvalidReadings(quantitiesAndMeters) {
          return $q(function(resolve) {
            $q.all(
              _.map(quantitiesAndMeters, function(quantity) {
                return applyInvalidReadingsRecursively(quantity.MainMeters);
              })
            ).then(
              function() {
                resolve(quantitiesAndMeters);
              }
            );
          });
        }

        function applyInvalidReadingsRecursively(meters) {
          return $q(function(resolve) {
            $q.all(
              _.map(meters, function(meterItem) {
                return applyInvalidReadingsToMeter(meterItem);
              })
            ).then(
              function() {
                resolve();
              }
            );
          });
        }

        function applyInvalidReadingsToMeter(meter) {
          return $q(function(resolve, reject) {
            const meterError = _.find($scope.invalidMeterReadings, { meterId: meter.Id });
            if (meterError) {
              meter.newReading = meterError.newReading;
              meter.validationErrors = meterError.validationErrors;

              checkSubMetersForErrors(meter, resolve, reject);
            } else {
              checkSubMetersForErrors(meter, resolve, reject);
            }
          });
        }

        function checkSubMetersForErrors(meter, resolve) {
          if (meter.SubMeters.length > 0) {
            applyInvalidReadingsRecursively(meter.SubMeters).then(
              function() {
                resolve();
              }
            );
          } else {
            resolve();
          }
        }

        function checkSubMeters(meter, valueArray, resolve, reject) {
          if (meter.SubMeters.length > 0) {
            getReadingsRecursively(meter.SubMeters, valueArray).then(
              function() {
                resolve({ validated: meter.Name });
              },
              function(recErr) {
                // How did we get here?
                $log.log('Failed getting submeter readings', recErr);
                reject(recErr);
              }
            );
          } else {
            resolve({ validated: meter.Name });
          }
        }

        function getMeterReadings(meter, valueArray) {
          return $q(function(resolve, reject) {
            mrcInputValidation.resetValidationErrors(meter);

            mrcInputValidation.validateMeterInput(meter).then(
              function(res) {
                if (res.success !== null && res.success) {
                  mrcDataParser.parseNewReadings(meter).then(function(parsed) {
                    valueArray.push(parsed);
                  }).finally(function() {
                    checkSubMeters(meter, valueArray, resolve, reject);
                  });
                } else {
                  checkSubMeters(meter, valueArray, resolve, reject);
                }
              },
              function() {
                const invalidReadings = {
                  meterId: meter.Id,
                  newReading: meter.newReading,
                  validationErrors: meter.validationErrors
                };

                $scope.invalidMeterReadings.push(invalidReadings);

                checkSubMeters(meter, valueArray, resolve, reject);
              }
            );
          });
        }

        function getReadingsRecursively(meters, valueArray) {
          return $q(function(resolve, reject) {
            $q.all(
              _.map(meters, function(meterItem) {
                return getMeterReadings(meterItem, valueArray);
              })
            ).then(
              function() {
                resolve({ success: true });
              },
              function(err) {
                // How did we get here?
                $log.log('Failed getting readings recursively', err);
                reject(err);
              }
            );
          });
        }

        function getAllReadings() {
          const metersAndReadings = [];

          return $q(function(resolve, reject) {
            $q.all(
              _.map($scope.quantities, function(quantity) {
                return getReadingsRecursively(quantity.MainMeters, metersAndReadings);
              })
            ).then(function() {
              resolve(metersAndReadings);
            }).catch(function(err) {
              $log.log('Failed getting location readings', err);
              reject(err);
            });
          });
        }

        $scope.saveAllConsumptions = function() {
          const data = {};
          $scope.unsavedValues.forEach(function(item) {
            if (!data[item.meterId]) {
              data[item.meterId] = { values: [], convert: item.convert };
            }
            let parsed = angular.copy(item.value);
            if (angular.isString(parsed)) {
              parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
            }
            data[item.meterId].values.push({ idx: item.cellIndex, value: parsed });
            data[item.meterId].properties = { meterId: item.meterId, convert: item.convert };
            data[item.meterId].silent = true;
          });
          $scope.facility.savingIndicatorVisible = true;
          $scope.facility.savingNewConsumptions = true;
          $timeout(function() {
            $scope.facility.savingIndicatorVisible = false;
          }, 3000);
          Object.keys(data).forEach(function(key) {
            updateMonthlyValues(data[key], true);
          });
        };

        $scope.saveAllReadings = function() {
          getAllReadings().then(
            function(resultArray) {
              if (resultArray.length > 0) {
                mrcapi.saveMeterValues($scope.facility.FacilityId, resultArray).then(function() {
                  utils.popUp('success', '', 'MRC.MEASUREMENTS_SAVED', true);
                  $scope.facility.hasUnsavedReadings = false;
                  $scope.getQuantities();
                }).catch(function() {
                  utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_MEASUREMENTS', true);
                });
              }
            },
            function(err) {
              // Should not come to here
              $log.log('Some validation(s) failed', err);
            }
          );
        };

        $scope.isOverlappingInterval = function(timeFrame, meterId) {
          return $q(function(resolve, reject) {
            const days = new Date(timeFrame.to.getFullYear(), timeFrame.to.getMonth() + 1, 0).getDate();
            const timef = {
              fromDate: new Date(
                Date.UTC(timeFrame.from.getFullYear(), timeFrame.from.getMonth(), 1, 0, 0, 0)
              ).toJSON(),
              toDate: new Date(
                Date.UTC(timeFrame.to.getFullYear(), timeFrame.to.getMonth(), days, 23, 59, 59)
              ).toJSON() };
            const options = { fromDate: timef.fromDate, toDate: timef.toDate, facilityId: $scope.facility.FacilityId };
            let overlapping = false;
            const meterIds = [meterId];
            mrcapi.getMonthValuesForMeters(options, meterIds).$promise.then(function(res) {
              if (angular.isDefined(res)) {
                if (res[meterId].length > 0) {
                  const promises = [];
                  _.each(res[meterId], function(value) {
                    if (value.IsCompleteMonth) {
                      overlapping = true;
                      return false;
                    } else {
                      const valueDate = new Date(value.ValueDate);
                      const month = {
                        from: new Date(Date.UTC(valueDate.getFullYear(), valueDate.getMonth(), 1, 0, 0, 0)).toJSON(),
                        to: new Date(Date.UTC(valueDate.getFullYear(), valueDate.getMonth() + 1, 0, 0, 0, 0)).toJSON()
                      };
                      promises.push(
                        mrcapi.getConsumptionRecordGapsForMeter($scope.facility.FacilityId, meterId, month)
                      );
                    }
                  });
                  if (promises.length > 0) {
                    $q.all(promises).then(
                      function(res) {
                        if (res.length > 0) {
                          res.forEach(function(gap) {
                            const start = new Date(gap.beginning);
                            const end = new Date(gap.end);
                            if (
                              end.getTime() >= timeFrame.from.getTime() ||
                                start.getTime() <= timeFrame.to.getTime()
                            ) {
                              overlapping = true;
                              return false;
                            }
                          });
                        }
                      },
                      function() {
                        utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_RECORD_GAPS', true);
                      }
                    );
                  }
                }
              } else {
                utils.popUp('info', '', 'MRC.NO_VALUES_AVAILABLE', true);
              }
            }).catch(function() {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METER_CONSUMPTIONS', true);
              reject();
            }).finally(function() {
              resolve(overlapping);
            });
          });
        };

        $scope.saveAllConsumptionValues = function() {
          const promises = [];
          const meterIds = [];
          _.each($scope.visibleMeters, function(meter) {
            if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
              const intervalValues = [];
              const props = { meterId: meter.Id, convert: meter.convertValues };
              _.each(meter.missingIntervalYears, function(year) {
                _.each(meter.missingIntervals[year], function(interval) {
                  if (
                    interval.value && interval.value.length > 0 &&
                      angular.isDefined(interval.from) &&
                      angular.isDefined(interval.to)
                  ) {
                    let parsed = angular.copy(interval.value);
                    if (angular.isString(parsed)) {
                      parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
                    }
                    const timef = { from: interval.from, to: interval.to };
                    intervalValues.push({ timeFrame: timef, value: parsed });
                  }
                });
              });
              if (intervalValues.length > 0) {
                promises.push(saveMissingIntervalValues({ properties: props, values: intervalValues }));
                meterIds.push(props.meterId);
              }
            }
          });
          if (promises.length > 0) {
            $q.all(promises).then(function() {
              $timeout(function() {
                const timef = {
                  from: moment().utc().year($scope.facility.selectedYear).startOf('year').toDate(),
                  to: moment().utc().year($scope.facility.selectedYear).endOf('year').toDate()
                };
                $scope.getMonthValuesForMeters(timef, meterIds).finally(function() {
                  if ($scope.facility.hasUnsavedValues) {
                    $scope.saveAllConsumptions();
                  }
                });
              }, 1000);
            });
          } else {
            if ($scope.facility.hasUnsavedValues) {
              $scope.saveAllConsumptions();
            }
          }
        };

        $scope.saveAllConsumptionIntervalValues = function() {
          const missingIntervalPromises = [];
          const newIntervalPromises = [];
          _.each($scope.visibleMeters, function(meter) {
            if (meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
              const intervalValues = [];
              let props = { meterId: meter.Id, convert: meter.convertValues };
              _.each(meter.missingIntervalYears, function(year) {
                _.each(meter.missingIntervals[year], function(interval) {
                  if (
                    interval.value && interval.value.length > 0 &&
                      angular.isDefined(interval.from) &&
                      angular.isDefined(interval.to)
                  ) {
                    let parsed = angular.copy(interval.value);
                    if (angular.isString(parsed)) {
                      parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
                    }
                    const timef = { from: interval.from, to: interval.to };
                    intervalValues.push({ timeFrame: timef, value: parsed });
                  }
                });
              });
              missingIntervalPromises.push(saveMissingIntervalValues({ properties: props, values: intervalValues }));
              if (
                !meter.isOpen && meter.newIntervalValue.value.length > 0 &&
                  angular.isDefined(meter.newIntervalValue.fromDate) &&
                  angular.isDefined(meter.newIntervalValue.toDate)
              ) {
                let parsed = angular.copy(meter.newIntervalValue.value);
                if (angular.isString(parsed)) {
                  parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
                }
                const timef = {
                  from: new Date(Date.UTC(
                    meter.newIntervalValue.fromDate.getFullYear(),
                    meter.newIntervalValue.fromDate.getMonth(),
                    meter.newIntervalValue.fromDate.getDate()
                  )),
                  to: new Date(Date.UTC(
                    meter.newIntervalValue.toDate.getFullYear(),
                    meter.newIntervalValue.toDate.getMonth(),
                    meter.newIntervalValue.toDate.getDate()
                  ))
                };
                const vals = [{ timeFrame: timef, value: parsed }];
                props = { meterId: meter.Id, convert: meter.convertValues };
                newIntervalPromises.push(saveNewIntervalValues({ values: vals, properties: props }));
              }
            }
          });
          $q.all(newIntervalPromises).then(function() {
            $q.all(missingIntervalPromises).then();
          });
        };

        $scope.facility.onSaveAll = function() {
          if ($scope.mrcState.activeTab === 'mrc.consumptions') {
            if ($scope.mrcState.intervalInputSwitch) {
              $scope.saveAllConsumptionIntervalValues();
            } else {
              $scope.saveAllConsumptionValues();
            }
          } else {
            $scope.saveAllReadings();
          }
        };

        $scope.showInfo = function(event) {
          event.stopPropagation();
          event.preventDefault();

          mrcModals.showFacilityInfo($scope.facility, $scope.properties).then();
        };

        $scope.showReport = () => {
          StateLocationService.openStateInNewTab('facilities.grid', {
            modalParams: {
              reportType: 'modal.report',
              reportParams: {
                facilityId: [$scope.facility.FacilityId]
              }
            }
          });
        };
      }
    ]
  };
}
