import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { AlarmClient, AlarmDto, AlarmTypeDto, AlarmTypeEnum, HookTypeDto, ILogDto } from '@enerkey/clients/alarm';
import { MeteringClient, Quantities } from '@enerkey/clients/metering';
import { getNumericEnumValues } from '@enerkey/ts-utils';

import { getHumanReadableCronFilter } from '../alarm-human-readable-cron-filter';
import {
  alarmExecutePresets,
  alarmTypeTranslation,
  etCurveAlarmTypes,
  hookTypeTranslation,
} from '../../constants/alarm.constant';
import { QuantityService } from './quantity.service';

const TEMPERATURE_ALARMTYPES: readonly AlarmTypeEnum[] = [
  AlarmTypeEnum.CoolingLimitMonth,
  AlarmTypeEnum.CoolingLimitDay,
  AlarmTypeEnum.CoolingLimitWeek,
  AlarmTypeEnum.WarmingLimitMonth,
  AlarmTypeEnum.WarmingLimitDay,
  AlarmTypeEnum.WarmingLimitWeek,
];

@Injectable({ providedIn: 'root' })
export class AlarmService {

  private readonly _alarmTypes$: Observable<AlarmTypeDto[]>;
  private readonly _hookTypes$: Observable<HookTypeDto[]>;
  private readonly _unitNames$: Observable<Record<number, string>>;

  public constructor(
    meteringClient: MeteringClient,
    private readonly translate: TranslateService,
    private readonly alarmClient: AlarmClient,
    private readonly quantityService: QuantityService
  ) {
    this._alarmTypes$ = this.alarmClient.getAllAlarmTypes().pipe(
      map(alarmTypes => alarmTypes.map(alarmType => {
        alarmType.name = this.getAlarmTypeName(alarmType.id);
        return alarmType;
      })),
      shareReplay(1),
      map(this.cloneAlarmTypes.bind(this))
    );

    this._hookTypes$ = this.alarmClient.getAllHookTypes().pipe(
      shareReplay(1),
      map(this.cloneHookTypes.bind(this))
    );

    this._unitNames$ = meteringClient.getUnits().pipe(
      map(units => units.toRecord(u => u.id, u => u.name)),
      shareReplay(1)
    );
  }

  public getAlarmTypeName(alarmType: AlarmTypeEnum | AlarmTypeDto): string {
    const alarmTypeEnum: AlarmTypeEnum = typeof alarmType === 'number'
      ? alarmType
      : alarmType?.id ?? undefined;

    return alarmTypeEnum in alarmTypeTranslation
      ? this.translate.instant(alarmTypeTranslation[alarmTypeEnum])
      : '';
  }

  public getAlarmTypeNames(): Record<AlarmTypeEnum, string> {
    return getNumericEnumValues(AlarmTypeEnum).toRecord(
      id => id,
      id => this.getAlarmTypeName(id)
    );
  }

  public getHookTypeName(translateHookType: HookTypeDto | number): Observable<string> {
    if (typeof translateHookType === 'number') {
      return this._hookTypes$.pipe(
        take(1),
        map(hookTypes => {
          const foundHookType = hookTypes.find(hookType => hookType.id === translateHookType);
          return this.translateHookType(foundHookType);
        })
      );
    } else {
      return of(this.translateHookType(translateHookType));
    }
  }

  public getAllAlarmTypes(): Observable<AlarmTypeDto[]> {
    return this._alarmTypes$;
  }

  public getAllHookTypes(): Observable<HookTypeDto[]> {
    return this._hookTypes$;
  }

  public isExecutePreset(cronValue: string): boolean {
    return alarmExecutePresets.some(preset => preset.cron === cronValue);
  }

  public getCronExecuteName(cron: string): string {
    const cronString = cron || '';
    const cronPreset = alarmExecutePresets.find(preset => preset.cron === cron);
    return cronPreset ? this.translate.instant(cronPreset.name) : cronString;
  }

  public getCronExecuteNames(): Record<string, string> {
    return alarmExecutePresets.reduce((cronExecuteNames, preset) => {
      cronExecuteNames[preset.cron] = this.translate.instant(preset.name);
      return cronExecuteNames;
    }, {} as Record<string, string>);
  }

  public getHumanReadableCronFilter(cron: string): string {
    const translations = this.getCronFilterTranslations();
    return getHumanReadableCronFilter(cron, translations);
  }

  public getUnitForAlarm(alarmTypeId: AlarmTypeEnum, quantityId: number | Quantities): Observable<string> {
    if (TEMPERATURE_ALARMTYPES.includes(alarmTypeId)) {
      return of('°C');
    }

    return this.quantityService.getQuantityById(quantityId).pipe(
      map(quantity => quantity.Units.Default.Unit)
    );
  }

  public getUnitForAlarmLog(log: ILogDto): Observable<string> {
    if (TEMPERATURE_ALARMTYPES.includes(log.alarmTypeId)) {
      return of('°C');
    }

    return this._unitNames$.pipe(
      take(1),
      switchMap(unitNames => {
        if (log.unitId in unitNames) {
          return of(unitNames[log.unitId]);
        }

        return this.quantityService.getQuantityById(log.quantityId).pipe(
          map(quantity => quantity.Units.Default.Unit)
        );
      })
    );
  }

  public getCronFilterTranslations(): Record<string, string> {
    return [
      'ALARM.CRON_FILTER.WHOLE_DAY',
      'ALARM.CRON_FILTER.EVERY_WEEKDAY',
      'ALARM.CRON_FILTER.EVERY_MONTH'
    ].reduce<Record<string, string>>((translations, key) => {
      translations[key] = this.translate.instant(key);
      return translations;
    }, {});
  }

  public isEtCurveAlarmType(alarmDto: AlarmDto): boolean {
    return etCurveAlarmTypes.includes(alarmDto.alarmTypeId);
  }

  private cloneAlarmTypes(alarmTypes: AlarmTypeDto[]): AlarmTypeDto[] {
    return alarmTypes.map(alarmType => new AlarmTypeDto(alarmType));
  }

  private cloneHookTypes(hookTypes: HookTypeDto[]): HookTypeDto[] {
    return hookTypes.map(hookType => new HookTypeDto(hookType));
  }

  private translateHookType(hookType: HookTypeDto): string {
    return hookType.systemCreated && hookTypeTranslation.has(hookType.name)
      ? this.translate.instant(hookTypeTranslation.get(hookType.name))
      : hookType.name;
  }
}
