<ngf-tabset
  [activeId]="reportType$ | async"
  [showCountLabel]="true"
  (tabChange)="tabChange($event)"
>
  <ngf-tab
    [title]="'FACILITIES.REPORT' | translate"
    [id]="ReportType.Period"
  >
    <ng-template ngfTabContent>
      <period-metergroup-report></period-metergroup-report>
    </ng-template>
  </ngf-tab>
</ngf-tabset>
